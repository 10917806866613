<template>
    
    <div v-if="preloader === true">
        <template>
            <v-dialog v-model="preloader" hide-overlay persistent width="300">
                <v-progress-linear indeterminate></v-progress-linear>
            </v-dialog>
        </template>
    </div>

    <div v-else>
        <v-card height="64px" width="100%"
            class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
            style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
            <v-card-title class="pl-0 ml-0">
                <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/campaign')">
                    Campaigns
                </p>
                <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                <span v-if="audienceType == 'All'"> <p class="breadcrumb-text" style="margin-top: 2px">All Campaigns</p> </span>
                <span v-if="audienceType == 'JOB_SEEKER'"> <p class="breadcrumb-text" style="margin-top: 2px">All Campaigns - Job Seeker</p> </span>
                <span v-if="audienceType == 'TEACHER'"> <p class="breadcrumb-text" style="margin-top: 2px">All Campaigns - VGOS</p> </span>
                <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </v-card-title>
            <div class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center" style="position: fixed; right: 0">
                <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
                    <v-hover>
                        <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg" />
                    </v-hover>
                </v-badge>

                <div>
                    <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
                </div>
                <div class="d-flex flex-column d-justify-evenly user-details">
                    <v-card-title class="ma-0 pa-0 elipsis">
                        {{ $store.state.userInfo.name }}
                    </v-card-title>
                    <v-card-subtitle class="ma-0 pa-0 elipsis">
                        {{ $store.state.userInfo.email }}
                    </v-card-subtitle>
                </div>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                            <v-icon class="">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-btn @click="logout">logout</v-btn>
                </v-menu>
            </div>
        </v-card>


        <div fluid class="padCampaignPage pb-8 pl-8">
            <v-dialog
            max-width="880px"
            class="cdz"
            v-model="UpdateCampaignDialog"
            center
          >
            <v-form ref="updateForm" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-2 pl-7"
                  >Update Campaign Date</v-card-title
                >
                <v-card-title>
                  <v-col>
                    <div v-if="campaignType=='JOB_SEEKER'" class="text-body-1 pl -2 pb-1">Screening End Date*</div>
                    <v-text-field
                      outlined
                      v-if="campaignType=='JOB_SEEKER'"
                      label="Start Date"
                      v-model="screeningEndDate"
                      rounded
                      class="rounded-xl elevation-0"
                      solo
                      flat
                      :rules="[screeningEndDateRule]"
                      type="date"
                    ></v-text-field>
                    <div class="text-body-1 pl -2 pb-1">{{campaignType=="MESTA_STUDENTS"? "Campaign End Date*" : "Mains End Date*"}}</div>
                    <v-text-field
                      outlined
                      label="Start Date"
                      v-model="computedEndDate"
                      rounded
                      class="rounded-xl elevation-0"
                      solo
                      flat
                      :rules="campaignType == 'MESTA_STUDENTS' ? [endDateRule] : [mainsEndDateRule]"
                      type="date"
                    ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    outlined
                    width="102px"
                    height="48px"
                    class="btn outline-btn pa-4"
                    @click="
                      () => {
                        UpdateCampaignDialog = false;
                      }
                    "
                    >Cancel</v-btn
                  >
                  <v-btn
                    width="102px"
                    height="48px"
                    rounded
                    class="btn primary-btn pa-4"
                    :loading="UpdateCampaignDialogLoading"
                    @click="camapignUpdate"
                    >Update</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
            <!-- QR Code Dialog -->
            <v-dialog v-model="qrDialog" class="cdz" persistent max-width="500px">
                <div v-if="qrDialog">
                <v-card>
                    <v-container fluid class="pa-8">
                    
                    <v-icon size="24" class="pt-4" @click="qrDialog=false">mdi-close</v-icon>

                    <v-card-text class="text-center d-flex flex-column justify-center align-center">
                        <div ref="qrDownloadRef" style="margin:8px; padding: 16px;">
                            <p class="text-h5 py-1 mx-1" style="font-weight: bold;">
                            {{ qrCampaignName }}
                            </p>
                            <VueQRCodeComponent style="display: flex; justify-content: center; margin-bottom: 8px; padding: 16px;" :text="qrCampaignLink" error-level="L"></VueQRCodeComponent>
                        </div>
                        <v-btn
                        class="btn primary-btn"
                        large
                        width="157px"
                        rounded
                        :loading="qrDownloadLoading"
                        @click="downloadQrCode">Download</v-btn>
                    </v-card-text>
                    </v-container>
                </v-card>

                </div>
            </v-dialog>

            <v-row justify="space-between" align="center" class="mt-4">
                <v-col cols="4" sm="4" md="4">
                    <div v-if="audienceType == 'All'" class="text-h6">
                        All Campaigns
                    </div>
                    <div v-if="audienceType == 'JOB_SEEKER'" class="text-h6">
                        All Campaigns - Job Seeker
                    </div>
                    <div v-if="audienceType == 'TEACHER'"class="text-h6">
                        All Campaigns - VGOS
                    </div>
                </v-col>
            </v-row>

            <v-row justify="space-between" class="mb-4 mt-0 mr-0 pr-4">
                <v-col cols="6">
                    <v-chip-group mandatory v-model="campaignStatus">
                    <v-chip outlined class="mr-4" :color="campaignStatus == 0 ? 'active-chip' : 'gray-04'" @click="getCampaigns('Live')">
                        LIVE ({{ getCount('Live') }})
                    </v-chip>
                    <v-chip outlined class="mr-4" :color="campaignStatus == 1 ? 'active-chip' : ''" @click="getCampaigns('Expired')">
                        CLOSED ({{ getCount('Expired') }})
                    </v-chip>
                    <v-chip outlined class="mr-4" :color="campaignStatus == 2 ? 'active-chip' : ''" @click="getCampaigns('Scheduled')">
                        SCHEDULED ({{ getCount('Scheduled') }})
                    </v-chip>
                    <v-chip outlined class="mr-4" :color="campaignStatus == 3 ? 'active-chip' : ''">
                        ARCHIVED ({{ 0 }})
                    </v-chip>
                </v-chip-group>

                </v-col>

                <v-col cols="6" class="pr-3 d-flex justify-end align-center">
                    <v-icon color="#06C270CC" class="mr-2 ml-4">mdi-circle</v-icon>
                    Assessed
                    <v-icon class="mr-2 ml-4" color="#1B72E8B2">mdi-circle</v-icon> In
                    progress
                    <v-icon class="mr-2 ml-4" color="#0000001F">mdi-circle</v-icon>
                    Yet to attempt

                </v-col>
            </v-row>

            <div class="d-flex flex-wrap pb-4 w-100" v-if="this.liveCampaigns.length != 0 && campaignStatus == 0">
                <!-- campaign card  -->
                <v-card elevation="1" width="32%" min-height="340px" class="px-5 pt-5 mr-4 mb-4 overflow-hidden cardBorder"
                    v-for="(data, i) in this.liveCampaigns" :key="i">
                    <div class="d-flex align-center justify-center" :class="data.audienceType == 'TEACHER'? 
                    'tiled-title-teacher' : ['MESTA_STUDENTS', 'DUMMY'].includes(data.audienceType) ?
                      'tiled-title-mesta-student' : 'tiled-title-job-seeker'">
                        {{ data.audienceType == "TEACHER" ? "VGOS" : data.audienceType == "JOB_SEEKER" ? "JOB SEEKER" : data.audienceType == "MESTA_STUDENTS" ? "MESTA" : "DUMMY" }}
                    </div>
                    <v-row>
                        <v-spacer></v-spacer>
                        <img width="28px" height="28px" @click="campaignUpdate(data)" class="cursor"
                            src="../assets/edit.svg" />
                    </v-row>

                    <v-chip color="#06C2700F" small pill>
                        <div class="c-dot mr-2"></div>

                        Live
                    </v-chip>

                    <div @click="goToUser(data.id, data.name, data.audienceType ,  data.schools)" class="cursor d-flex flex-column">
                        <v-card-title class="pa-0 d-flex-none campaign-title">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{ data.name }}
                                    </span>
                                </template>
                                <span>{{ data.name }}</span>
                            </v-tooltip>
                        </v-card-title>
                        <div class="my-2 text-subtitle-1">
                            <!-- Assessment title -->
                            <div class="d-flex flex-row w-100 flex-wrap pb-4 levels-chip-container">
                                <div v-for="(item, index) in data.levels" :key="index" class="w-fit">
                                    <v-chip small class="mr-2 mb-2" v-if="item != null" color="gray-05">
                                        {{ item }}
                                    </v-chip>
                                </div>
                            </div>
                            <!-- <div class="mt-4 center">
                <v-icon class="pr-2">mdi-notebook-outline</v-icon>
                {{ data.subjects.length }} Subjects
              </div> -->

              <div class="w-100 d-flex justify-space-between">
                  <v-tooltip bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.subjects.length }} Subjects
                      </span>
                    </template>
                    <span>{{ data?.subjects?.join(", ") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="data.audienceType == 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data?.assessments?.length || 0 }} Assessments
                      </span>
                    </template>
                    <div v-html="getNames(data?.assessments)"></div>
                  </v-tooltip>

                  <v-tooltip v-if="data.audienceType !== 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.schools.length }} Schools
                      </span>
                    </template>
                    <div v-html="getNames(data.schools)"></div>
                  </v-tooltip>
                </div>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex flex-row my-5 card-sub-text">
                            <v-icon class="text-medium-emphasis">mdi-calendar-blank-outline</v-icon>
                            <div class="d-flex flex-column ml-4">
                                <div>From</div>
                                <div class="c-date">{{ data.startDate }}</div>
                            </div>
                            <div class="ml-8 mr-8 d-flex align-center">-</div>
                            <div class="d-flex flex-column">
                                <div>Till</div>
                                <div class="c-date">
                                    <span v-if="data.audienceType == 'MESTA_STUDENTS'">{{ data.endDate }}</span>
                                    <span v-else>{{ data.mainsEndDate }}</span>
                                </div>
                            </div>
                        </div>


                        <v-card v-if="data.totalAudience > 0" class="d-flex flex-row pa-0 ma-0 rounded-0" width="100%" height="10px"
                            color="#D9D9D9" depressed elevation="0">
                            <v-card class="d-flex flex-row pa-0 ma-0 rounded-0" width="100%" height="10px" color="transparent"
                                depressed elevation="0">
                                <v-card class="d-flex flex-row pa-0 ma-0 justify-center rounded-0 elevation-0" 
                                :width="getPercentage(
                                    data.audienceAssessed,
                                    data.totalAudience
                                )
                                    " height="10px" color="#06C270">
                                    <div class="graph-value litegreen" 
                                        v-if="data.audienceAssessed != 0">
                                        <!-- {{
                      getPercentate(
                        data.assessed,
                        data.assessed + data.yet_to_attempt
                      )
                    }} -->
                                    {{ data.audienceAssessed }}
                                    </div>
                                </v-card>
                                <v-card class="d-flex flex-row pa-0 ma-0 justify-center rounded-0"
                                    :width="getPercentage(
                                            data.audienceInProgress,
                                            data.totalAudience
                                        )
                                        " height="10px" color="#1b72e8b3" elevation="0">
                                    <div class="graph-value liteblue"
                                        v-if="data.audienceInProgress!= 0"
                                    >
                                        {{ data.audienceInProgress }}
                                     </div>
                                </v-card>
                                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" :width="getPercentage(
                                    data.audienceInvited,
                                    data.totalAudience
                                )
                                    " height="10px" color="#D9D9D9">
                                    <div class="graph-value" v-if="data.audienceInvited != 0
                                        ">
                                        <!-- {{
                      getPercentate(
                        data.yet_to_attempt,
                        data.assessed + data.yet_to_attempt
                      )
                    }} -->
                                        {{
                                            data.audienceInvited
                                        }}
                                    </div>
                                </v-card>
                            </v-card>
                        </v-card>

                        <v-card v-if="data.totalAudience == 0" class="d-flex flex-row pa-0 ma-0 rounded-0" width="100%"
                            height="10px" color="#D9D9D9" depressed elevation="0">
                            <v-card class="d-flex flex-row pa-0 ma-0 rounded-0" width="100%" height="10px" color="transparent"
                                depressed elevation="0">
                            </v-card>
                        </v-card>
                        
                        <!-- temp display for audience count bar-->
                        <!-- <v-card
                        v-if="data.audienceType == 'MESTA_STUDENTS'"
                        class="d-flex flex-row pa-0 ma-0 rounded-0"
                        width="100%"
                        height="10px"
                        color="#D9D9D9"
                        depressed
                        elevation="0"
                        >
                        <v-card
                            class="d-flex flex-row pa-0 ma-0 rounded-0"
                            width="100%"
                            height="10px"
                            color="transparent"
                            depressed
                            elevation="0"
                        >
                        </v-card>
                        </v-card> -->
                        
                        <div class="w-100 d-flex fex-row justify-space-between mt-12">
                            <div class="w-50">
                                <v-btn v-if="isJobSeeker(data.audienceType)" @click="copyToClipboard(data.id, data.audienceType)" text
                                    color="#1B72E8"><v-icon>mdi-link-variant</v-icon></v-btn>

                                <v-btn v-if="isJobSeeker(data.audienceType)" :loading="data.isLoading" @click="generateQRCode(data)" text color="#1B72E8"><v-icon>mdi-qrcode</v-icon></v-btn>
                                
                            </div>
                            <div :class="data.audienceType == 'MESTA_STUDENTS' ? 'hide-proctoring-autocomplete-for-mesta w-50': 'w-50'">
                                <v-autocomplete label="Proctoring Type" @change='selectProctoring(i)'
                                    v-model="liveCampaigns[i].proctoringType" :items="proctoringType" outlined rounded
                                    dense :readonly="!$store.state.role.campaign.admin"></v-autocomplete>
                            </div>
                        </div>
                        <div class="text-center pb-4">
                          <v-btn
                          outlined
                            rounded                            
                            dark
                            class="btn outline-btn w-100"
                            v-if="$store.state.role.campaign.admin && data.audienceType != 'TEACHER'"
                            @click="openAddUserDialog(data.id)"
                          >
                            Invite Users
                          </v-btn>
                          <v-btn
                            outlined
                                rounded                                
                                dark
                                class="btn outline-btn w-100"
                                v-if="$store.state.role.campaign.admin && data.audienceType == 'TEACHER'"
                                @click="openAddTeacherDialog(data)"
                            >
                                Invite Teachers
                            </v-btn>
                        </div>

                    </div>
                </v-card>
                <!-- campaign card ending here           -->
            </div>






            <div class="d-flex flex-wrap pb-4 w-100" v-if="this.scheduledCampaigns.length > 0 &&
                this.scheduledCampaigns != undefined &&
                campaignStatus == 2 &&
                campaignStatus != undefined
                ">
                <!-- campaign card  -->
                <v-card elevation="1" width="32%" min-height="340px" class="pa-5 mr-4 mb-4 overflow-hidden cardBorder"
                    v-for="(data, i) in this.scheduledCampaigns" :key="i">
                    <div class="d-flex align-center justify-center" :class="data.audienceType == 'TEACHER'?
                    'tiled-title-teacher' : data.audienceType == 'MESTA_STUDENTS' ?
                      'tiled-title-mesta-student' : 'tiled-title-job-seeker'">
                        {{ data.audienceType == "TEACHER" ? "VGOS" : data.audienceType == "MESTA_STUDENTS" ? "MESTA" : "JOB SEEKER" }}
                    </div>
                    <v-row>
                        <v-spacer></v-spacer>
                        <img width="28px" height="28px" @click="campaignUpdate(data)" class="cursor"
                            src="../assets/edit.svg" />
                    </v-row>

                    <v-chip color="#06C2700F" small pill>
                        <!-- <div class="c-dot mr-2"></div> -->

                        Scheduled
                    </v-chip>

                    <div class="d-flex flex-column">
                        <v-card-title @click="goToUser(data.id, data.name, data.audienceType ,  data.schools)" class="pa-0 cursor d-flex-none campaign-title ">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{ data.name }}
                                    </span>
                                </template>
                                <span>{{ data.name }}</span>
                            </v-tooltip>
                        </v-card-title>
                        <div class="my-2 text-subtitle-1">
                            <!-- Assessment title -->
                            <div v-if="data.levels.length > 0 && data.levels.length <= 3"
                                class="d-flex flex-row w-100 flex-wrap pb-4">
                                <div v-for="(item, index) in data.levels" :key="index" class="w-fit">
                                    <v-chip small class="mr-2" v-if="item != null" color="#9cb9fba1">
                                        {{ item }}
                                    </v-chip>
                                </div>
                            </div>
                            <div v-if="data.levels.length > 0 && data.levels.length > 3"
                                class="d-flex flex-row w-100 flex-wrap pb-4">
                                <div class="w-fit">
                                    <v-chip small class="mr-2" color="#9cb9fba1">
                                        {{ data.levels[0] }}
                                    </v-chip>
                                </div>
                                <div class="w-fit">
                                    <v-chip small class="mr-2" color="#9cb9fba1">
                                        {{ data.levels[1] }}
                                    </v-chip>
                                </div>
                                <div class="w-fit">
                                    <v-chip small class="mr-2" color="#9cb9fba1">
                                        {{ data.levels[2] }}
                                    </v-chip>
                                </div>
                            </div>
                            <div class="w-100 d-flex justify-space-between">
                  <v-tooltip bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.subjects.length }} Subjects
                      </span>
                    </template>
                    <span>{{ data?.subjects?.join(", ") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="data.audienceType !== 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.schools.length }} Schools
                      </span>
                    </template>
                    <div v-html="getNames(data.schools)"></div>
                  </v-tooltip>
                </div>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex flex-row my-5 card-sub-text">
                            <v-icon class="text-medium-emphasis">mdi-calendar-blank-outline</v-icon>
                            <div class="d-flex flex-column ml-4">
                                <div>From</div>
                                <div class="c-date">{{ data.startDate }}</div>
                            </div>
                            <div class="ml-8 mr-8 d-flex align-center">-</div>
                            <div class="d-flex flex-column">
                                <div>Till</div>
                                <div class="c-date">
                                    <span v-if="data.audienceType == 'MESTA_STUDENTS'">{{ data.endDate }}</span>
                                    <span v-else>{{ data.mainsEndDate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="w-100% float-right mb-3" color="">
                            <v-btn color="red" icon @click="
                                deleteDialog = true;
                            selectedId = data.id;
                            ">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn v-if="isJobSeeker(data.audienceType)" @click="copyToClipboard(data.id, data.audienceType)" text
                                color="#1B72E8"><v-icon>mdi-link-variant</v-icon></v-btn>

                            <v-btn v-if="isJobSeeker(data.audienceType)" :loading="data.isLoading" @click="generateQRCode(data)" text color="#1B72E8"><v-icon>mdi-qrcode</v-icon></v-btn>
                        </div>
                    </div>

                </v-card>
                <!-- campaign card ending here           -->
            </div>






            <div class="d-flex flex-wrap pb-4 w-100" v-if="this.expiredCampaigns?.length > 0 && campaignStatus == 1">
                <!-- campaign card visited  -->
                <v-card elevation="1" width="32%" min-height="340px" class="pa-5 mr-4 mb-4 overflow-hidden cardBorder"
                    v-for="(data, i) in this.expiredCampaigns" :key="i">
                    <div class="d-flex align-center justify-center" :class="data.audienceType == 'TEACHER'? 
                    'tiled-title-teacher' : data.audienceType == 'MESTA_STUDENTS' ?
                      'tiled-title-mesta-student' : 'tiled-title-job-seeker'">
                        {{ data.audienceType == "TEACHER" ? "VGOS" : data.audienceType == "MESTA_STUDENTS" ? "MESTA" : "JOB SEEKER" }}
                    </div>
                    <v-row>
                        <v-spacer></v-spacer>
                        <img width="28px" height="28px" @click="campaignUpdate(data)" class="cursor"
                            src="../assets/edit.svg" />
                    </v-row>

                    <v-chip color="#06C2700F" small pill>
                        <!-- <div class="c-dot mr-2"></div> -->
                        Closed
                    </v-chip>

                    <div class="d-flex flex-column">
                        <v-card-title @click="goToUser(data.id, data.name, data.audienceType ,  data.schools)" class="pa-0 cursor d-flex-none campaign-title">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{ data.name }}
                                    </span>
                                </template>
                                <span>{{ data.name }}</span>
                            </v-tooltip>
                        </v-card-title>
                        <div class="my-2 text-subtitle-1">
                            <!-- Assessment title -->
                            <div v-if="data?.levels?.length > 0 && data.levels?.length <= 3"
                                class="d-flex flex-row w-100 flex-wrap pb-4">
                                <div v-for="(item, index) in data.levels" :key="index" class="w-fit">
                                    <v-chip small class="mr-2" v-if="item != null" color="#9cb9fba1">
                                        {{ item }}
                                    </v-chip>
                                </div>
                            </div>
                            <div v-if="data?.levels?.length > 0 && data?.levels?.length > 3"
                                class="d-flex flex-row w-100 flex-wrap pb-4">
                                <div class="w-fit">
                                    <v-chip small class="mr-2" color="#9cb9fba1">
                                        {{ data.levels[0] }}
                                    </v-chip>
                                </div>
                                <div class="w-fit">
                                    <v-chip small class="mr-2" color="#9cb9fba1">
                                        {{ data.levels[1] }}
                                    </v-chip>
                                </div>
                                <div class="w-fit">
                                    <v-chip small class="mr-2" color="#9cb9fba1">
                                        {{ data.levels[2] }}
                                    </v-chip>
                                </div>
                            </div>
                            <div class="w-100 d-flex justify-space-between">
                  <v-tooltip bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data?.subjects?.length }} Subjects
                      </span>
                    </template>
                    <span>{{ data?.subjects?.join(", ") }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="data.audienceType == 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data?.assessments?.length || 0 }} Assessments
                      </span>
                    </template>
                    <div v-html="getNames(data?.assessments)"></div>
                  </v-tooltip>
                  <v-tooltip v-if="data.audienceType !== 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data?.schools?.length }} Schools
                      </span>
                    </template>
                    <div v-html="getNames(data.schools)"></div>
                  </v-tooltip>
                </div>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex flex-row my-5 card-sub-text">
                            <v-icon class="text-medium-emphasis">mdi-calendar-blank-outline</v-icon>
                            <div class="d-flex flex-column ml-4">
                                <div>From</div>
                                <div class="c-date">{{ data.startDate }}</div>
                            </div>
                            <div class="ml-8 mr-8 d-flex align-center">-</div>
                            <div class="d-flex flex-column">
                                <div>Till</div>
                                <div class="c-date">
                                    <span v-if="data.audienceType == 'MESTA_STUDENTS'">{{ data.endDate }}</span>
                                    <span v-else>{{ data.mainsEndDate }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="w-100% float-right mb-2" color="">
              <v-btn @click="copyToClipboard(data.id)" text color="#1B72E8"
                ><span><v-icon>mdi-link-variant</v-icon>copy Link</span></v-btn
              >
            </div> -->
                        <v-card v-if="data.totalAudience > 0" class="d-flex flex-row pa-0 ma-0" width="100%" height="10px"
                            color="#D9D9D9" depressed elevation="0">
                            <v-card class="d-flex flex-row pa-0 ma-0" width="100%" height="10px" color="transparent"
                                depressed elevation="0">
                                <v-card class="d-flex flex-row pa-0 ma-0 justify-center rounded-0 elevation-0" :width="getPercentage(
                                    data.audienceAssessed,
                                    data.totalAudience
                                )
                                    " height="10px" color="#06C270">
                                    <div class="graph-value litegreen"
                                        v-if="data.audienceAssessed != 0"
                                    >
                                        {{ data.audienceAssessed }}
                                    </div>
                                </v-card>
                                <v-card class="d-flex flex-row pa-0 ma-0 justify-center rounded-0"
                                    :width="getPercentage(
                                            data.audienceInProgress,
                                            data.totalAudience
                                        )
                                        " height="10px" color="#1b72e8b3" elevation="0">
                                    <div class="graph-value liteblue"
                                        v-if="data.audienceInProgress != 0"
                                    >
                                        {{ data.audienceInProgress }}
                                    </div>
                                </v-card>
                                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" :width="getPercentage(
                                    data.audienceInvited,
                                    data.totalAudience
                                )
                                    " height="10px" color="#D9D9D9">
                                    <div class="graph-value" v-if="data.audienceInvited != 0
                                        ">
                                        <!-- {{
                    getPercentate(
                      data.yet_to_attempt,
                      data.assessed + data.yet_to_attempt
                    )
                  }} -->
                                        {{
                                            data.audienceInvited
                                        }}
                                    </div>
                                </v-card>
                            </v-card>
                        </v-card>

                        <v-card v-if="data.totalAudience == 0" class="d-flex flex-row pa-0 ma-0 rounded-0" width="100%"
                            height="10px" color="#D9D9D9" depressed elevation="0">
                            <v-card class="d-flex flex-row pa-0 ma-0" width="100%" height="10px" color="transparent"
                                depressed elevation="0">
                            </v-card>
                        </v-card>

                         <!-- temp display for audience count bar-->
                        <!-- <v-card v-if="data.audienceType == 'MESTA_STUDENTS'" class="d-flex flex-row pa-0 ma-0 rounded-0" width="100%"
                            height="10px" color="#D9D9D9" depressed elevation="0">
                            <v-card class="d-flex flex-row pa-0 ma-0" width="100%" height="10px" color="transparent"
                                depressed elevation="0">
                            </v-card>
                        </v-card> -->
                    </div>
                </v-card>
                <!-- campaign card ending here           -->
            </div>

            <!-- Notification Dialog -->
            <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
                <Notification @close-dialog="notificationDialog = false" />
            </v-dialog>

        </div>
        <AddTeachersToCampaign v-if="campaignId" :addTeacherDialog.sync="addTeacherDialog" :campaignName="campaignName" :campaignId="campaignId"/>
        <AddUserToCampaign :addUserDialog.sync="addUserDialog" :campaignId="campaignId"/>

    </div>
</template>

<script>
import FlipCard from "./FlipCard.vue";
import CampaignController from "@/controllers/CampaignController";
import LevelController from "@/controllers/LevelController";
import AssessmentController from "@/controllers/AssessmentController";

import SchoolController from "@/controllers/SchoolController";
import SkillsController from "@/controllers/SkillsController";

import SubjectController from "@/controllers/SubjectController";
import ClusterController from "@/controllers/ClusterController";
import UsersController from "@/controllers/UsersController";
import { collection, getDocs, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db,app } from "../firebase";

import AuthService from "../services/AuthService";
import chart1 from "./campaignChart/chart1";
import chart2 from "./campaignChart/chart2";
import chart3 from "./campaignChart/chart3";
import chart4 from "./campaignChart/chart4";
import chart5 from "./campaignChart/chart5";
import chart6 from "./campaignChart/chart6";
import chart7 from "./campaignChart/chart7";
import Papa from "papaparse";
import Notification from "./Notification";
import axios from "axios"
import VueQRCodeComponent from 'vue-qrcode-component'
import html2canvas from 'html2canvas';
import AddUserToCampaign from '../components/AddUserToCampaign.vue';
import AddTeachersToCampaign from "@/components/AddTeachersToCampaign.vue";
import { mapActions } from 'vuex';

export default {
    name: "campaignView",
    components: {
        Notification,
        FlipCard,
        chart1,
        chart2,
        chart3,
        chart4,
        chart5,
        chart6,
        chart7,
        VueQRCodeComponent,
        AddUserToCampaign,
        AddTeachersToCampaign
    },
    data() {
        return {
            addUserDialog:false,
            addTeacherDialog:false,
            qrDialog: false,
            qrCampaignLink:"",
            qrCampaignName:"",
            qrLoading: false,
            qrDownloadLoading: false,

            proctoringType: [{
                text: 'No Proctoring',
                value: 'NONE'
            },
            {
                text: 'Smart Proctoring',
                value: 'SMART'
            },
            {
                text: 'AI + Smart Proctoring',
                value: 'BOTH'
            }],
            notificationDialog: false,
            appliedFilter: false,
            deleteSuccessDialog: false,
            teachersInTotalCampaign: 0,
            jobSeekerInTotalCampaign: 0,
            inProgressCount: 0,
            isAccessedCount: 0,
            isUnaccessed: 0,
            isUnaccessedJS: 0,
            inProgressCountJS: 0,
            isAccessedCountJS: 0,
            tbdCount: 0,
            appraisalCount: 0,
            pipCount: 0,
            hiredCount: 0,
            rejectedCount: 0,
            teachersInSchoolTotalCampaign: 0,
            jobSeekerInSchoolTotalCampaign: 0,
            inProgressCountAtSchool: 0,
            isAccessedCountAtSchool: 0,
            isUnaccessedAtSchool: 0,
            inProgressCountJSAtSchool: 0,
            isAccessedCountJSAtSchool: 0,
            isUnaccessedJSAtSchool: 0,
            tbdCountAtSchool: 0,
            appraisalCountAtSchool: 0,
            pipCountAtSchool: 0,
            hiredCountAtSchool: 0,
            rejectedCountAtSchool: 0,
            panel: [0],
            disabled: false,
            readonly: false,
            isCampaignPublished: 0,
            snackbar: false,
            campaignStatus: null,
            clusterSchool: "",
            dialog: false,
            audienceType: "",
            countFlag: false,
            e1: 1,
            downlaodCamapigns: [],
            // seekerLiveCampaigns3Count: 0,
            // seekerScheduledCampaignsCount:0,
            // seekerExpiredCampaignsCount:0,
            // vgosLiveCampaignsCount:0,
            // vgosScheduledCampaignsCount:0,
            // vgosExpiredCampaignsCount:0,

            updateFlag: false,
            deleteDialog: false,
            successDialog: false,
            assessments: [],
            pageName: "All",
            schoolsData: [],
            selectedClusterFilters: [],
            selectedSchoolFilter: [],
            selectedLevelFlter: [],
            selectedSkillsFilter: [],
            search: "",
            searchAudeience: "",
            roleCluster: "",
            roleSchool: "",
            isLoading: true,
            currentStatus: "Live",
            viewUserIno: {},
            userPhoneNumber: "",
            userEmail: "",
            selectedId: null,
            startDate: "",
            screeningEndDate: "",
            singleSelect: false,
            skills: [],
            userFullName: "",
            userViewDialog: false,
            storeLiveCam: [],
            mainsEndDate: "",
            endDate:"",
            items: ["Daily", "Weekly", "Monthly"],
            firstRemainder: ["2 Min", "5 Min", "10 Min", "15 Min", "20 Min"],
            lastRemainder: ["2 Min", "5 Min", "10 Min", "15 Min", "20 Min"],
            schools: [],
            clusters: [],
            UpdateCampaignDialog: false,
            subjects: [],
            users: [],
            campaigns: {},
            allCampaigns: {},
            UpdateCampaignDialogLoading: false,
            resultTimeHrs: "",
            resultTimeMin: "",
            screeingEndTimeDialog: false,
            mainsEndTimeDialog: false,
            resultPublishTImeTimeDialog: false,
            allCampaignCount: 0,
            headers: [
                { text: "Name", value: "fullname" },
                { text: "Phone No", value: "personalInfo.contactInfo.phoneNumber" },
                { text: "Level", value: "levels" },
                { text: "Subjects", value: "subjects" },
                { text: "School Name", value: "schoolName" },
                { text: "Action", value: "actions" },
            ],
            startTimeDialog: false,
            levels: [],
            campaignStartTime: "08:00",
            startMM: "",
            screeningEndTime: "23:59",
            mainsEndTime: "23:59",
            isFilterLoading: false,
            endMM: "",
            editorConfig: {
                extraPlugins: "ckeditor_wiris",
            },
            selected: [],
            dLoading: false,
            // Temp dummy Data
            negativeMarking: "Yes",
            liveCampaigns: [],
            filterLiveCampaigns: [],
            filterScheduledCampaigns: [],
            filterExpiredCampaigns: [],
            filterDialog: false,
            preLiveCampaigns: [],
            coreSkillNoOfQuestionForScreening: 10,
            coreSkillWeightageForScreening: 50,
            coreSkillIsRequiredForScreening: true,
            coreSkillNoOfQuestionForMains: 20,
            coreSkillWeightageForMains: 50,
            coreSkillIsRequiredForMains: true,
            scheduledCampaigns: [],
            preScheduledCampaigns: [],
            expiredCampaigns: [],
            preExpiredCampaigns: [],
            countCampaignByLevel: {
                prePrimaryCount: 0,
                primaryCount: 0,
                secondaryCount: 0,
                seniorSecondaryCount: 0,
                middleSchoolCount: 0,
            },
            tag: false,
            campaignId: null,
            campaignName: null,
            campaignData: {
                campaignId: "",
                name: "",
                startDate: "",
                screeningEndDate: "",
                mainsEndDate: "",
                campaignStartTime: "",
                screeningEndTime: "",
                mainsEndTime: "",
                resultPublishTime: "10:00",
                publishPattern: {
                    frequency: "",
                    interval: {
                        day: "",
                        week: "",
                        month: "",
                    },
                },
                audienceType: "",
                clusters: [],
                skills: [],
                subjects: [],
                schools: [],
                levels: [],
                seekerLiveCampaignsCount: 0,
                seekerScheduledCampaignsCount: 0,
                seekerExpiredCampaignsCount: 0,
                vgosLiveCampaignsCount: 0,
                vgosScheduledCampaignsCount: 0,
                vgosExpiredCampaignsCount: 0,
                // audiance:this.selected,
                campaignConfigration: {
                    type: "DYNAMIC",
                    assessmentIds: [],
                    screeningConfigration: {
                        instruction: "",
                        subjectsDistribution: [],
                        skillsDistributions: [],
                        totalNoOfQuestions: 0,
                        levels: [],
                        adaptiveAssessment: "Yes",
                        negativeMarking: "No",
                        shuffleQuestions: "No",
                        passingCriteria: 60,
                        timeUpFirstRemainder: 0,
                        timeUpLastRemainder: 0,
                    },
                    mainsConfigration: {
                        instruction: "",
                        subjectsDistribution: [],
                        skillsDistributions: [],
                        totalNoOfQuestions: 0,
                        levels: [],
                        adaptiveAssessment: "Yes",
                        negativeMarking: "No",
                        shuffleQuestions: "No",
                        passingCriteria: 60,
                        timeUpFirstRemainder: 0,
                        timeUpLastRemainder: 0,
                    },
                },
            },
            subjectDistributions: [
                {
                    subjectName: "",
                    noOfQuestion: this.coreSkillNoOfQuestionForScreening,
                    weightage: this.coreSkillWeightageForScreening,
                    isRequired: this.coreSkillIsRequiredForScreening,
                },
            ],
            skillsDistributions: [
                {
                    skillName: "",
                    noOfQuestion: 0,
                    weightage: null,
                    isRequired: false,
                },
            ],
            subjectDistributionsForMains: [
                {
                    subjectName: "",
                    noOfQuestion: this.coreSkillNoOfQuestionForMains,
                    weightage: this.coreSkillWeightageForMains,
                    isRequired: this.coreSkillIsRequiredForMains,
                },
            ],
            skillsDistributionsForMains: [
                {
                    skillName: "",
                    noOfQuestion: 0,
                    weightage: null,
                    isRequired: false,
                },
            ],

            rules: {
                required: (value) => !!value || "Field is required",
            },
            preloader: true,
            chartSwitch: "",
            campaignStats: {
                totalCampaigns: 0,
                totalTeachersCampaign: 0,
                totalJobSeekersCampaign: 0,
            },
            outcomeTeachers: {
                assessedTeacher: 0,
                unAssessedTeacher: 0,
                inProgressTeacher: 0,
            },
            outcomeJobSeekers: {
                assessedJobSeeker: 0,
                unAssessedJobSeeker: 0,
                inProgressJobSeeker: 0,
            },
            recruitmentJobSeeker: {
                countHired: 0,
                countRejected: 0,
            },
            teacherAppraisal: {
                countAppraised: 0,
                countOnPip: 0,
                countTbd: 0,
            },

            schoolPrincipal: {
                totalInvited: 0,
                totalScreeningStarted: 0,
                totalScreeningPassed: 0,
                totalScreeningFailed: 0,
                totalMainsStarted: 0,
                totalMainsPassed: 0,
                totalMainsFailed: 0,
            },
            clusterPrincipal: {
                totalInvited: 0,
                totalScreeningStarted: 0,
                totalScreeningPassed: 0,
                totalScreeningFailed: 0,
                totalMainsStarted: 0,
                totalMainsPassed: 0,
                totalMainsFailed: 0,
            },

            responsibleTeachingAt: {},
            teachingSubjects: {},
            campaignType:"",
        };
    },
    computed: {
        dateRule() {
            return (value) => {
                if (!value) {
                    return "Please enter campaign start date";
                }
                if (this.isCampaignPublished == 0) {
                    const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
                    if (!regex.test(value)) {
                        return "Please enter a valid date (YYYY-MM-DD)";
                    }
                    const selectedDate = new Date(value);
                    const currentDate = new Date();
                    if (selectedDate <= currentDate) {
                        return "Campaign Start Date must be greater than current date";
                    }
                }

                return true;
            };
        },
        screeningEndDateRule() {
            return (value) => {
                if (!value) {
                    return "Screening end date is required";
                } else if (this.startDate == "") {
                    return "Please enter campaign start date firstly";
                } else {
                    const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
                    if (!regex.test(value)) {
                        return "Please enter a valid date (YYYY-MM-DD)";
                    }
                    const startDateObj = new Date();
                    const endDateObj = new Date(value);
                    if (startDateObj >= endDateObj) {
                        return "Campaign end date must be greater than current date";
                    }
                }
                return true;
            };
        },
        mainsEndDateRule() {
            return (value) => {
                if (!value) {
                    return "Mains end date is required";
                } else if (this.screeningEndDate == "") {
                    return "Please enter screening end  date firstly";
                } else {
                    const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
                    if (!regex.test(value)) {
                        return "Please enter a valid date (YYYY-MM-DD)";
                    }
                    const startDateObj = this.campaignType == "TEACHER" ? new Date() : new Date(this.screeningEndDate);
                    const endDateObj = new Date(value);
                    if (startDateObj >= endDateObj) {
                        return (this.campaignType == "TEACHER" ?
                        "Campaign end date must be greater than current date" :"Campaign mains end date must be greater than campaign screening end date");
                    }
                }
                return true;
            };
        },
        endDateRule(){
            return (value) => {
                if (!value) {
                    return "Mains end date is required";
                } else {
                    const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
                    if (!regex.test(value)) {
                        return "Please enter a valid date (YYYY-MM-DD)";
                    }
                    // const startDateObj = this.campaignType == "TEACHER" ? new Date() : new Date(this.screeningEndDate);
                    const endDateObj = new Date(value);
                    const startDateObj = new Date(this.startDate);
                    if (startDateObj >= endDateObj) {
                        return "Campaign end date must be greater than start date";
                    }
                }
                return true;
            };
        },
        computedEndDate: {
            get() {
                return this.campaignType === "MESTA_STUDENTS" ? this.endDate : this.mainsEndDate;
            },
            set(value) {
                if (this.campaignType === "MESTA_STUDENTS") {
                this.endDate = value;
                } else {
                this.mainsEndDate = value;
                }
            }
        }
    },
    watch: {
        search(newValue) {
            // this.searchBool=true
            // this.pageSize = this.options.itemsPerPage;
            // this.page = this.options.page;
            // this.options.page=1;
            if (newValue == "" || newValue == null) {
                this.liveCampaigns = this.filterLiveCampaigns;
                this.scheduledCampaigns = this.filterScheduledCampaigns;
                this.expiredCampaigns = this.filterExpiredCampaigns;
            } else {
                this.searchData(newValue);
            }
        },
        selectedClusterFilters(newValue) {
            console.log(newValue);
            if (newValue.length == 0 || newValue == null) {
                this.schoolsData = [];
                console.log("newValue1");
            } else {
                this.getClusterSchoolForFilter(newValue);
                console.log("newValue2");
            }
        },
    },
    methods: {
    ...mapActions(['clearPaginationStates']),
    isJobSeeker(audienceType = '') {
      return audienceType?.toLowerCase() === "job_seeker";
    },
    openAddUserDialog(campaignId){
      this.campaignId = campaignId
      this.addUserDialog = true;
    },
    async openAddTeacherDialog({id, name}){      
        this.addTeacherDialog = true;
        this.campaignId = id
        this.campaignName = name
    },
    getNames(name) {
      let names = "";
      for (let i = 0; i < name?.length; i++) {
        names = names + name[i] + "<br>";
      }
      return names;
    },
        async selectProctoring(i) {
            // console.log("Proctoring Type...?", this.liveCampaigns[i])
            console.log("campaign id..?", this.liveCampaigns[i].id)
            const campRef = doc(db, "campaigns", this.liveCampaigns[i].id);
            await updateDoc(campRef, {
                proctoringType: this.liveCampaigns[i].proctoringType
            })
        },
        downlaodCamapignsFun() {
            const fileName = 'campaigns.csv';
            this.downloadCSV(this.downlaodCamapigns, fileName);
        },
        async downloadCSV(csvData, fileName) {

            const csv = Papa.unparse(csvData);


            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

            if (navigator.msSaveBlob) {

                navigator.msSaveBlob(blob, fileName);
            } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {

                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", fileName);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },


        async filterCampaign(
            selectSchool,
            selectClusters,
            selectLevels,
            selectSkills
        ) {
            this.isFilterLoading = true;
            const response = await CampaignController.filterCampaign(
                selectSchool,
                selectClusters,
                selectLevels,
                selectSkills
            );

            if (response.status == 200) {
                console.log("campaign", response);
                this.allCampaignCount = response.data.count;
                this.campaigns = response.data.campaigns;
                this.filterDialog = false;
                this.isFilterLoading = false;

                if (this.campaigns.length != 0) {
                    this.preLiveCampaigns = this.campaigns.liveCampaign;
                    // console.log("campaign", this.liveCampaigns);
                    this.preScheduledCampaigns = this.campaigns.scheduledCampaign;
                    this.preExpiredCampaigns = this.campaigns.expiredCampaign;
                }
                this.preloader = false;
            } else {
                alert(response.data.error);
                this.isFilterLoading = false;
            }
            this.getTypeCampaign("All");
        },

        clearFilter() {
            this.appliedFilter = false;
            this.selectedClusterFilters = [];
            this.selectedSchoolFilter = [];
            this.selectedSkillsFilter = [];
            this.selectedLevelFlter = [];
            this.getCampaigns();
            // this.filterDialog = false;
        },
        deleteChip(itemNeedToRemove, array) {
            for (let i = 0; i < array.length; i += 1) {
                if (array[parseInt(i, 10)] === itemNeedToRemove) {
                    array.splice(i, 1);
                }
            }
        },
        searchData(search) {
            // console.log("search", search);
            var liveSearch = this.filterLiveCampaigns;
            var scheduleSearch = this.filterScheduledCampaigns;
            var expiredSearch = this.filterExpiredCampaigns;
            this.liveCampaigns = liveSearch.filter((item) =>
                new RegExp(search, "i").test(item.name)
            );
            this.scheduledCampaigns = scheduleSearch.filter((item) =>
                new RegExp(search, "i").test(item.name)
            );
            this.expiredCampaigns = scheduleSearch.filter((item) =>
                new RegExp(search, "i").test(item.name)
            );
        },
        async setuppForClusterAndSchool() {
            try {
                const dashboardRef = doc(db, "dashboards", "management-dashboard-001");
                const schoolsRef = collection(dashboardRef, "schools");
                // console.log("schoolsRef:>>>", schoolsRef);

                onSnapshot(schoolsRef, (snapshot) => {
                    snapshot.forEach((schoolDoc) => {
                        const schoolData = schoolDoc.data();

                        // const schoolName = schoolData.name;
                        // const schoolCluster = schoolData.cluster;
                        //for cluster principal
                        if (
                            schoolData.cluster === this.roleCluster &&
                            this.roleSchool === "All"
                        ) {
                            // console.log("filter", schoolData);
                            this.clusterPrincipal.totalInvited =
                                this.clusterPrincipal.totalInvited + schoolData.totalInvited;
                            this.clusterPrincipal.totalScreeningStarted =
                                this.clusterPrincipal.totalScreeningStarted +
                                schoolData.totalScreeningStarted;
                            this.clusterPrincipal.totalScreeningPassed =
                                this.clusterPrincipal.totalScreeningPassed +
                                schoolData.totalScreeningPassed;
                            this.clusterPrincipal.totalScreeningFailed =
                                this.clusterPrincipal.totalScreeningFailed +
                                schoolData.totalScreeningFailed;
                            this.clusterPrincipal.totalMainsFailed =
                                this.clusterPrincipal.totalMainsFailed +
                                schoolData.totalMainsFailed;
                            this.clusterPrincipal.totalMainsPassed =
                                this.clusterPrincipal.totalMainsPassed +
                                schoolData.totalMainsPassed;
                            this.clusterPrincipal.totalMainsStarted =
                                this.clusterPrincipal.totalMainsStarted +
                                schoolData.totalMainsStarted;
                        } else if (
                            schoolData.name === this.roleSchool &&
                            schoolData.cluster === this.roleCluster
                        ) {
                            this.schoolPrincipal.totalInvited =
                                this.schoolPrincipal.totalInvited + schoolData.totalInvited;
                            this.schoolPrincipal.totalScreeningPassed =
                                this.schoolPrincipal.totalScreeningPassed +
                                schoolData.totalScreeningPassed;
                            this.schoolPrincipal.totalScreeningFailed =
                                this.schoolPrincipal.totalScreeningFailed +
                                schoolData.totalScreeningFailed;
                            this.schoolPrincipal.totalScreeningStarted =
                                this.schoolPrincipal.totalScreeningStarted +
                                schoolData.totalScreeningStarted;
                            this.schoolPrincipal.totalMainsFailed =
                                this.schoolPrincipal.totalMainsFailed +
                                schoolData.totalMainsFailed;
                            this.schoolPrincipal.totalMainsPassed =
                                this.schoolPrincipal.totalMainsPassed +
                                schoolData.totalMainsPassed;
                            this.schoolPrincipal.totalMainsStarted =
                                this.schoolPrincipal.totalMainsStarted +
                                schoolData.totalMainsStarted;
                        }
                        // console.log("result>>",this.clusterPrincipal);
                        // console.log("reslt school>>",this.schoolPrincipal);

                        // console.log("School", schoolData);
                    });
                });
            } catch (error) {
                console.error("Error occurred:", error);
            }
        },

        async setupSnapshotForAssessingUsers() {
            const dashboardRef = doc(db, "dashboards", "management-campaign-001");
            // console.log("dashboardref>>>", dashboardRef);
            onSnapshot(dashboardRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.data();
                    // console.log("chart>>>", data.assessedTeacher);
                    if (this.$store.state.role.clusterName == "All Clusters") {
                        this.outcomeTeachers.assessedTeacher =
                            data.assessedTeacher == null ? 0 : data.assessedTeacher;
                        this.outcomeTeachers.inProgressTeacher =
                            data.inProgressTeacher == null ? 0 : data.inProgressTeacher;
                        this.outcomeTeachers.unAssessedTeacher =
                            data.unAssessedTeacher == null ? 0 : data.unAssessedTeacher;
                        // for job seekers
                        this.outcomeJobSeekers.assessedJobSeeker =
                            data.assessedJobSeeker == null ? 0 : data.assessedJobSeeker;
                        this.outcomeJobSeekers.inProgressJobSeeker =
                            data.inProgressJobSeeker == null ? 0 : data.inProgressJobSeeker;
                        this.outcomeJobSeekers.unAssessedJobSeeker =
                            data.unAssessedJobSeeker == null ? 0 : data.unAssessedJobSeeker;

                        // seeker
                        this.recruitmentJobSeeker.countHired =
                            data.jobSeekerHiredCount == null ? 0 : data.jobSeekerHiredCount;
                        this.recruitmentJobSeeker.countRejected =
                            data.jobSeekerRejectedCount == null
                                ? 0
                                : data.jobSeekerRejectedCount;

                        // teacher
                        this.teacherAppraisal.countAppraised =
                            data.teacherAppraisedrCount == null
                                ? 0
                                : data.teacherAppraisedrCount;
                        this.teacherAppraisal.countOnPip =
                            data.teacherOnPipCount == null ? 0 : data.teacherOnPipCount;
                        this.teacherAppraisal.countTbd =
                            data.teacherTbdCount == null ? 0 : data.teacherTbdCount;
                    }
                }
            });
        },
        // cluster level started ....
        async clusterLevelTotalCampaignSnapshot() {
            const campaignRef = collection(db, "campaigns");
            // console.log("heyyylooooo");
            onSnapshot(campaignRef, (snapshot) => {
                snapshot.docs.forEach(async (doc) => {
                    // if teacher, run this
                    //console.log("heyyylooooopalvii");
                    if (
                        doc.data().audienceType?.toLowerCase() === "teacher" &&
                        doc.data().clusters.includes(this.$store.state.role.clusterName)
                    ) {
                        try {
                            // console.log("heyyylooooopalviiSabharwal");
                            this.teachersInTotalCampaign += 1;
                        } catch (error) {
                            console.error("Error fetching audience collection:", error);
                        }
                    }
                });
                if (
                    this.$store.state.role.clusterName != "All Clusters" &&
                    this.$store.state.role.schoolName == "All"
                ) {
                    this.campaignStats.totalTeachersCampaign =
                        this.teachersInTotalCampaign;
                }
            });
        },

        async clusterLevelTotalCampaignJobSeekerSnapshot() {
            const campaignRef = collection(db, "campaigns");
            //console.log("heyyylooooo");
            onSnapshot(campaignRef, (snapshot) => {
                snapshot.docs.forEach(async (doc) => {
                    // if teacher, run this
                    //console.log("heyyylooooopalvii");
                    if (
                        doc.data().audienceType?.toLowerCase() === "job_seeker" &&
                        doc.data().clusters.includes(this.$store.state.role.clusterName)
                    ) {
                        try {
                            //console.log("heyyylooooopalviiSabharwal");
                            this.jobSeekerInTotalCampaign += 1;
                        } catch (error) {
                            console.error("Error fetching audience collection:", error);
                        }
                    }
                });
                if (
                    this.$store.state.role.clusterName != "All Clusters" &&
                    this.$store.state.role.schoolName == "All"
                ) {
                    this.campaignStats.totalJobSeekersCampaign =
                        this.jobSeekerInTotalCampaign;
                    this.campaignStats.totalCampaigns =
                        this.jobSeekerInTotalCampaign + this.teachersInTotalCampaign;

                    //console.log("count...>>>>>", this.campaignStats.totalCampaigns);
                }
            });
        },

        async setupSnapshotForTotalCampaign() {
            const dashboardRef = doc(db, "dashboards", "management-dashboard-001");

            onSnapshot(dashboardRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.data();

                    if (this.$store.state.role.clusterName == "All Clusters") {
                        this.campaignStats.totalCampaigns =
                            this.campaignStats.totalJobSeekersCampaign +
                            this.campaignStats.totalTeachersCampaign;
                        this.campaignStats.totalCampaigns =
                            data.totalCampaigns == null ? 0 : data.totalCampaigns;
                        this.campaignStats.totalTeachersCampaign =
                            data.totalTeachersCampaign == null
                                ? 0
                                : data.totalTeachersCampaign;
                        this.campaignStats.totalJobSeekersCampaign =
                            data.totalJobSeekersCampaign == null
                                ? 0
                                : data.totalJobSeekersCampaign;
                    }
                    // console.log("data>>", this.campaignStats);
                    //console.log("Checkpoint 001: Total Schools :", this.totalSchools);
                } else {
                    // console.log("Document does not exist");
                }
            });
        },

        async clusterLevelAccessedUnaccessedSnapshot() {
            const dashboardRef = collection(db, "users");
            // console.log("called");

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "teacher" &&
                        //userData.campaignStatus !== undefined &&
                        userData.cluster === this.$store.state.role.clusterName
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (
                                lastStatus === "INVITED" ||
                                lastStatus === "SCREENING STARTED" ||
                                lastStatus === "MAINS STARTED" ||
                                lastStatus === "SCREENING PASSED"
                            ) {
                                this.inProgressCount += 1;
                            } else if (
                                lastStatus === "SCREENING FAILED" ||
                                lastStatus === "MAINS PASSED" ||
                                lastStatus === "MAINS FAILED"
                            ) {
                                this.isAccessedCount += 1;
                            }
                        } else {
                            this.isUnaccessed += 1;
                        }
                    }
                });
                if (this.$store.state.role.clusterName !== "All Clusters") {
                    this.outcomeTeachers.assessedTeacher = this.isAccessedCount;
                    this.outcomeTeachers.inProgressTeacher = this.inProgressCount;
                    this.outcomeTeachers.unAssessedTeacher = this.isUnaccessed;
                }
                console.log("inProgressCount...>>>", this.inProgressCount);
                console.log("isAccessedCount...>>>", this.isAccessedCount);
                console.log("isUnaccessed...>>>", this.isUnaccessed);
            });
        },

        async clusterLevelAccessedUnaccessedJobSeekerSnapshot() {
            const dashboardRef = collection(db, "users");
            // console.log("called");

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "job seeker" &&
                        //userData.campaignStatus !== undefined &&
                        userData.cluster === this.$store.state.role.clusterName
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (
                                lastStatus === "INVITED" ||
                                lastStatus === "SCREENING STARTED" ||
                                lastStatus === "MAINS STARTED" ||
                                lastStatus === "SCREENING PASSED"
                            ) {
                                this.inProgressCountJS += 1;
                            } else if (
                                lastStatus === "SCREENING FAILED" ||
                                lastStatus === "MAINS PASSED" ||
                                lastStatus === "MAINS FAILED"
                            ) {
                                this.isAccessedCountJS += 1;
                            }
                        } else {
                            this.isUnaccessedJS += 1;
                        }
                    }
                });
                if (this.$store.state.role.clusterName !== "All Clusters") {
                    this.outcomeJobSeekers.assessedJobSeeker = this.isAccessedCountJS;
                    this.outcomeJobSeekers.inProgressJobSeeker = this.inProgressCountJS;
                    this.outcomeJobSeekers.unAssessedJobSeeker = this.isUnaccessedJS;
                }
                // console.log("jonb seeker inProgressCountJS ...>>>",this.inProgressCountJS);
                // console.log("abcdefgJS isAccessedCountJS...>>>",this.isAccessedCountJS);
                // console.log("abcdeJS isUnaccessedJS...>>>",this.isUnaccessedJS);
            });
        },

        async clusterLevelAppraisalSnapshot() {
            const dashboardRef = collection(db, "users");
            // console.log("called");

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "teacher" &&
                        //userData.campaignStatus !== undefined &&
                        userData.cluster === this.$store.state.role.clusterName
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (lastStatus === "SCREENING FAILED") {
                                this.tbdCount += 1;
                            } else if (lastStatus === "MAINS PASSED") {
                                this.appraisalCount += 1;
                            } else if (lastStatus === "MAINS FAILED") {
                                this.pipCount += 1;
                            }
                        }
                    }
                });
                if (this.$store.state.role.clusterName !== "All Clusters") {
                    this.teacherAppraisal.countAppraised = this.appraisalCount;
                    this.teacherAppraisal.countOnPip = this.pipCount;
                    this.teacherAppraisal.countTbd = this.tbdCount;
                }
                // console.log("tbd...>>>",this.tbdCount);
                // console.log("appraisal...>>>",this.appraisalCount);
                // console.log("pip...>>>",this.pipCount);
            });
        },

        async clusterLevelHiredRejSnapshot() {
            const dashboardRef = collection(db, "users");
            // console.log("called");

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "job seeker" &&
                        //userData.campaignStatus !== undefined &&
                        userData.cluster === this.$store.state.role.clusterName
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (lastStatus === "MAINS PASSED") {
                                this.hiredCount += 1;
                            } else if (lastStatus === "MAINS FAILED") {
                                this.rejectedCount += 1;
                            }
                        }
                    }
                });

                if (this.$store.state.role.clusterName !== "All Clusters") {
                    this.recruitmentJobSeeker.countHired = this.hiredCount;
                    this.recruitmentJobSeeker.countRejected = this.rejectedCount;
                }
                // console.log("hiredCount...>>>",this.hiredCount);
                // console.log("rejectedCount...>>>",this.rejectedCount);
            });
        },

        // school level started ......

        async schoolLevelTotalCampaignSnapshot() {
            const campaignRef = collection(db, "campaigns");
            //console.log("heyyylooooo");
            onSnapshot(campaignRef, (snapshot) => {
                snapshot.docs.forEach(async (doc) => {
                    if (
                        doc.data().audienceType?.toLowerCase() === "teacher" &&
                        doc.data().schools.includes(this.$store.state.role.schools)
                    ) {
                        try {
                            //console.log("heyyylooooopalviiSabharwal");
                            this.teachersInSchoolTotalCampaign += 1;
                        } catch (error) {
                            // console.error("Error fetching audience collection:", error);
                        }
                    }
                });

                if (
                    this.$store.state.role.clusterName !== "All Clusters" &&
                    this.$store.state.role.schoolName != "All"
                ) {
                    console.log(
                        "this.teachersInSchoolTotalCampaign",
                        this.teachersInSchoolTotalCampaign
                    );
                    this.campaignStats.totalTeachersCampaign =
                        this.teachersInSchoolTotalCampaign;
                }

                // console.log("Teacher in school level ..>",this.teachersInSchoolTotalCampaign);
                // if (this.$store.state.role.clusterName != "All Clusters") {
                //   this.campaignStats.totalTeachersCampaign =
                //     this.teachersInTotalCampaign;
                // }
            });
        },

        async schoolLevelTotalCampaignJobSeekerSnapshot() {
            const campaignRef = collection(db, "campaigns");
            //console.log("heyyylooooo");
            onSnapshot(campaignRef, (snapshot) => {
                snapshot.docs.forEach(async (doc) => {
                    // if teacher, run this
                    //console.log("heyyylooooopalvii");
                    if (
                        doc.data().audienceType?.toLowerCase() === "job_seeker" &&
                        doc.data().schools.includes(this.$store.state.role.schools)
                    ) {
                        try {
                            //console.log("heyyylooooopalviiSabharwal");
                            this.jobSeekerInSchoolTotalCampaign += 1;
                        } catch (error) {
                            console.error("Error fetching audience collection:", error);
                        }
                    }
                });
                console.log(
                    "Job Seeker in school level ..>",
                    this.jobSeekerInSchoolTotalCampaign
                );
                if (
                    this.$store.state.role.clusterName !== "All Clusters" &&
                    this.$store.state.role.schoolName != "All"
                ) {
                    this.campaignStats.totalJobSeekersCampaign =
                        this.jobSeekerInSchoolTotalCampaign;
                    this.campaignStats.totalCampaigns =
                        this.campaignStats.totalJobSeekersCampaign +
                        this.campaignStats.totalTeachersCampaign;
                }
            });
        },

        async schoolLevelAccessedUnaccessedSnapshot() {
            const dashboardRef = collection(db, "users");
            console.log("schools res", this.$store.state.role.schools);

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "teacher" &&
                        //userData.campaignStatus !== undefined &&
                        userData.schoolName === this.$store.state.role.schools
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (
                                lastStatus === "INVITED" ||
                                lastStatus === "SCREENING STARTED" ||
                                lastStatus === "MAINS STARTED" ||
                                lastStatus === "SCREENING PASSED"
                            ) {
                                this.inProgressCountAtSchool += 1;
                            } else if (
                                lastStatus === "SCREENING FAILED" ||
                                lastStatus === "MAINS PASSED" ||
                                lastStatus === "MAINS FAILED"
                            ) {
                                this.isAccessedCountAtSchool += 1;
                            }
                        } else {
                            this.isUnaccessedAtSchool += 1;
                        }
                    }
                });
                // if(this.$store.state.role.clusterName !== 'All Clusters'){
                //   this.outcomeTeachers.assessedTeacher =this.isAccessedCount;
                //   this.outcomeTeachers.inProgressTeacher =this.inProgressCount;
                //   this.outcomeTeachers.unAssessedTeacher = this.isUnaccessed;
                // }
                // console.log("inProgressCount...>>>",this.inProgressCountAtSchool);
                // console.log("isAccessedCount...>>>",this.isAccessedCountAtSchool);
                // console.log("isUnaccessed...>>>",this.isUnaccessedAtSchool);
            });
        },

        async schoolLevelAccessedUnaccessedJobSeekerSnapshot() {
            const dashboardRef = collection(db, "users");
            // console.log("called");

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "job seeker" &&
                        //userData.campaignStatus !== undefined &&
                        userData.schoolName === this.$store.state.role.schools
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (
                                lastStatus === "INVITED" ||
                                lastStatus === "SCREENING STARTED" ||
                                lastStatus === "MAINS STARTED" ||
                                lastStatus === "SCREENING PASSED"
                            ) {
                                this.inProgressCountJSAtSchool += 1;
                            } else if (
                                lastStatus === "SCREENING FAILED" ||
                                lastStatus === "MAINS PASSED" ||
                                lastStatus === "MAINS FAILED"
                            ) {
                                this.isAccessedCountJSAtSchool += 1;
                            }
                        } else {
                            this.isUnaccessedJSAtSchool += 1;
                        }
                    }
                });
                // if(this.$store.state.role.clusterName !== 'All Clusters'){
                //   this.outcomeJobSeekers.assessedJobSeeker = this.isAccessedCountJS;
                //   this.outcomeJobSeekers.inProgressJobSeeker =this.inProgressCountJS;
                //   this.outcomeJobSeekers.unAssessedJobSeeker = this.isUnaccessedJS;
                // }
                // console.log("jonb seeker inProgressCountJS ...>>>",this.inProgressCountJSAtSchool);
                // console.log("abcdefgJS isAccessedCountJS...>>>",this.isAccessedCountJSAtSchool);
                // console.log("abcdeJS isUnaccessedJS...>>>",this.isUnaccessedJSAtSchool);
            });
        },

        async schoolLevelAppraisalSnapshot() {
            const dashboardRef = collection(db, "users");
            // console.log("called");

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "teacher" &&
                        //userData.campaignStatus !== undefined &&
                        userData.schoolName === this.$store.state.role.schools
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (lastStatus === "SCREENING FAILED") {
                                this.tbdCountAtSchool += 1;
                            } else if (lastStatus === "MAINS PASSED") {
                                this.appraisalCountAtSchool += 1;
                            } else if (lastStatus === "MAINS FAILED") {
                                this.pipCountAtSchool += 1;
                            }
                        }
                    }
                });
                // if(this.$store.state.role.clusterName !== 'All Clusters'){
                //   this.teacherAppraisal.countAppraised =this.appraisalCount;
                //   this.teacherAppraisal.countOnPip =this.pipCount;
                //   this.teacherAppraisal.countTbd = this.tbdCount;
                // }
                // console.log("tbd...>>>",this.tbdCountAtSchool);
                // console.log("appraisal...>>>",this.appraisalCountAtSchool);
                // console.log("pip...>>>",this.pipCountAtSchool);
            });
        },

        async schoolLevelHiredRejSnapshot() {
            const dashboardRef = collection(db, "users");
            // console.log("called");

            onSnapshot(dashboardRef, (snapshot) => {
                //let countDocumentSchool = 0;
                //console.log("datamdnwbdfwbfj", snapshot);
                // const schools = [];
                snapshot.docs.forEach((doc) => {
                    const userData = doc.data();
                    if (
                        userData.userType?.toLowerCase() === "job seeker" &&
                        //userData.campaignStatus !== undefined &&
                        userData.schoolName === this.$store.state.role.schools
                    ) {
                        //countDocumentSchool++;
                        if (
                            doc.data().campaignStatus &&
                            doc.data().campaignStatus.length > 0
                        ) {
                            const lastStatus =
                                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                                    .status;
                            if (lastStatus === "MAINS PASSED") {
                                this.hiredCountAtSchool += 1;
                            } else if (lastStatus === "MAINS FAILED") {
                                this.rejectedCountAtSchool += 1;
                            }
                        }
                    }
                });

                // if(this.$store.state.role.clusterName !== 'All Clusters'){
                //   this.recruitmentJobSeeker.countHired =this.hiredCount;
                //   this.recruitmentJobSeeker.countRejected =this.rejectedCount;
                // }
                // console.log("hiredCount...>>>",this.hiredCountAtSchool);
                // console.log("rejectedCount...>>>",this.rejectedCountAtSchool);
            });
        },

        showSubjectField() {
            if (this.campaignData.skills.length >= 0) {
                if (this.campaignData.skills.includes("Core Skills")) {
                    this.tag = true; // Found a special string, return true
                } else {
                    this.tag = false;
                    this.campaignData.subjects = [];
                }
            }
        },

        getCurrentDate() {
            if (this.isCampaignPublished != 0) {
                var date = new Date();
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                if (day < 10) {
                    day = "0" + day;
                }
                if (month < 10) {
                    month = `0${month}`;
                }
                this.startDate = `${year}-${month}-${day}`;
                // console.log("date", this.startDate);
            } else {
                this.startDate = "";
            }
        },

        getClusterOrSchool() {
            this.roleCluster = this.$store.state.role.clusterName;
            this.roleSchool = this.$store.state.role.schools;

            if (this.roleSchool == undefined) {
                AuthService.logout();
                this.$router.push("/login");
            }
            if (this.roleCluster != "All Clusters") {
                this.campaignData.clusters = [this.roleCluster];
                this.getClusterSchool();
                console.log("cluster>>", this.campaignData.clusters);
            }
            if (this.roleSchool != "All") {
                this.campaignData.schools = [this.roleSchool];
                console.log("school>>", this.roleSchool);
            }
        },

        logout() {
            AuthService.logout();
            this.$router.push("/login");
        },

        async downloadQrCode(){
            // qrDownloadRef
            this.qrDownloadLoading=true;
            html2canvas(this.$refs.qrDownloadRef).then(canvas => {
                let link = document.createElement('a');
                // link.download = 'screenshot.jpg';
                link.download=`${this.qrCampaignName}.jpg`;
                link.href = canvas.toDataURL('image/jpeg', 0.5);
                link.click();
            });
            this.qrDownloadLoading=false;
        },

        async generateQRCode(data){
            // this.qrLoading=true;
            data.isLoading=true;
            const id=data.id
            const audienceType=data.audienceType

            console.log('data :', data);
        
            let shortCampaignLink=`https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=`+id

            if (audienceType?.toLowerCase()!=="teacher"){
                const campaignLink=`https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome`

                const apiRes=await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`, {
                "longDynamicLink":campaignLink,
                "suffix": {
                    "option": "SHORT"
                }
                })

                if (apiRes?.data?.shortLink){
                shortCampaignLink=apiRes?.data?.shortLink;
                }
            }
            this.qrCampaignName=data.name
            this.qrCampaignLink=shortCampaignLink
            // this.qrLoading=false;
            data.isLoading=false;
            this.qrDialog=true;
        },

        async copyToClipboard(id, audienceType) {
            // console.log(data);
            console.log("id_abc :", id, audienceType)
            let shortCampaignLink=`https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId='+id+'&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId='+id+'&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId=`+id;

            if (audienceType?.toLowerCase()!=="teacher"){
                const longLink=`https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome`


                const apiRes=await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`, {
                    "longDynamicLink":longLink,
                    "suffix": {
                        "option": "SHORT"
                    }
                })

                console.log("apiRes :", apiRes.data, apiRes.data?.shortLink)

                if (apiRes?.data?.shortLink){
                    shortCampaignLink=apiRes.data.shortLink
                }
                // campaignLink=apiRes?.data?.shortLink;
            }

            console.log("shortCampaignLink :", shortCampaignLink)
            


                    // 'https://this.$DEEPLINK/?link=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId%3D3zQJ5EbqOeEaoc5jhiX4&apn=this.$PACKAGE_NAME&afl=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId%3D3zQJ5EbqOeEaoc5jhiX4&isi=6446600030&ibi=this.$PACKAGE_NAME&ifl=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId%3D3zQJ5EbqOeEaoc5jhiX4'
            navigator.clipboard
                .writeText(shortCampaignLink
                    // 'https://this.$DEEPLINK/?link=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId='+id+'&apn=this.$PACKAGE_NAME&afl=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId='+id+'&isi=6446600030&ibi=this.$PACKAGE_NAME&ifl=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId=' +
                    // id
                )
                .then(() => {
                    // console.log('Text copied to clipboard');
                    this.snackbar = true;
                })
                .catch((error) => {
                    console.error("Failed to copy text: ", error);
                });
        },
        copyToClipboardCampaign() {
            // console.log(data);
            navigator.clipboard
                .writeText(
                    `https://${this.$FRONTEND_DOMAIN}/#/login?campaignId=` +
                    this.campaignData.campaignId
                )
                .then(() => {
                    this.snackbar = true;

                    // console.log('Text copied to clipboard');
                })
                .catch((error) => {
                    console.error("Failed to copy text: ", error);
                });
        },
        getSelectedSubject() {
            var sub = "";
            this.campaignData.subjects.forEach((subject) => {
                sub = sub + subject + ",";
            });
            return sub;
        },
        getSelectedLevels() {
            var levels = "";
            this.campaignData.levels.forEach((level) => {
                levels = levels + level + ",";
            });
            return levels;
        },

        getSelectedSkills() {
            var skills = "";
            this.campaignData.skills.forEach((skill) => {
                skills = skills + skill + ",";
            });
            return skills;
        },
        goToCampaignCreationForm(role) {
            // console.log("name", campaignName);
            this.$router.push({
                path: "/CreateCampaign",
                name: "CreateCampaign", //use name for router push
                query: {
                    role: role,
                },
            });
        },
        goToUser(id, campaignName, campaignType , schoolsList) {
            this.clearPaginationStates();
            // console.log("name", campaignName);
            if(campaignType === 'MESTA_STUDENTS' || campaignType === 'DUMMY'){
                this.$router.push({
                path: "/mestacampaignuser",
                name: "mestaCampaignUser",
                query: {
                    id: id,
                    campaignName: campaignName,
                    campaignType: campaignType,
                },
                });
            }
            else {
                this.$router.push({
                    path: "/campaignUser",
                    name: "CampaignUserView", //use name for router push
                    query: {
                        id: id,
                        campaignType: campaignType,
                    },
                });
            }
        },
        countNoOfQuestionMains() {
            var sum = 0;
            for (var i = 0; i < this.skillsDistributionsForMains.length; i++) {
                sum = sum + Number(this.skillsDistributionsForMains[i].noOfQuestion);
            }

            for (var J = 0; J < this.subjectDistributionsForMains.length; J++) {
                sum = sum + Number(this.subjectDistributionsForMains[J].noOfQuestion);
            }

            // console.log(
            //   "MAINS skills changing ",
            //   sum,
            //   this.campaignData.campaignConfigration.mainsConfigration
            //     .totalNoOfQuestions
            // );
            if (this.tag == true) {
                sum = sum - 20;
            }
            this.campaignData.campaignConfigration.mainsConfigration.totalNoOfQuestions =
                sum;
        },
        countNoOfQuestionScreening() {
            var sum = 0;
            for (var i = 0; i < this.skillsDistributions.length; i++) {
                sum = sum + Number(this.skillsDistributions[i].noOfQuestion);
            }

            for (var K = 0; K < this.subjectDistributions.length; K++) {
                sum = sum + Number(this.subjectDistributions[K].noOfQuestion);
            }

            // console.log(
            //   "SCREENIGN skills changing ",
            //   sum,
            //   this.campaignData.campaignConfigration.screeningConfigration
            //     .totalNoOfQuestions
            // );
            if (this.tag == true) {
                sum = sum - 10;
            }
            this.campaignData.campaignConfigration.screeningConfigration.totalNoOfQuestions =
                sum;
        },
        // async fetchUsers() {
        //   const response = await UsersController.getUsers();
        //   if (response.data.flag) {
        //     this.users = response.data.users;
        //     // this.count = response.data.count;
        //     // this.isLoading = false;
        //     // console.log("users", this.users);
        //   } else {
        //     alert(response.data.error);
        //   }
        // },
        addSkill() {
            this.subjectDistributions.push({
                subjectName: "",
                noOfQuestion: null,
            });
        },
        removeSkill(index) {
            this.subjectDistributions.splice(index, 1);
        },
        addSkillForMains() {
            this.subjectDistributionsForMains.push({
                subjectName: "",
                noOfQuestion: null,
            });
        },
        removeSkillForMains(index) {
            this.subjectDistributionsForMains.splice(index, 1);
        },

        getPercentage(value, totalValue) {
            var data = (value / totalValue) * 100;
            data = Math.trunc(data);

            return data + "%";
        },
        campaignButtonValue() {
            return this.updateFlag == true ? "Update" : "Create";
        },
        campaignButtonValues() {
            return this.updateFlag == true ? "Updated" : "Published";
        },
        async deleteCampaign(id) {
            // console.log(id)
            const response = await CampaignController.deleteCampaign(id);
            if (response.data.flag) {
                this.getCampaigns();
                this.deleteDialog = false;
                // console.log(response);
            } else {
                alert(response.data.error);
            }
        },
        convertDateFormat(dateString) {
            // console.log(dateString);
            const [year, month, day] = dateString.split("-");

            const formattedDate = `${year}-${month}-${day}`;

            return formattedDate;
        },
        convertDateFormatForUpdate(dateString) {
            // console.log(dateString);
            const [year, month, day] = dateString.split("-");

            const formattedDate = `${year}-${month}-${day}`;

            return formattedDate;
        },
        convertStartTimeFormatForUpdate(timeString) {
            // console.log(timeString);
            const [hours, min] = timeString.split(":");
            this.campaignStartTime = hours;
            this.startMM = min;
        },
        convertEndTimeFormatForUpdate(timeString) {
            // console.log(timeString);
            const [hours, min] = timeString.split(":");
            this.screeningEndTime = hours;
            this.endMM = min;
        },
        role(data) {
            // console.log("role" ,data)
            (this.campaignData.campaignId = ""),
                (this.campaignData.name = ""),
                (this.campaignData.startDate = ""),
                (this.campaignData.screeningEndDate = ""),
                (this.campaignData.mainsEndDate = ""),
                (this.campaignData.campaignStartTime = ""),
                (this.campaignData.screeningEndTime = ""),
                (this.campaignData.mainsEndTime = ""),
                (this.campaignData.resultPublishTime = "10:00"),
                (this.campaignData.publishPattern = {
                    frequency: "",
                    interval: {
                        day: "",
                        week: "",
                        month: "",
                    },
                }),
                (this.campaignData.audienceType = ""),
                (this.campaignData.skills = []),
                (this.campaignData.subjects = []),
                (this.campaignData.levels = []),
                (this.campaignData.seekerLiveCampaignsCount = 0),
                (this.campaignData.seekerScheduledCampaignsCount = 0),
                (this.campaignData.seekerExpiredCampaignsCount = 0),
                (this.campaignData.vgosLiveCampaignsCount = 0),
                (this.campaignData.vgosScheduledCampaignsCount = 0),
                (this.campaignData.vgosExpiredCampaignsCount = 0),
                // audiance:this.selected,
                (this.campaignData.campaignConfigration = {
                    type: "DYNAMIC",
                    assessmentIds: [],
                    screeningConfigration: {
                        instruction: "",
                        subjectsDistribution: [],
                        skillsDistributions: [],
                        totalNoOfQuestions: 0,
                        levels: [],
                        adaptiveAssessment: "Yes",
                        negativeMarking: "No",
                        shuffleQuestions: "No",
                        passingCriteria: 60,
                        timeUpFirstRemainder: 0,
                        timeUpLastRemainder: 0,
                    },
                    mainsConfigration: {
                        instruction: "",
                        subjectsDistribution: [],
                        skillsDistributions: [],
                        totalNoOfQuestions: 0,
                        levels: [],
                        adaptiveAssessment: "Yes",
                        negativeMarking: "No",
                        shuffleQuestions: "No",
                        passingCriteria: 60,
                        timeUpFirstRemainder: 0,
                        timeUpLastRemainder: 0,
                    },
                }),
                (this.startDate = ""),
                (this.screeningEndDate = ""),
                (this.mainsEndDate = ""),
                (this.isCampaignPublished = 0),
                (this.dialog = true);
            this.campaignData.audienceType = data;
            this.updateFlag = false;
            // console.log("data", this.campaignData.clusters);
        },
        campaignUpdate(data) {
            this.campaignId = data.id;
            this.campaignType = data.audienceType;
            this.screeningEndDate = data.screeningEndDate;
            if(this.campaignType == "MESTA_STUDENTS") {
                this.endDate = data.endDate;
            }
            else {
                this.mainsEndDate = data.mainsEndDate;
            }
            this.startDate = this.convertDateFormatForUpdate(data.startDate);
            this.UpdateCampaignDialog = true;
        },
      
        async camapignUpdate() {
            if (this.$refs.updateForm.validate()) {
                this.UpdateCampaignDialogLoading = true;
                let response;
                if(this.campaignType == "MESTA_STUDENTS"){
                this.campaignData.endDate = this.convertDateFormat(this.endDate);
                response = await CampaignController.updateCampaignDates({endDate:this.campaignData.endDate, campaignId: this.campaignId});
                }
                else{
                this.campaignData.mainsEndDate = this.convertDateFormat(this.mainsEndDate);
                if(this.campaignType == "JOB_SEEKER")
                    this.campaignData.screeningEndDate = this.convertDateFormat(this.screeningEndDate);
                response = await CampaignController.updateCampaignDates({screeningEndDate: this.campaignData.screeningEndDate, mainsEndDate: this.campaignData.mainsEndDate, campaignId: this.campaignId});
                }

                if (response.status == 200) {
                this.UpdateCampaignDialog = false;
                this.UpdateCampaignDialogLoading = false;
                this.getCampaigns();
                (this.screeningEndDate = ""), (this.mainsEndDate = "");
                } else {
                alert(response.data.error);
                this.UpdateCampaignDialog = false;
                this.UpdateCampaignDialogLoading = false;
                this.getCampaigns();
                (this.screeningEndDate = ""), (this.mainsEndDate = "");
                }
            }
        },
        goto(step) {
            switch (step) {
                case 1:
                    if (this.$refs.step1.validate()) {
                        this.dLoading = true;
                        this.subjectDistributions = [];
                        this.skillsDistributions = [];
                        // console.log("befour applied", this.campaignData.levels);
                        this.campaignData.levels.forEach((doc) => {
                            this.campaignData.campaignConfigration.screeningConfigration.levels.push(
                                doc
                            );
                        });
                        // console.log(
                        //   "data applied",
                        //   this.campaignData.campaignConfigration.screeningConfigration
                        //     .levels
                        // );

                        this.subjectDistributionsForMains = [];

                        this.skillsDistributionsForMains = [];
                        this.campaignData.campaignConfigration.screeningConfigration.levels =
                            this.campaignData.levels;
                        this.campaignData.campaignConfigration.mainsConfigration.levels =
                            this.campaignData.levels;
                        this.countNoOfQuestionScreening();
                        this.campaignData.subjects.forEach((item) => {
                            this.subjectDistributions.push({
                                subjectName: item,
                                noOfQuestion: this.coreSkillNoOfQuestionForScreening,
                                weightage: this.coreSkillWeightageForScreening,
                                isRequired: this.coreSkillIsRequiredForScreening,
                            });
                            this.subjectDistributionsForMains.push({
                                subjectName: item,
                                noOfQuestion: this.coreSkillNoOfQuestionForMains,
                                weightage: this.coreSkillWeightageForMains,
                                isRequired: this.coreSkillIsRequiredForMains,
                            });
                        });
                        this.campaignData.skills.forEach((item) => {
                            if (item == "Core Skills") {
                                this.skillsDistributions.push({
                                    skillName: item,
                                    noOfQuestion: 10,
                                    weightage: 50,
                                    isRequired: true,
                                });
                                this.skillsDistributionsForMains.push({
                                    skillName: item,
                                    noOfQuestion: 20,
                                    weightage: 50,
                                    isRequired: true,
                                });
                            } else {
                                this.skillsDistributions.push({
                                    skillName: item,
                                    noOfQuestion: 9,
                                    weightage: null,
                                    isRequired: false,
                                });
                                this.skillsDistributionsForMains.push({
                                    skillName: item,
                                    noOfQuestion: 18,
                                    weightage: null,
                                    isRequired: false,
                                });
                            }
                        });

                        // console.log(
                        //   "selected subjectDistributions",
                        //   this.subjectDistributions
                        // );
                        // this.campaignData.skills.forEach((item)=>{

                        // })

                        // this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions =
                        //   this.skillsDistributions;
                        // this.campaignData.campaignConfigration.screeningConfigration.subjectsDistribution =
                        //   this.subjectDistributions;
                        // this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions =
                        //   this.skillsDistributionsForMains;
                        // this.campaignData.campaignConfigration.mainsConfigration.subjectsDistribution =
                        //   this.subjectDistributionsForMains;

                        // console.log("scre skills", this.skillsDistributions);
                        console.log("scr subject", this.subjectDistributions);
                        // console.log("main skills", this.skillsDistributionsForMains);
                        console.log("mains subject", this.subjectDistributionsForMains);

                        this.createCampaign();
                    }

                    break;
                case 2:
                    if (this.$refs.step2.validate()) {
                        this.dLoading = true;
                        this.createCampaignConfigration();
                        //  this.e1++;
                    }
                    break;
                case 3:
                    this.dLoading = true;
                    this.createCampaignAudiance();
                    break;

                default:
                    this.e1++;
            }
        },
        async createCampaign() {
            this.campaignData.campaignStartTime = this.campaignStartTime;
            this.campaignData.screeningEndTime = this.screeningEndTime;
            this.campaignData.mainsEndTime = this.mainsEndTime;

            this.campaignData.startDate = this.convertDateFormat(this.startDate);
            this.campaignData.mainsEndDate = this.convertDateFormat(
                this.mainsEndDate
            );
            this.campaignData.screeningEndDate = this.convertDateFormat(
                this.screeningEndDate
            );

            if (this.updateFlag == false) {
                const response = await CampaignController.createCampaign({
                    isCampaignPublished: this.isCampaignPublished == 0 ? false : true,
                    name: this.campaignData.name,
                    startDate: this.campaignData.startDate,
                    screeningEndDate: this.campaignData.screeningEndDate,
                    mainsEndDate: this.campaignData.mainsEndDate,
                    campaignStartTime: this.campaignData.campaignStartTime,
                    screeningEndTime: this.campaignData.screeningEndTime,
                    mainsEndTime: this.campaignData.mainsEndTime,
                    resultPublishTime: this.campaignData.resultPublishTime,
                    publishPattern: this.campaignData.publishPattern,
                    audienceType: this.campaignData.audienceType,
                    clusters: this.campaignData.clusters,
                    skills: this.campaignData.skills,
                    subjects: this.campaignData.subjects,
                    schools: this.campaignData.schools,
                    levels: this.campaignData.levels,
                });
                // console.log("create", response);
                if (response.data.flag) {
                    this.e1++;
                    this.dLoading = false;
                    this.countNoOfQuestionMains();
                    this.countNoOfQuestionScreening();
                    this.campaignData.campaignId = response.data.id;
                } else {
                    this.dLoading = false;
                    alert(response.data.error);
                }
            } else {
                const response = await CampaignController.updateCampaign(
                    {
                        name: this.campaignData.name,
                        startDate: this.campaignData.startDate,
                        screeningEndDate: this.campaignData.screeningEndDate,
                        campaignStartTime: this.campaignData.campaignStartTime,
                        screeningEndTime: this.campaignData.screeningEndTime,
                        mainsEndTime: this.campaignData.mainsEndTime,
                        resultPublishTime: this.campaignData.resultPublishTime,
                        publishPattern: this.campaignData.publishPattern,
                        audienceType: this.campaignData.audienceType,
                        clusters: this.campaignData.clusters,
                        skills: this.campaignData.skills,
                        subjects: this.campaignData.subjects,
                        schools: this.campaignData.schools,
                        levels: this.campaignData.levels,
                    },
                    this.campaignId
                );
                // console.log("update", response);

                if (response.data.flag) {
                    this.e1++;
                    this.campaignId = null;
                } else {
                    alert(response.data.error);
                    this.campaignId = null;
                }
            }
        },
        filterBySubjects(mainArray, givenSubjects) {
            return mainArray.filter(async (obj) => {
                const subjects = await obj.responsibleTeachingAt.map(
                    (responsibility) => responsibility.subject
                );
                return givenSubjects.every((subject) => subjects.includes(subject));
            });
        },
        async createCampaignConfigration() {
            this.subjectDistributions.forEach((item) => {
                this.campaignData.campaignConfigration.screeningConfigration.subjectsDistribution.push(
                    {
                        subjectName: item.subjectName,
                        noOfQuestion: this.coreSkillNoOfQuestionForScreening,
                        weightage: this.coreSkillWeightageForScreening,
                        isRequired: this.coreSkillIsRequiredForScreening,
                    }
                );
            });

            this.subjectDistributionsForMains.forEach((item) => {
                this.campaignData.campaignConfigration.mainsConfigration.subjectsDistribution.push(
                    {
                        subjectName: item.subjectName,
                        noOfQuestion: this.coreSkillNoOfQuestionForMains,
                        weightage: this.coreSkillWeightageForMains,
                        isRequired: this.coreSkillIsRequiredForMains,
                    }
                );
            });

            this.skillsDistributions.forEach((item) => {
                if (item.skillName == "Core Skills") {
                    this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions.push(
                        {
                            skillName: item.skillName,
                            noOfQuestion: this.coreSkillNoOfQuestionForScreening,
                            weightage: this.coreSkillWeightageForScreening,
                            isRequired: this.coreSkillIsRequiredForScreening,
                        }
                    );
                } else {
                    this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions.push(
                        {
                            skillName: item.skillName,
                            noOfQuestion: item.noOfQuestion,
                            weightage: item.weightage,
                            isRequired: item.isRequired,
                        }
                    );
                }
            });

            this.skillsDistributionsForMains.forEach((item) => {
                if (item.skillName == "Core Skills") {
                    this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions.push(
                        {
                            skillName: item.skillName,
                            noOfQuestion: this.coreSkillNoOfQuestionForMains,
                            weightage: this.coreSkillWeightageForMains,
                            isRequired: this.coreSkillIsRequiredForMains,
                        }
                    );
                } else {
                    this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions.push(
                        {
                            skillName: item.skillName,
                            noOfQuestion: item.noOfQuestion,
                            weightage: item.weightage,
                            isRequired: item.isRequired,
                        }
                    );
                }
            });

            // this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions =
            //   this.skillsDistributions;
            // this.campaignData.campaignConfigration.screeningConfigration.subjectsDistribution =
            //   this.subjectDistributions;
            // this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions =
            //   this.skillsDistributionsForMains;
            // this.campaignData.campaignConfigration.mainsConfigration.subjectsDistribution =
            //   this.subjectDistributionsForMains;

            if (this.updateFlag == false) {
                const response = await CampaignController.createCampaignConfigration(
                    this.campaignData.campaignConfigration,
                    this.campaignData.campaignId
                    // "EOfr9hNdwIgEIfrfBW0O"
                );
                // console.log("Campaign configration", response);
                if (response.data.flag) {
                    this.e1++;
                    this.dLoading = false;
                    this.count = response.data.users.length;
                    this.isLoading = false;
                    if (this.campaignData.audienceType == "TEACHER") {
                        var userData = response.data.users;
                        this.users = await this.filterBySubjects(
                            userData,
                            this.campaignData.subjects
                        );
                        console.log(
                            "this.users...>>>>",
                            this.users,
                            this.campaignData.subjects
                        );
                        this.responsibleTeachingAt = {};
                        this.teachingSubjects = {};
                        for (const user of this.users) {
                            const lvl = [];
                            const sub = [];
                            for (const res of user.responsibleTeachingAt) {
                                lvl.push(res.level);
                                sub.push(res.subject);
                            }
                            this.responsibleTeachingAt[user.userId] = lvl;
                            this.teachingSubjects[user.userId] = sub;
                        }

                        // console.log("responsiblity...>>>", this.responsibleTeachingAt)
                        // console.log("teachingSubject...>>>", this.teachingSubjects)
                    }
                } else {
                    this.dLoading = false;
                    alert(response.data.error);
                }
            } else {
                const response = await CampaignController.createCampaignConfigration(
                    this.campaignData.campaignConfigration,
                    this.campaignData.campaignId
                    // "EOfr9hNdwIgEIfrfBW0O"
                );
                // console.log("update", response);

                if (response.data.flag) {
                    this.e1++;
                    this.campaignId = null;
                } else {
                    alert(response.data.error);
                    this.campaignId = null;
                }
            }
        },

        async createCampaignAudiance() {
            if (this.updateFlag == false) {
                const response = await CampaignController.createCampaignAudiance(
                    this.selected,
                    this.campaignData.campaignId
                    // "IPEsnYQwz8Jf30Wyw4ll"
                );
                // console.log("Campaign Audiance", response);
                if (response.data.flag) {
                    this.dLoading = false;
                    this.e1 = 1;
                    this.dialog = false;
                    this.successDialog = true;
                    this.getCampaigns();
                    // console.log("svnas hdsvadnsvhsdvavdsxvdkvbi",this.selected)
                } else {
                    this.dLoading = false;
                    alert(response.data.error);
                }
            } else {
                const response = await CampaignController.createCampaignAudiance(
                    this.campaignData.audiance,
                    this.campaignData.campaignId
                    // "IPEsnYQwz8Jf30Wyw4ll"
                );
                // console.log("update", response);

                if (response.data.flag) {
                    //  this.successDialog = true
                    this.campaignId = null;
                } else {
                    alert(response.data.error);
                    this.campaignId = null;
                }
            }
        },
        // async getSchool() {
        //   const response = await SchoolController.getSchool();
        //   // console.log(response);
        //   if (response.status == 200) {
        //     if (response.data.schools.length > 0) {
        //       // this.schools = response.data.schools;
        //     } else {
        //       // this.schools = [];
        //     }
        //   } else {
        //     alert(response.data.error);
        //   }
        // },
        async getClusterSchool() {
            // console.log("selected cluster", this.campaignData.clusters);
            if (this.campaignData.clusters.length > 0) {
                const response = await SchoolController.getClusterSchool(
                    this.campaignData.clusters
                );
                // console.log("school", response);
                if (response.status == 200) {
                    var schools = response.data.schools;
                    this.schools = schools;
                    //   console.log("school", this.schools);
                } else {
                    alert(response.data.error);
                }
            } else {
                this.schools = [];
            }
        },
        async getClusterSchoolForFilter(clusters) {
            // console.log("selected cluster", this.campaignData.clusters);
            if (this.clusters.length > 0) {
                const response = await SchoolController.getClusterSchool(clusters);
                // console.log("school", response);
                if (response.status == 200) {
                    var schools = response.data.schools;
                    this.schoolsData = schools;
                    console.log("school", this.schoolsData);
                } else {
                    alert(response.data.error);
                }
            } else {
                this.schoolsData = [];
            }
        },

        async getCampaigns(status=this.currentStatus) {
            this.preloader = true;
            this.currentStatus = status;
            let clusterData = this.$store.state.role.clusterName;
            if (clusterData == "All Clusters") {
                clusterData = "All";
            }
            let schoolData = this.$store.state.role.schools;
            let query = "?cluster=" + clusterData + "&school=" + schoolData + "&status=" + this.currentStatus + "&all=true" + "&audienceType=" + this.audienceType;
            const response = await CampaignController.getCampaigns(query);
            if (response.status == 200) {
                this.campaigns = response.data.campaigns;

                if (this.campaigns != null && this.campaigns.length != 0) {
                    this.preLiveCampaigns = this.campaigns.liveCampaign;
                    this.storeLiveCam = this.campaigns.liveCampaign;
                    this.preScheduledCampaigns = this.campaigns.scheduledCampaign;
                    this.preExpiredCampaigns = this.campaigns.expiredCampaign;
                }
                this.preloader = false;
            }
            this.getTypeCampaign("All");
        },
        getTypeCampaign(data) {
            // console.log("chartSwitch>>", data);
            this.chartData = data;
            if (data == "All" || data == "") {
                this.pageName = "All";
                this.liveCampaigns = this.preLiveCampaigns;
                this.scheduledCampaigns = this.preScheduledCampaigns;
                this.expiredCampaigns = this.preExpiredCampaigns;
                this.filterLiveCampaigns = this.preLiveCampaigns;
                this.filterScheduledCampaigns = this.preScheduledCampaigns;
                this.downlaodCamapigns = [...this.preLiveCampaigns, ...this.preScheduledCampaigns, ...this.preExpiredCampaigns]
                this.filterExpiredCampaigns = this.preExpiredCampaigns;
                if (this.preLiveCampaigns.length > 0) {
                    this.seekerLiveCampaignsCount = this.preLiveCampaigns.filter(
                        (item) => item.audienceType == "JOB_SEEKER"
                    ).length;
                    this.vgosLiveCampaignsCount = this.preLiveCampaigns.filter(
                        (item) => item.audienceType == "TEACHER"
                    ).length;
                } else {
                    this.seekerLiveCampaignsCount = 0;
                    this.vgosLiveCampaignsCount = 0;
                }
                if (this.preScheduledCampaigns.length > 0) {
                    this.seekerScheduledCampaignsCount =
                        this.preScheduledCampaigns.filter(
                            (item) => item.audienceType == "JOB_SEEKER"
                        ).length;
                    this.vgosScheduledCampaignsCount = this.preScheduledCampaigns.filter(
                        (item) => item.audienceType == "TEACHER"
                    ).length;
                } else {
                    this.seekerScheduledCampaignsCount = 0;
                    this.vgosScheduledCampaignsCount = 0;
                }
                if (this.preExpiredCampaigns.length > 0) {
                    this.seekerExpiredCampaignsCount = this.preExpiredCampaigns.filter(
                        (item) => item.audienceType == "JOB_SEEKER"
                    ).length;
                    this.vgosExpiredCampaignsCount = this.preExpiredCampaigns.filter(
                        (item) => item.audienceType == "TEACHER"
                    ).length;
                } else {
                    this.seekerExpiredCampaignsCount = 0;
                    this.vgosExpiredCampaignsCount = 0;
                }
            } else if (data == "JOB_SEEKER") {
                this.pageName = "JOB_SEEKER";
                this.downlaodCamapigns = [...this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                ), ...this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                ), ...this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                )]

                var jobLiveCam = this.storeLiveCam.filter(
                    (item) => item.audienceType == data
                );
                this.liveCampaigns = this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.scheduledCampaigns = this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.expiredCampaigns = this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.filterLiveCampaigns = this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.filterScheduledCampaigns = this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.filterExpiredCampaigns = this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                );

                this.countCampaignByLevel.prePrimaryCount = jobLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Pre Primary")
                ).length;
                this.countCampaignByLevel.primaryCount = jobLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Primary")
                ).length;
                this.countCampaignByLevel.secondaryCount = jobLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Secondary")
                ).length;
                this.countCampaignByLevel.seniorSecondaryCount = jobLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Senior Secondary")
                ).length;
                this.countCampaignByLevel.middleSchoolCount = jobLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Middle School")
                ).length;
            } else if(this.campaignType == "TEACHER"){
                this.downlaodCamapigns = [...this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                ), ...this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                ), ...this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                )]
                this.pageName = "TEACHER";
                var teacherLiveCam = this.storeLiveCam.filter(
                    (item) => item.audienceType == data
                );
                this.liveCampaigns = this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.scheduledCampaigns = this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.expiredCampaigns = this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.filterLiveCampaigns = this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.filterScheduledCampaigns = this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.filterExpiredCampaigns = this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                );

                this.countCampaignByLevel.prePrimaryCount = teacherLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Pre Primary")
                ).length;
                this.countCampaignByLevel.primaryCount = teacherLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Primary")
                ).length;
                this.countCampaignByLevel.secondaryCount = teacherLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Secondary")
                ).length;
                this.countCampaignByLevel.seniorSecondaryCount = teacherLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Senior Secondary")
                ).length;
                this.countCampaignByLevel.middleSchoolCount = teacherLiveCam.filter(
                    (item) => item.levels && item.levels.includes("Middle School")
                ).length;
            }
            else if( this.campaignType == "MESTA_STUDENTS"){
                this.downlaodCamapigns = [...this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                ), ...this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                ), ...this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                )]
                this.pageName = "MESTA_STUDENTS";
                this.liveCampaigns = this.preLiveCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.scheduledCampaigns = this.preScheduledCampaigns.filter(
                    (item) => item.audienceType == data
                );
                this.expiredCampaigns = this.preExpiredCampaigns.filter(
                    (item) => item.audienceType == data
                );
            }
            if (this.search != "" && this.search != null) {
                this.searchData(this.search);
                // console.log("this.searchData(this.search);")
            }
            // console.log("live" ,  teacherLiveCam)
        },

    async  getCampaignsCount() {
            let clusterData = this.$store.state.role.clusterName;
            if (clusterData == "All Clusters") {
                clusterData = "All";
            }
            let schoolData = this.$store.state.role.schools;
         let query = "?cluster=" + clusterData + "&school=" + schoolData + "&audienceType=" + this.audienceType;
    const response = await CampaignController.getCampaignsCount(query);
      if (response.status == 200) {
        this.allCampaignCount = response.data.counts;
        this.countFlag = response.data.flag;
        // if (this.allCampaignCount) {
        //   this.seekerExpiredCampaignsCount = this.allCampaignCount.seekerExpiredCount;
        //   this.seekerLiveCampaignsCount = this.allCampaignCount.seekerLiveCount;
        //   this.seekerScheduledCampaignsCount = this.allCampaignCount.seekerScheduleCount;
        //   this.vgosExpiredCampaignsCount = this.allCampaignCount.vgosExpiredCount;
        //   this.vgosLiveCampaignsCount = this.allCampaignCount.vgosLiveCount;
        //   this.vgosScheduledCampaignsCount = this.allCampaignCount.vgosScheduleCount;
                 
        // }
    }
  },
        async getSkills() {
            const response = await SkillsController.getSkills();
            // console.log(response);
            if (response.status == 200) {
                if (response.data.skills.length > 0) {
                    this.skills = response.data.skills;
                } else {
                    this.skills = [];
                }
            }
        },
        async getSubjects() {
            const response = await SubjectController.getSubject();
            if (response.status == 200) {
                if (response.data.subjects.length > 0) {
                    this.subjects = response.data.subjects;
                } else {
                    this.subjects = [];
                }
                // console.log("subjects", this.subjects);
            }
            //console.log("surbject responser", this.subjectData);
        },
        async fetchAllClusters() {
            const response = await ClusterController.getAllClusters();

            if (response.status == 200) {
                // console.log(response);
                if (response.data.clusters.length > 0) {
                    this.clusters = response.data.clusters;
                } else {
                    this.clusters = [];
                }
                // console.log("afkdsbxbfabsbdj,a", this.clusters);
            }
        },
        goToAssessment() {
            // this.$router.push({
            //   path: '/assessment',
            //   name: "dataList", //use name for router push
            //   // params: { data }
            // });
            // this.$router.push("/assessment")
            this.$router.push({
                name: "assessment",
                params: { cdialog: true },
            });
        },

        // trigger reload

        showAlert(event) {
            // show alert message
            if (event) {
                confirm("Press a button!");
            }
            // event.preventDefault()
            event.stopImmediatePropagation();
            // event.returnValue = 'my current value null'
        },

        viewUserInfoFun(item) {
            this.viewUserIno = item;
            this.userViewDialog = true;
            this.userFullName =
                item.personalInfo.firstName + item.personalInfo.lastName;
            this.userPhoneNumber = item.personalInfo.contactInfo.phoneNumber;
            this.userEmail = item.personalInfo.contactInfo.email;
        },

        async getLevels() {
            const response = await LevelController.getLevel();
            // console.log("responce " , response.status)

            if (response.status == 200) {
                if (response.data.levels.length > 0) {
                    this.levels = response.data.levels;
                    // console.log("bsfebdsdbbvjf" , this.levels)
                } else {
                    this.levels = [];
                }
            }

            // console.log("level data", this.levelData.rows);
        },
        async fetchAssessment() {
            const response = await AssessmentController.getAssessments();
            // console.log("response from ", response);

            if (response.status == 200) {
                if (response.data.assessments.length > 0) {
                    this.assessments = response.data.assessments;
                } else {
                    this.assessments = [];
                }
            }

            // console.log(response.data.data);
        },

        toCamelCaseFromPascalCase(str) {
            return str.charAt(0).toLowerCase() + str.slice(1);
        },


        getCount(status) {
            if (!this.countFlag) return 0;

            const statusMapping = {
                Live: 'LiveCount',
                Expired: 'ExpiredCount',
                Scheduled: 'ScheduleCount',
            };

            const key = statusMapping[status];
            const audienceTypeMapping = {
                JOB_SEEKER: `seeker${key}`,
                TEACHER: `vgos${key}`,
                All: this.toCamelCaseFromPascalCase(key),
                MESTA_STUDENTS: `mesta${key}`,
            };

            return this.allCampaignCount?.[audienceTypeMapping[this.audienceType]] || 0;
        },

    },
    mounted() {
        // this.getCampaigns();
        this.getClusterOrSchool();
        // add event listener for beforeunload event
        window.addEventListener("beforeunload", this.showAlert);
    },
    beforeUnmount() {
        // remove event listener when component is unmounted
        window.removeEventListener("beforeunload", this.showAlert);
    },

    created() {
        this.campaignStatus = Number(this.$route.query.campaignStatusGroupIndex);
        this.currentStatus = this.$route.query.campaignStatusGroupName
        this.audienceType = this.$route.params?.audienceType || 'All';
        // this.setupSnapshotForClusterAndSchool();
        this.getCampaignsCount();
        this.schoolLevelHiredRejSnapshot();
        this.schoolLevelAppraisalSnapshot();
        this.schoolLevelAccessedUnaccessedJobSeekerSnapshot();
        this.schoolLevelAccessedUnaccessedSnapshot();
        this.schoolLevelTotalCampaignJobSeekerSnapshot();
        this.schoolLevelTotalCampaignSnapshot();
        this.clusterLevelHiredRejSnapshot();
        this.clusterLevelAppraisalSnapshot();
        this.clusterLevelAccessedUnaccessedJobSeekerSnapshot();
        this.clusterLevelAccessedUnaccessedSnapshot();
        this.clusterLevelTotalCampaignJobSeekerSnapshot();
        this.clusterLevelTotalCampaignSnapshot();
        this.setupSnapshotForAssessingUsers();
        this.setupSnapshotForTotalCampaign();
        this.getCampaigns();
        this.getLevels();
        this.fetchAssessment();
        this.getSkills();
        this.getSubjects();
        this.fetchAllClusters();
        // this.getSchool();
        // this.fetchUsers(); // change
        // if (!this.$store.state.role.campaign.read) {
        //   this.$router.push("/notFound");
        // }
    },
};
</script>

<style scoped>
.chart-clas {
    height: 132px;
}

.graph {
    border-radius: 8px !important;
}

.card-container {
    position: relative;
    perspective: 1000px;
}

.card-content {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    background-color: #ffffff;
}

.card-content.flipped {
    transform: rotateY(180deg);
}

.link-container {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2px;
}

.flip-link {
    color: #007fff;
    cursor: pointer;
    font-weight: bold;
}

.v-application .primary--text {
    color: #864f20 !important;
    caret-color: #864f20 !important;
}

.padCampaignPage {
    padding-top: 60px;
}
</style>
