import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});


export default {
    getSchool: async function () {
        try {
            const response = await instance.get('schools')
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getClusterSchool: async function (cluster) {
        console.log('getClusterSchool',cluster)
        try {
            const response = await instance.put('getClusterSchool',{cluster})
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getSchoolByPagiation: async function (query) {
        try {
            const response = await instance.get('schools'+query)
            return response;
        } catch (error) {
            return error.response;
        }
    },


    updateSchool: async function (data,id) {
               try {
            const response = await instance.put('school?id='+id, 
                data
            //     ,{
            //     headers: {
            //         'Authorization': AuthService.getToken()
            //       }
            // }
            )
            return response;
        } catch (error) {
            return error.response;
        }
    },


    

   createSchool : async function (data) {
    
        try {
            console.log("data from school controller")
            const response = await instance.post('school',data       

            //     ,{
            //     headers: {
            //         'Authorization': AuthService.getToken()
            //       }
            // }
            
            )
            return response;
        } catch (error) {
            return error.response;
        }
    },



    deleteSchool : async function (ids) {
        console.log("delete selected id",ids)
        try {
            const response = await instance.delete('schools',
            {
                data: ids
            }




            //  { headers: {
            //     'Authorization': AuthService.getToken()
            //   }
            // }
            
            
            )
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkSchool : async function (ids) {
        console.log("delete",ids)
        try {
            const response = await instance.delete('schools'+ids,
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    filterSchool : async function (brands,clusters, boards ,subjects){
        console.log("filter function called");
        try{
            const response = await instance.put('/filterSchools',{brands,clusters,boards,subjects},
            {
                headers:{
                    'Authorization':AuthService.getToken()
                }
            })
            return response;
        }
        catch (error){
            return error.response; 
        }
    },
    searchSchool: async function (pageSize,page , search) {
        try {
            const response = await instance.get('meta/schools?pageSize='+pageSize+'&page='+page+'&search='+search)
            return response;
        } catch (error) {
            return error.response;
        }
    },

}