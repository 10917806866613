import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});
export default {
    getCategory: async function () {
        try {
            const response = await instance.get('subject-categories')
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getCategoryByPagination: async function (query) {
        try {
            const response = await instance.get('subject-categories'+query);
            return response;
        } catch (error) {
            return error.response;
        }
    },



    updateCategory: async function (name, description,id) {
               try {
            const response = await instance.put('subject-category?id='+id, {
                "name": name,
                "description": description

            },{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },


    

   createCategory : async function (name, description) {
        try {
            const response = await instance.post('subject-category', {
                "name": name,
                "description": description,
                

            },{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },



    deleteCategory : async function (id) {
        console.log("delete selected url link",'admin/meta/subject-categories/'+id)
        try {
            const response = await instance.delete('admin/meta/subject-categories/'+id,
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkCategory : async function (ids) {
        console.log("delete ",ids)
        try {
            const response = await instance.delete('subject-categories',{
                data:{
                    ids:ids
                }
            },
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    searchSubCat: async function (pageSize,page,search) {
        try {
            const response = await instance.get('meta/subject-categories?pageSize='+pageSize+'&page='+page+'&search='+search)
            return response;
        } catch (error) {
            return error.response;
        }
    },


}