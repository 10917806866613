export function getDateFromTimestamp (timeStamp) {
  const date = new Date(timeStamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? '0' + day : day;
  const formattedMonth = month < 10 ? '0' + month : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
}

export function getTime(d) {
  let ans = ""

  if (d) {
      let sec = parseInt(d % 60);
      let min = parseInt(d / 60);
      let hr = parseInt(min / 60);
      min = min % 60;

      if (hr > 0) {
          ans += `${hr} hrs `
      }
      if (min > 0) {
          ans += `${min} min `
      }
      if (sec > 0) {
          ans += `${sec} sec`
      }
  }
  return ans;
}