import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});


export default {
    getAudienceRoles: async function (query) {
        try {
            const response = await instance.get(`audienceRoles`+query);
            return response;
        } catch (error) {
            console.info('error: ', error.lineNumber)
            return error.response;
        }
    },
    updateAudienceRole: async function (data,id) {
        try {
            const response = await instance.put('audienceRoles?id='+id, data);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    createAudienceRoles : async function (data) {
        try {
            const response = await instance.post('audienceRoles', data);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteAudienceRole : async function (ids) {
        try {
            const response = await instance.delete('audienceRoles', { data: ids });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkAudienceRoles : async function (ids) {
        try {
            const response = await instance.delete('audienceRoles'+ids,
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getAudienceRoleGroups: async function(){
        try {
            const response = await instance.get(`getAudienceRoleGroups`);
            return response;
        } catch (error) {
            console.info('error: ', error.lineNumber)
            return error.response;
        }
    }

}