import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import UsersView from "../views/UsersView.vue";
import LoginView from "../views/LoginView.vue";

import MastersView from "../views/masters/MastersView.vue";
import ClustersView from "../views/masters/ClustersView.vue";
import SubjectCategoryView from "../views/masters/SubjectCategoryView.vue";
import BoardsView from "../views/masters/BoardsView.vue";
import DemoTopicsView from "../views/masters/DemoTopicsView.vue";

import BrandsView from "../views/masters/BrandsView.vue";
import LevelsView from "../views/masters/LevelsView.vue";
import LoBankView from "../views/masters/LOBankView.vue";
import SchoolsView from "../views/masters/SchoolsView.vue";
import SkillsView from "../views/masters/SkillsView.vue";
import SubjectsView from "../views/masters/SubjectsView.vue";
import GradesView from "../views/masters/GradesView.vue";
import SkillsWeightageThresholdsView from "../views/masters/SkillsWeightageThresholdsView.vue";
import AudienceRolesView from "../views/masters/AudienceRolesView.vue";

import TestView from "../views/TestView.vue";
import QuestionBankView from "../views/QuestionBankView.vue";
import AssessmentView from "../views/AssessmentView.vue";
import StaticAssessmentView from "../views/StaticAssessmentView.vue";
import CreateStaticAssessment from "../views/CreateStaticAssessment.vue";
import TeachersView from "../views/TeachersView.vue";
import JobSeekersView from "../views/JobSeekersView.vue";
import CampaignView from "../views/CampaignView.vue";
import AuthService from "../services/AuthService.js";
import CampaignUserView from "../views/CampaignUserView.vue";
import RoleView from "../views/RoleView.vue";
import CreateRoleView from "../views/CreateRoleView.vue";
import InvitedLogin from "../views/InvitedLogin.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";

import NotFoundView from "../views/NotFoundView.vue";
import CampaignTeachersResult from "../views/CampaignTeachersResult.vue";
import CampaignJobSeekersResult from "../views/CampaignJobSeekersResult.vue";

import TeachersAppraisal from "../views/TeachersAppraisal.vue";
import TeachersPIP from "../views/TeachersPIP.vue";
import TeachersOthers from "../views/TeachersOthers.vue";
import TeachersTBD from "../views/TeachersTBD.vue";
import JobSeekersAppraisal from "../views/JobSeekersAppraisal.vue";
import JobSeekersPIP from "../views/JobSeekersPIP.vue";
import JobSeekersTBD from "../views/JobSeekersTBD.vue";

import ClusterTeachersAppraisal from "../views/ClusterDashboardPages/ClusterTeachersAppraisal.vue";
import ClusterTeachersPIP from "../views/ClusterDashboardPages/ClusterTeachersPIP.vue";
import ClusterTeachersTBD from "../views/ClusterDashboardPages/ClusterTeachersTBD.vue";
import ClusterCampaignTeachersResult from "../views/ClusterDashboardPages/ClusterCampaignTeachersResult.vue";
import ClusterJobSeekersAppraisal from "../views/ClusterDashboardPages/ClusterJobSeekersAppraisal.vue";
import ClusterJobSeekersPIP from "../views/ClusterDashboardPages/ClusterJobSeekersPIP.vue";
import ClusterJobSeekersTBD from "../views/ClusterDashboardPages/ClusterJobSeekersTBD.vue";
import ClusterCampaignJobSeekersResult from "../views/ClusterDashboardPages/ClusterCampaignJobSeekersResult.vue";

import SchoolTeachersAppraisal from "../views/SchoolDashboardPages/SchoolTeachersAppraisal.vue";
import SchoolTeachersPIP from "../views/SchoolDashboardPages/SchoolTeachersPIP.vue";
import SchoolTeachersTBD from "../views/SchoolDashboardPages/SchoolTeachersTBD.vue";
import SchoolJobSeekersAppraisal from "../views/SchoolDashboardPages/SchoolJobSeekersAppraisal.vue";
import SchoolJobSeekersPIP from "../views/SchoolDashboardPages/SchoolJobSeekersPIP.vue";
import SchoolJobSeekersTBD from "../views/SchoolDashboardPages/SchoolJobSeekersTBD.vue";
import SchoolCampaignTeachersResult from "../views/SchoolDashboardPages/SchoolCampaignTeachersResult.vue";
import SchoolCampaignJobSeekersResult from "../views/SchoolDashboardPages/SchoolCampaignJobSeekersResult.vue";
import CreateTeacher from "../views/CreateTeacher.vue";
import createJobSeekerView from "../views/createJobSeekerView.vue";
import UpdateJobSeekerView from '../views/UpdateJobSeekerView.vue'

import UpdateTeacherView from "../views/UpdateTeacherView.vue";
import ForgetPassword from "../views/ForgetPassword.vue";


import CreateCampaign from "../views/CreateCampaign.vue";
import ViewSchoolData from "../views/ViewSchoolData.vue";

import UpdateRole from "../views/UpdateRole";

import LeagueView from "../views/masters/LeagueView.vue";
// import CharTestView from '../views/CharTestView.vue'

import CampaignCardFullView from "../views/CampaignCardFullView.vue";
import PsychometryView from '../views/PsychometryView.vue'
import DemoReport from '../views/DemoReport.vue'
import UserReport from '../views/UserReport.vue'
import TestReport from "../views/TestReport.vue"
import ScheduleInterView from "../components/ScheduleInterView.vue"
import InterviewFeedBackFrom from "../views/InterviewFeedbackForm.vue"
import InterviewReport from "@/views/InterviewReport.vue";
import JobSeekersOthers from "@/views/JobSeekersOthers.vue";
import QuestionPreview from "@/views/QuestionPreview.vue";
import Support from "@/views/Support.vue";
import RoleUser from "@/views/RoleUser.vue";
import StaticAssessmentEdit from "@/views/StaticAssessmentEdit.vue";
import EditStaticAssessment from "@/views/EditStaticAssessment.vue";
import MestaDashboard from "@/views/MestaDashboard.vue";
import MestaCampaignUser from "@/views/MestaCampaignUser.vue";
import AssessmentListingView from "@/views/AssessmentListingView.vue";
import AssessmentListingEditQuestion from "@/views/AssessmentListingEditQuestion.vue";

import UserTestReport from "@/views/UserTestReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
      title:'Hubble STAR-Admin'
    },
  },
  {
    path: "/campaignUser",
    name: "CampaignUserView",
    component: CampaignUserView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/interviewFeedback",
    name: "InterviewFeedbackForm",
    component: InterviewFeedBackFrom,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/campaignCardFullView/:audienceType",
    name: "CampaignCardFullView",
    component: CampaignCardFullView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/update-jobseeker",
    name: "UpdateJobSeekerView",
    component: UpdateJobSeekerView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/scheduleInterview",
    name: "scheduleInterview",
    component: ScheduleInterView,
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/create-jobseeker",
    name: "createJobSeekerView",
    component: createJobSeekerView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/create-teacher",
    name: "CreateTeacher",
    component: CreateTeacher,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/update-role",
    name: "UpdateRole",
    component: UpdateRole,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/update-teacher",
    name: "UpdateTeacherView",
    component: UpdateTeacherView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/createRole",
    name: "CreateRoleView",
    component: CreateRoleView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/registerAdmin",
    name: "InvitedLogin",
    component: InvitedLogin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPasswordView",
    component: ResetPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/roles",
    name: "RoleView",
    component: RoleView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaign",
    name: "campaignView",
    component: CampaignView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/teachers",
    name: "teachers",
    component: TeachersView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/jobseekers",
    name: "JobSeekersView",
    component: JobSeekersView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/question-bank",
    name: "question bank",
    component: QuestionBankView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assessment",
    name: "assessment",
    component: AssessmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/static-assessment",
    name: "static assessment",
    component: StaticAssessmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
  path: "/create-static-assessment",  
    name: "CreateStaticAssessment",
    component: CreateStaticAssessment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/static-assessment/edit",
    name: "static assessment edit",
    component: StaticAssessmentEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-static-assessment/",
    name: "edit static assessment",
    component: EditStaticAssessment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/masters",
    name: "masters",
    component: MastersView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/clusters",
    name: "clusters",
    component: ClustersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subject-category",
    name: "subjectCategory",
    component: SubjectCategoryView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/boards",
    name: "boards",
    component: BoardsView,
    meta: {
      requiresAuth: true,
    },
  },
  

  {
    path: "/demoTopics",
    name: "demoTopics",
    component: DemoTopicsView,
    meta: {
      requiresAuth: true,
    },
  },  {
    path: "/brands",
    name: "brands",
    component: BrandsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/grades",
    name: "grades",
    component: GradesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notFound",
    name: "NotFoundView",
    component: NotFoundView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/levels",
    name: "levels",
    component: LevelsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lo-banks",
    name: "lobanks",
    component: LoBankView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schools",
    name: "schools",
    component: SchoolsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/skills",
    name: "skills",
    component: SkillsView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/skills-weightage-thresholds",
    name: "skills-weightage-thresholds",
    component: SkillsWeightageThresholdsView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/audience-roles",
    name: "audience-roles",
    component: AudienceRolesView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/league",
    name: "LeagueView",
    component: LeagueView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/subjects",
    name: "subjects",
    component: SubjectsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/campaignteachersresult",  
    name: "campaignteachersresult",
    component: CampaignTeachersResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaignjobseekersresult",  
    name: "campaignjobseekersresult",
    component: CampaignJobSeekersResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teachersappraisal/:id",  
    name: "teachersappraisal",
    component: TeachersAppraisal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teacherspip",  
    name: "teacherspip",
    component: TeachersPIP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teachersothers",  
    name: "teachersothers",
    component: TeachersOthers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teacherstbd",  
    name: "teacherstbd",
    component: TeachersTBD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobseekersappraisal",  
    name: "jobseekersappraisal",
    component: JobSeekersAppraisal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobseekerspip",  
    name: "jobseekerspip",
    component: JobSeekersPIP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobseekerstbd",  
    name: "jobseekerstbd",
    component: JobSeekersTBD,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/jobSeekersOthers",  
    name: "jobSeekersOthers",
    component: JobSeekersOthers,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/clusterteachersappraisal/:id",  
    name: "clusterteachersappraisal",
    component: ClusterTeachersAppraisal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clusterteacherspip",  
    name: "clusterteacherspip",
    component: ClusterTeachersPIP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clusterteacherstbd",  
    name: "clusterteacherstbd",
    component: ClusterTeachersTBD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clustercampaignteachersresult",  
    name: "clustercampaignteachersresult",
    component: ClusterCampaignTeachersResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clusterjobseekersappraisal",  
    name: "clusterjobseekersappraisal",
    component: ClusterJobSeekersAppraisal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clusterjobseekerspip",  
    name: "clusterjobseekerspip",
    component: ClusterJobSeekersPIP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clusterjobseekerstbd",  
    name: "clusterjobseekerstbd",
    component: ClusterJobSeekersTBD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clustercampaignjobseekersresult",  
    name: "clustercampaignjobseekersresult",
    component: ClusterCampaignJobSeekersResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schoolteachersappraisal/:id",  
    name: "schoolteachersappraisal",
    component: SchoolTeachersAppraisal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schoolteacherspip",  
    name: "schoolteacherspip",
    component: SchoolTeachersPIP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schoolteacherstbd",  
    name: "schoolteacherstbd",
    component: SchoolTeachersTBD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schooljobseekersappraisal",  
    name: "schooljobseekersappraisal",
    component: SchoolJobSeekersAppraisal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schooljobseekerspip",  
    name: "schooljobseekerspip",
    component: SchoolJobSeekersPIP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schooljobseekerstbd",  
    name: "schooljobseekerstbd",
    component: SchoolJobSeekersTBD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schoolcampaignteachersresult",  
    name: "schoolcampaignteachersresult",
    component: SchoolCampaignTeachersResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schoolcampaignjobseekersresult",  
    name: "schoolcampaignjobseekersresult",
    component: SchoolCampaignJobSeekersResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/CreateCampaign",  
    name: "CreateCampaign",
    component: CreateCampaign,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/view/:schoolName", // Seeing here View Each School data
    name: "ViewSchoolData",
    component: ViewSchoolData,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/psychometry", // Seeing here View Each School data
    name: "PsychometryView",
    component: PsychometryView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demoreport/:campaignId/:userId", 
    name: "DemoReport",
    component: DemoReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/userreport", 
    name: "UserReport",
    component: UserReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/testreport/:campaignId/:userId",
    name: "TestReport",
    component: TestReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
      path: "/interviewReport/:campaignId/:userId", 
      name: "InterviewReport",
      component: InterviewReport,
      meta: {
        requiresAuth: true,
      },
  },
  {
      path: "/questionPreview",
      name: "QuestionPreview",
      component:QuestionPreview,
      meta:{
        requiresAuth: true,
      }
  },
  {
      path: "/support",
      name: "Support",
      component:Support,
      meta:{
        requiresAuth: true,
      }
  },
  {
    path: "/roleUser",
    name: "roleUserView",
    component: RoleUser,
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/mestacampaignuser",
    name: "mestaCampaignUser",
    component: MestaCampaignUser,
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/assessment-listing-view",
    name: "assessmentListingView",
    component: AssessmentListingView,
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/userTestReport/",
    name: "userTestReport",
    component: UserTestReport,
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/assessment-listing-edit-question",
    name: "assessmentListingEditQuestion",
    component: AssessmentListingEditQuestion,
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path:"/mestaDashboard",
    name:"mestaDashboard",
    component: MestaDashboard,
    meta:{
      requiresAuth: true,
    }
  }
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "Hubble STAR-Admin"
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!AuthService.isAuthenticated()) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
