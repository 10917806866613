<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else>
    <v-card
      height="80px"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Campaigns</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
        style="position: fixed; right: 0"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <v-hover>
            <img
              @click="notificationDialog = true"
              class="cursor"
              src="../assets/bell 1.svg"
            />
          </v-hover>
        </v-badge>
        <div>
          <img
            src="../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad pb-0 px-8">
      <!-- Notification Dialog -->
      <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
        <Notification @close-dialog="notificationDialog = false" />
      </v-dialog>

      <!-- QR Code Dialog -->
      <v-dialog v-model="qrDialog" class="cdz" persistent max-width="500px">
        <div v-if="qrDialog">
          <v-card>
            <v-container fluid class="pa-8">
              
              <v-icon size="24" class="pt-4" @click="qrDialog=false">mdi-close</v-icon>

              <v-card-text class="text-center d-flex flex-column justify-center align-center">
                <div ref="qrDownloadRef" style="margin:8px; padding: 16px;">
                    <p class="text-h5 py-1 mx-1" style="font-weight: bold;">
                      {{ qrCampaignName }}
                    </p>
                    <VueQRCodeComponent style="display: flex; justify-content: center; margin-bottom: 8px; padding: 16px;" :text="qrCampaignLink" error-level="L"></VueQRCodeComponent>
                </div>
                <v-btn
                  class="btn primary-btn"
                  large
                  width="162px"
                  rounded
                  :loading="qrDownloadLoading"
                  @click="downloadQrCode">Download</v-btn>
              </v-card-text>
            </v-container>
          </v-card>

        </div>
      </v-dialog>

      <!-- Filter Dialog -->

      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div
                @click="clearFilter"
                class="clear-filter-btn"
              >
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>

          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <v-tab class="black--text justify-start" active-class="activeTab"
                >
                <span>Clusters</span>
                <span v-if="selectedClusterFilters.length > 0" class="ml-1"
                  >({{ selectedClusterFilters.length }})</span
                ></v-tab
              >
              <v-tab class="black--text justify-start" active-class="activeTab"
                >
                <span>Schools</span>
                <span v-if="selectedSchoolFilter.length > 0" class="ml-1"
                  >({{ selectedSchoolFilter.length }})</span
                ></v-tab
              >
              <v-tab class="black--text justify-start" active-class="activeTab"
                >
                <span>Levels</span>
                <span v-if="selectedLevelFlter.length > 0" class="ml-1"
                  >({{ selectedLevelFlter.length }})</span
                ></v-tab
              >
              <v-tab class="black--text justify-start" active-class="activeTab"
                >
                <span>Skills</span>
                <span v-if="selectedSkillsFilter.length > 0" class="ml-1"
                  >({{ selectedSkillsFilter.length }})</span
                ></v-tab
              >
            </div>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedClusterFilters"
                    active-class="active-chip"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(clusterType, index) in clusters"
                      :key="index"
                      :value="clusterType.name"
                      elevated
                    >
                      {{ clusterType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card
                v-if="selectedClusterFilters.length > 0"
                class="school-filter"
              >
                <v-card-text>
                  <v-chip-group
                    v-model="selectedSchoolFilter"
                    active-class="active-chip"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(schoolType, index) in schoolsData"
                      :key="index"
                      :value="schoolType.name"
                      elevated
                    >
                      {{ schoolType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
              <v-card
                elevation="0"
                v-if="selectedClusterFilters.length == 0"
                class="school-filter"
              >
                <v-card-text class="d-flex justify-center">
                  Please Select Cluster First
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedLevelFlter"
                    active-class="active-chip"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(levelType, index) in levels"
                      :key="index"
                      :value="levelType.name"
                      elevated
                    >
                      {{ levelType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedSkillsFilter"
                    active-class="active-chip"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(skillType, index) in skills"
                      :key="index"
                      :value="skillType.name"
                      elevated
                    >
                      {{ skillType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-0">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                class="btn outline-btn pa-4"
                @click="closeFilter"
                >Cancel</v-btn
              >
              <v-btn
                :disabled="
                  selectedClusterFilters.length == 0 &&
                  selectedSchoolFilter.length == 0 &&
                  selectedLevelFlter.length == 0 &&
                  selectedSkillsFilter.length == 0
                "
                :loading="isFilterLoading"
                rounded
                class="btn primary-btn pa-4"
                @click="
                  (appliedFilter = true),
                    filterCampaign(
                      selectedSchoolFilter,
                      selectedClusterFilters,
                      selectedLevelFlter,
                      selectedSkillsFilter
                    ),
                    getFilterCount()
                "
                >Apply</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

    

      <v-dialog
        max-width="880px"
        class="cdz"
        v-model="UpdateCampaignDialog"
        center
      >
        <v-form ref="updateForm" lazy-validation>
          <v-card>
            <v-card-title class="mb-2 pl-7 gray-02"
              >Update Campaign Date</v-card-title
            >
            <v-card-title>
              <v-col>
                <div v-if="campaignType=='JOB_SEEKER'" class="text-body-1 pl -2 pb-1">Screening End Date*</div>
                <v-text-field
                  v-if="campaignType=='JOB_SEEKER'"
                  outlined
                  label="Start Date"
                  v-model="screeningEndDate"
                  rounded
                  class="rounded-xl elevation-0"
                  solo
                  flat
                  :rules="[screeningEndDateRule]"
                  type="date"
                ></v-text-field>
                <div class="text-body-1 pl -2 pb-1">{{campaignType=="MESTA_STUDENTS"? "Campaign End Date*" : "Mains End Date*"}}</div>
                <v-text-field
                  outlined
                  label="Start Date"
                  v-model="computedEndDate"
                  rounded
                  class="rounded-xl elevation-0"
                  solo
                  flat
                  :rules="campaignType == 'MESTA_STUDENTS' ? [endDateRule] : [mainsEndDateRule]"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-card-title>
            <v-card-actions class="px-6 pb-6">
              <small>*All fields are mandatory</small>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                width="102px"
                height="48px"
                class="btn outline-btn pa-4"
                @click="
                  () => {
                    UpdateCampaignDialog = false;
                  }
                "
                >Cancel</v-btn
              >
              <v-btn
                width="102px"
                height="48px"
                rounded
                class="btn primary-btn pa-4"
                :loading="UpdateCampaignDialogLoading"
                @click="camapignUpdate"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- sucess dialog -->

      <v-dialog
        v-model="successDialog"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-1">
                New Campaign {{ campaignButtonValues() }}
              </p>
              <div class="w-100% mb-2" color="">
                <v-btn  @click="copyToClipboardCampaign()" text color="#1B72E8"
                  ><span
                    ><v-icon>mdi-link-variant</v-icon>Copy Link</span
                  ></v-btn
                >
              </div>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="successDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>



      <v-dialog v-model="screeingEndTimeDialog" max-width="500px">
        <v-card fluid>
          <v-time-picker
            v-model="screeningEndTime"
            class=""
            format="24hr"
            landscape="landscape"
            @change="screeingEndTimeDialog = false"
            elevation="0"
          ></v-time-picker>
        </v-card>
      </v-dialog>

      <v-dialog v-model="mainsEndTimeDialog" max-width="500px">
        <v-card fluid>
          <v-time-picker
            v-model="mainsEndTime"
            class=""
            format="24hr"
            landscape="landscape"
            @change="mainsEndTimeDialog = false"
            elevation="0"
          ></v-time-picker>
        </v-card>
      </v-dialog>



      <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-icon size="70" class="pt-4">mdi-trash-can-outline</v-icon>
              <p class="text-h5 pt-4 pb-0">Delete User</p>
              <p
                class="text-disabled grey--text text-subtitle-1"
                color="rgba(0, 0, 0, 0.6)"
                disabled
              >
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn
                  depressed
                  class="black--text"
                  large
                  width="157px"
                  rounded
                  @click="deleteDialog = false"
                  >CANCEL</v-btn
                >

                <v-btn
                  class="accent white--text"
                  @click="
                    deleteCampaign(selectedId),
                      (deleteSuccessDialog = true),
                      (deleteDialog = false)
                  "
                  depressed
                  large
                  width="157px"
                  rounded
                  >DELETE</v-btn
                >
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="deleteSuccessDialog"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 font-weight-bold py-4">Campaign Deleted</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="deleteSuccessDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

   

      <!-- first row -->
      <div class="w-100 d-flex flex-row background fixCampaignRow">
        <v-card
          elevation="0"
          class="background mb-4 mr-3"
          height="157px"
          width="16%"
        >
          <!-- <v-btn to="/users" width="195px" elevation="0" class="my-1 white rounded-xl text-subtitle-2 justify-start"><v-icon small class="pr-2"> mdi-bullhorn-outline</v-icon>
            ALL (5000)
          </v-btn> -->

          <!-- <v-btn
            elevation="1"
            @click="getTypeCampaign('All')"
            width="98%"
            height="32px"
            :outlined="pageName == 'All'"
            class="rounded-xl ma-2 justify-start blue-outline"
            >
            <v-icon small class="pr-2 rounded-xl"> mdi-bullhorn-outline</v-icon
            >ALL ({{ this.allCampaignCount }})</v-btn> -->

          <v-btn
            elevation="0"
            @click="changeAudienceCampaign(currentStatus,'All')"
            width="98%"
            style="height: 32px !important"
            :outlined="pageName == 'All'"
            class="rounded-lg ma-1 mt-2 justify-start blue-outline"
            :class="{
              selectedTab: pageName == 'All',
              unselectedTab: pageName != 'All',
            }"
          >
            <v-icon small class="pr-2 rounded-xl" > mdi-bullhorn-outline</v-icon
            >ALL ({{  this.countFlag ? 
              
              this.seekerLiveCampaignsCount +
              this.seekerScheduledCampaignsCount +
              this.seekerExpiredCampaignsCount +
              this.vgosLiveCampaignsCount +
              this.vgosScheduledCampaignsCount +
              this.vgosExpiredCampaignsCount +
              this.mestaLiveCampaignsCount +
              this.mestaScheduledCampaignsCount +
              this.mestaExpiredCampaignsCount
             : 0 }}) </v-btn
          >

          <v-btn
            elevation="0"
            @click="changeAudienceCampaign(currentStatus,'JOB_SEEKER')"
            width="98%"
            style="height: 32px !important"
            :outlined="pageName == 'JOB_SEEKER'"
            class="rounded-lg ma-1 justify-start blue-outline"
            :class="{
              selectedTab: pageName == 'JOB_SEEKER',
              unselectedTab: pageName != 'JOB_SEEKER',
            }"
            ><v-icon small class="pr-2"> mdi-briefcase-search-outline</v-icon>
            Jobseekers ({{ this.countFlag ? 
              this.seekerLiveCampaignsCount +
              this.seekerScheduledCampaignsCount +
              this.seekerExpiredCampaignsCount
              : 0
            }})
          </v-btn>
          <v-btn
            elevation="0"
            @click="changeAudienceCampaign(currentStatus,'TEACHER')"
            width="98%"
            style="height: 32px !important"
            :outlined="pageName == 'TEACHER'"
            class="rounded-lg ma-1 justify-start blue-outline"
            :class="{
              selectedTab: pageName == 'TEACHER',
              unselectedTab: pageName != 'TEACHER',
            }"
            ><v-icon small class="pr-2"> mdi-human-male-board</v-icon> VGOS ({{
              this.countFlag ? 
              this.vgosLiveCampaignsCount +
              this.vgosScheduledCampaignsCount +
              this.vgosExpiredCampaignsCount
              :0
            }})
          </v-btn>
          <v-btn
            elevation="0"
            @click="changeAudienceCampaign(currentStatus,'MESTA_STUDENTS')"
            width="98%"
            style="height: 32px !important"
            :outlined="pageName == 'MESTA_STUDENTS'"
            class="rounded-lg ma-1 justify-start blue-outline"
            :class="{
              selectedTab: pageName == 'MESTA_STUDENTS',
              unselectedTab: pageName != 'MESTA_STUDENTS',
            }"
            ><v-icon small class="pr-2"> mdi-briefcase-search-outline</v-icon>
            MESTA ({{ this.countFlag ? 
              this.mestaLiveCampaignsCount +
              this.mestaScheduledCampaignsCount +
              this.mestaExpiredCampaignsCount
              : 0
            }})
          </v-btn>
        </v-card>
        <!-- ### for all page -->
        <template v-if="roleCluster === 'All Cluster'">
          <v-card
            v-if="chartData === 'All'"
            width="80px"
            elevation="0"
            class="ma-2 mb-0 ml-3 cardBorder overflow-hidden rounded-lg"
          >
            <chart1 :stats="campaignStats" />
          </v-card>

          <v-card
            v-if="chartData === 'All'"
            width="80px"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart2 :stats="outcomeTeachers" />
          </v-card>

          <v-card
            v-if="chartData === 'All'"
            width="120px"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart3 :stats="outcomeJobSeekers" />
          </v-card>

          <v-card
            v-if="chartData === 'All'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart8 :stats="outcomeStudents" />
          </v-card>

          <!-- ### for job seekers-->

          <v-card
            v-if="chartData === 'JOB_SEEKER'"
            width="30%"
            height="100%"
            elevation="0"
            class="graph ma-2 mb-0 ml-3 cardBorder overflow-hidden rounded-lg"
          >
            <p class="mt-3 mb-4 ml-3" style="font-size: 12px; font-weight: 600">
              Live Campaign per level
            </p>
            <v-row class="ml-1">
              <v-col class="my-1 py-0" style="font-size: 12px">
                Foundational -
                <b>{{ this.countCampaignByLevel.foundationalCount }}</b>
              </v-col>
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Preparatory -
                <b>{{ this.countCampaignByLevel.preparatoryCount }}</b></v-col
              >
            </v-row>
            <v-row class="ml-1">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Secondary -
                <b>{{ this.countCampaignByLevel.secondaryCount }}</b></v-col
              >
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Senior Secondary -
                <b>{{
                  this.countCampaignByLevel.seniorSecondaryCount
                }}</b></v-col
              >
            </v-row>
            <v-row class="text-center">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Middle -
                <b>{{ this.countCampaignByLevel.middleCount }}</b></v-col
              >
            </v-row>
          </v-card>

          <v-card
            v-if="chartData === 'JOB_SEEKER'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart4 />
          </v-card>

          <v-card
            v-if="chartData === 'JOB_SEEKER'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart5 :stats="recruitmentJobSeeker" />
          </v-card>

          <!-- ##### for VGOS teacehr -->

          <v-card
            v-if="chartData === 'TEACHER'"
            width="28%"
            height="132px"
            elevation="0"
            class="graph ma-2 mb-0 ml-3 cardBorder overflow-hidden rounded-lg"
          >
            <v-card-text  style="text-align: left" class="py-0 pl-0 pr-2">
              <FlipCard :stats="allCampaignCount.vgosLevelLiveCount" />
            </v-card-text>
          </v-card>

          <v-card
            v-if="chartData === 'TEACHER'"
            width="25%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart6 :stats="outcomeTeachers" />
          </v-card>

          <v-card
            v-if="chartData === 'TEACHER'"
            width="25%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart7 :stats="teacherAppraisal" />
          </v-card>

          <!-- ### for job seekers-->

        </template>

        <!-- for cluster principal -->
        <template v-if="'All Cluster' !== 'All Clusters' && 'All' == 'All'">
          <v-card
            v-if="chartData === 'All'"
            width="28%"
            elevation="0"
            class="ma-2 mb-0 ml-3 cardBorder overflow-hidden rounded-lg"
          >
            <chart1 :stats="campaignStats" />
          </v-card>

          <v-card
            v-if="chartData === 'All'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart2 :stats="outcomeTeachers" />
          </v-card>

          <v-card
            v-if="chartData === 'All'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart3 :stats="outcomeJobSeekers" />
          </v-card>

          <v-card
            v-if="chartData === 'All'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart8 :stats="outcomeStudents" />
          </v-card>


          <!-- ### for job seekers-->

          <v-card
            v-if="chartData === 'JOB_SEEKER'"
            width="30%"
            height="132px"
            elevation="0"
            class="graph ma-2 mb-0 ml-3 cardBorder overflow-hidden rounded-lg"
          >
            <p class="mt-3 mb-4 ml-3" style="font-size: 12px; font-weight: 600">
              LIVE CAMPAIGN PER LEVEL
            </p>
            <v-row class="ml-1">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Foundational -
                <!-- <b>{{ this.countCampaignByLevel.foundationalCount }}</b> -->
                <b>{{ this.allCampaignCount.seekerLevelLiveCount["Foundational"] || 0 }}</b>
                </v-col
              >
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Preparatory -
                <b>{{ this.allCampaignCount.seekerLevelLiveCount?.Preparatory || 0  }}</b></v-col
              >
            </v-row>
            <v-row class="ml-1">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Secondary -
                <b>{{ this.allCampaignCount.seekerLevelLiveCount?.Secondary || 0  }}</b></v-col
              >
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Senior Secondary -
                <b>{{ this.allCampaignCount.seekerLevelLiveCount['Senior Secondary']}}</b></v-col
              >
            </v-row>
            <v-row class="text-center">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Middle -
                <b>{{ this.allCampaignCount.seekerLevelLiveCount?.Middle || 0  }}</b></v-col
              >
            </v-row>
          </v-card>

          <v-card
            v-if="chartData === 'JOB_SEEKER'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart4 :stats="outcomeJobSeekers" />
          </v-card>

          <v-card
            v-if="chartData === 'JOB_SEEKER'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart5 :stats="recruitmentJobSeeker" />
          </v-card>

          <!-- ##### for VGOS teacehr -->

          <v-card
            v-if="chartData === 'TEACHER'"
            width="32%"
            height="132px"
            elevation="0"
            class="graph ma-2 mb-0 ml-3 cardBorder overflow-hidden rounded-lg"
          >
            <v-card-text style="text-align: left" class="py-0 pl-0 pr-2">
              <FlipCard :stats="allCampaignCount.vgosLevelLiveCount" :clusterStats="allCampaignCount.vgosClusterLiveCount"/>
            </v-card-text>
          </v-card>

          <v-card
            v-if="chartData === 'TEACHER'"
            width="25%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart6 :stats="outcomeTeachers" />
          </v-card>

          <v-card
            v-if="chartData === 'TEACHER'"
            width="25%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart7 :stats="teacherAppraisal" />
          </v-card>

          <!-- <v-card
            v-if="chartData === 'MESTA_STUDENTS'"
            width="30%"
            height="132px"
            elevation="0"
            class="graph ma-2 mb-0 ml-3 cardBorder overflow-hidden rounded-lg"
          >
            <p class="mt-3 mb-4 ml-3" style="font-size: 12px; font-weight: 600">
              LIVE CAMPAIGN LEVEL
            </p>
            <v-row class="ml-1">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Foundational -
                
                <b>{{ this.allCampaignCount.mestaLevelLiveCount["Foundational"] || 0 }}</b>
                </v-col
              >
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Preparatory -
                <b>{{ this.allCampaignCount.mestaLevelLiveCount?.Preparatory || 0  }}</b></v-col
              >
            </v-row>
            <v-row class="ml-1">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Secondary -
                <b>{{ this.allCampaignCount.mestaLevelLiveCount?.Secondary || 0  }}</b></v-col
              >
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Senior Secondary -
                <b>{{ this.allCampaignCount.mestaLevelLiveCount['Senior Secondary']}}</b></v-col
              >
            </v-row>
            <v-row class="text-center">
              <v-col class="my-1 py-0" style="font-size: 12px"
                >Middle -
                <b>{{ this.allCampaignCount.mestaLevelLiveCount?.Middle || 0  }}</b></v-col
              >
            </v-row>
          </v-card> -->
          

          <v-card
            v-if="chartData === 'MESTA_STUDENTS'"
            width="27%"
            elevation="0"
            class="ma-2 mb-0 cardBorder overflow-hidden rounded-lg"
          >
            <chart8 :stats="outcomeStudents" />
          </v-card>
        </template>

        <!-- for  school Prinicpal-->
      </div>

      <!-- second row create filter etc -->
      <v-row justify="space-between" align="center" class="ma-0 mt-4 mb-2 pa-0">
        <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
          <div class="text-h6">All Campaigns{{ pgNameMappign.get(pageName) }}</div>
        </v-col>

        <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
          <v-card width="350px" class="elevation-0 transparent">
            <v-text-field
              solo
              label="Search here..."
              hide-details
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              class="search-bar border-button rounded-pill mx-3"
              dense
              clearable
              @input="searchData"
            >

          </v-text-field>
          </v-card>
          <!-- <v-btn  rounded outlined class="border-button mx-3"
            ><v-icon>mdi-tune</v-icon>
            <span v-if="filterCount > 0" @click="filterDialog = true"
              >Filter12 ({{ filterCount }})</span
            >
            <span v-else @click="filterDialog = true">Filter23</span>
          </v-btn> -->

          <v-btn @click="filterDialog = true" class="btn outline-btn elevation-0 pl-4 pr-6 d-flex flex-row justify-center align-center">
            <span class="icon"><v-icon>mdi-tune</v-icon></span> <!-- Add your icon here -->
            <span v-if="filterCount > 0">FILTER ({{ filterCount }})</span>
            <span v-else >FILTER</span>
          </v-btn>
          <v-btn
            depressed
            outlined
            rounded
            :loading="loaderForDownloadCampaigns"
            @click="downloadCampaigns"
            class="btn outline-btn elevation-0 mx-3"
            ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn
          >
          
        <v-menu offset-y v-if="$store.state.role.campaign.admin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                class="btn primary-btn mx-3 pl-4 pr-6"
                v-bind="attrs"
                v-on="on"
                rounded
                dark
                :v-show="['TEACHER','JOB_SEEKER','MESTA_STUDENTS', 'All' ].includes(pageName)"
                @click="goToCampaignCreationForm(pageName)"
                ><v-icon class="pr-2">mdi-plus</v-icon> Create</v-btn>
            </template>
            <v-list v-if="pageName == 'All'">
              <v-list-item
                @click="handleClick('TEACHER')"
              >
                <v-list-item-title>
                  <v-icon>mdi-email-plus-outline</v-icon> FOR VGOS TEACHERS
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="handleClick('JOB_SEEKER')"
              >
                <v-list-item-title>
                  <v-icon>mdi-email-plus-outline</v-icon> FOR JOB SEEKERS
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="handleClick('MESTA_STUDENTS')"
              >
                <v-list-item-title>
                  <v-icon>mdi-email-plus-outline</v-icon> FOR MESTA STUDENTS
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>



      <!-- live closed scheduled arrived -->
      <v-row justify="space-between" class="ma-0 pa-0 mt-2">
        <v-col cols="6" class="ma-0 pa-0">
          <v-chip-group mandatory v-model="campaignStatus" class="">
            <v-chip class="mr-4" :color="campaignStatus == 0 ? 'active-chip' : ''" @click="getCampaigns('Live')">
              LIVE ({{ this.countFlag ? liveCount: 0 }})
            </v-chip>
            <v-chip class="mr-4" :color="campaignStatus == 1 ? 'active-chip' : ''" @click="getCampaigns('Expired')">
              CLOSED ({{ this.countFlag ? expiredCount: 0 }})
            </v-chip>
            <v-chip class="mr-4" :color="campaignStatus == 2 ? 'active-chip' : ''" @click="getCampaigns('Scheduled')">
              SCHEDULED ({{ this.countFlag ? scheduledCount: 0 }})
            </v-chip>
            <v-chip class="mr-4" :color="campaignStatus == 3 ? 'active-chip' : ''" >
              ARCHIVED ({{ 0 }})
            </v-chip>
          </v-chip-group>
        </v-col>

        <v-col cols="6" class="pr-3 pa-0 ma-0 d-flex justify-end align-center">
          <v-icon color="#06C270CC" id="closeFilterId" class="mr-2 ml-4">mdi-circle</v-icon>
          Assessed
          <v-icon class="mr-2 ml-4" color="#1B72E8B2">mdi-circle</v-icon> In
          progress
          <v-icon class="mr-2 ml-4" color="#0000001F">mdi-circle</v-icon>
          Yet to attempt

          <v-btn
            @click="$router.push({path: `/campaignCardFullView/${audienceType}`, query:{campaignStatusGroupName:currentStatus, campaignStatusGroupIndex: campaignStatus }})"
            class="btn outline-btn cursor rounded-xl elevation-0 ml-4 px-2"
            style="text-transform: none"
            >View All Campaigns</v-btn
          >
        </v-col>
      </v-row>
      <div class="camapigns-cards">
      <!--  live campaign -->
      <div v-if="this.liveCampaigns.length != 0 && campaignStatus == 0" class="d-flex flex-row mt-3">

        <div
        class="d-flex flex-row pb-4 campaign-card pb-4"
        v-for="(data, i) in liveCampaigns" :key="i"  v-if="i < 3"
        
      >
        <!-- campaign card  -->
  
          <v-card
           
            elevation="1"
            width="100%"
            max-width="100%"
            min-width="350px"
            min-height="300px"
            class="pt-5 px-5 mr-4 overflow-hidden cardBorder"
          >
            <div
              class="d-flex align-center justify-center"
              :class="data.audienceType == 'TEACHER'? 
                'tiled-title-teacher' : ['MESTA_STUDENTS', 'DUMMY'].includes(data.audienceType) ?
                  'tiled-title-mesta-student' : 'tiled-title-job-seeker'"
            >
            {{ data.audienceType == "TEACHER" ? "VGOS" : data.audienceType == "JOB_SEEKER" ? "JOB SEEKER" : data.audienceType == "MESTA_STUDENTS" ? "MESTA" : "DUMMY" }}
            </div>
            <v-row v-if="$store.state.role.campaign.admin">
              <v-spacer></v-spacer>
              <img
                width="28px"
                height="28px"
                @click="campaignUpdate(data)"
                class="cursor"
                src="../assets/edit.svg"
              />
            </v-row>

            <v-chip color="#06C2700F" small pill>
              <div class="c-dot mr-2"></div>

              Live
            </v-chip>

            <div
              @click="goToUser(data.id, data.name, data.audienceType , data.schools)"
              class="cursor d-flex flex-column"
            >
              <v-card-title class="pa-0 d-flex-none campaign-title">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ data.name }}
                    </span>
                  </template>
                  <span>{{ data.name }}</span>
                </v-tooltip>
              </v-card-title>
              <div class="my-2 text-subtitle-1">
                <!-- Assessment title -->
                <div class="d-flex flex-row w-100 flex-wrap pb-4 levels-chip-container">
                  <div
                    v-for="(item, index) in data.levels"
                    :key="index"
                    class="w-fit"
                  >
                    <v-chip
                      small
                      class="mr-2 mb-2"
                      v-if="item != null"
                      color="#9cb9fba1"
                    >
                      {{ item }}
                    </v-chip>
                  </div>
                </div>
                <div class="w-100 d-flex justify-space-between">
                  <v-tooltip bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.subjects.length }} Subjects
                      </span>
                    </template>
                    <span>{{ data.subjects.join(", ") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="data.audienceType == 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data?.assessments?.length || 0 }} Assessments
                      </span>
                    </template>
                    <div v-html="getNames(data?.assessments)"></div>
                  </v-tooltip>

                  <v-tooltip v-if="data.audienceType !== 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.schools.length }} Schools
                      </span>
                    </template>
                    <div v-html="getNames(data.schools)"></div>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex flex-row my-5 card-sub-text">
                <v-icon class="text-medium-emphasis"
                  >mdi-calendar-blank-outline</v-icon
                >
                <div class="d-flex flex-column ml-4">
                  <div>From</div>
                  <div class="c-date">{{ data.startDate }}</div>
                </div>
                <div class="ml-8 mr-8 d-flex align-center">-</div>
                <div class="d-flex flex-column">
                  <div>Till</div>
                  <div class="c-date">
                    <span v-if="data.audienceType == 'MESTA_STUDENTS'">{{ data.endDate }}</span>
                    <span v-else>{{ data.mainsEndDate }}</span>
                  </div>
                </div>
              </div>

              <v-card
                v-if="data.totalAudience > 0"
                class="d-flex flex-row pa-0 ma-0 rounded-0"
                width="100%"
                height="10px"
                color="#D9D9D9"
                depressed
                elevation="0"
              >
                <v-card
                  class="d-flex flex-row pa-0 ma-0 rounded-0"
                  width="100%"
                  height="10px"
                  color="transparent"
                  depressed
                  elevation="0"
                >
                  <v-card
                    class="d-flex flex-row pa-0 ma-0 justify-center rounded-0 elevation-0"
                    :width="
                      getPercentage(
                        data.audienceAssessed,
                        data.totalAudience
                      )
                    "
                    height="10px"
                    color="blue"
                  >
                    <div
                      class="graph-value litegreen"
                      v-if="data.audienceAssessed !=0"
                    >
                      <!-- {{
                      getPercentate(
                        data.assessed,
                        data.assessed + data.yet_to_attempt
                      )
                    }} -->
                      {{
                        data.audienceAssessed
                      }}
                    </div>
                  </v-card>
                  <v-card
                    class="d-flex flex-row pa-0 ma-0 justify-center rounded-0 elevation-0"
                    :width="
                      getPercentage(
                        data.audienceInProgress,
                        data.totalAudience
                      )
                    "
                    height="10px"
                    color="blue"
                    elevation="0"
                  >
                    <div
                      class="graph-value liteblue"
                      v-if="data.audienceInProgress != 0"
                    >
                      {{data.audienceInProgress}}
                    </div>
                  </v-card>
                  <v-card
                    elevation="0"
                    class="d-flex flex-row pa-0 ma-0 justify-center rounded-0 elevation-0"
                    :width="
                      getPercentage(
                        data.audienceInvited,
                        data.totalAudience
                      )
                    "
                    height="10px"
                    color="gray-02"
                  >
                    <div
                      class="graph-value"
                      v-if="data.audienceInvited != 0"
                    >
                      <!-- {{
                      getPercentate(
                        data.yet_to_attempt,
                        data.assessed + data.yet_to_attempt
                      )
                    }} -->
                      {{
                        data.audienceInvited
                      }}
                    </div>
                  </v-card>
                </v-card>
              </v-card>

              <v-card
                v-if="data.totalAudience == 0"
                class="d-flex flex-row pa-0 ma-0 rounded-0"
                width="100%"
                height="10px"
                color="#D9D9D9"
                depressed
                elevation="0"
              >
                <v-card
                  class="d-flex flex-row pa-0 ma-0 rounded-0"
                  width="100%"
                  height="10px"
                  color="gray-02"
                  depressed
                  elevation="0"
                >
                </v-card>
              </v-card>
              
              <!-- temp display for audience count bar-->
              <!-- <v-card
              v-if="data.audienceType == 'MESTA_STUDENTS'"
              class="d-flex flex-row pa-0 ma-0 rounded-0"
              width="100%"
              height="10px"
              color="#D9D9D9"
              depressed
              elevation="0"
            >
              <v-card
                class="d-flex flex-row pa-0 ma-0 rounded-0"
                width="100%"
                height="10px"
                color="transparent"
                depressed
                elevation="0"
              >
              </v-card>
            </v-card> -->

              <div class="w-100 d-flex fex-row justify-space-between mt-12">
                <div class="w-50">
                  <v-btn v-if="isJobSeeker(data.audienceType)" @click="copyToClipboard(data.id, data.audienceType)" text color="#1B72E8"
                    ><v-icon>mdi-link-variant</v-icon></v-btn>

                  <v-btn v-if="isJobSeeker(data.audienceType)" :loading="data.isLoading" @click="generateQRCode(data)" text color="#1B72E8"><v-icon>mdi-qrcode</v-icon></v-btn>

                </div>
                <div :class="data.audienceType == 'MESTA_STUDENTS' ? 'hide-proctoring-autocomplete-for-mesta w-50': 'w-50'">
                  <v-autocomplete
                    label="Proctoring Type"
                    @change="selectProctoring(i)"
                    v-model="liveCampaigns[i].proctoringType"
                    :items="proctoringType"
                    outlined
                    rounded
                    dense
                    :readonly="!$store.state.role.campaign.admin"
                  ></v-autocomplete>
                </div>
              </div>
            </div>

            <div class="text-center pb-4">
              <v-btn
                outlined
                rounded                
                dark
                class="btn outline-btn w-100"
                v-if="$store.state.role.campaign.admin && data.audienceType != 'TEACHER'"
                @click="openAddUserDialog(data.id)"
              >
                Invite Users
              </v-btn>
              <v-btn
                outlined
                rounded                
                dark
                class="btn outline-btn w-100"
                v-if="$store.state.role.campaign.admin && data.audienceType == 'TEACHER'"
                @click="openAddTeacherDialog(data)"
              >
                Invite Teachers
              </v-btn>
            </div>
          </v-card>
    
        <!-- campaign card ending here           -->
      </div>
     
      </div>   
      <!-- scheduled campaign scroll view ending here -->
      <div
        class="d-flex flex-row pb-4 w-100"
        v-if="
          this.scheduledCampaigns.length > 0 &&
          this.scheduledCampaigns != undefined &&
          campaignStatus == 2 &&
          campaignStatus != undefined
        "
      >
        <!-- campaign card  -->

        <div v-for="(data, i) in this.scheduledCampaigns">
          <v-card
  elevation="1"
  min-width="450px"
  min-height="340px"
  class="pa-5 mr-4 overflow-hidden cardBorder"
>
  <div
    class="d-flex align-center justify-center"
    :class="data.audienceType == 'TEACHER'? 
      'tiled-title-teacher' : data.audienceType == 'MESTA_STUDENTS' ?
        'tiled-title-mesta-student' : 'tiled-title-job-seeker'"
  >
  {{ data.audienceType == "TEACHER" ? "VGOS" : data.audienceType == "MESTA_STUDENTS" ? "MESTA" : "JOB SEEKER" }}
  </div>
  <v-row v-if="$store.state.role.campaign.admin">
    <v-spacer></v-spacer>
    <img
      width="28px"
      height="28px"
      @click="campaignUpdate(data)"
      class="cursor"
      src="../assets/edit.svg"
    />
  </v-row>

  <v-chip color="#06C2700F" small pill>
    Scheduled
  </v-chip>

  <div class="d-flex flex-column">
    <v-card-title
      @click="goToUser(data.id, data.name, data.audienceType, data.schools)"
      class="pa-0 cursor d-flex-none campaign-title"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ data.name }}
          </span>
        </template>
        <span>{{ data.name }}</span>
      </v-tooltip>
    </v-card-title>
    <div class="my-2 text-subtitle-1">
      <div
        v-if="data.levels.length > 0 && data.levels.length <= 3"
        class="d-flex flex-row w-100 flex-wrap pb-4"
      >
        <div
          v-for="(item, index) in data.levels"
          :key="index"
          class="w-fit"
        >
          <v-chip
            small
            class="mr-2"
            v-if="item != null"
            color="#9cb9fba1"
          >
            {{ item }}
          </v-chip>
        </div>
      </div>
      <div
        v-if="data.levels.length > 0 && data.levels.length > 3"
        class="d-flex flex-row w-100 flex-wrap pb-4"
      >
        <div class="w-fit">
          <v-chip small class="mr-2" color="#9cb9fba1">
            {{ data.levels[0] }}
          </v-chip>
        </div>
        <div class="w-fit">
          <v-chip small class="mr-2" color="#9cb9fba1">
            {{ data.levels[1] }}
          </v-chip>
        </div>
        <div class="w-fit">
          <v-chip small class="mr-2" color="#9cb9fba1">
            {{ data.levels[2] }}
          </v-chip>
        </div>
      </div>
      <div class="w-100 d-flex justify-space-between">
        <v-tooltip bottom style="z-index: 999">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon class="pr-1">mdi-notebook-outline</v-icon>
              {{ data.subjects.length }} Subjects
            </span>
          </template>
          <span>{{ data.subjects.join(", ") }}</span>
        </v-tooltip>

          <v-tooltip v-if="data.audienceType == 'MESTA_STUDENTS'" bottom style="z-index: 999">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                {{ data?.assessments?.length || 0 }} Assessments
              </span>
            </template>
            <div v-html="getNames(data?.assessments)"></div>
          </v-tooltip>

        <v-tooltip v-if="data.audienceType !== 'MESTA_STUDENTS'" bottom style="z-index: 999">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon class="pr-1">mdi-notebook-outline</v-icon>
              {{ data.schools.length }} Schools
            </span>
          </template>
          <div v-html="getNames(data.schools)"></div>
        </v-tooltip>
      </div>
    </div>
  </div>
  <div>
    <div class="d-flex flex-row my-5 card-sub-text">
      <v-icon class="text-medium-emphasis"
        >mdi-calendar-blank-outline</v-icon
      >
      <div class="d-flex flex-column ml-4">
        <div>From</div>
        <div class="c-date">{{ data.startDate }}</div>
      </div>
      <div class="ml-8 mr-8 d-flex align-center">-</div>
      <div class="d-flex flex-column">
        <div>Till</div>
        <div class="c-date">
          <span v-if="data.audienceType == 'MESTA_STUDENTS'">{{ data.endDate }}</span>
          <span v-else>{{ data.mainsEndDate }}</span>
      </div>
      </div>
    </div>
    <div class="w-100% float-right mb-3" color="">
      <v-btn
        color="red"
        icon
        @click="
          deleteDialog = true;
          selectedId = data.id;
        "
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="isJobSeeker(data.audienceType)"
        @click="copyToClipboard(data.id, data.audienceType)"
        text
        color="#1B72E8"
      >
        <v-icon>mdi-link-variant</v-icon>
      </v-btn>

      <v-btn
        v-if="isJobSeeker(data.audienceType)"
        :loading="data.isLoading"
        @click="generateQRCode(data)"
        text
        color="#1B72E8"
      >
        <v-icon>mdi-qrcode</v-icon>
      </v-btn>
    </div>
  </div>
</v-card>

        </div>
        <!-- campaign card ending here           -->
      </div>
      <!--expired campaign scroll view ending here -->
      <div
        class="d-flex flex-row pb-4 w-100"
        v-if="this.expiredCampaigns.length > 0 && campaignStatus == 1"
      >
        <!-- campaign card visited  -->
        <div v-for="(data, i) in this.expiredCampaigns" :key="i">
          <v-card
            v-if="i < 3"
            elevation="1"
            width="32%"
            min-width="450px"
            min-height="340px"
            class="pa-5 mr-4  overflow-hidden cardBorder"
          >
            <div
              class="d-flex align-center justify-center"
              :class="data.audienceType == 'TEACHER'? 
                'tiled-title-teacher' : data.audienceType == 'MESTA_STUDENTS' ?
                  'tiled-title-mesta-student' : 'tiled-title-job-seeker'"
            >
              {{ data.audienceType == "TEACHER" ? "VGOS" : data.audienceType == "MESTA_STUDENTS" ? "MESTA" : "JOB SEEKER" }}
            </div>
            <v-row v-if="$store.state.role.campaign.admin">
              <v-spacer></v-spacer>
              <img
                width="28px"
                height="28px"
                @click="campaignUpdate(data)"
                class="cursor"
                src="../assets/edit.svg"
              />
            </v-row>

            <v-chip color="#06C2700F" small pill>
           
              Closed
            </v-chip>

            <div class="d-flex flex-column">
              <v-card-title
                @click="goToUser(data.id, data.name, data.audienceType , data.schools)"
                style="max-width: 30ch"
                class="pa-0 cursor d-flex-none campaign-title"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ data.name }}
                    </span>
                  </template>
                  <span>{{ data.name }}</span>
                </v-tooltip>
              </v-card-title>
              <div class="my-2 text-subtitle-1">
       
                <div
                  v-if="data.levels.length > 0 && data.levels.length <= 3"
                  class="d-flex flex-row w-100 flex-wrap pb-4"
                >
                  <div
                    v-for="(item, index) in data.levels"
                    :key="index"
                    class="w-fit"
                  >
                    <v-chip
                      small
                      class="mr-2"
                      v-if="item != null"
                      color="#9cb9fba1"
                    >
                      {{ item }}
                    </v-chip>
                  </div>
                </div>
                <div
                  v-if="data.levels.length > 0 && data.levels.length > 3"
                  class="d-flex flex-row w-100 flex-wrap pb-4"
                >
                  <div class="w-fit">
                    <v-chip small class="mr-2" color="#9cb9fba1">
                      {{ data.levels[0] }}
                    </v-chip>
                  </div>
                  <div class="w-fit">
                    <v-chip small class="mr-2" color="#9cb9fba1">
                      {{ data.levels[1] }}
                    </v-chip>
                  </div>
                  <div class="w-fit">
                    <v-chip small class="mr-2" color="#9cb9fba1">
                      {{ data.levels[2] }}
                    </v-chip>
                  </div>
                </div>
                <div class="w-100 d-flex justify-space-between">
                  <v-tooltip bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.subjects.length }} Subjects
                      </span>
                    </template>
                    <span>{{ data.subjects.join(", ") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="data.audienceType == 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data?.assessments?.length || 0 }} Assessments
                      </span>
                    </template>
                    <div v-html="getNames(data?.assessments)"></div>
                  </v-tooltip>

                  <v-tooltip v-if="data.audienceType !== 'MESTA_STUDENTS'" bottom style="z-index: 999">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="pr-1">mdi-notebook-outline</v-icon>
                        {{ data.schools.length }} Schools
                      </span>
                    </template>
                    <div v-html="getNames(data.schools)"></div>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex flex-row my-5 card-sub-text">
                <v-icon class="text-medium-emphasis"
                  >mdi-calendar-blank-outline</v-icon
                >
                <div class="d-flex flex-column ml-4">
                  <div>From</div>
                  <div class="c-date">{{ data.startDate }}</div>
                </div>
                <div class="ml-8 mr-8 d-flex align-center">-</div>
                <div class="d-flex flex-column">
                  <div>Till</div>
                  <div class="c-date">
                    <span v-if="data.audienceType == 'MESTA_STUDENTS'">{{ data.endDate }}</span>
                    <span v-else>{{ data.mainsEndDate }}</span>
                </div>
                </div>
              </div>

              <v-card
                v-if="data.totalAudience > 0"
                class="d-flex flex-row pa-0 ma-0 rounded-0"
                width="100%"
                height="10px"
                color="#D9D9D9"
                depressed
                elevation="0"
              >
                <v-card
                  class="d-flex flex-row pa-0 ma-0 rounded-0"
                  width="100%"
                  height="10px"
                  color="transparent"
                  depressed
                  elevation="0"
                >
                  <v-card
                    class="d-flex flex-row pa-0 ma-0 justify-center rounded-0 elevation-0"
                    :width="
                      getPercentage(
                        data.audienceAssessed,
                        data.totalAudience
                      )
                    "
                    height="10px"
                    color="gray-02"
                  > 
                    <div
                      class="graph-value litegreen"
                      v-if="data.audienceAssessed != 0"
                    >

                      {{data.audienceAssessed}}
                    </div>
                  </v-card>
                  <v-card
                    class="d-flex flex-row pa-0 ma-0 justify-center rounded-0"
                    :width="
                      getPercentage(data.audienceInProgress ,data.totalAudience
                      )
                    "
                    height="10px"
                    color="#1b72e8b3"
                    elevation="0"
                  >
                    <div
                      class="graph-value liteblue"
                      v-if="data.audienceInProgress!= 0"
                    >
                      {{data.audienceInProgress}}
                    </div>
                  </v-card>
                  <v-card
                    elevation="0"
                    class="d-flex flex-row pa-0 ma-0 justify-center rounded-0"
                    :width="getPercentage(data.audienceInvited, data.totalAudience)"
                    height="10px"
                    color="#D9D9D9"
                  >
                    <div
                      class="graph-value"
                      v-if="data.audienceInvited != 0"
                    >
                      <!-- {{
                    getPercentate(
                      data.yet_to_attempt,
                      data.assessed + data.yet_to_attempt
                    )
                  }} -->
                      {{data.audienceInvited}}
                    </div>
                  </v-card>
                </v-card>
              </v-card>

              <v-card
                v-if="data.totalAudience == 0"
                class="d-flex flex-row pa-0 ma-0 rounded-0"
                width="100%"
                height="10px"
                color="#D9D9D9"
                depressed
                elevation="0"
              >
                <v-card
                  class="d-flex flex-row pa-0 ma-0 rounded-0"
                  width="100%"
                  height="10px"
                  color="transparent"
                  depressed
                  elevation="0"
                >
                </v-card>
              </v-card>

              <!-- temp display for audience count bar-->
              <!-- <v-card
                v-if="data.audienceType == 'MESTA_STUDENTS'"
                class="d-flex flex-row pa-0 ma-0 rounded-0"
                width="100%"
                height="10px"
                color="#D9D9D9"
                depressed
                elevation="0"
              >
                <v-card
                  class="d-flex flex-row pa-0 ma-0 rounded-0"
                  width="100%"
                  height="10px"
                  color="transparent"
                  depressed
                  elevation="0"
                >
                </v-card>
              </v-card> -->
            </div>
          </v-card>
        </div>
        <!-- campaign card ending here           -->
      </div>
    </div>
    </div>


    <AddTeachersToCampaign v-if="campaignId" :addTeacherDialog.sync="addTeacherDialog" :campaignName="campaignName" :campaignId="campaignId"/>
    <AddUserToCampaign :addUserDialog.sync="addUserDialog" :campaignId="campaignId"/>
    <!-- <PDFPreviewer :pdfPath="pdfPath" /> -->
    <v-snackbar v-model="snackbar" :timeout="1000">
      {{ "Link Copied" }}
    </v-snackbar>
  </div>
</template>

<script>
import FlipCard from "./FlipCard.vue";
import CampaignController from "@/controllers/CampaignController";
import LevelController from "@/controllers/LevelController";
import AssessmentController from "@/controllers/AssessmentController";

import SchoolController from "@/controllers/SchoolController";
import SkillsController from "@/controllers/SkillsController";

import SubjectController from "@/controllers/SubjectController";
import ClusterController from "@/controllers/ClusterController";
import UsersController from "@/controllers/UsersController";
import { collection, getDocs, onSnapshot, doc, updateDoc, query, where, } from "firebase/firestore";
import { db,app } from "../firebase";

import AuthService from "../services/AuthService";
import chart1 from "./campaignChart/chart1";
import chart2 from "./campaignChart/chart2";
import chart3 from "./campaignChart/chart3";
import chart4 from "./campaignChart/chart4";
import chart5 from "./campaignChart/chart5";
import chart6 from "./campaignChart/chart6";
import chart7 from "./campaignChart/chart7";
import chart8 from "./campaignChart/chart8";
import Papa from "papaparse";
import Notification from "./Notification";
import axios from "axios";

import VueQRCodeComponent from 'vue-qrcode-component'
import html2canvas from 'html2canvas';
import AddUserToCampaign from '../components/AddUserToCampaign.vue';
import { mapActions } from 'vuex';
import AddTeachersToCampaign from "@/components/AddTeachersToCampaign.vue";
import { debounce } from "lodash";

export default {
  name: "campaignView",
  components: {
    Notification,
    FlipCard,
    chart1,
    chart2,
    chart3,
    chart4,
    chart5,
    chart6,
    chart7,
    chart8,
    VueQRCodeComponent,
    AddUserToCampaign,
    AddTeachersToCampaign
  },
  data() {
    
    return {
      currentStatus: 'Live',
      audienceType: 'All',
      search: '',
      campaignStatus: 0,
      debouncedFilterCampaign: null,
      liveCount: 0,
      expiredCount: 0,
      scheduledCount: 0,
      qrDialog: false,
      qrCampaignLink:"",
      qrCampaignName:"",
      qrLoading:false,
      qrDownloadLoading:false,

      proctoringType: [
        {
          text: "No Proctoring",
          value: "NONE",
        },
        {
          text: "Smart Proctoring",
          value: "SMART",
        },
        {
          text: "AI + Smart Proctoring",
          value: "BOTH",
        },
      ],
      filterCount: 0,
      dataschools: [],
      dataclusters: [],
      dataskills: [],
      datalevels: [],
      notificationDialog: false,
      appliedFilter: false,
      deleteSuccessDialog: false,
      teachersInTotalCampaign: 0,
      jobSeekerInTotalCampaign: 0,
      inProgressCount: 0,
      isAccessedCount: 0,
      isUnaccessed: 0,
      isUnaccessedJS: 0,
      inProgressCountJS: 0,
      isAccessedCountJS: 0,
      tbdCount: 0,
      appraisalCount: 0,
      pipCount: 0,
      hiredCount: 0,
      rejectedCount: 0,
      teachersInSchoolTotalCampaign: 0,
      jobSeekerInSchoolTotalCampaign: 0,
      inProgressCountAtSchool: 0,
      isAccessedCountAtSchool: 0,
      isUnaccessedAtSchool: 0,
      inProgressCountJSAtSchool: 0,
      isAccessedCountJSAtSchool: 0,
      isUnaccessedJSAtSchool: 0,
      tbdCountAtSchool: 0,
      appraisalCountAtSchool: 0,
      pipCountAtSchool: 0,
      hiredCountAtSchool: 0,
      rejectedCountAtSchool: 0,
      panel: [0],
      disabled: false,
      readonly: false,
      isCampaignPublished: 0,
      snackbar: false,
      clusterSchool: "",
      dialog: false,
      e1: 1,
      campaignsForDownload: [],
      // seekerLiveCampaigns3Count: 0,
      // seekerScheduledCampaignsCount:0,
      // seekerExpiredCampaignsCount:0,
      // vgosLiveCampaignsCount:0,
      // vgosScheduledCampaignsCount:0,
      // vgosExpiredCampaignsCount:0,

      updateFlag: false,
      deleteDialog: false,
      successDialog: false,
      assessments: [],
      pageName: "All",
      schoolsData: [],
      selectedClusterFilters: [],
      selectedSchoolFilter: [],
      selectedLevelFlter: [],
      selectedSkillsFilter: [],
      searchAudeience: "",
      roleCluster: "",
      roleSchool: "",
      isLoading: true,
      countFlag: false,
      viewUserIno: {},
      userPhoneNumber: "",
      userEmail: "",
      selectedId: null,
      startDate: "",
      screeningEndDate: "",
      singleSelect: false,
      skills: [],
      userFullName: "",
      userViewDialog: false,
      storeLiveCam: [],
      mainsEndDate: "",
      endDate: "",
      items: ["Daily", "Weekly", "Monthly"],
      firstRemainder: ["2 Min", "5 Min", "10 Min", "15 Min", "20 Min"],
      lastRemainder: ["2 Min", "5 Min", "10 Min", "15 Min", "20 Min"],
      schools: [],
      clusters: [],
      UpdateCampaignDialog: false,
      subjects: [],
      currentStatus: "Live",
      users: [],
      campaigns: {},
      allCampaigns: {},
      UpdateCampaignDialogLoading: false,
      resultTimeHrs: "",
      resultTimeMin: "",
      screeingEndTimeDialog: false,
      mainsEndTimeDialog: false,
      resultPublishTImeTimeDialog: false,
      allCampaignCount: 0,
      headers: [
        { text: "Name", value: "fullname" },
        { text: "Phone No", value: "personalInfo.contactInfo.phoneNumber" },
        { text: "Level", value: "levels" },
        { text: "Subjects", value: "subjects" },
        { text: "School Name", value: "schoolName" },
        { text: "Action", value: "actions" },
      ],
      startTimeDialog: false,
      audienceType: "All",
      levels: [],
      campaignStartTime: "08:00",
      startMM: "",
      screeningEndTime: "23:59",
      mainsEndTime: "23:59",
      isFilterLoading: false,
      endMM: "",
      editorConfig: {
        extraPlugins: "ckeditor_wiris",
      },
      selected: [],
      dLoading: false,
      // Temp dummy Data
      negativeMarking: "Yes",
      liveCampaigns: [],
      filterLiveCampaigns: [],
      filterScheduledCampaigns: [],
      filterExpiredCampaigns: [],
      filterDialog: false,
      preLiveCampaigns: [],
      coreSkillNoOfQuestionForScreening: 10,
      coreSkillWeightageForScreening: 50,
      coreSkillIsRequiredForScreening: true,
      coreSkillNoOfQuestionForMains: 20,
      coreSkillWeightageForMains: 50,
      coreSkillIsRequiredForMains: true,
      scheduledCampaigns: [],
      preScheduledCampaigns: [],
      expiredCampaigns: [],
      CampaignCounts: [],
      preExpiredCampaigns: [],
      countCampaignByLevel: {
        foundationalCount: 0,
        preparatoryCount: 0,
        secondaryCount: 0,
        seniorSecondaryCount: 0,
        middleCount: 0,
      },
      tag: false,
      campaignId: null,
      campaignName: null,
      campaignType:"",
      campaignData: {
        campaignId: "",
        name: "",
        startDate: "",
        screeningEndDate: "",
        mainsEndDate: "",
        campaignStartTime: "",
        screeningEndTime: "",
        mainsEndTime: "",
        resultPublishTime: "10:00",
        publishPattern: {
          frequency: "",
          interval: {
            day: "",
            week: "",
            month: "",
          },
        },
        audienceType: "",
        clusters: [],
        skills: [],
        subjects: [],
        schools: [],
        levels: [],
        seekerLiveCampaignsCount: 0,
        seekerScheduledCampaignsCount: 0,
        seekerExpiredCampaignsCount: 0,
        vgosLiveCampaignsCount: 0,
        vgosScheduledCampaignsCount: 0,
        vgosExpiredCampaignsCount: 0,
        mestaLiveCampaignsCount: 0,
        mestaScheduledCampaignsCount: 0,
        mestaExpiredCampaignsCount: 0,
        // audiance:this.selected,
        campaignConfigration: {
          type: "DYNAMIC",
          assessmentIds: [],
          screeningConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "Yes",
            negativeMarking: "No",
            shuffleQuestions: "No",
            passingCriteria: 60,
            timeUpFirstRemainder: 0,
            timeUpLastRemainder: 0,
          },
          mainsConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "Yes",
            negativeMarking: "No",
            shuffleQuestions: "No",
            passingCriteria: 60,
            timeUpFirstRemainder: 0,
            timeUpLastRemainder: 0,
          },
        },
      },
      subjectDistributions: [
        {
          subjectName: "",
          noOfQuestion: this.coreSkillNoOfQuestionForScreening,
          weightage: this.coreSkillWeightageForScreening,
          isRequired: this.coreSkillIsRequiredForScreening,
        },
      ],
      skillsDistributions: [
        {
          skillName: "",
          noOfQuestion: 0,
          weightage: null,
          isRequired: false,
        },
      ],
      subjectDistributionsForMains: [
        {
          subjectName: "",
          noOfQuestion: this.coreSkillNoOfQuestionForMains,
          weightage: this.coreSkillWeightageForMains,
          isRequired: this.coreSkillIsRequiredForMains,
        },
      ],
      skillsDistributionsForMains: [
        {
          skillName: "",
          noOfQuestion: 0,
          weightage: null,
          isRequired: false,
        },
      ],

      rules: {
        required: (value) => !!value || "Field is required",
      },
      preloader: true,
      loaderForDownloadCampaigns: false,
      chartSwitch: "",
      addUserDialog:false,
      addTeacherDialog:false,
      campaignStats: {
        totalCampaigns: 0,
        totalTeachersCampaign: 0,
        totalJobSeekersCampaign: 0,
        totalMestaCampaign: 0,
      },
      outcomeTeachers: {
        assessedTeacher: 0,
        unAssessedTeacher: 0,
        inProgressTeacher: 0,
      },
      outcomeJobSeekers: {
        assessedJobSeeker: 0,
        unAssessedJobSeeker: 0,
        inProgressJobSeeker: 0,
      },
      outcomeStudents: {
        assessedStudent: 0,
        unAssessedStudent: 0,
        inProgressStudent: 0,
      },
      recruitmentJobSeeker: {
        countHired: 0,
        countRejected: 0,
      },
      teacherAppraisal: {
        countAppraised: 0,
        countOnPip: 0,
        countTbd: 0,
      },
      outcomeMesta: {
        assessedMesta: 0,
        unAssessedMesta: 0,
        inProgressMesta: 0,
      },
      schoolPrincipal: {
        totalInvited: 0,
        totalScreeningStarted: 0,
        totalScreeningPassed: 0,
        totalScreeningFailed: 0,
        totalMainsStarted: 0,
        totalMainsPassed: 0,
        totalMainsFailed: 0,
      },
      clusterPrincipal: {
        totalInvited: 0,
        totalScreeningStarted: 0,
        totalScreeningPassed: 0,
        totalScreeningFailed: 0,
        totalMainsStarted: 0,
        totalMainsPassed: 0,
        totalMainsFailed: 0,
      },

      responsibleTeachingAt: {},
      teachingSubjects: {},
      tag: "",
      emailList: [],
      inviteLoading: false,
      campaignUserEmail:'',
      pdfName:"",
      pgNameMappign:  new Map([
                        ["All", ""],
                        ["TEACHER", " - VGOS"],
                        ["JOB_SEEKER", " - Job Seeker"],
                        ["MESTA_STUDENTS", " - MESTA"]
                    ])
    };

  },
  computed: {
    dateRule() {
      return (value) => {
        if (!value) {
          return "Please enter campaign start date";
        }
        if (this.isCampaignPublished == 0) {
          const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
          if (!regex.test(value)) {
            return "Please enter a valid date (YYYY-MM-DD)";
          }
          const selectedDate = new Date(value);
          const currentDate = new Date();
          if (selectedDate <= currentDate) {
            return "Campaign Start Date must be greater than current date";
          }
        }

        return true;
      };
    },
    screeningEndDateRule() {
      return (value) => {
        if (!value) {
          return "Screening end date is required";
        } else if (this.startDate == "") {
          return "Please enter campaign start date firstly";
        } else {
          const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
          if (!regex.test(value)) {
            return "Please enter a valid date (YYYY-MM-DD)";
          }
          const startDateObj = new Date();
          const endDateObj = new Date(value);
          if (startDateObj >= endDateObj) {
            return "Screening end date must be greater than Current Date";
          }
        }
        return true;
      };
    },
    mainsEndDateRule() {
      return (value) => {
        if (!value) {
          return "Mains end date is required";
        } else if (this.screeningEndDate == "") {
          return "Please enter screening end  date firstly";
        } else {
          const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
          if (!regex.test(value)) {
            return "Please enter a valid date (YYYY-MM-DD)";
          }
          const startDateObj = this.campaignType == "TEACHER" ? new Date() : new Date(this.screeningEndDate);
          const endDateObj = new Date(value);
          if (startDateObj >= endDateObj) {
            return (this.campaignType == "TEACHER" ?
            "Campaign end date must be greater than current date" :"Campaign mains end date must be greater than campaign screening end date");
          }
        }
        return true;
      };
    },
    endDateRule(){
      return (value) => {
          if (!value) {
              return "Mains end date is required";
          } else {
              const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
              if (!regex.test(value)) {
                  return "Please enter a valid date (YYYY-MM-DD)";
              }
              // const startDateObj = this.campaignType == "TEACHER" ? new Date() : new Date(this.screeningEndDate);
              const endDateObj = new Date(value);
              const startDateObj = new Date(this.startDate);
              if (startDateObj >= endDateObj) {
                  return "Campaign end date must be greater than start date";
              }
          }
          return true;
      };
    },
    computedEndDate: {
      get() {
        return this.campaignType === "MESTA_STUDENTS" ? this.endDate : this.mainsEndDate;
      },
      set(value) {
        if (this.campaignType === "MESTA_STUDENTS") {
          this.endDate = value;
        } else {
          this.mainsEndDate = value;
        }
      }
    }
  },
  watch: {
    '$route.query': {
    immediate: true,
    handler(newQuery) {
      // Update component state from URL query parameters
      this.currentStatus = newQuery.status || 'Live';
      this.audienceType = newQuery.audienceType || 'All';
      this.search = newQuery.search || '';
    }
  },
    currentStatus(newValue) {
      console.log("newValue-----------------", newValue, this.currentStatus);
      this.getCampaigns(this.currentStatus);
    },
    search(newValue) {
      // this.searchBool=true
      // this.pageSize = this.options.itemsPerPage;
      // this.page = this.options.page;
      // this.options.page=1;
      // if (newValue == "" || newValue == null) {
      //   this.liveCampaigns = this.filterLiveCampaigns;
      //   this.scheduledCampaigns = this.filterScheduledCampaigns;
      //   this.expiredCampaigns = this.filterExpiredCampaigns;
      // } else {
      //   this.searchData(newValue);
      // }
      
      this.searchData(newValue);
    },
    selectedClusterFilters(newValue) {
      if (newValue.length == 0 || newValue == null) {
        this.schoolsData = [];
        console.log("newValue1");
      } else {
        this.getClusterSchoolForFilter(newValue);
        console.log("newValue2");
      }
    },

    // liveCampaigns(newValue){
    //   console.log("new_value_campains", newValue);
    // }
  
  },
  methods: {
     ...mapActions(['saveUserType', 'clearPaginationStates']),
     isJobSeeker(audienceType = '') {
      return audienceType?.toLowerCase() === "job_seeker";
    },
    openAddUserDialog(campaignId){
      this.campaignId = campaignId
      this.addUserDialog = true;
  },
  async openAddTeacherDialog({id, name}){
      this.addTeacherDialog = true;
      this.campaignId = id
      this.campaignName = name
  },
  handleClick(type) {
    console.log('type',type)
      this.goToCampaignCreationForm(type);
      this.saveUserType(type); // Save the type globally in Vuex
  },
 async closeFilter(){
    this.filterDialog = false;
    console.log("closing filter")
    await new Promise(resolve => setTimeout(resolve, 2000));
    console.log("closing filter2")

    document.getElementById("closeFilterId").click();
  },
    getNames(name) {
      let names = "";
      for (let i = 0; i < name?.length; i++) {
        names = names + name[i] + "<br>";
      }
      return names;
    },
    async selectProctoring(i) {
      // console.log("Proctoring Type...?", this.liveCampaigns[i])
      console.log("campaign id..?", this.liveCampaigns[i].id);
      const campRef = doc(db, "campaigns", this.liveCampaigns[i].id);
      await updateDoc(campRef, {
        proctoringType: this.liveCampaigns[i].proctoringType,
      });
    },
    async downloadCampaigns() {
      this.loaderForDownloadCampaigns = true;
      const fileName = "campaigns.csv";
      await this.getCampaigns(this.currentStatus, this.audienceType, true);
      let filterCsvData = this.campaignsForDownload.map((item)=>{
        return {
          "Type" : item.audienceType || 'NA',
          "Name": item.name || 'NA',
          "Description": item.description || 'NA',
          "Created_Date": this.formatCreatedDate(item.createdOn) || 'NA', 
          "Proctoring_Type": item.proctoringType || 'NA',
          "Screening_End_Date": item.screeningEndDate || 'NA',
          "Mains_End_Date": item.mainsEndDate || 'NA', 
          "Level": this.convertArrayToCommaSepratedVals(item?.levels) || 'NA', 
          'Subject': this.convertArrayToCommaSepratedVals(item?.subjects)||'NA',
          'Cluster': this.convertArrayToCommaSepratedVals(item?.clusters) || 'NA', 
          'School': this.convertArrayToCommaSepratedVals(item?.schools) || 'NA', 
          'No_Of_Candidates': item.totalAudience || 'NA',
          'Screening_passed': item.audienceType !=='MESTA_STUDENTS' ? item.audienceScreeningPassed : 'NA', 
          'Screening_failed':  item.audienceType !== 'MESTA_STUDENTS' ? item.audienceScreeningFailed : 'NA', 
          'Mains_passed': item.audienceType !== 'MESTA_STUDENTS' ? item.audienceMainsPassed : 'NA', 
          'Mains_failed': item.audienceType !== 'MESTA_STUDENTS' ? item.audienceMainsFailed : 'NA', 
          "Demo_Submitted": item.audienceType !== 'MESTA_STUDENTS' ? item.audienceDemoSubmitted : 'NA',
          "Interview_Conducted": item.audienceType !== 'MESTA_STUDENTS' ? item.audienceInterviewConducted : 'NA',
        }
      })
      this.loaderForDownloadCampaigns = false;
      this.downloadCSV(filterCsvData, fileName);
    },
    getInterviewConductedCount(audience){
      return audience.filter(aud => aud.interViewFeedback && aud.interViewFeedback.length).length
    },
    getInterviewRecommendedCount(audience){
      let count = 0
      let interviewData = audience.filter(aud => aud.interViewFeedback && aud.interViewFeedback.length)  // people who has interview feedback
      if(interviewData.length > 0){
        interviewData.forEach((item)=>{
         if((item.interViewFeedback.reduce((acc, cur)=> acc + parseFloat(cur.avgScore), 0) / item.interViewFeedback.length) >= 6){
          count++
         }
        }) 
      }
      return count;
   
    },
    isLiteralObject(a) {
    return (!!a) && (a.constructor === Object);
    },
    formatCreatedDate(date){
      date = new Date(date)
      return (
        String(date.getDate()).padStart(2, "0") +
        "/" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(date.getFullYear()).padStart(2, "0")
      );
    },
    convertArrayToCommaSepratedVals(data){
        return data?.length ? data.join(", "): "NA"
    },
    getPassedFailed(data ,key){
      if(data.length){
        return data.filter((item)=> item.campaignStatus.filter((status)=> status.status == key).length > 0).length
        }
    },
    getDemoCount(data){
      return data.filter(item => item?.demoStatus).length
    },
    getStatusIndex(status) {
    switch (status) {
      case "Live":
        return 0;
      case "Expired":
        return 1;
      case "Scheduled":
        return 2;
      case "Archived":
        return 3;
      default:
        return -1;
    }
  },
    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async filterCampaign(
      selectSchool,
      selectClusters,
      selectLevels,
      selectSkills
    ) {
      this.isFilterLoading = true;
      var query = "?status=" + this.currentStatus + "&audienceType=" + this.audienceType + "&search=" + this.search;

      const response = await CampaignController.filterCampaign(query,selectSchool, selectClusters, selectLevels, selectSkills);
      if (response.status == 200) {
        console.log("campaign", response);
        this.campaigns = response.data.campaigns;
        this.filterDialog = false;
        this.isFilterLoading = false;

        if (this.campaigns.length != 0) {
          this.preLiveCampaigns = this.campaigns.liveCampaign;
          // console.log("campaign", this.liveCampaigns);
          this.preScheduledCampaigns = this.campaigns.scheduledCampaign;
          this.preExpiredCampaigns = this.campaigns.expiredCampaign;
        }
        else {
          this.preLiveCampaigns = [];
          this.preScheduledCampaigns = [];
          this.preExpiredCampaigns = [];
        }
        this.preloader = false;
      } else {
        alert(response.data.error);
        this.isFilterLoading = false;
      }
      this.isFilterLoading = false;
      this.getTypeCampaign(this.audienceType);
      this.getCampaigns();
    },

    updateUrlParams() {
      const query = {
        status: this.currentStatus,
        audienceType: this.audienceType,
      }
      
      if (this.search?.trim()) {
        query.search = this.search.trim()
      }

      this.$router.push({
        query
      })
    },
    clearFilter() {
      this.appliedFilter = false;
      this.selectedClusterFilters = [];
      this.selectedSchoolFilter = [];
      this.selectedSkillsFilter = [];
      this.selectedLevelFlter = [];
      this.search = "";
      this.updateUrlParams();
      this.getCampaigns();
      this.filterCount = 0;

      // this.filterDialog = false;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedSchoolFilter.length > 0) {
        this.filterCount++;
      }
      if (this.selectedClusterFilters.length > 0) {
        this.filterCount++;
      }

      if (this.selectedLevelFlter.length > 0) {
        this.filterCount++;
      }

      if (this.selectedSkillsFilter.length > 0) {
        this.filterCount++;
      }
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },
    searchData(search) {
      console.log(search);
      this.search = search;
      this.updateUrlParams();
      this.debouncedFilterCampaign();
      return;


    },
    async setuppForClusterAndSchool() {
      try {
        const dashboardRef = doc(db, "dashboards", "management-dashboard-001");
        const schoolsRef = collection(dashboardRef, "schools");
        // console.log("schoolsRef:>>>", schoolsRef);

        onSnapshot(schoolsRef, (snapshot) => {
          snapshot.forEach((schoolDoc) => {
            const schoolData = schoolDoc.data();

            // const schoolName = schoolData.name;
            // const schoolCluster = schoolData.cluster;
            //for cluster principal
            if (
              schoolData.cluster === this.roleCluster &&
              this.roleSchool === "All"
            ) {
              // console.log("filter", schoolData);
              this.clusterPrincipal.totalInvited =
                this.clusterPrincipal.totalInvited + schoolData.totalInvited;
              this.clusterPrincipal.totalScreeningStarted =
                this.clusterPrincipal.totalScreeningStarted +
                schoolData.totalScreeningStarted;
              this.clusterPrincipal.totalScreeningPassed =
                this.clusterPrincipal.totalScreeningPassed +
                schoolData.totalScreeningPassed;
              this.clusterPrincipal.totalScreeningFailed =
                this.clusterPrincipal.totalScreeningFailed +
                schoolData.totalScreeningFailed;
              this.clusterPrincipal.totalMainsFailed =
                this.clusterPrincipal.totalMainsFailed +
                schoolData.totalMainsFailed;
              this.clusterPrincipal.totalMainsPassed =
                this.clusterPrincipal.totalMainsPassed +
                schoolData.totalMainsPassed;
              this.clusterPrincipal.totalMainsStarted =
                this.clusterPrincipal.totalMainsStarted +
                schoolData.totalMainsStarted;
            } else if (
              schoolData.name === this.roleSchool &&
              schoolData.cluster === this.roleCluster
            ) {
              this.schoolPrincipal.totalInvited =
                this.schoolPrincipal.totalInvited + schoolData.totalInvited;
              this.schoolPrincipal.totalScreeningPassed =
                this.schoolPrincipal.totalScreeningPassed +
                schoolData.totalScreeningPassed;
              this.schoolPrincipal.totalScreeningFailed =
                this.schoolPrincipal.totalScreeningFailed +
                schoolData.totalScreeningFailed;
              this.schoolPrincipal.totalScreeningStarted =
                this.schoolPrincipal.totalScreeningStarted +
                schoolData.totalScreeningStarted;
              this.schoolPrincipal.totalMainsFailed =
                this.schoolPrincipal.totalMainsFailed +
                schoolData.totalMainsFailed;
              this.schoolPrincipal.totalMainsPassed =
                this.schoolPrincipal.totalMainsPassed +
                schoolData.totalMainsPassed;
              this.schoolPrincipal.totalMainsStarted =
                this.schoolPrincipal.totalMainsStarted +
                schoolData.totalMainsStarted;
            }
            // console.log("result>>",this.clusterPrincipal);
            // console.log("reslt school>>",this.schoolPrincipal);

            // console.log("School", schoolData);
          });
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },

    async setupSnapshotForAssessingUsers() {
      const dashboardRef = doc(db, "dashboards", "management-campaign-001");
      // console.log("dashboardref>>>", dashboardRef);
      onSnapshot(dashboardRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          // console.log("chart>>>", data.assessedTeacher);
          if (this.$store.state.role.clusterName == "All Clusters") {
            this.outcomeTeachers.assessedTeacher =
              data.assessedTeacher == null ? 0 : data.assessedTeacher;
            this.outcomeTeachers.inProgressTeacher =
              data.inProgressTeacher == null ? 0 : data.inProgressTeacher;
            this.outcomeTeachers.unAssessedTeacher =
              data.unAssessedTeacher == null ? 0 : data.unAssessedTeacher;
            // for job seekers
            this.outcomeJobSeekers.assessedJobSeeker =
              data.assessedJobSeeker == null ? 0 : data.assessedJobSeeker;
            this.outcomeJobSeekers.inProgressJobSeeker =
              data.inProgressJobSeeker == null ? 0 : data.inProgressJobSeeker;
            this.outcomeJobSeekers.unAssessedJobSeeker =
              data.unAssessedJobSeeker == null ? 0 : data.unAssessedJobSeeker;

            

            // seeker
            this.recruitmentJobSeeker.countHired =
              data.jobSeekerHiredCount == null ? 0 : data.jobSeekerHiredCount;
            this.recruitmentJobSeeker.countRejected =
              data.jobSeekerRejectedCount == null
                ? 0
                : data.jobSeekerRejectedCount;

            // teacher
            this.teacherAppraisal.countAppraised =
              data.teacherAppraisedrCount == null
                ? 0
                : data.teacherAppraisedrCount;
            this.teacherAppraisal.countOnPip =
              data.teacherOnPipCount == null ? 0 : data.teacherOnPipCount;
            this.teacherAppraisal.countTbd =
              data.teacherTbdCount == null ? 0 : data.teacherTbdCount;
          }
          this.outcomeStudents.assessedStudent =
              data.assessedStudent == null ? 0 : data.assessedStudent;
            console.log('this.outcomeStudents.assessedStudent',this.outcomeStudents.assessedStudent)
            this.outcomeStudents.inProgressStudent =
              data.inProgressStudent == null ? 0 : data.inProgressStudent;
              console.log('this.outcomeStudents.inProgressStudent',this.outcomeStudents.inProgressStudent)
            this.outcomeStudents.unAssessedStudent =
              data.unAssessedStudent == null ? 0 : data.unAssessedStudent;
            console.log('this.outcomeStudents.unAssessedStudent')
        }
      });
    },
    // cluster level started ....
    async clusterLevelTotalCampaignSnapshot() {
      const campaignRef = collection(db, "campaigns");
      // console.log("heyyylooooo");
      onSnapshot(campaignRef, (snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          // if teacher, run this
          //console.log("heyyylooooopalvii");
          if (
            doc.data().audienceType?.toLowerCase() === "teacher" &&
            doc.data().clusters.includes(this.$store.state.role.clusterName)
          ) {
            try {
              // console.log("heyyylooooopalviiSabharwal");
              this.teachersInTotalCampaign += 1;
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
        if (
          this.$store.state.role.clusterName != "All Clusters" &&
          this.$store.state.role.schoolName == "All"
        ) {
          this.campaignStats.totalTeachersCampaign =
            this.teachersInTotalCampaign;
        }
      });
    },

    async clusterLevelTotalCampaignJobSeekerSnapshot() {
      const campaignRef = collection(db, "campaigns");
      //console.log("heyyylooooo");
      onSnapshot(campaignRef, (snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          // if teacher, run this
          //console.log("heyyylooooopalvii");
          if (
            doc.data().audienceType?.toLowerCase() === "job_seeker" &&
            doc.data().clusters.includes(this.$store.state.role.clusterName)
          ) {
            try {
              //console.log("heyyylooooopalviiSabharwal");
              this.jobSeekerInTotalCampaign += 1;
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
        if (
          this.$store.state.role.clusterName != "All Clusters" &&
          this.$store.state.role.schoolName == "All"
        ) {
          this.campaignStats.totalJobSeekersCampaign =
            this.jobSeekerInTotalCampaign;
          this.campaignStats.totalCampaigns =
            this.jobSeekerInTotalCampaign + this.teachersInTotalCampaign;

          //console.log("count...>>>>>", this.campaignStats.totalCampaigns);
        }
      });
    },

    async setupSnapshotForTotalCampaign() {
      const dashboardRef = doc(db, "dashboards", "management-dashboard-001");

      onSnapshot(dashboardRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();

          if (this.$store.state.role.clusterName == "All Clusters") {
            this.campaignStats.totalCampaigns =
              this.campaignStats.totalJobSeekersCampaign +
              this.campaignStats.totalTeachersCampaign;
            this.campaignStats.totalCampaigns =
              data.totalCampaigns == null ? 0 : data.totalCampaigns;
            this.campaignStats.totalTeachersCampaign =
              data.totalTeachersCampaign == null
                ? 0
                : data.totalTeachersCampaign;
            this.campaignStats.totalJobSeekersCampaign =
              data.totalJobSeekersCampaign == null
                ? 0
                : data.totalJobSeekersCampaign;
          }
          // console.log("data>>", this.campaignStats);
          //console.log("Checkpoint 001: Total Schools :", this.totalSchools);
        } else {
          // console.log("Document does not exist");
        }
      });
    },

    async clusterLevelAccessedUnaccessedSnapshot() {
      const dashboardRef = collection(db, "users");
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
          if (
            userData.userType?.toLowerCase() === "teacher" &&
            //userData.campaignStatus !== undefined &&
            userData.cluster === this.$store.state.role.clusterName
          ) {
            //countDocumentSchool++;
            if (
              doc.data().campaignStatus &&
              doc.data().campaignStatus.length > 0
            ) {
              const lastStatus =
                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                  .status;
              if (
                lastStatus === "INVITED" ||
                lastStatus === "SCREENING STARTED" ||
                lastStatus === "MAINS STARTED" ||
                lastStatus === "SCREENING PASSED"
              ) {
                this.inProgressCount += 1;
              } else if (
                lastStatus === "SCREENING FAILED" ||
                lastStatus === "MAINS PASSED" ||
                lastStatus === "MAINS FAILED"
              ) {
                this.isAccessedCount += 1;
              }
            } else {
              this.isUnaccessed += 1;
            }
          }
        });
        if (this.$store.state.role.clusterName !== "All Clusters") {
          this.outcomeTeachers.assessedTeacher = this.isAccessedCount;
          this.outcomeTeachers.inProgressTeacher = this.inProgressCount;
          this.outcomeTeachers.unAssessedTeacher = this.isUnaccessed;
        }
        console.log("inProgressCount...>>>", this.inProgressCount);
        console.log("isAccessedCount...>>>", this.isAccessedCount);
        console.log("isUnaccessed...>>>", this.isUnaccessed);
      });
    },

    async clusterLevelAccessedUnaccessedJobSeekerSnapshot() {
      const dashboardRef = query(collection(db, "users"),where("userType","==","Job Seeker"),where("cluster","array-contains",this.roleCluster));
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
            //countDocumentSchool++;
            if (
              userData.campaignStatus &&
              userData.campaignStatus.length > 0
            ) {
              const lastStatus =
                userData.campaignStatus[userData.campaignStatus.length - 1]
                  .status;
              if (
                lastStatus === "INVITED" ||
                lastStatus === "SCREENING STARTED" ||
                lastStatus === "MAINS STARTED" ||
                lastStatus === "SCREENING PASSED"
              ) {
                this.inProgressCountJS += 1;
              } else if (
                lastStatus === "SCREENING FAILED" ||
                lastStatus === "MAINS PASSED" ||
                lastStatus === "MAINS FAILED"
              ) {
                this.isAccessedCountJS += 1;
              }
            } else {
              this.isUnaccessedJS += 1;
          }
        });
        if (this.roleCluster !== "All Clusters") {
          this.outcomeJobSeekers.assessedJobSeeker = this.isAccessedCountJS;
          this.outcomeJobSeekers.inProgressJobSeeker = this.inProgressCountJS;
          this.outcomeJobSeekers.unAssessedJobSeeker = this.isUnaccessedJS;
        }
        // console.log("jonb seeker inProgressCountJS ...>>>",this.inProgressCountJS);
        // console.log("abcdefgJS isAccessedCountJS...>>>",this.isAccessedCountJS);
        // console.log("abcdeJS isUnaccessedJS...>>>",this.isUnaccessedJS);
      });
    },

    async clusterLevelAppraisalSnapshot() {
      const dashboardRef = query(collection(db, "users"),where("userType","==","Teacher"),where("cluster","==",this.roleCluster));
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
            //countDocumentSchool++;
            if (
              userData.campaignStatus &&
              userData.campaignStatus.length > 0
            ) {
              const lastStatus =
                userData.campaignStatus[userData.campaignStatus.length - 1]
                  .status;
                console.log("Status",lastStatus);
              if (lastStatus === "SCREENING FAILED") {
                this.tbdCount += 1;
              } else if (lastStatus === "MAINS PASSED") {
                this.appraisalCount += 1;
              } else if (lastStatus === "MAINS FAILED") {
                this.pipCount += 1;
              }

          }
        });
        console.log(this.tbdCount,this.appraisalCount,this.pipCount)
        if (this.roleCluster !== "All Clusters") {
          this.teacherAppraisal.countAppraised = this.appraisalCount;
          this.teacherAppraisal.countOnPip = this.pipCount;
          this.teacherAppraisal.countTbd = this.tbdCount;
          console.log(this.teacherAppraisal,"Total Teacher")
        }
        // console.log("tbd...>>>",this.tbdCount);
        // console.log("appraisal...>>>",this.appraisalCount);
        // console.log("pip...>>>",this.pipCount);
      });
    },

    async clusterLevelHiredRejSnapshot() {
      const dashboardRef = query(collection(db, "users"),where("userType","==","Job Seeker"),where("cluster","array-contains",this.roleCluster));
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
            //countDocumentSchool++;
            if (
              userData.campaignStatus &&
              userData.campaignStatus.length > 0
            ) {
              const lastStatus =
                userData.campaignStatus[userData.campaignStatus.length - 1]
                  .status;
              if (lastStatus === "MAINS PASSED") {
                this.hiredCount += 1;
              } else if (lastStatus === "MAINS FAILED") {
                this.rejectedCount += 1;
              }
              }
        });

        if (this.$store.state.role.clusterName !== "All Clusters") {
          this.recruitmentJobSeeker.countHired = this.hiredCount;
          this.recruitmentJobSeeker.countRejected = this.rejectedCount;
        }
        // console.log("hiredCount...>>>",this.hiredCount);
        // console.log("rejectedCount...>>>",this.rejectedCount);
      });
    },

    // school level started ......

    async schoolLevelTotalCampaignSnapshot() {
      const campaignRef = collection(db, "campaigns");
      //console.log("heyyylooooo");
      onSnapshot(campaignRef, (snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          if (
            doc.data().audienceType?.toLowerCase() === "teacher" &&
            doc.data().schools.includes(this.$store.state.role.schools)
          ) {
            try {
              //console.log("heyyylooooopalviiSabharwal");
              this.teachersInSchoolTotalCampaign += 1;
            } catch (error) {
              // console.error("Error fetching audience collection:", error);
            }
          }
        });

        if (
          this.$store.state.role.clusterName !== "All Clusters" &&
          this.$store.state.role.schoolName != "All"
        ) {
          console.log(
            "this.teachersInSchoolTotalCampaign",
            this.teachersInSchoolTotalCampaign
          );
          this.campaignStats.totalTeachersCampaign =
            this.teachersInSchoolTotalCampaign;
        }

        // console.log("Teacher in school level ..>",this.teachersInSchoolTotalCampaign);
        // if (this.$store.state.role.clusterName != "All Clusters") {
        //   this.campaignStats.totalTeachersCampaign =
        //     this.teachersInTotalCampaign;
        // }
      });
    },

    async schoolLevelTotalCampaignJobSeekerSnapshot() {
      const campaignRef = collection(db, "campaigns");
      //console.log("heyyylooooo");
      onSnapshot(campaignRef, (snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          // if teacher, run this
          //console.log("heyyylooooopalvii");
          if (
            doc.data().audienceType?.toLowerCase() === "job_seeker" &&
            doc.data().schools.includes(this.$store.state.role.schools)
          ) {
            try {
              //console.log("heyyylooooopalviiSabharwal");
              this.jobSeekerInSchoolTotalCampaign += 1;
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
        console.log(
          "Job Seeker in school level ..>",
          this.jobSeekerInSchoolTotalCampaign
        );
        if (
          this.$store.state.role.clusterName !== "All Clusters" &&
          this.$store.state.role.schoolName != "All"
        ) {
          this.campaignStats.totalJobSeekersCampaign =
            this.jobSeekerInSchoolTotalCampaign;
          this.campaignStats.totalCampaigns =
            this.campaignStats.totalJobSeekersCampaign +
            this.campaignStats.totalTeachersCampaign;
        }
      });
    },

    async schoolLevelAccessedUnaccessedSnapshot() {
      const dashboardRef = query(collection(db, "users"),where("userType","==","Teacher"),where("schoolName","==",this.roleSchool));
      console.log("schools res", this.$store.state.role.schools);

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
            if (
              doc.data().campaignStatus &&
              doc.data().campaignStatus.length > 0
            ) {
              const lastStatus =
                doc.data().campaignStatus[doc.data().campaignStatus.length - 1]
                  .status;
              if (
                lastStatus === "INVITED" ||
                lastStatus === "SCREENING STARTED" ||
                lastStatus === "MAINS STARTED" ||
                lastStatus === "SCREENING PASSED"
              ) {
                this.inProgressCountAtSchool += 1;
              } else if (
                lastStatus === "SCREENING FAILED" ||
                lastStatus === "MAINS PASSED" ||
                lastStatus === "MAINS FAILED"
              ) {
                this.isAccessedCountAtSchool += 1;
              }
            } else {
              this.isUnaccessedAtSchool += 1;          }
        });
        // if(this.$store.state.role.clusterName !== 'All Clusters'){
          this.outcomeTeachers.assessedTeacher =this.isAccessedCountAtSchool;
          this.outcomeTeachers.inProgressTeacher =this.inProgressCountAtSchool;
          this.outcomeTeachers.unAssessedTeacher = this.isUnaccessedAtSchool;
        // }
        // console.log("inProgressCount...>>>",this.inProgressCountAtSchool);
        // console.log("isAccessedCount...>>>",this.isAccessedCountAtSchool);
        // console.log("isUnaccessed...>>>",this.isUnaccessedAtSchool);
      });
    },

    async schoolLevelAccessedUnaccessedJobSeekerSnapshot() {
      const dashboardRef = query(collection(db, "users"),where("userType","==","Job Seeker"),where("schoolName","array-contains",this.roleSchool));
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
            if (
              userData.campaignStatus &&
              userData.campaignStatus.length > 0
            ) {
              const lastStatus =
                userData.campaignStatus[userData.campaignStatus.length - 1]
                  .status;
              if (
                lastStatus === "INVITED" ||
                lastStatus === "SCREENING STARTED" ||
                lastStatus === "MAINS STARTED" ||
                lastStatus === "SCREENING PASSED"
              ) {
                this.inProgressCountJSAtSchool += 1;
              } else if (
                lastStatus === "SCREENING FAILED" ||
                lastStatus === "MAINS PASSED" ||
                lastStatus === "MAINS FAILED"
              ) {
                this.isAccessedCountJSAtSchool += 1;
              }
            } else {
              this.isUnaccessedJSAtSchool += 1;
            }
        });
        // if(this.$store.state.role.clusterName !== 'All Clusters'){
          this.outcomeJobSeekers.assessedJobSeeker = this.isAccessedCountJSAtSchool;
          this.outcomeJobSeekers.inProgressJobSeeker =this.inProgressCountJSAtSchool;
          this.outcomeJobSeekers.unAssessedJobSeeker = this.isUnaccessedJSAtSchool;
        // }
        // console.log("jonb seeker inProgressCountJS ...>>>",this.inProgressCountJSAtSchool);
        // console.log("abcdefgJS isAccessedCountJS...>>>",this.isAccessedCountJSAtSchool);
        // console.log("abcdeJS isUnaccessedJS...>>>",this.isUnaccessedJSAtSchool);
      });
    },

    async schoolLevelAppraisalSnapshot() {
      const dashboardRef = query(collection(db, "users"),where("userType","==","Teacher"),where("schoolName","==",this.roleSchool));
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
      
            //countDocumentSchool++;
            if (
              userData.campaignStatus &&
              userData.campaignStatus.length > 0
            ) {
              const lastStatus =
                userData.campaignStatus[userData.campaignStatus.length - 1]
                  .status;
              if (lastStatus === "SCREENING FAILED") {
                this.tbdCountAtSchool += 1;
              } else if (lastStatus === "MAINS PASSED") {
                this.appraisalCountAtSchool += 1;
              } else if (lastStatus === "MAINS FAILED") {
                this.pipCountAtSchool += 1;
              }
          }
        });
        // if(this.$store.state.role.clusterName !== 'All Clusters'){
        //   this.teacherAppraisal.countAppraised =this.appraisalCount;
        //   this.teacherAppraisal.countOnPip =this.pipCount;
        //   this.teacherAppraisal.countTbd = this.tbdCount;
        // }
        // console.log("tbd...>>>",this.tbdCountAtSchool);
        // console.log("appraisal...>>>",this.appraisalCountAtSchool);
        // console.log("pip...>>>",this.pipCountAtSchool);
      });
    },

    async schoolLevelHiredRejSnapshot() {
      const dashboardRef = query(collection(db, "users"),where("userType","==","Job Seeker"),where("schoolName","array-contains",this.roleSchool));
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
            //countDocumentSchool++;
            if (
              userData.campaignStatus &&
              userData.campaignStatus.length > 0
            ) {
              const lastStatus =
                userData.campaignStatus[userData.campaignStatus.length - 1]
                  .status;
              if (lastStatus === "MAINS PASSED") {
                this.hiredCountAtSchool += 1;
              } else if (lastStatus === "MAINS FAILED") {
                this.rejectedCountAtSchool += 1;
              }
          }
        });

        // if(this.$store.state.role.clusterName !== 'All Clusters'){
          this.recruitmentJobSeeker.countHired =this.hiredCountAtSchool;
          this.recruitmentJobSeeker.countRejected =this.rejectedCountAtSchool;
        // }
        // console.log("hiredCount...>>>",this.hiredCountAtSchool);
        // console.log("rejectedCount...>>>",this.rejectedCountAtSchool);
      });
    },

    showSubjectField() {
      if (this.campaignData.skills.length >= 0) {
        if (this.campaignData.skills.includes("Core Skills")) {
          this.tag = true; // Found a special string, return true
        } else {
          this.tag = false;
          this.campaignData.subjects = [];
        }
      }
    },

    getCurrentDate() {
      if (this.isCampaignPublished != 0) {
        var date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = `0${month}`;
        }
        this.startDate = `${year}-${month}-${day}`;
        // console.log("date", this.startDate);
      } else {
        this.startDate = "";
      }
    },

    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      if (this.roleSchool == undefined) {
        AuthService.logout();
        this.$router.push("/login");
      }
      if (this.roleCluster != "All Clusters") {
        this.campaignData.clusters = [this.roleCluster];
        this.getClusterSchool();
        console.log("cluster>>", this.campaignData.clusters);
      }
      if (this.roleSchool != "All") {
        this.campaignData.schools = [this.roleSchool];
        console.log("school>>", this.roleSchool);
      }
    },

    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async downloadQrCode(){
      // qrDownloadRef
      this.qrDownloadLoading=true;
      html2canvas(this.$refs.qrDownloadRef).then(canvas => {
        let link = document.createElement('a');
        // link.download = 'screenshot.jpg';
        link.download=`${this.qrCampaignName}.jpg`;
        link.href = canvas.toDataURL('image/jpeg', 0.5);
        link.click();
      });
      this.qrDownloadLoading=false;
    },

    async generateQRCode(data){
      // this.qrLoading=true;
      data.isLoading=true;
      const id=data.id
      const audienceType=data.audienceType

      console.log('data :', data);
 
      let shortCampaignLink=`https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=`+id

      if (audienceType?.toLowerCase()!=="teacher"){
        const campaignLink=`https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome`

        const apiRes=await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`, {
          "longDynamicLink":campaignLink,
          "suffix": {
              "option": "SHORT"
          }
        })

        if (apiRes?.data?.shortLink){
          shortCampaignLink=apiRes?.data?.shortLink;
        }
      }
      this.qrCampaignName=data.name
      this.qrCampaignLink=shortCampaignLink
      // this.qrLoading=false;
      data.isLoading=false;
      this.qrDialog=true;
    },

    async copyToClipboard(id, audienceType) {

      let shortCampaignLink=`https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=`+id

      if (audienceType?.toLowerCase()!=="teacher"){
        // const campaignLink=`https://this.$DEEPLINK/?link=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId%3D${id}&apn=this.$PACKAGE_NAME&afl=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId%3D${id}&isi=6446600030&ibi=this.$PACKAGE_NAME&ifl=https%3A%2F%2Fthis.$FRONTEND_DOMAIN%2F%23%2FcampaignInvite%3FcampaignId%3D${id}`

        const campaignLink=`https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome`

        const apiRes=await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`, {
          "longDynamicLink":campaignLink,
          "suffix": {
              "option": "SHORT"
          }
        })

        if (apiRes?.data?.shortLink){
          shortCampaignLink=apiRes?.data?.shortLink;
        }
      }

      console.log("campaignLink :", shortCampaignLink)
      navigator.clipboard
        .writeText(
          shortCampaignLink
        )
        .then(() => {
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    },
    copyToClipboardCampaign() {
      // console.log(data);
      navigator.clipboard
        .writeText(
          `https://${this.$FRONTEND_DOMAIN}/#/login?campaignId=` +
            this.campaignData.campaignId
        )
        .then(() => {
          this.snackbar = true;

          // console.log('Text copied to clipboard');
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    },
    getSelectedSubject() {
      var sub = "";
      this.campaignData.subjects.forEach((subject) => {
        sub = sub + subject + ",";
      });
      return sub;
    },
    getSelectedLevels() {
      var levels = "";
      this.campaignData.levels.forEach((level) => {
        levels = levels + level + ",";
      });
      return levels;
    },

    getSelectedSkills() {
      var skills = "";
      this.campaignData.skills.forEach((skill) => {
        skills = skills + skill + ",";
      });
      return skills;
    },
    goToCampaignCreationForm(role) {
      // console.log("name", campaignName);
      if(role.toLowerCase() !== 'all'){
        this.$router.push({
          path: "/CreateCampaign",
          name: "CreateCampaign", //use name for router push
          query: {
            role: role,
          },
        });
      }
    },
    goToUser(id, campaignName, campaignType , schoolsList) {
      this.clearPaginationStates();
      if(campaignType === 'MESTA_STUDENTS' || campaignType === 'DUMMY'){
          this.$router.push({
          path: "/mestacampaignuser",
          name: "mestaCampaignUser",
          query: {
              id: id,
              campaignName: campaignName,
              campaignType: campaignType,
          },
          });
      }
      else {
          this.$router.push({
              path: "/campaignUser",
              name: "CampaignUserView", //use name for router push
              query: {
                  id: id,
                  campaignType: campaignType,
              },
          });
      }
    },
    countNoOfQuestionMains() {
      var sum = 0;
      for (var i = 0; i < this.skillsDistributionsForMains.length; i++) {
        sum = sum + Number(this.skillsDistributionsForMains[i].noOfQuestion);
      }

      for (var J = 0; J < this.subjectDistributionsForMains.length; J++) {
        sum = sum + Number(this.subjectDistributionsForMains[J].noOfQuestion);
      }

      // console.log(
      //   "MAINS skills changing ",
      //   sum,
      //   this.campaignData.campaignConfigration.mainsConfigration
      //     .totalNoOfQuestions
      // );
      if (this.tag == true) {
        sum = sum - 20;
      }
      this.campaignData.campaignConfigration.mainsConfigration.totalNoOfQuestions =
        sum;
    },
    countNoOfQuestionScreening() {
      var sum = 0;
      for (var i = 0; i < this.skillsDistributions.length; i++) {
        sum = sum + Number(this.skillsDistributions[i].noOfQuestion);
      }

      for (var K = 0; K < this.subjectDistributions.length; K++) {
        sum = sum + Number(this.subjectDistributions[K].noOfQuestion);
      }

      // console.log(
      //   "SCREENIGN skills changing ",
      //   sum,
      //   this.campaignData.campaignConfigration.screeningConfigration
      //     .totalNoOfQuestions
      // );
      if (this.tag == true) {
        sum = sum - 10;
      }
      this.campaignData.campaignConfigration.screeningConfigration.totalNoOfQuestions =
        sum;
    },
    // async fetchUsers() {
    //   const response = await UsersController.getUsers();
    //   if (response.data.flag) {
    //     this.users = response.data.users;
    //     // this.count = response.data.count;
    //     // this.isLoading = false;
    //     // console.log("users", this.users);
    //   } else {
    //     alert(response.data.error);
    //   }
    // },
    addSkill() {
      this.subjectDistributions.push({
        subjectName: "",
        noOfQuestion: null,
      });
    },
    removeSkill(index) {
      this.subjectDistributions.splice(index, 1);
    },
    addSkillForMains() {
      this.subjectDistributionsForMains.push({
        subjectName: "",
        noOfQuestion: null,
      });
    },
    removeSkillForMains(index) {
      this.subjectDistributionsForMains.splice(index, 1);
    },

    getPercentage(value, totalValue) {
      var data = (value / totalValue) * 100;
      data = Math.trunc(data);

      return data + "%";
    },
    campaignButtonValue() {
      return this.updateFlag == true ? "Update" : "Create";
    },
    campaignButtonValues() {
      return this.updateFlag == true ? "Updated" : "Published";
    },
    async deleteCampaign(id) {
      // console.log(id)
      const response = await CampaignController.deleteCampaign(id);
      if (response.data.flag) {
        this.getCampaigns();
        this.deleteDialog = false;
        // console.log(response);
      } else {
        alert(response.data.error);
      }
    },
    convertDateFormat(dateString) {
      // console.log(dateString);
      const [year, month, day] = dateString.split("-");

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    convertDateFormatForUpdate(dateString) {
      // console.log(dateString);
      const [year, month, day] = dateString.split("-");

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    convertStartTimeFormatForUpdate(timeString) {
      // console.log(timeString);
      const [hours, min] = timeString.split(":");
      this.campaignStartTime = hours;
      this.startMM = min;
    },
    convertEndTimeFormatForUpdate(timeString) {
      // console.log(timeString);
      const [hours, min] = timeString.split(":");
      this.screeningEndTime = hours;
      this.endMM = min;
    },
    role(data) {
      // console.log("role" ,data)
      (this.campaignData.campaignId = ""),
        (this.campaignData.name = ""),
        (this.campaignData.startDate = ""),
        (this.campaignData.screeningEndDate = ""),
        (this.campaignData.mainsEndDate = ""),
        (this.campaignData.campaignStartTime = ""),
        (this.campaignData.screeningEndTime = ""),
        (this.campaignData.mainsEndTime = ""),
        (this.campaignData.resultPublishTime = "10:00"),
        (this.campaignData.publishPattern = {
          frequency: "",
          interval: {
            day: "",
            week: "",
            month: "",
          },
        }),
        (this.campaignData.audienceType = ""),
        (this.campaignData.skills = []),
        (this.campaignData.subjects = []),
        (this.campaignData.levels = []),
        (this.campaignData.seekerLiveCampaignsCount = 0),
        (this.campaignData.seekerScheduledCampaignsCount = 0),
        (this.campaignData.seekerExpiredCampaignsCount = 0),
        (this.campaignData.vgosLiveCampaignsCount = 0),
        (this.campaignData.vgosScheduledCampaignsCount = 0),
        (this.campaignData.vgosExpiredCampaignsCount = 0),
        // audiance:this.selected,
        (this.campaignData.campaignConfigration = {
          type: "DYNAMIC",
          assessmentIds: [],
          screeningConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "Yes",
            negativeMarking: "No",
            shuffleQuestions: "No",
            passingCriteria: 60,
            timeUpFirstRemainder: 0,
            timeUpLastRemainder: 0,
          },
          mainsConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "Yes",
            negativeMarking: "No",
            shuffleQuestions: "No",
            passingCriteria: 60,
            timeUpFirstRemainder: 0,
            timeUpLastRemainder: 0,
          },
        }),
        (this.startDate = ""),
        (this.screeningEndDate = ""),
        (this.mainsEndDate = ""),
        (this.isCampaignPublished = 0),
        (this.dialog = true);
      this.campaignData.audienceType = data;
      this.updateFlag = false;
      // console.log("data", this.campaignData.clusters);
    },
    campaignUpdate(data) {
      this.campaignId = data.id;
      this.campaignType = data.audienceType;
      this.screeningEndDate = data.screeningEndDate;
      if(this.campaignType == "MESTA_STUDENTS") {
        this.endDate = data.endDate;
      }
      else {
        this.mainsEndDate = data.mainsEndDate;
      }
      this.startDate = this.convertDateFormatForUpdate(data.startDate);
      this.UpdateCampaignDialog = true;
    },
   
    async camapignUpdate() {
      if (this.$refs.updateForm.validate()) {
        this.UpdateCampaignDialogLoading = true;
        let response;
        if(this.campaignType == "MESTA_STUDENTS"){
          this.campaignData.endDate = this.convertDateFormat(this.endDate);
          response = await CampaignController.updateCampaignDates({endDate:this.campaignData.endDate, campaignId: this.campaignId});
        }
        else{
          this.campaignData.mainsEndDate = this.convertDateFormat(this.mainsEndDate);
          if(this.campaignType == "JOB_SEEKER")
            this.campaignData.screeningEndDate = this.convertDateFormat(this.screeningEndDate);
          response = await CampaignController.updateCampaignDates({screeningEndDate: this.campaignData.screeningEndDate, mainsEndDate: this.campaignData.mainsEndDate, campaignId: this.campaignId});
        }

        if (response.status == 200) {
          this.UpdateCampaignDialog = false;
          this.UpdateCampaignDialogLoading = false;
          this.getCampaigns();
          (this.screeningEndDate = ""), (this.mainsEndDate = "");
        } else {
          alert(response.data.error);
          this.UpdateCampaignDialog = false;
          this.UpdateCampaignDialogLoading = false;
          this.getCampaigns();
          (this.screeningEndDate = ""), (this.mainsEndDate = "");
        }
      }
    },
    goto(step) {
      switch (step) {
        case 1:
          if (this.$refs.step1.validate()) {
            this.dLoading = true;
            this.subjectDistributions = [];
            this.skillsDistributions = [];
            // console.log("befour applied", this.campaignData.levels);
            this.campaignData.levels.forEach((doc) => {
              this.campaignData.campaignConfigration.screeningConfigration.levels.push(
                doc
              );
            });
            // console.log(
            //   "data applied",
            //   this.campaignData.campaignConfigration.screeningConfigration
            //     .levels
            // );

            this.subjectDistributionsForMains = [];

            this.skillsDistributionsForMains = [];
            this.campaignData.campaignConfigration.screeningConfigration.levels =
              this.campaignData.levels;
            this.campaignData.campaignConfigration.mainsConfigration.levels =
              this.campaignData.levels;
            this.countNoOfQuestionScreening();
            this.campaignData.subjects.forEach((item) => {
              this.subjectDistributions.push({
                subjectName: item,
                noOfQuestion: this.coreSkillNoOfQuestionForScreening,
                weightage: this.coreSkillWeightageForScreening,
                isRequired: this.coreSkillIsRequiredForScreening,
              });
              this.subjectDistributionsForMains.push({
                subjectName: item,
                noOfQuestion: this.coreSkillNoOfQuestionForMains,
                weightage: this.coreSkillWeightageForMains,
                isRequired: this.coreSkillIsRequiredForMains,
              });
            });
            this.campaignData.skills.forEach((item) => {
              if (item == "Core Skills") {
                this.skillsDistributions.push({
                  skillName: item,
                  noOfQuestion: 10,
                  weightage: 50,
                  isRequired: true,
                });
                this.skillsDistributionsForMains.push({
                  skillName: item,
                  noOfQuestion: 20,
                  weightage: 50,
                  isRequired: true,
                });
              } else {
                this.skillsDistributions.push({
                  skillName: item,
                  noOfQuestion: 9,
                  weightage: null,
                  isRequired: false,
                });
                this.skillsDistributionsForMains.push({
                  skillName: item,
                  noOfQuestion: 18,
                  weightage: null,
                  isRequired: false,
                });
              }
            });

            // console.log(
            //   "selected subjectDistributions",
            //   this.subjectDistributions
            // );
            // this.campaignData.skills.forEach((item)=>{

            // })

            // this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions =
            //   this.skillsDistributions;
            // this.campaignData.campaignConfigration.screeningConfigration.subjectsDistribution =
            //   this.subjectDistributions;
            // this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions =
            //   this.skillsDistributionsForMains;
            // this.campaignData.campaignConfigration.mainsConfigration.subjectsDistribution =
            //   this.subjectDistributionsForMains;

            // console.log("scre skills", this.skillsDistributions);
            console.log("scr subject", this.subjectDistributions);
            // console.log("main skills", this.skillsDistributionsForMains);
            console.log("mains subject", this.subjectDistributionsForMains);

            this.createCampaign();
          }

          break;
        case 2:
          if (this.$refs.step2.validate()) {
            this.dLoading = true;
            this.createCampaignConfigration();
            //  this.e1++;
          }
          break;
        case 3:
          this.dLoading = true;
          this.createCampaignAudiance();
          break;

        default:
          this.e1++;
      }
    },
    async createCampaign() {
      this.campaignData.campaignStartTime = this.campaignStartTime;
      this.campaignData.screeningEndTime = this.screeningEndTime;
      this.campaignData.mainsEndTime = this.mainsEndTime;

      this.campaignData.startDate = this.convertDateFormat(this.startDate);
      this.campaignData.mainsEndDate = this.convertDateFormat(
        this.mainsEndDate
      );
      this.campaignData.screeningEndDate = this.convertDateFormat(
        this.screeningEndDate
      );

      if (this.updateFlag == false) {
        const response = await CampaignController.createCampaign({
          isCampaignPublished: this.isCampaignPublished == 0 ? false : true,
          name: this.campaignData.name,
          startDate: this.campaignData.startDate,
          screeningEndDate: this.campaignData.screeningEndDate,
          mainsEndDate: this.campaignData.mainsEndDate,
          campaignStartTime: this.campaignData.campaignStartTime,
          screeningEndTime: this.campaignData.screeningEndTime,
          mainsEndTime: this.campaignData.mainsEndTime,
          resultPublishTime: this.campaignData.resultPublishTime,
          publishPattern: this.campaignData.publishPattern,
          audienceType: this.campaignData.audienceType,
          clusters: this.campaignData.clusters,
          skills: this.campaignData.skills,
          subjects: this.campaignData.subjects,
          schools: this.campaignData.schools,
          levels: this.campaignData.levels,
        });
        // console.log("create", response);
        if (response.data.flag) {
          this.e1++;
          this.dLoading = false;
          this.countNoOfQuestionMains();
          this.countNoOfQuestionScreening();
          this.campaignData.campaignId = response.data.id;
        } else {
          this.dLoading = false;
          alert(response.data.error);
        }
      } else {
        const response = await CampaignController.updateCampaign(
          {
            name: this.campaignData.name,
            startDate: this.campaignData.startDate,
            screeningEndDate: this.campaignData.screeningEndDate,
            campaignStartTime: this.campaignData.campaignStartTime,
            screeningEndTime: this.campaignData.screeningEndTime,
            mainsEndTime: this.campaignData.mainsEndTime,
            resultPublishTime: this.campaignData.resultPublishTime,
            publishPattern: this.campaignData.publishPattern,
            audienceType: this.campaignData.audienceType,
            clusters: this.campaignData.clusters,
            skills: this.campaignData.skills,
            subjects: this.campaignData.subjects,
            schools: this.campaignData.schools,
            levels: this.campaignData.levels,
          },
          this.campaignId
        );
        // console.log("update", response);

        if (response.data.flag) {
          this.e1++;
          this.campaignId = null;
        } else {
          alert(response.data.error);
          this.campaignId = null;
        }
      }
    },
    filterBySubjects(mainArray, givenSubjects) {
      return mainArray.filter(async (obj) => {
        const subjects = await obj.responsibleTeachingAt.map(
          (responsibility) => responsibility.subject
        );
        return givenSubjects.every((subject) => subjects.includes(subject));
      });
    },
    async createCampaignConfigration() {
      this.subjectDistributions.forEach((item) => {
        this.campaignData.campaignConfigration.screeningConfigration.subjectsDistribution.push(
          {
            subjectName: item.subjectName,
            noOfQuestion: this.coreSkillNoOfQuestionForScreening,
            weightage: this.coreSkillWeightageForScreening,
            isRequired: this.coreSkillIsRequiredForScreening,
          }
        );
      });

      this.subjectDistributionsForMains.forEach((item) => {
        this.campaignData.campaignConfigration.mainsConfigration.subjectsDistribution.push(
          {
            subjectName: item.subjectName,
            noOfQuestion: this.coreSkillNoOfQuestionForMains,
            weightage: this.coreSkillWeightageForMains,
            isRequired: this.coreSkillIsRequiredForMains,
          }
        );
      });

      this.skillsDistributions.forEach((item) => {
        if (item.skillName == "Core Skills") {
          this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions.push(
            {
              skillName: item.skillName,
              noOfQuestion: this.coreSkillNoOfQuestionForScreening,
              weightage: this.coreSkillWeightageForScreening,
              isRequired: this.coreSkillIsRequiredForScreening,
            }
          );
        } else {
          this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions.push(
            {
              skillName: item.skillName,
              noOfQuestion: item.noOfQuestion,
              weightage: item.weightage,
              isRequired: item.isRequired,
            }
          );
        }
      });

      this.skillsDistributionsForMains.forEach((item) => {
        if (item.skillName == "Core Skills") {
          this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions.push(
            {
              skillName: item.skillName,
              noOfQuestion: this.coreSkillNoOfQuestionForMains,
              weightage: this.coreSkillWeightageForMains,
              isRequired: this.coreSkillIsRequiredForMains,
            }
          );
        } else {
          this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions.push(
            {
              skillName: item.skillName,
              noOfQuestion: item.noOfQuestion,
              weightage: item.weightage,
              isRequired: item.isRequired,
            }
          );
        }
      });

      // this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions =
      //   this.skillsDistributions;
      // this.campaignData.campaignConfigration.screeningConfigration.subjectsDistribution =
      //   this.subjectDistributions;
      // this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions =
      //   this.skillsDistributionsForMains;
      // this.campaignData.campaignConfigration.mainsConfigration.subjectsDistribution =
      //   this.subjectDistributionsForMains;

      if (this.updateFlag == false) {
        const response = await CampaignController.createCampaignConfigration(
          this.campaignData.campaignConfigration,
          this.campaignData.campaignId
          // "EOfr9hNdwIgEIfrfBW0O"
        );
        // console.log("Campaign configration", response);
        if (response.data.flag) {
          this.e1++;
          this.dLoading = false;
          this.count = response.data.users.length;
          this.isLoading = false;
          if (this.campaignData.audienceType == "TEACHER") {
            var userData = response.data.users;
            this.users = await this.filterBySubjects(
              userData,
              this.campaignData.subjects
            );
            console.log(
              "this.users...>>>>",
              this.users,
              this.campaignData.subjects
            );
            this.responsibleTeachingAt = {};
            this.teachingSubjects = {};
            for (const user of this.users) {
              const lvl = [];
              const sub = [];
              for (const res of user.responsibleTeachingAt) {
                lvl.push(res.level);
                sub.push(res.subject);
              }
              this.responsibleTeachingAt[user.userId] = lvl;
              this.teachingSubjects[user.userId] = sub;
            }

            // console.log("responsiblity...>>>", this.responsibleTeachingAt)
            // console.log("teachingSubject...>>>", this.teachingSubjects)
          }
        } else {
          this.dLoading = false;
          alert(response.data.error);
        }
      } else {
        const response = await CampaignController.createCampaignConfigration(
          this.campaignData.campaignConfigration,
          this.campaignData.campaignId
          // "EOfr9hNdwIgEIfrfBW0O"
        );
        // console.log("update", response);

        if (response.data.flag) {
          this.e1++;
          this.campaignId = null;
        } else {
          alert(response.data.error);
          this.campaignId = null;
        }
      }
    },

    async createCampaignAudiance() {
      if (this.updateFlag == false) {
        const response = await CampaignController.createCampaignAudiance(
          this.selected,
          this.campaignData.campaignId
          // "IPEsnYQwz8Jf30Wyw4ll"
        );
        // console.log("Campaign Audiance", response);
        if (response.data.flag) {
          this.dLoading = false;
          this.e1 = 1;
          this.dialog = false;
          this.successDialog = true;
          this.getCampaigns();
          // console.log("svnas hdsvadnsvhsdvavdsxvdkvbi",this.selected)
        } else {
          this.dLoading = false;
          alert(response.data.error);
        }
      } else {
        const response = await CampaignController.createCampaignAudiance(
          this.campaignData.audiance,
          this.campaignData.campaignId
          // "IPEsnYQwz8Jf30Wyw4ll"
        );
        // console.log("update", response);

        if (response.data.flag) {
          //  this.successDialog = true
          this.campaignId = null;
        } else {
          alert(response.data.error);
          this.campaignId = null;
        }
      }
    },
    // async getSchool() {
    //   const response = await SchoolController.getSchool();
    //   // console.log(response);
    //   if (response.status == 200) {
    //     if (response.data.schools.length > 0) {
    //       // this.schools = response.data.schools;
    //     } else {
    //       // this.schools = [];
    //     }
    //   } else {
    //     alert(response.data.error);
    //   }
    // },
    async getClusterSchool() {
      // console.log("selected cluster", this.campaignData.clusters);
      if (this.campaignData.clusters.length > 0) {
        const response = await SchoolController.getClusterSchool(
          this.campaignData.clusters
        );
        // console.log("school", response);
        if (response.status == 200) {
          var schools = response.data.schools;
          this.schools = schools;
          //   console.log("school", this.schools);
        } else {
          alert(response.data.error);
        }
      } else {
        this.schools = [];
      }
    },
    async getClusterSchoolForFilter(clusters) {
      // console.log("selected cluster", this.campaignData.clusters);
      if (this.clusters.length > 0) {
        const response = await SchoolController.getClusterSchool(clusters);
        console.log("school", response);
        if (response.status == 200) {
          var schools = response.data.schools;
          this.schoolsData = schools;
          console.log("school", this.schoolsData);
        } else {
          alert(response.data.error);
        }
      } else {
        this.schoolsData = [];
      }
    },

    async  getCampaignsCount() {
      let clusterData = this.$store.state.role.clusterName;
      if (clusterData == "All Clusters") {
        clusterData = "All";
      }
      let schoolData = this.$store.state.role.schools;
      let query = "?cluster=" + clusterData + "&school=" + schoolData + "&audienceType=" + this.audienceType;
      const response = await CampaignController.getCampaignsCount(query);
      if (response.status == 200) {
        this.allCampaignCount = response.data.counts;
        this.countFlag = response.data.flag;
        if (this.allCampaignCount) {
          this.liveCount = this.allCampaignCount.liveCount;
          this.expiredCount = this.allCampaignCount.expiredCount;
          this.scheduledCount = this.allCampaignCount.scheduledCount;
          this.seekerExpiredCampaignsCount = this.allCampaignCount.seekerExpiredCount;
          this.seekerLiveCampaignsCount = this.allCampaignCount.seekerLiveCount;
          this.seekerScheduledCampaignsCount = this.allCampaignCount.seekerScheduleCount;
          this.vgosExpiredCampaignsCount = this.allCampaignCount.vgosExpiredCount;
          this.vgosLiveCampaignsCount = this.allCampaignCount.vgosLiveCount;
          this.vgosScheduledCampaignsCount = this.allCampaignCount.vgosScheduleCount;
          this.mestaLiveCampaignsCount= this.allCampaignCount.mestaLiveCount;
          this.mestaScheduledCampaignsCount= this.allCampaignCount.mestaScheduleCount;
          this.mestaExpiredCampaignsCount= this.allCampaignCount.mestaExpiredCount;
        }

        this.campaignStats.totalTeachersCampaign = this.vgosLiveCampaignsCount+ this.vgosExpiredCampaignsCount + this.vgosScheduledCampaignsCount;
        this.campaignStats.totalJobSeekersCampaign = this.seekerLiveCampaignsCount + this.seekerExpiredCampaignsCount + this.seekerScheduledCampaignsCount;
        this.campaignStats.totalMestaCampaign = this.mestaLiveCampaignsCount + this.mestaScheduledCampaignsCount + this.mestaExpiredCampaignsCount;
        this.campaignStats.totalCampaigns = this.campaignStats.totalTeachersCampaign + this.campaignStats.totalJobSeekersCampaign + this.campaignStats.totalMestaCampaign;
        this.changeAudienceCampaign([], this.audienceType);

    }
  },

  updateQueryParams(params) {
    this.$router.push({
      query: {
        ...this.$route.query,
        ...params,
      }
    });
  },

  async changeAudienceCampaign(status,audienceType){
    this.audienceType = audienceType;
    this.updateQueryParams({ audienceType });
    switch (audienceType) {
      case 'JOB_SEEKER':
        this.pageName = "JOB_SEEKER";
        this.liveCount = this.allCampaignCount.seekerLiveCount;
        this.expiredCount = this.allCampaignCount.seekerExpiredCount;
        this.scheduledCount = this.allCampaignCount.seekerScheduleCount;
        break;
      case 'TEACHER':
        this.pageName = "TEACHER";
        this.liveCount = this.allCampaignCount.vgosLiveCount;
        this.expiredCount = this.allCampaignCount.vgosExpiredCount;
        this.scheduledCount = this.allCampaignCount.vgosScheduleCount;
        break;
      case 'MESTA_STUDENTS':
      this.pageName = "MESTA_STUDENTS";
        this.liveCount = this.allCampaignCount.mestaLiveCount;
        this.expiredCount = this.allCampaignCount.mestaExpiredCount;
        this.scheduledCount = this.allCampaignCount.mestaScheduleCount;
        break;
      case 'All':
      default:
        this.pageName = "All";
        this.liveCount = this.allCampaignCount.liveCount;
        this.expiredCount = this.allCampaignCount.expiredCount;
        this.scheduledCount = this.allCampaignCount.scheduledCount;
        break;
    }

    await this.getCampaigns();
  },
  async changeCampaignStatus(status) {
    this.currentStatus = status;
    this.updateQueryParams({ status });
    await this.getCampaigns();
  },

    async getCampaigns(
      status = this.currentStatus, 
      audienceType = this.audienceType, 
      fetchForDownload = false
    ) {
      try {
        
        this.currentStatus = status;
        this.updateUrlParams();

        const queryParams = new URLSearchParams({
          cluster: this.$store.state.role.clusterName === "All Clusters" ? "All" : this.$store.state.role.clusterName,
          school: this.$store.state.role.schools,
          status,
          audienceType,
          ...(fetchForDownload && { all: 'true' }),
          ...(this.search && { search: this.search })
        });
        const response = await CampaignController.getCampaigns(
          "?"+queryParams.toString(), 
          // this.dataschools,
          // this.dataskills,
          // this.dataclusters,
          // this.datalevels
        );
        if (response.status === 200) {
          this.updateCampaignData(response.data.campaigns, fetchForDownload);
        } else {
        }
      } catch (error) {
        console.error('Failed to fetch campaigns:', error);
      } finally {
        if (!fetchForDownload) {
          this.preloader = false;
        }
      }
    },

    resetFilters() {
      this.appliedFilter = false;
      this.selectedClusterFilters = [];
      this.selectedSchoolFilter = [];
      this.selectedSkillsFilter = [];
      this.selectedLevelFlter = [];
      this.filterCount = 0;
    },

    updateCampaignData(campaigns, fetchForDownload) {
      if (campaigns) {
        console.log("Here",campaigns)
        this.campaigns = campaigns;
        this.liveCampaigns = campaigns.liveCampaign || [];
        this.storeLiveCam = campaigns.liveCampaign || [];
        this.scheduledCampaigns = campaigns.scheduledCampaign || [];
        this.expiredCampaigns = campaigns.expiredCampaign || [];
      }
      this.getTypeCampaign(this.audienceType);
    },
   
     getTypeCampaign(data) {
      // console.log("chartSwitch>>", data);
      this.chartData = data;
      this.campaignsForDownload = [
          ...this.liveCampaigns,
          ...this.scheduledCampaigns,
          ...this.expiredCampaigns,
        ];
      if (data == "All" || data == "") {
        // this.pageName = "All";
        // this.liveCampaigns = this.preLiveCampaigns;
        // this.scheduledCampaigns = this.preScheduledCampaigns;
        // this.expiredCampaigns = this.preExpiredCampaigns;
        // this.filterLiveCampaigns = this.preLiveCampaigns;
        // this.filterScheduledCampaigns = this.preScheduledCampaigns;
        // this.campaignsForDownload = [
        //   ...this.preLiveCampaigns,
        //   ...this.preScheduledCampaigns,
        //   ...this.preExpiredCampaigns,
        // ];
        // this.filterExpiredCampaigns = this.preExpiredCampaigns;
       
      
        
      } else if (data == "JOB_SEEKER") {
        // this.pageName = "JOB_SEEKER";
        // this.campaignsForDownload = [
        //   ...this.preLiveCampaigns.filter((item) => item.audienceType == data),
        //   ...this.preScheduledCampaigns.filter(
        //     (item) => item.audienceType == data
        //   ),
        //   ...this.preExpiredCampaigns.filter(
        //     (item) => item.audienceType == data
        //   ),
        // ];

        // var jobLiveCam = this.storeLiveCam.filter(
        //   (item) => item.audienceType == data
        // );
        // this.liveCampaigns = this.preLiveCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.scheduledCampaigns = this.preScheduledCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.expiredCampaigns = this.preExpiredCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.filterLiveCampaigns = this.preLiveCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.filterScheduledCampaigns = this.preScheduledCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.filterExpiredCampaigns = this.preExpiredCampaigns.filter(
        //   (item) => item.audienceType == data
        // );

        this.countCampaignByLevel.foundationalCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Foundational")
        ).length;
        this.countCampaignByLevel.preparatoryCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Preparatory")
        ).length;
        this.countCampaignByLevel.secondaryCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Secondary")
        ).length;
        this.countCampaignByLevel.seniorSecondaryCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Senior Secondary")
        ).length;
        this.countCampaignByLevel.middleCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Middle")
        ).length;
      } else if(data == "TEACHER") {
        // this.campaignsForDownload = [
        //   ...this.preLiveCampaigns.filter((item) => item.audienceType == data),
        //   ...this.preScheduledCampaigns.filter(
        //     (item) => item.audienceType == data
        //   ),
        //   ...this.preExpiredCampaigns.filter(
        //     (item) => item.audienceType == data
        //   ),
        // ];
        // // this.pageName = "TEACHER";
        // var teacherLiveCam = this.storeLiveCam.filter(
        //   (item) => item.audienceType == data
        // );
        // this.liveCampaigns = this.preLiveCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.scheduledCampaigns = this.preScheduledCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.expiredCampaigns = this.preExpiredCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.filterLiveCampaigns = this.preLiveCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.filterScheduledCampaigns = this.preScheduledCampaigns.filter(
        //   (item) => item.audienceType == data
        // );
        // this.filterExpiredCampaigns = this.preExpiredCampaigns.filter(
        //   (item) => item.audienceType == data
        // );

        this.countCampaignByLevel.foundationalCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Foundational")
        ).length;
        this.countCampaignByLevel.preparatoryCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Preparatory")
        ).length;
        this.countCampaignByLevel.secondaryCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Secondary")
        ).length;
        this.countCampaignByLevel.seniorSecondaryCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Senior Secondary")
        ).length;
        this.countCampaignByLevel.middleCount = this.storeLiveCam.filter(
          (item) => item.levels && item.levels.includes("Middle")
        ).length;
      } else if (data == "MESTA_STUDENTS") {
        // this.pageName = "MESTA_STUDENTS";
        // this.campaignsForDownload = [
        //   ...this.preLiveCampaigns.filter((item) => item.audienceType == data),
        //   ...this.preScheduledCampaigns.filter(
        //     (item) => item.audienceType == data
        //   ),
        //   ...this.preExpiredCampaigns.filter(
        //     (item) => item.audienceType == data
        //   ),
        // ];
        // this.liveCampaigns = this.preLiveCampaigns.filter(
        //   (item) => item.audienceType == data || item.audienceType == 'DUMMY'
        // );
        // this.scheduledCampaigns = this.preScheduledCampaigns.filter(
        //   (item) => item.audienceType == data || item.audienceType == 'DUMMY'
        // );
        // this.expiredCampaigns = this.preExpiredCampaigns.filter(
        //   (item) => item.audienceType == data || item.audienceType == 'DUMMY'
        // );
      }
      // if (this.search != "" && this.search != null) {
      //   this.searchData(this.search);
      //   // console.log("this.searchData(this.search);")
      // }
      // console.log("live" ,  teacherLiveCam)
    },

    async getSkills() {
      const response = await SkillsController.getSkills();
      // console.log(response);
      if (response.status == 200) {
        if (response.data.skills.length > 0) {
          this.skills = response.data.skills;
        } else {
          this.skills = [];
        }
      }
    },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      if (response.status == 200) {
        if (response.data.subjects.length > 0) {
          this.subjects = response.data.subjects;
        } else {
          this.subjects = [];
        }
        // console.log("subjects", this.subjects);
      }
      //console.log("surbject responser", this.subjectData);
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();

      if (response.status == 200) {
        // console.log(response);
        if (response.data.clusters.length > 0) {
          this.clusters = response.data.clusters;
        } else {
          this.clusters = [];
        }
        // console.log("afkdsbxbfabsbdj,a", this.clusters);
      }
    },
    goToAssessment() {
      // this.$router.push({
      //   path: '/assessment',
      //   name: "dataList", //use name for router push
      //   // params: { data }
      // });
      // this.$router.push("/assessment")
      this.$router.push({
        name: "assessment",
        params: { cdialog: true },
      });
    },

    // trigger reload

    showAlert(event) {
      // show alert message
      if (event) {
        confirm("Press a button!");
      }
      // event.preventDefault()
      event.stopImmediatePropagation();
      // event.returnValue = 'my current value null'
    },

    viewUserInfoFun(item) {
      this.viewUserIno = item;
      this.userViewDialog = true;
      this.userFullName =
        item.personalInfo.firstName + item.personalInfo.lastName;
      this.userPhoneNumber = item.personalInfo.contactInfo.phoneNumber;
      this.userEmail = item.personalInfo.contactInfo.email;
    },

    async getLevels() {
      const response = await LevelController.getLevel();
      // console.log("responce " , response.status)

      if (response.status == 200) {
        if (response.data.levels.length > 0) {
          this.levels = response.data.levels;
          // console.log("bsfebdsdbbvjf" , this.levels)
        } else {
          this.levels = [];
        }
      }

      // console.log("level data", this.levelData.rows);
    },
    async fetchAssessment() {
      const response = await AssessmentController.getAssessments();
      // console.log("response from ", response);

      if (response.status == 200) {
        if (response.data.assessments.length > 0) {
          this.assessments = response.data.assessments;
        } else {
          this.assessments = [];
        }
      }

      // console.log(response.data.data);
    },

    // validateFormData(){
    //   if (this.$refs.step1){
    //     console.log(this.$refs.step1.validate())
    //   }
    //   else{
    //     console.log("false")
    //   }
    // }
  },
  async mounted() {
    // this.getCampaigns();
    this.getClusterOrSchool();
    this.setupSnapshotForAssessingUsers();

    if(this.$store.state.role.clusterName !== "All Clusters" && this.$store.state.role.schools === "All") {
    await Promise.all([
      this.clusterLevelHiredRejSnapshot(),
      this.clusterLevelAppraisalSnapshot(),
      this.clusterLevelAccessedUnaccessedJobSeekerSnapshot(),
      this.clusterLevelAccessedUnaccessedSnapshot(),
    ])
    }
    if(
      this.$store.state.role.schools !== "All"
    )
    {
    await Promise.all([
      this.schoolLevelHiredRejSnapshot(),
      this.schoolLevelAppraisalSnapshot(),
      this.schoolLevelAccessedUnaccessedJobSeekerSnapshot(),
      this.schoolLevelAccessedUnaccessedSnapshot(),
    ]);
    }
    // add event listener for beforeunload event
    window.addEventListener("beforeunload", this.showAlert);

  },
  beforeUnmount() {
    // remove event listener when component is unmounted
    window.removeEventListener("beforeunload", this.showAlert);
  },

  async created() {
    this.debouncedFilterCampaign = debounce(this.filterCampaign, 500)
    if (this.$route.query.status) {
      this.currentStatus = this.$route.query.status;
    }
    if (this.$route.query.audienceType) {
      this.audienceType = this.$route.query.audienceType;
    }
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }

    await Promise.all([
    this.getCampaigns(),
    this.getCampaignsCount(),
    ])
    await Promise.all([
      this.getLevels(),
      this.getSkills(),
      this.getSubjects(),
      this.fetchAllClusters(),
    ])
      
    // ***** Not in Used Currently *****
        /// Super Admin
    // await this.setupSnapshotForTotalCampaign();
        /// Cluster Admin
    // await this.setuppForClusterAndSchool()
    // await this.clusterLevelTotalCampaignJobSeekerSnapshot();
    // await this.clusterLevelTotalCampaignSnapshot();
        /// School Admin
    // await this.schoolLevelTotalCampaignJobSeekerSnapshot();
    // await this.schoolLevelTotalCampaignSnapshot();

    /// **** End ***** ///
   
    
    // this.getSchool();
    // this.fetchUsers(); // change
    // if (!this.$store.state.role.campaign.read) {
    //   this.$router.push("/notFound");
    // }
  },
  watch: {
  '$route': {
    immediate: true,
    handler(newRoute) {
      if (newRoute.query.status) {
        this.currentStatus = newRoute.query.status;
        this.campaignStatus = this.getStatusIndex(this.currentStatus);
      }
      
      if (newRoute.query.audienceType) {
        this.audienceType = newRoute.query.audienceType;
        this.pageName = this.audienceType;
      }

      if (newRoute.query.search) {
        this.search = newRoute.query.search;
      }
    }
  }
},
};
</script>

<style scoped>

@import url("../common.css");

.custom-button {
      display: inline-block;
      height: 40px;
      padding: 0px 16px;
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.26) !important; /* Change color as needed */
      cursor: pointer;
      background-color: #ffffff; /* Change color as needed */
      color:rgba(0, 0, 0, 0.87); /* Change color as needed */
      text-align: center;
      text-decoration: none;
      font-size: 0.875rem;
       line-height: 1;
    font-weight: 500;
    text-indent: 0.0892857143em;
    text-transform: uppercase;

    }

    .custom-button:hover {
      background-color: rgba(0, 0, 0, 0.08);/* Change color as needed on hover */
      
    }

    .icon {
      margin-right: 8px;
    }
.chart-clas {
  height: 132px;
}

.graph {
  border-radius: 8px !important;
}

.card-container {
  position: relative;
  perspective: 1000px;
}

.card-content {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: #ffffff;
}

.card-content.flipped {
  transform: rotateY(180deg);
}

.link-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
}

.flip-link {
  color: #007fff;
  cursor: pointer;
  font-weight: bold;
}

.v-application .primary--text {
  color: #864f20 !important;
  caret-color: #864f20 !important;
}
.no-details >>> .v-text-field__details {
  display: none;
}
.no-details >>> .v-input__slot{
  min-height: 42px !important;
}
.custom-btn {
  
  border: 1px solid #DECFC3; /* Outline color */
  color: #864F20; /* Text color */
}
.square-btn {
  width: 40px;  /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  min-width: 40px; /* To ensure button is square */
}
</style>
