import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});
export default {
    getSubject: async function () {
        try {
            const response = await instance.get('subjects')
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getSubjectByPagination: async function (query) {
        try {
            const response = await instance.get('subjects'+query)
            return response;
        } catch (error) {
            return error.response;
        }
    },
    searchSubject: async function (pageSize,page,search) {
        try {
            const response = await instance.get('/meta/subjects?pageSize='+pageSize+'&page='+page+'&search='+search)
            return response;
        } catch (error) {
            return error.response;
        }
    },



    updateSubject: async function (subName, subCat,icon, id) {
        console.log("update  subject", {
            "name": subName,
            "subjectCategory": subCat,
            "icon": icon
        } )
               try {
            const response = await instance.put('subject?id='+id, {
                "name": subName,
                "subjectCategory": subCat,
                "icon": icon
            },{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },


    

   createSubject : async function ( subName, subCat,icon) {
        console.log("creqating subject", {
            "name": subName,
            "subjectCategory": subCat,
            "icon": icon
        } )
        try {        
            const response = await instance.post('subject',
                {
                    "name": subName,
                    "subjectCategory": subCat,
                    "icon": icon
                }              

            ,{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },



    deleteSubject : async function (id) {
        console.log("delete selected url link",'admin/meta/subject-category/'+id)
        try {
            const response = await instance.delete('admin/meta/subjects/'+id,
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkSubject : async function (ids) {
        console.log("delete"+ids)
        try {
            const response = await instance.delete('subjects',{
                data:{
                    ids:ids
                }
            },
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    filterSubject : async function (subjects){
        console.log("filter function called");
        try{
            const response = await instance.put('/filterSubjects',{subjects},
            {
                headers:{
                    'Authorization':AuthService.getToken()
                }
            })
            return response;
        }
        catch (error){
            return error.response;
        }
    }



}