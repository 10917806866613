<template>
  <div>
    <v-form ref="form" class="overflow-hidden" lazy-validation>
      <v-card class="">
        <v-card-title class="gray-02 mb-8">SCHEDULE INTERVIEW</v-card-title>
        <div class="px-9">
          <v-card width="100%" height="5.813rem" class="" outlined>
            <div class="w-100 h-100 d-flex flex-row">
              <!-- parent div -->
              <div class="w-50 h-100 d-flex flex-row align-center">
                <img src="../assets/avtar.png" class="rounded-xl mx-2" width="32" height="32" />
                <div class="SInterview-card-left h-100 d-flex flex-column py-2">
                  <div class="d-flex w-100 flex-row py-2 px-2 justify-space-between">
                    <h5>
                      {{
                        this.userData.personalInfo.firstName +
                        " " +
                        this.userData.personalInfo.lastName
                      }}
                    </h5>
                  </div>
                  <div v-if="userData.userType == 'Job Seeker'" class="d-flex pb-2 px-2 flex-row">
                    <v-chip class="mr-2" v-for="i in this.userData.teachingInterests?.levels" :key="i" small>{{ i
                      }}</v-chip>
                  </div>
                  <div v-if="userData.userType == 'Teacher'" class="d-flex pb-2 px-2 flex-row">
                    <v-chip class="mr-2" v-for="i in this.userData.responsibleTeachingAt" :key="i.level" small>{{
                        i.level
                      }}</v-chip>
                  </div>
                </div>
              </div>
              <!-- total Experience -->
              <div class="w-50 px-2 py-2 h-100 d-flex flex-row">
                <div class="w-50 h-100 d-flex flex-row justify-space-between">
                  <v-divider vertical></v-divider>
                  <div class="d-flex flex-column">
                    <div class="">
                      <span class="text-body-2"> Total Experience - </span>
                      <span class="breadcrumb-text"> {{ this.candidateDetails.totalExperience.years }}.{{
                        this.candidateDetails.totalExperience.months }} Years</span>
                    </div>
                    <div>
                      <span class="text-body-2"> Relevant Experience - </span>
                      <span class="breadcrumb-text"> {{ this.candidateDetails.relevantExperience.years }}.{{
                        this.candidateDetails.relevantExperience.months }} Years </span>
                    </div>
                    <div>
                      <span class="text-body-2"> Notice Period - </span>
                      <span class="breadcrumb-text"> {{ this.candidateDetails.noticePeriod }}</span>
                      <span class="breadcrumb-text px-1" v-if="this.candidateDetails.noticePeriod > 1">Days</span>
                      <span class="breadcrumb-text px-1" v-else>Day</span>
                    </div>
                  </div>
                  <v-divider vertical></v-divider>
                </div>
                <div class="w-50 py-2 h-100 d-flex flex-column justify-center">
                  <div class="d-flex flex-column pl-10">
                    <div class="text-left">
                      <span class="text-body-2"> Current CTC - </span>
                      <span class="breadcrumb-text"> {{ this.candidateDetails.currentCTC }} LPA </span>
                    </div>
                    <div class="text-left">
                      <span class="text-body-2"> Expected CTC - </span>
                      <span class="breadcrumb-text"> {{ this.candidateDetails.expectedCTC }} LPA </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <h6 class="font-12 breadcrumb-text text--secondary mt-4 mb-1">
            Select School
          </h6>
          <v-autocomplete readonly label="Select School" outlined hide-details class="rounded-xl" chips item-text="name"
            item-value="name" flat solo required v-model="userData.teachingInterests.schools" :items="[userData.teachingInterests.schools]"
            :rules="[(v) => !!v || 'School is required']">
          </v-autocomplete>

          <div class="w-100 d-flex flex-row">
            <div class="w-50">
              <h6 class="breadcrumb-text mt-4 mb-1">Schedule Interview:</h6>
              <v-card height="13.563rem" outlined class="d-flex pa-2 ma-1 flex-column">
                <h6 class="captio mt-1">Select a date</h6>
                <div class="w-100 d-flex flex-row">
                  <v-item-group class="d-flex flex-row w-100" mandatory>
                    <v-item v-for="(data, index) in this.slotsData" :key="data.slotDate" v-slot="{ toggle }"
                      class="w-33">
                      <v-card outlined :class="currentSlotId === data.slotDate ? 'gray-02 blue--text outline--blue' : ''
                        " @click="toggle, findTimeSlots(index)"
                        class="d-flex flex-column cursor justify-center align-center pa-2 ma-1">
                        <div class="f-14-w-400-h-14">{{ data.weekday }}</div>
                        <div class="f-14-w-400-h-14">{{ data.date }}</div>
                      </v-card>
                    </v-item>
                  </v-item-group>
                </div>
                <h6 class="caption mt-4">Select A time-slot</h6>
                <div class="w-100 d-flex flex-row">
                  <v-item-group class="d-flex flex-row w-100" mandatory>
                    <v-item v-for="data in this.currentTimeSlots" class="w-33" v-slot="{ toggle }" :key="data.index">
                      <v-card width="33%" class="d-flex flex-column rounded-r-0 justify-center align-center pa-2 pt">
                        <v-card @click="toggle, setCurrentTimeSlotIndex(data.index)" :class="currentTimeSlotIndex === data.index
                        ? 'SI-time-card-active'
                        : 'SI-time-card'
                        " class="w-100 h-100 rounded-lg text-center align-center d-flex justify-center py-2">
                          {{ data.slotStart }} - {{ data.slotEnd }}
                        </v-card>
                      </v-card>
                    </v-item>
                  </v-item-group>
                </div>
              </v-card>
            </div>
            <div class="w-50">
              <h6 class="breadcrumb-text mt-4 mb-1">Candidate details:</h6>
              <v-card height="13.563rem" outlined class="d-flex h-100 pa-2 ma-1 flex-column">

                <v-row class="px-1">
                  <v-col class="px- py-0 mx-0 mt-2" cols="6">
                    <div class="text-body-2 pb-4">Total Experience</div>
                    <v-row>
                      <v-col class="px- py-0 mx-0 my-0" cols="6" sm="6">
                        <div class="text-caption">
                          Years
                        </div>
                        <v-text-field :rules="[
           (v) => v >= 0 || 'Only a positive value is allowed',
        ]" outlined type="number" class="rounded-xl" min="0"

                          v-model="candidateDetails.totalExperience.years" solo text hide-details></v-text-field>
                      </v-col>
                      <v-col class="px- py-0 mx-0 my-0" cols="6" sm="6">
                        <div class="text-caption">
                          Months
                        </div>
                        <v-text-field outlined class="rounded-xl" :rules="[
           (v) => v >= 0 || 'Only a positive value is allowed',
        ]" v-model="candidateDetails.totalExperience.months" min="0" max="11"
                          type="number" solo text hide-details></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="px- py-0 mx-0 mt-2" cols="6">
                    <div class="text-body-2 pb-4">Relevant Experience</div>
                    <v-row>
                      <v-col class="px- py-0 mx-0 my-0" cols="6" sm="6">
                        <div class="text-caption">
                          Years
                        </div>
                        <v-text-field outlined class="rounded-xl" :rules="[
           (v) => v >= 0 || 'Only a positive value is allowed',
           this.checkExperience() || 'Relevant experience cannot be more than the Total Experience'
        ]" v-model="candidateDetails.relevantExperience.years" min="0"
                          solo text type="number" hide-details></v-text-field>
                      </v-col>
                      <v-col class="px- py-0 mx-0 my-0" cols="6" sm="6">
                        <div class="text-caption">
                          Months
                        </div>
                        <v-text-field :rules="[
           (v) => v >= 0 || 'Only a positive value is allowed',
           this.checkExperience() || 'Relevant experience cannot be more than the Total Experience'
        ]" outlined class="rounded-xl" v-model="candidateDetails.relevantExperience.months" min="0" max="11"
                          solo text type="number" hide-details></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pt-1 px-1">
                  <v-col cols="4" class="px- py-0 mx-0 my-0">
                    <div class="text-body-2 font-weight-regular">
                      Notice Period
                    </div>
                    <v-text-field outlined class="rounded-xl pt-1" v-model="candidateDetails.noticePeriod" solo text min="0"
                    :rules="[
           (v) => v >= 0 || 'Only a positive value is allowed',
        ]"  type="number" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="4" class="px- py-0 mx-0 my-0">
                    <div class="text-body-2">
                      Current CTC
                    </div>
                    <v-text-field :rules="[
           (v) => v >= 0 || 'Only a positive value is allowed',
        ]" outlined class="rounded-xl pt-1" v-model="candidateDetails.currentCTC" solo text min="0"
                      type="number" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="4" class="px- py-0 mx-0 my-0">
                    <div class="text-body-2">
                      Expected CTC
                    </div>
                    <v-text-field :rules="[
           (v) => v >= 0 || 'Only a positive value is allowed',
        ]" outlined class="rounded-xl pt-1" v-model="candidateDetails.expectedCTC" solo min="0"
                      type="number" text hide-details></v-text-field>
                  </v-col>

                </v-row>
              </v-card>
            </div>
          </div>
          <h6 class="breadcrumb-text mt-4 mb-1">Interviewer email -</h6>

          <div outlined class="rounded-xl outlined d-flex tag-compo">
            <!-- <div outlined class="rounded-xl outlined"> -->

            <v-chip v-for="(tag, index) in emailList" :key="index" :close="true" :disabled="userData.isResuduleFun"
              @click:close="removeTag(index)" class="ma-2 blue--text email-chip n-overflow-hidden">
              <div v-if="!tag.isSlotAvalable" class="red--text fit-content h-fit-content ma-4">
                <v-icon>mdi-file-account-outline</v-icon>
                <span class="tooltip">{{ tag.email
                  }}<span class="tooltiptext">Already three interviews are scheduled for this user on given
                    date</span></span>
              </div>
              <div v-if="tag.isSlotAvalable">{{ tag.email }}</div>
            </v-chip>
            <input class="ma-4 tag-input" :disabled="this.emailList.length >= 3 || userData.disableReschedule" placeholder="Email, comma seperated"
              @keydown.enter="addTag" v-model="tag" />
          </div>
        </div>
        <div width="100%" class="d-flex box-shadow sticky-bottom mt-4 white flex-row justify-end py-2">
          <v-btn width="125px" height="48px" rounded outlined class="btn outline-btn mr-4 pa-4"
            @click="closeDialogs">Cancel</v-btn>
          <v-btn width="125px" :disabled="this.emails.length == 0 || userData.teachingInterests.schools == undefined || userData.disableReschedule || !checkExperience()" height="48px"
            :loading="loading" rounded @click="saveInputs" class="btn primary-btn pa-4 mr-2">{{ this.userData.isResuduleFun ?
                        "RESCHEDULE" : "SCHEDULE" }}</v-btn>
        </div>
        <!---------------------- subject upload section ends here --------------------------->
      </v-card>
    </v-form>
    <!-- Success Dialog -->
    <v-dialog v-model="successDialog" max-width="366px" class="cdz" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center d-flex flex-column align-center">
            <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-4">Interview {{ this.userData.isResuduleFun ?
                        "Rescheduled" : "Scheduled" }} Successfully</p>
            <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false, $emit('closeDialog', false);">OK</v-btn>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//  import Notification from "../Notification.vue";
import CampaignController from "@/controllers/CampaignController";

export default {
  props: ["userData"],
  setup(props) {
    // setup() receives props as the first argument.
    console.log("props.userData", props.userData);
  },
  name: "ScheduleInterView.vue",
  components: {},
  data() {
    return {
      successDialog: false,
      emailList: [],
      years: 0,
      tag: "",
      loading: false,
      schoolName: "",
      slotsData: [],
      currentSlotId: "",
      currentTimeSlotIndex: "0",
      currentTimeSlots: [],
      oldSlotId: "",
      oldSlotIndex: "",
      emails: [],
      candidateDetails: {
        totalExperience: {
          years: 0,
          months: 0,
        },
        relevantExperience: {
          years: 0,
          months: 0,
        },
        noticePeriod: 0,
        currentCTC: 0.0,
        expectedCTC: 0.0,
      },
    };
  },
  watch: {
    tag(newValue) {
      if (newValue.indexOf(",") != -1) {
        var temp = newValue.split(",")[0];
        if (temp != "") {
          // var value ={
          //   email : temp,
          //   isSlotAvalable : true
          // }
          // this.emailList.push(value);
        }
        this.tag = "";
      }
      console.log("index value", newValue.indexOf(","));
      console.log("outside", this.tag);
    },
    // options: {
    //   handler() {
    //     console.log(this.options);
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     if(this.searchBool){
    //       this.searchData(this.searchBoards);
    //     }else{
    //       this.getBoards();
    //     }
    //   },
    //   deep: true,
    // },
    // searchBoards(newValue){
    //   console.log(newValue);
    //     this.searchBool=true
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     this.options.page=1;
    //     this.searchData(newValue);
    //     if(newValue=="" || newValue==null){
    //       this.getBoards();
    //       this.searchBool=false;
    //     }
    // }
  },
  methods: {
    checkExperience(){
      let relevantExperience  = Number(this.candidateDetails.relevantExperience.months) + Number(this.candidateDetails.relevantExperience.years)*12;
      let totalExperience = Number(this.candidateDetails.totalExperience.months) + Number(this.candidateDetails.totalExperience.years)*12;
      return   totalExperience >= relevantExperience;
    },
    closeDialogs() {
      this.$emit("closeDialog", false);
    },
    setCurrentTimeSlotIndex(index) {
      console.log("slot index)", index);
      this.currentTimeSlotIndex = index;
    },
    async addTag() {
      try {
        const responce =
          await CampaignController.checkAvalableSlotsForInterviewer(
            this.tag,
            this.currentSlotId,
            this.currentTimeSlotIndex.toString()
          );
        if (responce.data.flag) {
          if (this.tag && !this.emailList.some(item => item.email === this.tag)) {
            this.emailList.push({
              email: this.tag,
              isSlotAvalable: true,
            });
            this.emails.push(this.tag);

            this.tag = "";
          }
        } else {
          if (this.tag && !this.emailList.some(item => item.email === this.tag)) {
            this.emailList.push({
              email: this.tag,
              isSlotAvalable: false,
            });
            this.tag = "";
          }
        }
      } catch (error) {
        alert(error);
      }
    },

    findTimeSlots(index) {
      this.currentSlotId = this.slotsData[index].slotDate;
      this.currentTimeSlots = this.slotsData[index].subAllSlot;
      console.log("this.currentTimeSlots", this.currentTimeSlots);
      this.slotEnd;
    },
    async getAvalableSlots() {
      try {
        this.slotsData = this.userData.slotsData;
        this.currentTimeSlots = this.slotsData[0]?.subAllSlot;
        this.currentSlotId = this.slotsData[0]?.slotDate;
        this.currentTimeSlotIndex = this.slotsData[0]?.subAllSlot[0]?.index;
        console.log(
          "this.slotsData",
          this.currentSlotId,
          this.currentTimeSlotIndex
        );
      } catch (error) {
        alert(error);
      }
    },
    removeTag(index) {
      console.log("index", index);
      if (this.emailList[index]?.isSlotAvalable) {
        this.emailList.splice(index, 1);
        this.emails.splice(index, 1);
      } else {
        this.emailList.splice(index, 1);
      }
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async saveInputs() {
      if(!this.$refs.form.validate()){
        return
      }


      
      if (this.userData.isResuduleFun) {
        var data = {
          schoolName: this.schoolName,
          interviewerEmails: this.emails,
          slotId: this.currentSlotId,
          slotIndex: this.currentTimeSlotIndex.toString(),
          campaignId: this.userData.campaignId,
          userId: this.userData.userId,
          candidateDetails: this.candidateDetails,
          isReshedule: true,
          oldSlotId: this.oldSlotId.toString(),
          oldSlotIndex: this.oldSlotIndex.toString(),
        };
        try {
          const responce =
            await CampaignController.submitInterviewScheduleDetails(data);
          if (responce.data.flag) {
            console.log("interview schedule");
            this.successDialog = true;
          } else {
            alert(responce.data.message);
          }
        } catch (error) {
          alert(error);
        }
      } else {
        var data = {
          schoolName: this.schoolName,
          interviewerEmails: this.emails,
          slotId: this.currentSlotId,
          slotIndex: this.currentTimeSlotIndex.toString(),
          campaignId: this.userData.campaignId,
          userId: this.userData.userId,
          candidateDetails: this.candidateDetails,
          isReshedule: false,
        };
        try {
          const responce =
            await CampaignController.submitInterviewScheduleDetails(data);
          if (responce.data.flag) {
            console.log("interview schedule");
            this.successDialog = true;
          } else {
            alert(responce.data.message);
          }
        } catch (error) {
          alert(error);
        }
      }
    },

    async isReshedule() {
      if (this.userData.isResuduleFun) {
        (this.schoolName =
          this.userData.interviewDetails.interviewSlotDetails.schoolName),
          this.candidateDetails = this.userData.interviewDetails.candidateDetails,
          (this.emails =
            this.userData.interviewDetails.interviewSlotDetails.interviewerEmails),
          this.userData.interviewDetails.interviewSlotDetails.interviewerEmails.forEach(
            (email) => {
              this.emailList.push({
                email: email,
                isSlotAvalable: true,
              });
            }
          );
        (this.currentSlotId =
          this.userData.interviewDetails.interviewSlotDetails.slotDate),
          (this.currentTimeSlotIndex = parseInt(
            this.userData.interviewDetails.interviewSlotDetails.timeSlotDetails
              .index
          )),
          (this.oldSlotId =
            this.userData.interviewDetails.interviewSlotDetails.slotDate),
          (this.oldSlotIndex =
            this.userData.interviewDetails.interviewSlotDetails.timeSlotDetails.index);
      }
    },
    // async searchData(search){
    //   const response = await BoardController.searchBoard(
    //     this.pageSize,
    //     this.page,
    //     search,
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },
  },
  created() {
    this.getAvalableSlots();
    this.isReshedule();
  },
};
</script>
<style scoped></style>