<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Users</p> <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover v-model="hover">
            <img src="../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad pb-8 px-8">
      <v-row class="py-0 pl-2 mt-4 mb-4">
        <v-card elevation="0" class="background mb-2" height="132px" width="200px">
          <!-- <v-btn to="/users" width="195px" elevation="0" class="my-1 white rounded-xl text-subtitle-2 justify-start"><v-icon small class="pr-2"> mdi-bullhorn-outline</v-icon>
            ALL (5000)
          </v-btn> -->
          <v-btn to="/users" active-class="highEmphasis" depressed width="195px" height="40px" elevation="0" outlined
            class="my-1 white rounded-xl text-subtitle-2 justify-start my-1"> <img class="mr-3"
              src="../assets/All Nav SVG.svg" />ALL ({{
                this.allCount
              }})</v-btn>

          <v-btn to="/teachers" active-class="highEmphasis" width="195px" height="40px" elevation="0" depressed outlined
            class="my-1 white rounded-xl text-subtitle-2 justify-start blue-border"> <img class="mr-3"
              src="../assets/Section Icons.svg" /> VGOS
            ({{ this.count }})
          </v-btn>
          <v-btn to="/jobseekers" active-class="highEmphasis" width="195px" height="40px" elevation="0" depressed outlined
            class="my-1 white rounded-xl text-subtitle-2 justify-start"><img class="mr-3"
              src="../assets/Job_Seeker.svg" />
            Jobseekers ({{ this.jobSeekerCount }})
          </v-btn>
        </v-card>
        <div class="chart-row d-flex flex-row justify-end">
          <v-card v-if="roleCluster == 'All Clusters'" width="33.3%" elevation="0"
            class="graph justify-center align-center overflow-hidden">
            <div class="w-100 text-center data-not-ava">
              Data Not Available
            </div>
            <HorizontalBarChartVue class="z-index-2" @data-updated="handleClusterData" :graphData="horizontalBarData" />
          </v-card>

          <v-card width="33.3%" elevation="0" class="graph justify-center align-center overflow-hidden">
            <div class="w-100 text-center data-not-ava">
              Data Not Available
            </div>
            <CampTotalPieChart class="z-index-2" :graphData="completionStatusGraph" @data-updated="handleValue"
              message="completion Status" />
          </v-card>

          <v-card width="33.3%" elevation="0" class="graph justify-center align-center overflow-hidden">
            <div class="w-100 text-center data-not-ava">
              Data Not Available
            </div>
            <CampTotalPieChartLevel class="z-index-2" @data-updated="handleData" :graphData="levelChartData"
              message="levels" />
          </v-card>
        </div>
      </v-row>

      <v-row class="mar-row pl-0 mb-2 pr-0 w-100" justify="space-between">
        <v-col cols="2" class="pt-1 pb-0 pl-0">
          <div class="text-h6 pt-1">All Users - VGOS</div>
        </v-col>

        <v-col cols="10" class="px-0 pt-0 d-flex flex-row align-center justify-end">
          <v-card width="326px" class="elevation-0 transparent">
            <v-text-field hide-details solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
              class="search-bar2 border-button rounded-pill mx-2" dense clearable></v-text-field>
          </v-card>

          <v-btn depressed rounded outlined class="border-button mx-2"
            @click="filterDialog = true"><v-icon>mdi-tune</v-icon>Filter</v-btn>
          <!-- <v-btn depressed outlined rounded class="mx-3"><v-icon>mdi-archive</v-icon>VIEW ARCHIVE</v-btn> -->
          <v-btn class="border-button mx-2" outlined rounded @click="deleteDialog = true"
            :disabled="selected.length == 0"><v-icon>mdi-trash-can-outline</v-icon>Delete</v-btn><v-btn
            class="border-button mx-2" outlined rounded
            :disabled="selected.length == 0"><v-icon>mdi-email-sync-outline</v-icon>Resend Invite</v-btn>
          <v-btn depressed outlined rounded class="border-button mx-2"><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="accent ml-2" rounded depressed v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
                <div class="pl-1">Create</div>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="() => {
                formbtnBool = false;
                dialog = true;
              }
                ">
                <v-list-item-title>
                  <v-icon class="pr-1">mdi-email-plus-outline</v-icon>CREATE NEW
                  TEACHER
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-title>
                  <v-icon>mdi-email-plus-outline</v-icon> INVITE NEW ADMIN
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item>
                <v-list-item-title>
                  <v-icon>mdi-account-multiple-plus </v-icon>
                  <input width="100%" height="100%" type="file" @change="handleFileUpload" />
                  CREATE USERS IN BULK
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-container></v-container>
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>
              <p class="text-h5 pt-6 pb-0">Delete User</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="background black--text" large width="157px" rounded @click="() => {
                  deleteDialog = false;
                  formbtnBool = false;
                }
                  ">CANCEL</v-btn>
                <v-btn class="accent white--text" depressed large width="157px" rounded :loading="dLoading"
                  :disabled="selected.length == 0" @click="deleteUser(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog max-width="600px" max-height="80vh" v-model="uploadingUsersDialog" center>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6" md="6">
              <v-card-title class="pa-0 ma-0">
                Uploading... {{ bulkUserUploadResponse.length }} /
                {{ bulkUsersJsonData.length }}
              </v-card-title>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn class="float-right" icon @click="SnapshotOnCluster"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-data-table :headers="headersresponce" :items="bulkUserUploadResponse" :items-per-page="-1">
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex flex-row ml-2">
                {{ item.status }}

                <v-icon v-if="item.status.includes('Uploaded Successfully!')" color="green">mdi-check-all</v-icon>
                <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-data-table fixed-header height="40vh" :loading="isLoading" v-model="selected" :headers="headers" :items="users"
        show-select :single-select="singleSelect" item-key="userId" :options.sync="options" :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }" :server-items-length="count" class="usertable rounded-table">
        <template v-slot:[`item.fullname`]="{ item }">
          {{ item.personalInfo.firstName + " " + item.personalInfo.lastName }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <img width="36px" height="36" @click="updateData(item)" class="cursor" src="../assets/edit.svg" />
            <img width="36px" height="36" class="cursor" src="../assets/userdelete.svg" />
          </div>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <!-- <template v-slot:[`item.updated_at`]="{ item }">
          {{ getDate(item.created_at) }}
        </template> -->
      </v-data-table>


      <!-- Success Dialog -->
      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">
                New User {{ formbtnBool == false ? "Created" : "Updated" }}
              </p>
              <v-btn class="accent" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- delete success dialog -->
      <v-dialog v-model="deleteSuccessDialog" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Teacher Deleted</p>
              <v-btn class="accent" large width="157px" rounded @click="deleteSuccessDialog = false"
                v-on:click="reload">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- dialog for resend invite success-->
      <v-dialog v-model="resendInviteSuccessDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Invite Sent Successfully</p>
              <v-btn class="accent" large width="157px" rounded @click="resendInviteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!--diolog for delete academic and professional array-->
      <v-dialog v-model="deleteUserDetails" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="#db44371f" size="90"><v-icon size="65"
                  color="#DB4437">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-4 pb-0">
                Are Sure you want to delete this Info ?
              </p>
              <p class="text-disabled grey--text text-subtitle-1" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn class="black--text" color="#0000001a" large width="157px" rounded
                  @click="deleteUserDetails = false">CANCEL</v-btn>
                <v-btn class="primary" large width="157px" rounded @click="removeDataFromSteps()">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Filter Dialog -->
      <!-- <v-dialog v-model="filterDialog" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-body1 font-weight-bold black--text">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card
                height="450px"
                elevation="0"
                id="myScroll"
                class="pt-5 ,pb-5"
              >
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Question Type
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedQuestionType"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(questionType, index) in questionTypeList"
                    :key="index"
                    :value="questionType"
                    :class="{
                      'selectedQuestionType-chip':
                        selectedQuestionType.includes(questionType),
                    }"
                    elevated
                    @click="updateQuestionType"
                  >
                    {{ questionType }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(level, index) in levels"
                    :key="index"
                    :value="level"
                    :class="{
                      'selectedLevel-chip': selectedLevel.includes(level),
                    }"
                    elevated
                    @click="updateLevel"
                  >
                    {{ level.name }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Difficulty Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selecteddifficultyLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(difficultyLevel, index) in levels"
                    :key="index"
                    :value="difficultyLevel"
                    :class="{
                      'selecteddifficultyLevel-chip':
                        selecteddifficultyLevel.includes(difficultyLevel),
                    }"
                    elevated
                    @click="updatedifficultyLevel"
                  >
                    {{ difficultyLevel.name }}
                  </v-chip>
                </v-chip-group>
  
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    outlined
                    class="pa-4"
                    @click="filterDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn rounded class="primary pa-4">Apply</v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->


      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div @click="clearFilter" class="clear-filter-btn">
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>

          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <!-- <v-tab class="black--text justify-start" active-class="activeTab">Invited on</v-tab> -->

              <v-tab class="black--text justify-start" active-class="activeTab">Status</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Screening test status</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Mains test status</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Level</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Subject</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Cluster & School</v-tab>
            </div>


            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedStatusFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(value, index) in status" :key="index" elevated :value="value">
                      {{ value }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="screeningTestFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(s, index) in ScreeningTestStatus" :key="index" elevated :value="s">
                      {{ s }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="mainsTestFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(s, index) in ScreeningTestStatus" :key="index" elevated :value="s">
                      {{ s }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>


            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="levelTypeFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(data, index) in tableLevels" :key="index" elevated :value="data.name">
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="subjectTypeFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(data, index) in subjectsData" :key="index" elevated :value="data.name">
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- cluster and school -->
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-card-title class="ml-0 pl-0"> Clusters </v-card-title>
                  <v-chip-group v-model="clusterTypeFilter" active-class="secondary" column :multiple="true">
                    <!-- @click="getClustersSchool" -->
                    <v-chip v-for="(data, index) in clusters" :key="index" elevated :value="data.name">
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>

                  <v-card-title class="pb-4 ml-0 pl-0"> Schools </v-card-title>

                  <v-card width="326px" class="elevation-0 transparent">
                    <v-text-field solo label="Search here..." hide-details prepend-inner-icon="mdi-magnify"
                      v-model="searchSchool" class="search-bar2 border-button rounded-pill mx-2" dense
                      clearable></v-text-field>
                  </v-card>
                  <div class="d-flex flex-row justify-center">
                    <v-progress-circular class="mt-10" indeterminate
                      v-if="clustersSchool.length == 0 && clusterTypeFilter.length != 0" :size="76"></v-progress-circular>
                  </div>


                  <v-chip-group v-model="schoolTypeFilter" class="mt-4" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(data, index) in showClusterSchool" height="40px" class="w-100 rounded-lg"
                      :rounded="10" :key="index" elevated :value="data.name">
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div>
            <v-card-actions class="px-6 my-2">
              <v-spacer></v-spacer>
              <v-btn rounded outlined class="accent--text pa-4" @click="filterDialog = false">Cancel</v-btn>
              <v-btn rounded class="accent mx-4 pa-4" :loading="dLoading" @click="
                filterUser(
                  selectedStatusFilter,
                  screeningTestFilter,
                  mainsTestFilter,
                  roleTypeFilter,
                  levelTypeFilter,
                  subjectTypeFilter,
                  schoolTypeFilter
                )
                ">Apply</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>
<script>
import "../styles.css";
import ClusterController from "@/controllers/ClusterController";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AddressController from "@/controllers/AddressController";
import UsersController from "@/controllers/UsersController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";
import BoardController from "@/controllers/BoardController";
import SchoolController from "@/controllers/SchoolController";
import algoliasearch from "algoliasearch";
import AuthService from "../services/AuthService";
import { onSnapshot, doc } from "firebase/firestore";
import CampTotalPieChartLevel from "./CampTotalPieChartLevel.vue";

import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";
import Papa from "papaparse";
import CampTotalPieChart from "./CampTotalPieChart.vue";
import HorizontalBarChartVue from "./HorizontalBarChart";
export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  components: {
    CampTotalPieChart,
    HorizontalBarChartVue,
    CampTotalPieChartLevel,
  },
  name: "TeachersView",
  data() {
    return {
      dobRules: [
        (v) => !!v || "Date of Birth is required",
        // (v) =>
        //   /^\d{4}-\d{2}-\d{2}$/.test(v) ||
        //   "Date of Birth must be in YYYY-MM-DD format",
      ],
      rules: {
        required: (value) => !!value || "Field is required",
      },
      algoliaIndex: null,
      options: {},
      isLoading: true,
      pageSize: 10,
      clustersSchool: [],
      selectedStatusFilter: [],
      screeningTestFilter: [],
      mainsTestFilter: [],
      roleTypeFilter: [],
      levelTypeFilter: [],
      subjectTypeFilter: [],
      clusterTypeFilter: [],
      schoolTypeFilter: [],
      showClusterSchool: [],
      status: ["active", "Inactive", "Invited"],
      ScreeningTestStatus: ["Passed", "Failed", "Yet to Start"],
      role: ["Teacher", "Job Seekers"],
      searchSchool: "",
      page: 1,
      count: 0,
      e1: 1,
      dLoading: false,
      unsubscribe: [],
      clustersSnap: [],
      graphSchools: [],
      horizontalBarData: [],
      completionStatusGraph: [],
      levelChartData: [],
      snapCluter: [],
      schools: [],
      school: [],
      grades: [],
      roleCluster: "",
      clusters: [],
      cluster: "",
      editId: null,
      clusterSchool: "",
      formbtnBool: false,
      experience: "Experienced",
      levels: [],
      bulkUsersJsonData: [],
      bulkUserUploadResponse: [],
      levelId: -1,
      hover: "",
      subjects: [],
      subjectId: [],
      isCurrentlyWorking: false,
      errorMessage: "Failed",

      dialog: false,
      deleteDialog: false,
      singleSelect: false,
      selected: [],
      experienceYear: -1,
      deleteUserDetails: false,
      experienceMonth: -1,
      filterDialog: false,
      uploadingUsersDialog: false,
      rolePosition: "",
      employeeTypeId: -1,
      board: "",
      gradesData: [],
      gradeId: -1,
      startDate: -1,
      endDate: -1,
      extraCarricularActivities: "",
      isCreatingUser: false,
      successDialog: false,
      resendInviteSuccessDialog: false,
      deleteSuccessDialog: false,
      errorDialog: false,
      boardsData: "",
      tableLevels: [],
      subjectsData: [],
      schoolData: "",
      store: "",
      searchVal: "Teacher",
      search: "",
      allCount: 0,
      jobSeekerCount: 0,
      headersresponce: [
        { text: "User Index in CSV", value: "userIndex" },
        { text: "Status", value: "status" },
      ],

      headers: [
        { text: "Full Name", value: "fullname", cellClass: "w-10" },
        {
          text: "Email ID",
          value: "personalInfo.contactInfo.email",
          cellClass: "w-15",
        },
        {
          text: "Phone No",
          value: "personalInfo.contactInfo.phoneNumber",
          cellClass: "w-15",
        },
        { text: "Created On", value: "createdOn", cellClass: "w-15" },
        { text: "Status", value: "status", cellClass: "w-15" },
        { text: "Role", value: "userType", cellClass: "w-15" },
        { text: "Last active on", value: "updatedOn", cellClass: "w-15" },
        { text: "Actions", value: "actions", cellClass: "w-10" },
      ],
      personalInfo: {
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        isEmailVerified: false,
        isPhoneVerified: false,
        contactInfo: {
          email: "",
          phoneNumber: "",
        },
        addressInfo: {
          countryName: "India",
          stateName: "",
          districtName: "",
          tulakName: "",
          cityName: "",
          pincode: null,
          address: "",
        },
      },
      academicsInfo: [
        {
          institution: "",
          programme: "",
          startDate: "",
          endDate: "",
          fieldOfStudy: "",
          gradeScore: 0,
          gradeType: "",
          certificateUrl: "",
        },
      ],
      professionalInfo: [
        {
          experienceYear: 0,
          experienceMonth: 0,
          position: "",
          employeeTypeId: 0,
          board: 0,
          startDate: Date.now(),
          endDate: Date.now(),
          levels: [],
          grades: [],
          subjects: [],
          school: "",
          otherName: "",
        },
      ],
      expandedPanelIndex: 0, // set the last panel as expanded by default
      countries: [
        {
          name: "India",
        },
      ],
      states: [],
      districts: [],
      cities: [],
      talukas: [],
      users: [],
      employeeType: [
        {
          id: 1,
          name: "Permanent",
        },
        {
          id: 2,
          name: "Contract",
        },
        {
          id: 3,
          name: "Prohibition",
        },
      ],
      algoliaData: [],
      preloader: true,
    };
  },

  watch: {
    clusterTypeFilter(newValue) {
      console.log("cluster New Value", newValue)
      this.getClustersSchool(newValue);
    },
    options: {
      handler() {
        console.log(this.options);
        this.pageSize = this.options.itemsPerPage;
        this.page = this.options.page;
        this.fetchUsers();

      },
      deep: true,
    },
    searchSchool(newValue) {

      if (newValue === '') {
        this.showClusterSchool = this.clustersSchool;
      } else {
        const lowercaseNewValue = newValue.toLowerCase();
        this.showClusterSchool = this.clustersSchool.filter((obj) =>
          obj.name.toLowerCase().includes(lowercaseNewValue)
        );
      }

    },
    searchVal(newValue) {
      this.searchVal = newValue

      this.fetchUsers();
    },

    search(newValue) {
      var searchValue = "Teacher";
      (this.searchVal = ""), (this.pageSize = this.options.itemsPerPage);
      this.page = this.options.page;
      this.options.page = 1;
      this.searchVal = searchValue + " " + newValue;
      console.log(this.searchVal);
      if (this.search == "Un-assessed") {
        this.search = 'inprogress';
      }
      this.fetchUsers();
    },
    clustersSnap: {
      handler() { },
      deep: true,
    },
  },

  methods: {
    async getClustersSchool(clusterTypeFilter) {
      if (this.clusterTypeFilter.length > 0) {
        const response = await SchoolController.getClusterSchool(
          [this.clusterTypeFilter]
        );
        // console.log("school", response);
        if (response.status == 200) {
          var schools = response.data.schools;
          this.clustersSchool = schools;
          this.showClusterSchool = this.clustersSchool

          //   console.log("school", this.schools);
        } else {
          alert(response.data.error);
        }
      } else {
        this.schools = [];
      }
    },
    clearFilter() {
      this.selectedStatusFilter = []
      this.screeningTestFilter = []
      this.mainsTestFilter = []
      this.roleTypeFilter = []
      this.levelTypeFilter = []
      this.subjectTypeFilter = []
      this.schoolTypeFilter = [],
        this.filterVal = "",
        this.filterDialog = false;
      this.filterData = false;
      this.filterUser(
        this.selectedStatusFilter,
        this.screeningTestFilter,
        this.mainsTestFilter,
        this.roleTypeFilter,
        this.levelTypeFilter,
        this.subjectTypeFilter,
      );
    },
    handleData(data) {
      this.search = this.levelChartData[data][0];
    },

    async filterUser(
      selectedStatusFilter,
      screeningTestFilter,
      mainsTestFilter,
      roleTypeFilter,
      levelTypeFilter,
      subjectTypeFilter,

    ) {
      this.dLoading = true;
      var statusId = "";
      var screeningId = "";
      var mainsId = "";
      var roleId = "";
      var levelId = "";
      var subjectId = "";
      var schoolId = "";
      selectedStatusFilter.forEach((item) => {
        statusId = statusId + item + " ";
      });
      screeningTestFilter.forEach((item) => {
        screeningId = screeningId + item + " ";
      });
      mainsTestFilter.forEach((item) => {
        mainsId = mainsId + item + " ";
      });
      roleTypeFilter.forEach((item) => {
        roleId = roleId + item + " ";
      });
      levelTypeFilter.forEach((item) => {
        levelId = levelId + item + " ";
      });
      subjectTypeFilter.forEach((item) => {
        subjectId = subjectId + item + " ";
      });
      this.schoolTypeFilter.forEach((item) => {
        schoolId = schoolId + item + " ";
      });


      this.filterVal =
        statusId +
        screeningId +
        mainsId +
        roleId +
        levelId +
        subjectId +
        schoolId + "Teachers"
      console.log("filter value = ", this.filterVal)

      await this.algoliaIndex
        .search(this.filterVal, {
          hitsPerPage: this.pageSize,
          page: this.page - 1,
        })
        .then(({ hits }) => {
          console.log(hits);
          this.users = hits;
          this.dLoading = false;

        });
      const { nbHits } = await this.algoliaIndex.search(this.filterVal, {
        hitsPerPage: 0,
      });
      this.dLoading = false;
      this.count = nbHits;
      console.log("filter responce", this.filterVal);
      this.filterDialog = false;
      this.filterData = true;

    },
    handleClusterData(data) {
      this.search = data
    },
    handleValue(value) {
      this.search = value; // Update the childValue data property with the emitted value
    },
    levelChart() {
      var secondary_c = 0;
      var primary_c = 0;
      var middle_c = 0;
      var pre_primary_c = 0;
      var senior_secondary_c = 0;
      this.graphSchools.forEach((scl) => {
        primary_c += scl.primary_teacher;
        secondary_c += scl.secondary_teacher;
        middle_c += scl.middle_teacher;
        pre_primary_c += scl.pre_primary_teacher;
        primary_c += scl.primary_teacher;
        senior_secondary_c += scl.senior_secondary_teacher;
      });
      this.levelChartData = [["Primary", primary_c], ["Pre Primary", pre_primary_c], ["secondary", secondary_c], ["Middle", middle_c], ["Senior Secondry", senior_secondary_c]]
    },
    CompletionStatusData() {
      this.$store.state.assesedChart = null
      var a_teacher_c = 0;
      var u_teacher_c = 0;
      this.graphSchools.forEach((scl) => {
        a_teacher_c += scl.accessed_teacher;
        u_teacher_c += scl.unaccessed_teacher;
      });

      if ((a_teacher_c && u_teacher_c) == 0) {
        this.$store.state.assesedChart = null
      }
      else {
        this.$store.state.assesedChart = [["Assessed", a_teacher_c], ["Un-assessed", u_teacher_c]]

      }
      this.completionStatusGraph = [["Assessed", a_teacher_c], ["Un-assessed", u_teacher_c]]
    },

    dataOnCluster() {
      var horizontalBarData = [["not found", "", { role: "style" }]];
      this.$store.state.clusterChart[["not found", "500", { role: "style" }]]
      var a_jobseeker_c = 0;
      var u_jobseeker_c = 0;
      var a_teacher_c = 0;
      var u_teacher_c = 0;
      this.clustersSnap.forEach((clstr) => {
        a_jobseeker_c = 0;
        u_jobseeker_c = 0;
        a_teacher_c = 0;
        u_teacher_c = 0;
        this.graphSchools.forEach((scl) => {
          if (clstr == scl.cluster) {
            a_jobseeker_c += scl.accessed_jobseeker;
            u_jobseeker_c += scl.unaccessed_jobseeker;
            a_teacher_c += scl.accessed_teacher;
            u_teacher_c += scl.unaccessed_teacher;
          }
        });
        const tcount = a_jobseeker_c + u_jobseeker_c
        horizontalBarData.push([
          clstr,
          tcount,
          "color: #6A4F80",
        ]);
        this.$store.state.clusterChart.push([
          clstr,
          tcount,
          "color: #6A4F80",
        ])
      });


      this.$store.state.clusterChart = horizontalBarData
      this.horizontalBarData = horizontalBarData;
    },



    async SnapshotOnCluster() {
      this.graphSchools = [];
      this.clustersSnap = [];
      this.uploadingUsersDialog = false;
      var isSchool = false;
      const querySnapshot = await getDocs(collection(db, "clusters"));
      if (querySnapshot.empty) {
        alert("Cluster Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          this.clustersSnap.push(doc.data().name);
        });
      }
      if (this.roleCluster != "All Clusters" && this.roleSchool == "All") {
        this.clustersSnap = [];
        this.clustersSnap = [this.roleCluster];
      } else if (
        this.roleCluster != "All Clusters" &&
        this.roleSchool != "All"
      ) {
        this.clustersSnap = [];
        this.clustersSnap = [this.roleCluster];
        isSchool = true;
      } else {
        console.log("empty");
      }
      const clustersRef = new Array(this.clustersSnap.length);

      for (var i = 0; i < this.clustersSnap.length; i++) {
        if (isSchool) {
          clustersRef[i] = doc(
            collection(db, "dashboards", "ums-dashboard", this.clustersSnap[i]),
            this.roleSchool
          );
          onSnapshot(
            doc(
              db,
              "dashboards",
              "ums-dashboard",
              this.clustersSnap[i],
              this.roleSchool
            ),
            { includeMetadataChanges: true },
            (doc) => {
              // ...
              // console.log("single school data !!!!!!!!!", doc.data());
              this.graphSchools[0] = doc.data();
              this.dataOnCluster();
              this.CompletionStatusData();
              this.levelChart();
            }
          );
        } else {
          clustersRef[i] = collection(
            db,
            "dashboards",
            "ums-dashboard",
            this.clustersSnap[i]
          );
          const unsubscriberef = onSnapshot(clustersRef[i], (querySnapshot) => {
            // schools = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                this.graphSchools.push(change.doc.data());
              }
              if (change.type === "modified") {
                let data = change.doc.data();

                const index = this.graphSchools.findIndex(
                  (school) => school.id === data.id
                );
                this.graphSchools[index] = data;
              }
              if (change.type === "removed") {
                let remove = change.doc.data();
                const removeindex = this.graphSchools.findIndex(
                  (school) => school.id === remove.id
                );
                this.graphSchools.splice(removeindex, 1);
              }
            });

            this.dataOnCluster();
            this.CompletionStatusData();
            this.levelChart();
          });
          this.unsubscribe.push(unsubscriberef);
        }
      }
    },

    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();
      if (response.data.flag) {
        // console.log(response);
        this.clusters = response.data.clusters;
        // console.log("afkdsbxbfabsbdj,a", this.clusters);
      } else {
        alert(response.data.error);
      }
    },
    async getClusterSchool() {
      console.log("cluster = ", this.cluster);
      const response = await SchoolController.getClusterSchool([this.cluster]);
      console.log("school", response);
      if (response.data.flag) {
        var schools = response.data.schools;
        this.schools = schools;
        console.log("school", this.schools);
      } else {
        alert(response.data.error);
      }
    },
    // getClusterOrSchool(){
    //   var CData =this.$store.state.role.clusterName
    //   var SData =this.$store.state.role.schools

    //   this.clusterSchool = "";
    //   if( (CData == 'All Clusters')){
    //     this.clusterSchool = ""
    //   }
    // else if((CData != 'All Clusters') && (SData == 'All') ){
    //   this.clusterSchool = CData
    //   }
    //   else {
    //     this.clusterSchool = SData;
    //   }
    // },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;
      if (this.roleSchool == undefined) {
        AuthService.logout();
        this.$router.push("/login");
      }
      if (this.roleCluster != "All Clusters") {
        this.cluster = this.roleCluster;
        this.clusterSchool = this.roleCluster;
        this.getClusterSchool();
      }
      if (this.roleSchool != "All") {
        this.school = this.roleSchool;
        this.clusterSchool = this.roleSchool;
      }

      // console.log(
      //   "clusterRole=",
      //   this.roleCluster,
      //   "schoolRole",
      //   this.roleSchool
      // );
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async getTotalCount() {
      if (this.clusterSchool != "") {
        const { nbHits } = await this.algoliaIndex.search(this.searchVal, {
          hitsPerPage: 0,
        });

        const allHits = await this.algoliaIndex.search(this.clusterSchool, {
          hitsPerPage: 0,
        });
        const vgoscount = await this.algoliaIndex.search(
          "Teacher " + this.clusterSchool
        );

        const jobSeekerCount = await this.algoliaIndex.search(
          "Job Seeker " + this.clusterSchool
        );
        this.count = vgoscount.nbHits;
        this.allCount = allHits.nbHits;
        this.jobSeekerCount = jobSeekerCount.nbHits;
      } else {
        const { nbHits } = await this.algoliaIndex.search(
          this.searchVal + " " + this.clusterSchool,
          {
            hitsPerPage: 0,
          }
        );
        const allHits = await this.algoliaIndex.search(this.clusterSchool);
        const jobSeekerHits = await this.algoliaIndex.search(
          "Job Seeker" + " " + this.clusterSchool
        );
        this.count = nbHits;
        this.allCount = allHits.nbHits;
        this.jobSeekerCount = jobSeekerHits.nbHits;
      }
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.csvFileToJSON(file);
        this.uploadingUsersDialog = true;
      }
    },

    // csvFileToJSON(file) {
    //   try {

    //   } catch (e) {
    //     console.error(e);
    //   }
    //   this.fetchUsers()
    // }
    csvFileToJSON(file) {
      var contents = [];
      this.unsubscribe.forEach((obj) => {
        obj();
      });

      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results;
            this.parsed = true;

            contents = this.content.data;

            var jsonData = [];

            var blankcell = false;
            for (var i = 0; i < contents.length; i++) {
              // var cells = rows[i].split(",");
              var responibleTeaching = [];
              var rowData = {};
              var cells = contents[i];
              for (var j = 0; j < cells.length; j++) {
                if (i == 0) {
                  var headers = contents[i];
                } else {
                  if (headers[j].includes("title")) {
                    var tempMap = {};
                    for (var z = 0; z < 11; z++) {
                      // parent1 loop for personal info
                      //  personalInfo/addressInfo
                      if (headers[j].includes("country")) {
                        var tempAddrMap = {};
                        // loop for add addrs info
                        for (var l = 0; l < 7; l++) {
                          tempAddrMap[headers[j]] = cells[j];
                          j++;
                        }
                        j--;
                        tempMap["addressInfo"] = tempAddrMap;
                      } else if (headers[j].includes("phoneNumber")) {
                        // condition for constact info
                        //personalInfo/constactInfo
                        var tempContactMap = {};
                        // loop for add contact info
                        for (var m = 0; m < 2; m++) {
                          tempContactMap[headers[j]] = cells[j];
                          j++;
                        }
                        j--;
                        tempMap["contactInfo"] = tempContactMap;
                      } else {
                        tempMap[headers[j]] = cells[j];
                        j++;
                      }
                    }
                    j--;
                    rowData["personalInfo"] = tempMap;
                  } else if (headers[j].includes("grade")) {
                    tempMap = {};
                    for (var n = 0; n < 3; n++) {
                      tempMap[headers[j].substring(0, headers[j].length - 1)] =
                        cells[j];
                      j++;
                    }
                    j--;
                    responibleTeaching.push(tempMap);
                  } else {
                    rowData[headers[j]] = cells[j].trim();
                  }
                }
                if (cells.length - 1 == j) {
                  rowData["responsibleTeachingAt"] = responibleTeaching;
                  rowData["userType"] = "Teacher";
                }
              }
              if (i != 0) {
                jsonData.push(rowData);
              }
            }

            this.bulkUsersJsonData = jsonData;
            var index = 2;
            if (!blankcell) {
              this.bulkUsersJsonData.forEach(async (user_) => {
                try {
                  const response = await UsersController.createUser(user_);
                  if (response.status == 200) {
                    console.log(user_);
                    this.bulkUserUploadResponse.push({
                      userIndex: index,
                      status: "Uploaded Successfully!",
                    });
                  } else {
                    this.bulkUserUploadResponse.push({
                      userIndex: index,
                      status: "Uploaded Failed!",
                    });
                  }
                  index++;
                } catch (error) {
                  console.log(error);
                }
                console.log(this.bulkUserUploadResponse);
              });
            }
          }.bind(this),
        });
      } catch (e) {
        console.error(e);
      }
    },

    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    async updateProfessionalInfo(data, userId) {
      const response = await UsersController.updateProfessionalInfo(
        data,
        userId,
        data.id
      );
      console.log(response);
    },
    goToStep2() {
      if (this.formbtnBool) {
        if (this.$refs.step1.validate()) {
          this.e1 = 2;
        }
      } else {
        if (this.$refs.step1.validate()) {
          this.e1 = 2;
          this.expandedPanelIndex = 0;
        }
      }
    },
    updateData(item) {
      this.editId = item.userId;
      this.formbtnBool = true; // change update/create btn value
      (this.personalInfo.title = item.title),
        (this.personalInfo.firstName = item.personalInfo.firstName),
        (this.personalInfo.middleName = item.personalInfo.middleName),
        (this.personalInfo.lastName = item.personalInfo.lastName),
        (this.personalInfo.contactInfo.email =
          item.personalInfo.contactInfo.email),
        (this.personalInfo.dob = item.personalInfo.dob),
        (this.personalInfo.gender = item.personalInfo.gender),
        (this.personalInfo.contactInfo.phoneNumber =
          item.personalInfo.contactInfo.phoneNumber),
        (this.personalInfo.addressInfo.countryName =
          item.personalInfo.addressInfo.countryName),
        (this.personalInfo.addressInfo.stateName =
          item.personalInfo.addressInfo.stateName),
        (this.personalInfo.addressInfo.cityName =
          item.personalInfo.addressInfo.cityName),
        (this.personalInfo.addressInfo.districtName =
          item.personalInfo.addressInfo.districtName),
        (this.personalInfo.addressInfo.address =
          item.personalInfo.addressInfo.address),
        (this.personalInfo.addressInfo.pincode =
          item.personalInfo.addressInfo.pincode),
        (this.academicsInfo = item.academicsInfo);
      this.professionalInfo = item.professionalInfo;
      // this.professionalInfo.experienceYear = item.experienceYear,
      // this.professionalInfo.experienceMonth = item.experienceMonth,
      // this.professionalInfo.position = item.position,
      // this.professionalInfo.employeeTypeId = item.employeeTypeId,
      // this.professionalInfo.board = item.board,
      this.dialog = true;
      // this.getAcademics(this.editId),
      // this.getProfessionalInfo(this.editId)
    },
    // nextStep() {
    //   if (this.e1 == 3) {
    //     this.createUser();
    //   } else {
    //     this.e1 = this.e1 + 1;
    //   }
    // },
    async getSubjects() {
      const response = await SubjectController.getSubject();

      if (response.status == 200) {
        this.subjectsData = response.data.subjects;
      } else {
        alert("subject Not Found!!");
      }
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        this.tableLevels = response.data.levels;
      } else {
        alert("Levels Not Found!!");
      }

      // console.log("level data", this.levelData.rows);
    },
    async getGrades() {
      const array = this.tableLevels.filter((level) =>
        this.levels.includes(level.name)
      );
      console.log(array);

      this.gradesData = [];
      array.forEach((subArray) => {
        subArray.grades.forEach((grade) => {
          this.gradesData.push(grade);
        });
      });
    },
    goToStep3() {
      if (this.formbtnBool) {
        // this.updateAcademics();
        this.e1 = 3;
      } else {
        if (this.$refs.step2.validate()) {
          this.e1 = 3;
          this.expandedPanelIndex = 0;
        }
      }
    },
    async createUser() {
      console.log("create method", this.formbtnBool, "condition bool");
      if (this.formbtnBool) {
        this.dialog = false;
        this.formbtnBool = false;
      } else if (this.$refs.step3.validate()) {
        (this.personalInfo.contactInfo.phoneNumber =
          "+91" + this.personalInfo.contactInfo.phoneNumber),
          console.log("userif conditon data", {
            personalInfo: this.personalInfo,
            academicsInfo: this.academicsInfo,
            professionalInfo: this.professionalInfo,
          });
        this.isCreatingUser = true;

        const response = await UsersController.createUser({
          userType: "Teacher",
          status: "INACTIVE",
          cluster: this.cluster,
          schoolName: this.school,


          board: this.board,
          responsibleTeachingAt: [
            {
              level: this.levels,
              grade: this.grades,
              subject: this.subjects,
            },
          ],
          subjects: this.subjects,
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        });
        console.log("create res", response);
        if (response.status == 200) {
          this.isCreatingUser = false;
          this.successDialog = true;
          this.dialog = false;
          this.fetchUsers();
        } else {
          this.isCreatingUser = false;
          this.errorMessage = response.data.error;
          this.errorDialog = true;
        }
      }
    },
    async updateUser() {
      // for update personal info
      const response = await UsersController.updateUserInfo(
        {
          userType: "Teachers",
          status: "INACTIVE",
          cluster: this.cluster,
          schoolName: this.school,
          responsibleTeachingAt: [
            {
              level: this.levels,
              grade: this.grades,
              subject: this.subjects,
            },
          ],
          levels: this.levels,
          grades: this.grades,
          board: this.board,
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        },
        this.editId
      );

      if (response.status) {
        this.isCreatingUser = false;
        this.successDialog = true;
        this.dialog = false;
        this.fetchUsers();
      } else {
        this.isCreatingUser = false;
        this.errorMessage = response.data.error;
        this.errorDialog = true;
      }
    },
    async updateAcademics(qualification, usrid) {
      // console.log("data  id acadmic id", qualification, usrid);
      const response = await UsersController.updateAcademics(
        qualification,
        usrid,
        qualification.id
      );
      console.log(response);
    },
    async getAcademics(id) {
      const response = await UsersController.getAcademics(id);
      //console.log("res", response)
      this.academicsInfo = response.data.data;
      // console.log("academic data comming", this.academicsInfo)
    },

    async getProfessionalInfo(id) {
      const response = await UsersController.getprofessionalInfo(id);
      this.professionalInfo = response.data.data;
      // console.log("professional data comming", this.professionalInfo)
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    async deleteUser(items) {
      console.log("selected user", items);
      this.dLoading = true;
      var ids = [];
      items.forEach((doc) => {
        ids.push(doc.userId);
      });
      const response = await UsersController.deleteUser({
        ids: ids,
      });
      if (response.status) {
        this.selected = [];
        this.selected.length = 0;
        ids = [];
        this.dLoading = false;
        setTimeout(() => {
          // This code will execute after a 1 second delay
          this.initAlgoliaClient();
          this.fetchUsers();
          this.getTotalCount();
        }, 5000);
      } else {
        alert(response.data.error);
        this.dLoading = false;
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;

    },

    addAcademicQualification() {
      this.academicsInfo.push({
        institution: "",
        programme: "",
        startDate: "",
        endDate: "",
        fieldOfStudy: "",
        gradeScore: 0,
        gradeType: "",
        certificateUrl: "",
      });
      this.expandedPanelIndex = this.academicsInfo.length - 1;
    },
    addProfessionalInfo() {
      this.professionalInfo.push({
        experienceYear: 0,
        experienceMonth: 0,
        position: "",
        employeeTypeId: 0,
        board: 0,
        startDate: "",
        endDate: "",
        school: "",
      });
      this.expandedPanelIndex = this.professionalInfo.length - 1;
    },
    async fetchUsers() {
      console.log("dhvshcmhdvfj")
      await this.getUsersFromAlgolia();
      // this.count = this.users.length;
      this.isLoading = false;
      this.preloader = false;
      this.getTotalCount();
      // const response = await UsersController.getUsers();
      // //console.log("users", response);
      // if (response.data.flag) {
      //   this.users = response.data.users;
      //   this.count = response.data.count;
      //   //   this.users.forEach((item) => {
      //   //   item.fullName = item.first_name + " " + item.last_name;
      //   // });
      //   //console.log(response.data.data);
      // } else {
      //   alert(response.data.error);
      // }
    },
    async fetchCountries() {
      const response = await AddressController.getCountries();

      if (response.data.success) {
        this.countries = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
      //console.log(this.countries);
    },

    async fetchStates() {
      var states = [];
      const querySnapshot = await getDocs(collection(db, "states"));
      if (querySnapshot.empty) {
        alert("States Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          states.push(doc.data());
        });
      }
      this.states = states;
    },
    async fetchDistricts() {
      //console.log(this.personalInfo.state_id);
      const q = query(
        collection(db, "districts"),
        where("state", "==", this.personalInfo.addressInfo.stateName)
      );
      const querySnapshots = await getDocs(q);
      this.districts = [];
      querySnapshots.forEach((doc) => {
        this.districts.push(doc.data());
        // console.log(doc.data());
      });
    },
    // async fetchCitiesAndTalukas() {
    //   var cities = [];
    //   var talukas = [];
    //   console.log('city and taluka is ruuhigb ', "states/" + this.personalInfo.addressInfo.stateName + "/districts/" + this.personalInfo.addressInfo.districtName + "/cities");
    //   const querySnapshotcities = await getDocs(collection(db, "states/" + this.personalInfo.addressInfo.stateName + "/districts/" + this.personalInfo.addressInfo.districtName + "/cities"));
    //   const querySnapshottalukas = await getDocs(collection(db, "states/" + this.personalInfo.addressInfo.stateName + "/districts/" + this.personalInfo.addressInfo.districtName + "/talukas"));
    //   // const querySnapshotcities = await getDocs(collection(db, "states/Bihar/districts/Sitatmarhi/cities"));
    //   console.log('city and taluka is not cr5asehed ', querySnapshotcities);
    //   if (querySnapshotcities.empty) {
    //     alert('Cities not found Not Found!!')
    //     return;
    //   }
    //   else {
    //     querySnapshotcities.forEach((doc) => {
    //       cities.push(doc.data())
    //     });
    //   }
    //   if (querySnapshottalukas.empty) {
    //     alert('Talukas not found Not Found!!')
    //     return;
    //   }
    //   else {
    //     querySnapshottalukas.forEach((doc) => {
    //       talukas.push(doc.data())
    //     });
    //   }

    //   this.talukas = talukas;
    //   this.cities = cities;
    //   console.log("demo ref here", cities)
    // },

    async fetchCities() {
      const q = query(
        collection(db, "cities"),
        where("state", "==", this.personalInfo.addressInfo.stateName),
        where("district", "==", this.personalInfo.addressInfo.districtName)
      );
      const querySnapshots = await getDocs(q);
      this.cities = [];
      querySnapshots.forEach((doc) => {
        this.cities.push(doc.data());
        // console.log(doc.data());
      });
      // console.log("district",this.cities)

      //console.log(this.cities);
    },

    async fetchTalukas() {
      const response = await AddressController.getTalukas(
        this.personalInfo.addressInfo.districtName
      );

      if (response.data.success) {
        this.talukas = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
      //console.log(this.talukas);
    },
    // async fetchCities() {
    //   const response = await AddressController.getCities(
    //     this.personalInfo.addressInfo.stateName,
    //     this.personalInfo.addressInfo.tulakName,
    //   );
    //   if (response.data.success) {
    //     this.cities = response.data.data.rows;

    //   }
    //   else {
    //     alert(response.data.error)
    //   }
    //   //console.log(this.cities);
    // },
    removeDataFromSteps() {
      this.deleteUserDetails = true;
      if (this.e1 == 2) {
        this.academicsInfo.splice(this.indexValue, 1);
      } else if (this.e1 == 3) {
        this.professionalInfo.splice(this.indexValue, 1);
      }
      this.deleteUserDetails = false;
      this.indexValue = null;
    },
    openDeleteDiolog(index) {
      this.indexValue = index;
      this.deleteUserDetails = true;
    },
    async getBoards() {
      const response = await BoardController.getBoards();
      // console.log(response);

      if (response.status == 200) {
        this.boardsData = response.data.boards;
      } else {
        alert("Boards Not Found!!");
      }

      //  console.log("board log", this.boardsData);
    },
    async getSchool() {
      const response = await SchoolController.getSchool();
      // console.log(response);

      if (response.status == 200) {
        this.schoolData = response.data.levels;
      } else {
        alert("Schools Not Found!!");
      }
    },
    async getUsersFromAlgolia() {
      // console.log("getting users from algolia")
      if (this.clusterSchool != "") {
        await this.algoliaIndex
          .search(this.searchVal + " " + this.clusterSchool, {
            hitsPerPage: this.pageSize,
            page: this.page - 1,
          })
          .then(({ hits }) => {
            this.users = hits;
            this.preloader = false;
          });
      } else {
        await this.algoliaIndex
          .search(this.clusterSchool + " " + this.searchVal, {
            hitsPerPage: this.pageSize,
            page: this.page - 1,
          })
          .then(({ hits }) => {
            console.log("data collected", hits)
            this.users = hits;
            this.preloader = false;
          });
      }
    },
    initAlgoliaClient() {
      // Initialize Algolia client
      const client = algoliasearch(
        this.$ALGOLIA_APP_ID,
        "a814971ff9eaeb34a7a143e7226d2b31"
      );
      this.algoliaIndex = client.initIndex(this.$USERS_INDEX);
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    filteredLevel() {
      if (this.searchLevels) {
        const regex = new RegExp(this.searchLevels);
        return this.tableLevels.filter((tableLevels) =>
          regex.test(tableLevels.name)
        );
      } else {
        return this.tableLevels;
      }
    },

    filteredBoard() {
      if (this.searchBoards) {
        const regex = new RegExp(this.searchBoards);
        return this.boardsData.filter((boardData) =>
          regex.test(boardData.name)
        );
      } else {
        return this.tableLevels;
      }
    },
    filteredSubject() {
      if (this.searchSubject) {
        const regex = new RegExp(this.searchSubject);
        return this.subject.filter((subject) => regex.test(subject.name));
      } else {
        return this.subject;
      }
    },
  },
  created() {
    // this.fetchCountries();
    this.fetchStates();
    this.initAlgoliaClient();
    this.fetchUsers();
    this.getTotalCount();
    this.getLevels();
    this.getSubjects();
    this.getGrades();
    this.getBoards();
    this.getSchool();
    this.fetchAllClusters();
    this.SnapshotOnCluster();

    this.$store.state.breadcrumb = "Teachers";
  },
  mounted() {
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
    }
    if (!this.$store.state.role.teacherOnboarding.read) {
      this.$router.push("/notFound");
    }
    this.getClusterOrSchool();
  },
};
</script>
<style scoped>
.graph {
  border-radius: 8px !important;
  margin-left: 8px;
}

.blue-border {
  border-color: blue !important;
}

.mar-row {
  margin: 2px !important;
}
</style>







