<template>
   <!-- author: suraj -->
   <div class="background login-view-container">
     <!-- <v-dialog v-model="successDialog" max-width="366px" persistent>
     <v-card>
       <v-container fluid class="pa-8">
         <v-card-text class="text-center">
           <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
           <p class="text-h5 py-4">
           Your Password Is Created Successfully
           </p>
           <v-btn
             class="accent"
             large
             width="157px"
             rounded
             @click="invited"
             >OK</v-btn
           >
         </v-card-text>
       </v-container>
     </v-card>
   </v-dialog> -->
       
     <v-app-bar color="transparent" class="rounded-xl" elevation="0" absolute height="120">
       <v-list-item class="ml-8">
         <v-list-item-icon>
           <v-img src="../assets/logo.svg" height="48px" width="178px"></v-img>
         </v-list-item-icon>
       </v-list-item>
     </v-app-bar>
     <v-row align="center" justify="center" style="height: 100vh" dense>
       <v-col
         cols="12"
         lg="12"
         md="12"
         class="fill-height d-flex flex-column justify-center align-center"
       >
         <v-card  width="426" height="auto">
           <div class="text-center pa-8">
             <img
               src="../assets/icon.svg"
               class="pt-3"
               width="70px"
               height="70px"
             />
             <v-card-title class="justify-center">
               <h5 class="text-h5 font-weight-medium">Forgot Password</h5>
             </v-card-title>
 
             <v-form v-model="valid">
          
               <!-- :rules="emailRules" -->
               <v-text-field
                 label="Enter Email Id"
                 placeholder=" Enter Email Id"
                 outlined
                 v-model="email"
                 :rules="emailRules"
               required
                 class="rounded-xl"
               ></v-text-field>
 
               <v-btn
                 @click.prevent="resetPassword"
                 class="btn primary-btn text-subtitle-2 text-capitalize font-weight-medium mb-2 py-4"
                 rounded
                 large
                 width="100%"
                 :loading="loading"
               :disabled="!valid"
               
               >
               Send Reset Link
               </v-btn>
 
            
             </v-form>
 
           </div>
         </v-card>

       </v-col>
     </v-row>
   </div>
 </template>
   
 <script>
 import {  sendPasswordResetEmail } from "firebase/auth";

import {auth} from '../firebase'
 import AuthService from "@/services/AuthService";
 import "../styles.css";
 export default {
   components: {},
   name: "ForgetPassword",
   data() {
     return {
       cnfpasswd: false,
       forgetBool: false,
       successDialog: false,
       loading: false,
       roleId: "",
       ctList: false,
       cnfPasswordView: false,

       valid: false,
       email: "",
       password: "",
       cnfPassword: "",
       emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
        requiredRule: [v => !!v || 'This field is required'],
       show2: false,
    
       rules: {
         required: (value) => !!value || "Required.",
         min: (v) => v.length >= 8 || "Min 8 characters",
         emailMatch: () => `The email and password you entered don't match`,
       },
     };
   },
   
   methods: {

async resetPassword(){
  this.loading = true
   auth.languageCode = 'en';
   
   //check if users exists
   let response = await AuthService.checkAdminEmailExist(this.email)
      
      if(response){
        sendPasswordResetEmail(auth, this.email)
          .then(() => {
            console.log('email',this.email)
            // Password reset email sent!
            alert('A password reset email has been sent to '+this.email);
          this.loading = false

          this.$router.push('/login')
          })
          .catch((error) => {
          this.loading = false

            console.error('Error sending password reset email:', error);
            alert('There was an error sending the password reset email. Please try again later.');
          });
      }
      else{
        alert(this.email + ' does not exist');
        this.loading = false

        this.$router.push('/login')
      }
   
   

}


    
   },
   created() {

   },
 };
 </script>