<template>
    <div>
        <v-card height="64px" width="100%"
            class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
            style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
            <v-card-title class="pl-0 ml-0">
                <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
                    Masters
                </p>
                <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                <v-menu offset-y class="menuZindex">
                    <template v-slot:activator="{ on }">
                            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
                            Skills - Weightage and Thresholds
                            <v-icon class="breadcrumb-arrow cursor"
                                style="margin-top: 5px !important">mdi-chevron-down</v-icon>
                        </span>
                    </template>
                    <MastersDropdownComponent />
                </v-menu>
                <!-- <p class="breadcrumb-text" style="margin-top: 2px">Skills</p>
          <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
            </v-card-title>
            <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center"
                style="position: fixed; right: 0;">
                <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
                    <v-hover>
                        <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg">
                    </v-hover>
                </v-badge>

                <div>

                    <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

                </div>
                <div class="d-flex flex-column d-justify-evenly user-details">
                    <v-card-title class="ma-0 pa-0 elipsis">
                        {{ $store.state.userInfo.name }}
                    </v-card-title>
                    <v-card-subtitle class="ma-0 pa-0 elipsis">
                        {{ $store.state.userInfo.email }}
                    </v-card-subtitle>
                </div>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                            <v-icon class="">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-btn @click="logout">logout</v-btn>
                </v-menu>
            </div>
        </v-card>
        <div fluid class="px-8 pad">

            <v-row>
                <v-col>
                    <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-container></v-container>
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-6 pb-0">Delete Skill Weightage Thresholds</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>This action
                will
                permanently delete the item . This cannot be undone</p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid> <v-btn depressed class="btn outline-btn"
                  large width="157px" rounded @click="deleteDialog = false">CANCEL</v-btn> <v-btn
                  class="btn primary-btn" depressed large width="157px" rounded :loading="dLoading"
                  @click="deleteWeightage(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center create-update-popup d-flex flex-column align-center">
              <v-icon color="success">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4"> {{ editSkillsAndWeightage == false ? 'Updated' : 'Created' }} successfully</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
 

      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Weightage Thresholds Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
                    <v-dialog max-width="950px" class="cdz" v-model="dialog" center>
                        <v-form ref="form" lazy-validation>
                            <v-card>
                                <v-card-title v-if="editSkillsAndWeightage" class="gray-02 mb-8">Update Skill Weightage and
                                    Threshold</v-card-title>
                                <v-card-title v-else class="gray-02 mb-8">Create Skill Weightage and
                                    Threshold</v-card-title>
                                <v-card-text class="py-0 px-6">
                                    <v-row class="py-0">
                                        <v-col class="py-0" cols="6">
                                            <div class="text-body-1 pb-0 mb-0">Test Type</div>
                                            <v-radio-group :disabled="editSkillsAndWeightage" v-model="selectedTestType" row class="mt-0">
                                                <v-radio label="SCREENING" value="SCREENING" class="mx-4"></v-radio>
                                                <v-radio label="MAINS" value="MAINS" class="mx-4"></v-radio>
                                            </v-radio-group>
                                        </v-col>

                                        <v-col class="py-0" cols="6">
                                            <v-autocomplete :disabled="editSkillsAndWeightage" v-model="selectedLevel" clearable deletable-chips
                                                label="Select Level*" outlined class="rounded-xl" small-chips :rules="[(v)=> !!v || 'Level is required',]"
                                                :items="levels" item-text="name" item-value="name">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-text class="py-0 my-0 px-6">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row class="py-0">
                                                <v-col cols="2" class="py-0 mb-4">
                                                    <div class="mt-4 subtitle-1 font-weight-bold black--text">Skills</div>
                                                </v-col>
                                                <v-col cols="3" class="py-0">
                                                    <div class="mt-4 mx-4 subtitle-1 font-weight-bold black--text">Must Pass
                                                    </div>
                                                </v-col>
                                                <v-col cols="2" class="py-0">
                                                    <div class="mt-4 subtitle-1 font-weight-bold black--text">Weightage
                                                    </div>
                                                </v-col>
                                                <v-col cols="5" class="py-0">
                                                    <div class="mt-4 subtitle-1 font-weight-bold black--text">Threshold
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row v-for="(skill, index) in skillsList" :key="index" class="mb-3">
                                                <v-col cols="2" class="py-0">
                                                    <div class="mt-3 subtitle-1 black--text">{{ skill.skillName }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="3" class="py-0">
                                                    <v-radio-group v-model="skill.isMustPass" row class="ma-0 pa-0 mt-3"
                                                        hide-details :disabled="skill.skillName === 'Psychometry' && selectedTestType === 'SCREENING'">
                                                        <v-radio value="true" class="mx-4"></v-radio>
                                                        <div class="text-subtitle-2">YES</div>
                                                        <v-radio value="false" class="mx-4"></v-radio>
                                                        <div class="text-subtitle-2">NO</div>                                                        
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="2" class="py-0">
                                                    <v-text-field type="number" outlined v-model="skill.weightage"
                                                    :rules="[value => value >= 0 && value <= 100 || 'Value must be between 0 and 100']"
                                                        hide-details dense class="rounded-xl py-0" :disabled="skill.skillName === 'Psychometry' && selectedTestType === 'SCREENING'"
                                                        suffix="%"></v-text-field>
                                                </v-col>
                                                <v-col cols="5" class="py-0 px-3">
                                                    <SliderComponent :sliderDisabled="skill.isMustPass == 'false' || (skill.skillName === 'Psychometry' && selectedTestType === 'SCREENING')"
                                                        :skill="skill"
                                                        @thresholdSending="handleThresholdValue($event, index)" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                </v-card-text>

                                <v-card-actions class="px-6 pb-6">
                                    <v-card-text>
                                        <div class="text-body-1 pb-0 mb-2">Total of all weightage should be 100%</div>
                                        <div class="text-body-1 pb-0 mb-0">*All fields are mandatory</div>
                                    </v-card-text>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex flex-column">

                               
                                   <div class="d-flex flex-row">
                                    <v-btn width="102px" height="48px" rounded outlined class="btn outline-btn mr-8 pa-4"
                                        @click="dialog = false">Cancel</v-btn>
                                    <v-btn :disabled="calculateWeightage || validateLevels()" width="102px" height="48px" :loading="loading" rounded v-if="editSkillsAndWeightage" @click="updateSkillsAndWeightage" class="btn primary-btn pa-4">Update</v-btn>
                                    <v-btn :disabled="calculateWeightage || validateLevels()" width="102px" height="48px" :loading="loading" rounded @click="saveInputs"
                                       v-else class="btn primary-btn pa-4">Create</v-btn>
                                   </div>
                                <span v-if="weightageSum > 100" class="caption red--text text-center mt-1">Weightage sum should be 100</span>

                                </div>
                                </v-card-actions>
                                <!--  -->

                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-col>

            </v-row>
            <v-row class="d-flex justify-center my-0 py-0">
                <v-col cols="6">
                    <div class="text-h6">Skills - Weightage and Thresholds</div>
                </v-col>

                <v-col cols="6" class="pl-0 ml-0">
                    <v-row justify="end" class="mb-1 mt-0">

                        <v-btn @click="exportSkillWeightageToCSV" depressed outlined rounded class="btn outline-btn mx-3"><v-icon>mdi-import
                                mdi-rotate-90</v-icon></v-btn>

                        <v-btn v-if="$store.state.role.skills.admin" @click="(dialog = true), emptyForm()"
                            class="btn primary-btn mx-3" depressed rounded><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>

                    </v-row>
                </v-col>
            </v-row>

            <v-row class="mt-0 pb-0">
                <v-col>
                    <v-chip-group v-model="selection" active-class="active-chip blue--text"
                        class="mt-0" mandatory>
                        <v-chip class="mr-0 px-6 border-cut-right" outlined :value="`SCREENING`">SCREENING</v-chip>
                        <v-chip class="px-6 border-cut-left" outlined :value="`MAINS`">MAINS</v-chip>
                    </v-chip-group>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3" v-for="(item, index) in selectedArray" :key="index">
                    <v-card class="pa-4 rounded-xl">
                        <div class="d-flex justify-space-between mb-3">
                            <v-chip color="px-6 font-weight-bold text-subtitle-2">{{
                                item.level.levelName }}</v-chip>
                            <v-menu offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                    <button v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </button>
                                </template>
                                <!-- <v-list>
                                    <v-list-item @click="deleteWeightage(item.id)">
                                        <v-list-item-title>
                                            <v-icon>mdi-trash-can-outline</v-icon> Delete
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list> -->
                                <div class="d-flex flex-column">
                                    <v-btn class="d-flex justify-start" :loading="dLoading" text @click="selected = item.id;(dialog = true);editForm(item, selected)"><v-icon class="mr-2">mdi-pencil-outline</v-icon>Edit</v-btn>
                                    <v-btn class="d-flex justify-start" :loading="dLoading" text @click="selected = item.id;deleteDialog= true"> <v-icon class="mr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
                                </div>

                            </v-menu>
                        </div>
                        <v-card v-for="(skill, index) in item.skills" :key="index" color="#f7f7f4" class="rounded-xl mb-3">
                            <v-card-subtitle class="breadcrumb-text black--text mb-0 pb-2">{{ skill.skillName
                            }}</v-card-subtitle>
                            <v-card-text class="d-flex justify-space-between">
                                <div>
                                    <h5>Weightage</h5>
                                    <h3>{{ skill.weightage }}%</h3>
                                </div>
                                <div>
                                    <h5>Threshold</h5>
                                    <h3 v-if="skill.threshold > 9">{{ skill.threshold }}</h3>
                                    <h3 v-else>0{{ skill.threshold }}</h3>
                                </div>

                            </v-card-text>
                        </v-card>

                    </v-card>

                </v-col>

            </v-row>

            <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
                <v-card>
                    <v-container fluid class="pa-8">
                        <v-card-text class="text-center">
                            <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
                            <p class="text-h5 pt-2 font-weight-medium">Error</p>
                            <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
                            <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
                        </v-card-text>
                    </v-container>
                </v-card>
            </v-dialog>




        </div>

        <!-- Notification Dialog -->
        <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
            <Notification @close-dialog="notificationDialog = false" />
        </v-dialog>

    </div>
</template>
<script>
import SkillsWeightageThresholdsController from "../../controllers/SkillsWeightageThresholdsController"
import LevelController from "@/controllers/LevelController";
import SkillsController from "@/controllers/SkillsController";

import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import SliderComponent from "../../components/SliderComponent.vue"
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"

export default {
    name: "SkillsView",
    components: {
        Notification, SliderComponent, MastersDropdownComponent
    },
    data() {
        return {
            screening: [],
            mains: [],
            dLoading: false,
            selectedLevel: "",
            levels: [],
            skillsList: [],
            skillsSend: [],
            coreSkillThreshold: "",
            communicationSkillThreshold: "",
            pedagogyThreshold: "",
            digitalLiteracyThreshold: "",
            coreSkillMustPass: "NO",
            communicationSkillMustPass: "NO",
            pedagogyMustPass: "NO",
            digitalLiteracyMustPass: "NO",
            coreSkill: "",
            communicationSkill: "",
            pedagogy: "",
            digitalLiteracy: "",
            ticksLabels: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
            selectedTestType: "SCREENING",
            selection: "SCREENING",
            notificationDialog: false,
            appliedFilter: false,
            filterCount: 0,
            options: {},
            pageSize: 10,
            page: 1,
            count: 0,
            errorDialog: false,
            successDialog: false,
            deleteSuccessDialog: false,
            skillFilterLoading: false,            
            deleteDialog: false,
            dialog: false,
            dialogTitle: "Dialog Title",
            name: "",
            errorMessage: "",
            novicMin: null,
            novicMax: null,
            advBegMin: null,
            advBegMax: null,
            competentMin: null,
            competentMax: null,
            proficientMin: null,
            proficientMax: null,
            roficientMax: null,
            expertMin: null,
            expertMax: null,
            selectedSubjects: [],
            dLoading: false,
            filterData: false,
            boards: [],
            board: null,
            skillsData: null,
            singleSelect: false,
            selected: [],
            filterDialog: false,
            selectedSubjectFilter: [],
            subjects: [],
            search: "",
            loading: false,
            searchBool: false,
            dataTableLoading: true,
            weightageSum: 0,
            editSkillsAndWeightage: false,
            editSkillData: {},

            headers: [
                { text: "Skill Name", value: "name", cellClass: "w-20" },
                { text: "Subjects", value: "subjects", cellClass: "w-25" },
                { text: "Created  On", value: "createdOn", cellClass: "w-20" },
                { text: "Actions", value: "actions" },
            ],
            tableData: [],
            rules: {
                required: (value) => !!value || "Field is required",
            },
        };
    },
    watch: {
       
        selection(newValue) {
            this.$router.replace({ 
                query: { 
                    ...this.$route.query,
                    type: newValue || undefined 
                }
            }).catch(() => {});
        }
    },
    computed: {
        calculateWeightage(){
            try {
                var sum= 0;
            this.skillsList.forEach((obj)=>{
               if(obj.weightage !== ''){
                sum +=  parseFloat(obj.weightage)
               } 
               
            })    
            this.weightageSum = sum;
            if(sum !== 100){
                return true
            }
            else {
                return false;
            } 
            }
            catch(error){
                return false
            }
            
        },
        selectedArray() {
            return this.selection === "SCREENING" ? this.screening : this.mains;
        },
    },
    methods: {
        logout() {
            AuthService.logout();
            this.$router.push("/login");
        },
        validateLevels(){
           return this.selectedLevel == null || this.selectedLevel == undefined || this.selectedLevel=="";
        },
        downloadCSV(csvData, fileName) {
            const csv = Papa.unparse(csvData);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName);
            } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", fileName);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
},
        exportSkillWeightageToCSV(){
            let downloadData = []
           this.selectedArray.forEach((item)=>{
                    if(item?.skills.length > 0){
                        item.skills.forEach((skill)=>{
                        let row = {}
                        row['Test Type'] = item?.testType
                        row['Level'] = item?.level?.levelName;
                        row['Skill Name'] = skill.skillName;
                        row['Weightage'] = skill.weightage;
                        row['Must Pass'] = skill.isMustPass;
                        row['Threshold'] = skill.threshold
                        downloadData.push(row)
                        })                            
                    } else {
                        downloadData.push({level: item?.level?.levelName, testType: item?.testType })
                    }
                  
                })
            this.downloadCSV(downloadData, `${this.selection}-skill-weightage-threshold-master.csv`.toLowerCase())  

        },
        editForm(skill, weightageId) {
            this.editSkillsAndWeightage = true;

            this.selectedTestType = skill.testType;
            this.selectedLevel = skill.level.levelName;
            const skillsList = skill.skills.map((skill) => {
                skill.isMustPass = skill.isMustPass.toString();
                return skill;
            })
            this.skillsList = skillsList;
                    
            this.editSkillData = {
                weightageId: weightageId,
                skills: skillsList
            }
        },

        async updateSkillsAndWeightage() {

            let skillsToSend = [];

            this.editSkillsAndWeightage = false;
            this.editSkillData.skills.forEach((skill) => {
                let skillData = {
                    "skillName": skill.skillName,
                    "isMustPass": skill.isMustPass.toLowerCase() === "false" ? false : true,
                    "weightage": skill.weightage,
                    "threshold": skill.threshold
                };

                skillsToSend.push(skillData);
            });

            this.dialog = false;

            const data = {
                weightageId: this.editSkillData.weightageId,
                skills: skillsToSend           
            }

            const response = await SkillsWeightageThresholdsController.updateSkillsWeightageThresholds(data);
            if(response.status == 200){
                this.successDialog = true;
            }
            else if(response.status == 403){
                alert("Weightage Already Exist")
            }
            else{
                alert("Something Went Wrong")
            }

            this.dialog = false;
        },
        emptyForm(){
            this.editSkillsAndWeightage = false;
            this.selectedLevel = "";
            this.skillsList = [];
            this.skillsSend = [];
            this.getSkills();
        },

        async deleteWeightage(weightageId){
            this.dLoading = true
            const id = "ewbdfhkbkhdvkfbkjg";
           
            console.log(weightageId)

            let wId = [];
            wId.push(weightageId);

            const response = await SkillsWeightageThresholdsController.deleteWeightages(id,wId);
            this.dLoading = false
            this.deleteDialog = false
            if (response.status == 200) {
                if (response.data) {
                    this.deleteSuccessDialog = true
                    console.log("response of delete", response.data);

                } else {
                    alert(response.data.error)
                }
            }
            else {
                alert(response.data.error)
            }       
            
            this.getWeightages();

            this.selectedLevel = "";
           

        },

        async getWeightages() {
            const id = "ewbdfhkbkhdvkfbkjg";

            const response = await SkillsWeightageThresholdsController.getWeightages(id);
            if(response.status == 404){
                this.screening = []
                    this.mains =[]
                alert('data not found')

            }
            else if (response.status == 200) {
                if (response.data) {
                    console.log("response of weightages", response.data);
                    this.screening = response.data.data.screening;
                    this.mains = response.data.data.mains;

                    console.log("mains", this.mains);
                    console.log("screening", this.screening);

                } else {

                }
            }
            else {
                alert(response.data.error || 'something went wrong')
            }
        },


        async getLevels() {
            const response = await LevelController.getLevel();

            if (response.status == 200) {
                if (response.data.levels.length > 0) {
                    this.levels = response.data.levels;
                } else {
                    this.levels = [];
                }
            }
            else {
                alert(response.data.error)
            }
        },

        async getSkills() {
            const response = await SkillsController.getSkills();
            //console.log(response);

            if (response.status) {
                if (response.data.skills.length > 0) {
                    // this.skillsList = response.data.skills;
                    response.data.skills.forEach((skill) => {
                        let data = {
                            "skillName": skill.name,
                            "isMustPass": "false",
                            "weightage": 0,
                            "threshold": 6
                        }

                        this.skillsList.push(data);

                    })

                    // console.log('New Array:', this.skillsList);

                } else {
                    this.skillsList = [];
                }
                // console.log(this.skillsList)
            } else {
                alert(response.data.error);
            }

        },

        handleThresholdValue(data, index) {
            this.skillsList[index].threshold = data;
        },

        async saveInputs() {

        this.editSkillsAndWeightage = true;

          if(this.$refs.form.validate()){        
            this.skillsList.forEach((skill) => {
                let data = {
                    "skillName": skill.skillName,
                    "isMustPass": skill.isMustPass.toLowerCase() === "false" ? false : true,
                    "weightage": skill.weightage,
                    "threshold": skill.threshold
                };

                this.skillsSend.push(data);
            });

            console.log("skillssend", this.skillsSend)

            const level = this.levels.find(level => level.name === this.selectedLevel);

            if(this.selectedTestType === "SCREENING"){
                this.skillsSend.pop();
            }

            const data = {
                testType: this.selectedTestType,
                level: {
                    id: level.id,
                    levelName: level.name,
                },
                skills: this.skillsSend
            };

            const id = "ewbdfhkbkhdvkfbkjg";

            const response = await SkillsWeightageThresholdsController.createSkillsWeightageThresholds(data, id);
            if(response.status == 200){
                this.successDialog = true;
            }
            else if(response.status == 403){
                alert("Weightage Already Exist")
            }
            else{
                alert("Something Went Wrong")
            }

            console.log("response", response);

            this.getWeightages();

            this.dialog = false;
        }
        }
    },
    created() {
        const urlType = this.$route.query.type;
        if (urlType && ['SCREENING', 'MAINS'].includes(urlType)) {
            this.selection = urlType;
            this.selectedTestType = urlType;
        }
        this.getLevels();
        this.getSkills();
        this.getWeightages();

    },
};
</script>

<style>
.v-menu__content {
    background: white;
}

.create-update-popup .v-icon {
    font-size: 40px !important;
}
</style>
