<template>
  <div v-if="preloader">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div> 
  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text underline-on-hover cursor" @click="$router.push('/static-assessment')">Assessments</p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
        >mdi-chevron-right
        </v-icon>
        <p class="breadcrumb-text underline-on-hover cursor" @click="goToViewAssessment">Create Assessment</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
        <p class="breadcrumb-text">Edit Question</p>
      </v-card-title>

      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <div>
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32"/>
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad px-8 background">
      <div class="d-flex align-center mb-4">
        <h3 class="mr-auto">Question</h3>
        <v-btn class="btn outline-btn mr-4" @click="goToViewAssessment" outlined rounded>Cancel</v-btn>
        <v-btn class="btn primary-btn px-6" rounded elevation="0" :disabled="enableSave"  :loading="uploadingImage" @click="saveQuestionDetail()">Save</v-btn>
      </div>

      <v-chip-group v-model="currentSelectedLanguage" class="mb-4 language-chips">
        <v-chip
          v-for="(chip, index) in availableQuesLanguages"
          :key="index"
          :value="chip"
          :class="{'active-chip': currentSelectedLanguage === chip}"                  
          outlined
          rounded
          @click="selectChip(chip)"
        >
          {{ chip }}
        </v-chip>
      </v-chip-group>
      
      <div class="py-4 bg-white">
        <v-form ref="updateForm" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" cols="6">
                <v-text-field
                type="text"
                label="Questio ID"
                outlined disabled
                class="rounded-lg"
                v-model="questionDetails.questionId"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="6">
                <v-select
                label="Level*"
                outlined disabled
                class="rounded-lg"
                v-model="questionDetails.level"
                @change="onLevelChange"
                :items="levels"
                >
                </v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-select
                label="Grade*"
                outlined disabled
                class="rounded-lg"
                v-model="questionDetails.grade"
                :items="gradeNames"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="6">
                <v-select
                label="Skills*"
                outlined
                class="rounded-lg"
                v-model="questionDetails.skill"
                :items="skillNames"
                >
                </v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-select
                label="Subject*"
                outlined
                class="rounded-lg"
                v-model="getQuestionSubject"
                :items="['English', 'Mathematics', 'Science', 'Logical Reasoning']"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="6">
                <v-text-field
                type="text"
                label="Strand"
                outlined
                class="rounded-lg"
                v-model="getQuestionStrand"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-text-field
                  type="text"
                  label="Sub-Strand"
                  outlined
                  class="rounded-lg"
                  v-model="getQuestionSubStrand"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-text-field
                type="text"
                label="Topic*"
                outlined
                class="rounded-lg"
                v-model="getQuestionTopic"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-select
                label="Select or search Lo"
                outlined
                class="rounded-lg"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="6">
                <v-select
                  label="Question Type*"
                  outlined
                  class="rounded-lg"
                  v-model="questionDetails.questionType"
                  :items="questionTypeList"
                >
                </v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-text-field
                  type="text"
                  label="Language of Question*"
                  outlined
                  disabled
                  class="rounded-lg"
                  v-model="currentSelectedLanguage"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-row align="center" class="d-flex flex-row justify-space-between">
                <v-col class="pt-0 pb-0">
                  <div class="text-body-1">Question*</div>
                </v-col>    
              <v-col class="pa-0">  
              <div>
              <v-btn text class="float-right btn outline-btn mb-2" v-if="this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].questionAssetUrl === 'NA'">
                <v-icon>mdi-attachment</v-icon>
                <span class="ml-2">Attach Image</span>
                <input class="w-100" type="file" name="image" @change="onAttachmentChange(false)" accept="image/*, audio/*, video/*" />
              </v-btn>                    
              <span>
                <v-chip text class="float-right my-2 btn outline-btn" v-if="mainImage && this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].questionAssetUrl !== 'NA'"
                  @click:close="closeChip" @click="openImagePreviewDialog" close>View Attachment
                </v-chip>
              </span>
            </div>
          </v-col>    
        </v-row>
            </v-col>
            </v-row>
            <v-row>
              <v-col>         
                <ckeditor :config="editorConfig" @input="saveVmodelValueInQuestionsStatement" v-model="displayQuestionsStatement" class="m-ckeditor mb-4"  @ready="onEditorReady">
                </ckeditor>                
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div @click="addOption" v-if="questionOptions.length<4" class="d-inline-flex align-center add-options">
                  <div class="icon-wrapper">
                    <img src="../assets/plus.svg" />
                  </div>
                  <span class="ml-2 font-weight-medium">ADD OPTIONS</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0" v-if="questionDetails.questionType == 'SINGLE_CHOICE'">
              <v-col class="py-0">
                <v-radio-group v-model="questionDetails.correctAnswer">                  
                  <v-list-item class="options-row" v-for="(option, index) in questionOptions" :key="index">
                    <div class="questions-options">
                      <div :class="questionDetails.correctAnswer == option.optionKey ? 'lightGreen'
                            : 'grey lighten-4'" 
                            class="d-flex align-center rounded-xl px-2">
                        <v-list-item-avatar>
                          <v-radio :value="option.optionKey">Personality traits</v-radio>
                        </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                          <span class="d-flex align-start"><v-img contain @loadstart="resolveQuestionOptionUrl" :src="option.resolvedOptionUrl" alt="" max-width="95%" width="auto" height="150" class="img"></v-img>
                          <v-btn icon class="ml-1"><v-icon @click="removeImageOption(option)">mdi-close</v-icon></v-btn></span>
                        </v-list-item-subtitle>
                        <v-list-item-title class="align-center" v-else>
                          <div v-html="option.optionValue"></div> 
                        </v-list-item-title>
                      </v-list-item-content>
                      <div class="d-flex buttons-container">
                        <v-list-item-action>                        
                          <v-btn class="bg-transparent" elevation="0" @click="editOption(index)">
                            <v-icon large class="pa-2">mdi-format-textbox
                            </v-icon>
                          </v-btn> 
                          <v-btn text color="blue"  x-large @click="selectFile(index)" >
                            Upload Image
                            <input class="w-100 cursor d-none"   :key="'fileInput' + index"  :ref="el => fileInputRefs[index] = el"  type="file" name="image" accept="image/*" @change="onAttachmentChange(true, index)" />
                          </v-btn>          
                          <!-- <v-btn icon @click="removeOption(index)">
                            <v-icon>mdi-delete </v-icon>
                          </v-btn>                         -->
                        </v-list-item-action>
                      </div>
                    </div>
                  </div>                  
                    <div @click="removeOption(index)" class="close-icon-wrapper d-flex justify-center align-center">
                      <img src="../assets/close.svg" alt="Remove Option" />
                    </div>                
                  </v-list-item>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="mt-0" v-if="questionDetails.questionType == 'MULTIPLE_CHOICE'">
              <v-col class="py-0">
                <v-list-item class="options-row" v-for="(option, index) in questionOptions" :key="index">
                  <div class="questions-options">
                    <div :class="selectedAnswers.includes(
                        option.optionKey
                      )
                        ? 'lightGreen'
                        : 'grey lighten-4'
                        "
                        class="d-flex align-center rounded-xl px-2">
                      <v-list-item-avatar>                          
                        <v-checkbox v-model="selectedAnswers"
                          :value="option.optionKey"
                          @change="updateCorrectAnswer">
                        </v-checkbox>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                          <span class="d-flex align-start"><v-img contain @loadstart="resolveQuestionOptionUrl" :src="option.resolvedOptionUrl" alt=""  max-width="95%" width="auto" height="150" class="img" ></v-img>
                          <v-btn icon class="ml-1"><v-icon @click="removeImageOption(option)">mdi-close</v-icon></v-btn></span>
                        </v-list-item-subtitle>
                        <v-list-item-title class="align-center" v-else>
                          <div v-html="option.optionValue"></div> 
                        </v-list-item-title>
                      </v-list-item-content>                      
                      <div class="d-flex buttons-container">
                        <v-list-item-action>
                          <v-btn class="bg-transparent" elevation="0" @click="editOption(index)">
                            <v-icon large class="pa-2">mdi-format-textbox
                            </v-icon>
                          </v-btn> 
                          <v-btn text color="blue" x-large @click="selectFile(index)">
                            Upload Image
                            <input class="w-100 cursor d-none"   :key="'fileInput' + index"  :ref="el => fileInputRefs[index] = el"  type="file" name="image" accept="image/*" @change="onAttachmentChange(true, index)" />
                          </v-btn>          
                          <!-- <v-btn icon >
                            <v-icon>mdi-delete </v-icon>
                          </v-btn> -->
                        </v-list-item-action>  
                      </div>
                    </div>
                  </div>
                  <div @click="removeOption(index)" class="close-icon-wrapper d-flex justify-center align-center">
                    <img src="../assets/close.svg" alt="Remove Option" />
                  </div>
                </v-list-item>                
              </v-col>
            </v-row>
            
            <v-row class="mt-0" v-if="questionDetails.questionType == 'TRUE_FALSE'">
            <v-col class="py-0">
              <v-radio-group v-model="questionDetails.correctAnswer">
                <v-list-item class="options-row" v-for="(option, index) in questionOptions" :key="index">
                  <div class="questions-options">
                    <div :class="questionDetails.correctAnswer == option.optionKey ? 'lightGreen'
                        : 'grey lighten-4'
                        "                    
                        class="d-flex align-center rounded-xl px-2"
                      >
                        <v-list-item-avatar>
                          <v-radio :value="option.optionKey">Personality traits</v-radio>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                            <span class="d-flex align-start"><v-img contain @loadstart="resolveQuestionOptionUrl" :src="option.resolvedOptionUrl" alt=""  max-width="95%" width="auto" height="150" class="img" ></v-img>
                            <v-btn icon class="ml-1"><v-icon @click="removeImageOption(option)">mdi-close</v-icon></v-btn></span>
                          </v-list-item-subtitle>
                          <v-list-item-title class="align-center" v-else>
                            <div v-html="option.optionValue"></div> 
                          </v-list-item-title>
                        </v-list-item-content>                        
                        <div class="d-flex buttons-container">
                          <v-list-item-action>
                            <v-btn class="bg-transparent" elevation="0" @click="editOption(index)">
                              <v-icon large class="pa-2">mdi-format-textbox
                              </v-icon>
                            </v-btn> 
                            <v-btn text color="blue" x-large @click="selectFile(index)">
                              Upload Image
                              <input class="w-100 cursor d-none"   :key="'fileInput' + index"  :ref="el => fileInputRefs[index] = el"  type="file" name="image" accept="image/*" @change="onAttachmentChange(true, index)" />
                            </v-btn>          
                            <!-- <v-btn icon @click="removeOption(index)">
                              <v-icon>mdi-delete </v-icon>
                            </v-btn>                           -->
                          </v-list-item-action>
                        </div>
                      </div>
                    </div>
                    <div @click="removeOption(index)" class="close-icon-wrapper d-flex justify-center align-center">
                      <img src="../assets/close.svg" alt="Remove Option" />
                    </div>
                  </v-list-item>                
                </v-radio-group>
              </v-col>
            </v-row>

           
            <v-row>
              <v-col class="py-0" cols="2">
                <v-text-field
                  type="number" v-model="questionDetails.marksAllotted"
                  label="Correct Answer Score*"
                  outlined
                  class="rounded-lg"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col class="py-0">
                <label class="d-block mb-2">Answer Explanation*</label>
                <ckeditor :config="editorConfigForOption" v-model="getQuestionExplanation" class="m-ckeditor mb-8">
                </ckeditor>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col class="py-0" cols="6">
                <v-select
                label="Blooms Taxonomy"
                outlined
                class="rounded-lg"
                v-model="questionDetails.bloomsTaxonomy"
                :items="bloomsTaxonomyLevel"
                >
                </v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-select
                label="Choose Difficulty Level*"
                outlined
                class="rounded-lg"
                v-model="questionDetails.difficultyLevel"
                :items="difficultyLevels"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-select
                label="Choose Complexity Level"
                outlined
                class="rounded-lg"
                v-model="questionDetails.complexityLevel"
                :items="complexityLevels"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <label class="d-block mb-2">Hint</label>
                <textarea class="rounded-lg mb-4 pa-4" v-model="getQuestionHint"></textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="6">
                <v-select
                label="Proficiency Level"
                outlined
                v-model="questionDetails.proficiencyLevel"
                class="rounded-lg"
                :items="proficiencyLevelList"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </div>

          <!-- Edit Option Dialog -->
    <v-dialog max-width="887px" v-model="optionTextFieldDialog" center>
      <v-card>
        <v-card-title class="secondary mb-8">Edit Option</v-card-title>
        <v-card-text>
          <ckeditor :config="editorConfigForOption" v-model="editedCkeditorValue" class="m-ckeditor"  @ready="onEditorReady"></ckeditor>
          <p v-if="!isValid" class="error-message red--text">Please enter a valid value (maximum 200 characters).</p>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn depressed rounded class="btn outline-btn pa-4"
            @click="optionTextFieldDialog = false">Cancel</v-btn>
          <v-btn depressed rounded @click="saveEditedValue" class="btn primary-btn pa-4" :disabled="!isValid">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <template>
      <div v-if="imagePreview">
        <v-dialog v-model="imagePreview">
          <v-card>
            <div class="d-flex justify-center align-center flex-column">
              <v-img :src="questionAssetUrlForPreview"
                     :alt="`Image preview of ${questionAssetUrlForPreview}`" 
                     class="img pa-0 w-auto h-auto" />
            </div>
            <div class="d-flex justify-end pb-2">
              <v-btn class="btn outline-btn font-weight-medium mx-3 text-right" rounded
                @click="closeDialog">
                Cancel
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </template>



  </div>
</template>

<script>

    import AuthService from "../services/AuthService";
    import StaticAssessmentController from "@/controllers/StaticAssessmentController";
    import LevelController from "@/controllers/LevelController";
    import GradeController from "@/controllers/GradeController";
    import SkillsController from "@/controllers/SkillsController";
    import QuestionsController from "../controllers/QuestionsController";
    import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
    import { storage } from "../firebase";
    import { mapGetters } from "vuex";
    import { cloneDeep } from "lodash";

    const IMAGE_ALT_SRC = '../assets/caution.png'

    export default {
        name: "EditStaticAssessment",
        data() {
            return {
                fileInputRefs:[],
                preloader: true,
                selectedChip: null,
                chips: ['English', 'Hindi', 'Marathi'],
                assessmentId: "",
                questionId: "",
                questionDetails: {},
                gradesData: [],
                levels: [],
                allLevels: [],
                gradeNames: [],
                skillsData: [],
                skillNames:[],
                questionTypeList: [
                  "SINGLE_CHOICE",
                  "MULTIPLE_CHOICE",
                  "TRUE_FALSE",
                ],
                currentSelectedLanguage: null,
                availableQuesLanguages: [],
                complexityLevels: ["P1", "P2", "P3", "P4", "P5"],
                difficultyLevels: ["EASY", "MEDIUM", "HARD", "VERY_HARD"],
                bloomsTaxonomyLevel: [
                  "REMEMBER",
                  "UNDERSTAND",
                  "APPLY",
                  "ANALYZE",
                  "EVALUATE",
                  "CREATE",
                ],
                options: [
                  {
                    optionKey: "A",
                    optionValue: "Option 1",
                  },
                  {
                    optionKey: "B",
                    optionValue: "Option 2",
                  },
                  {
                    optionKey: "C",
                    optionValue: "Option 3",
                  },
                  {
                    optionKey: "D",
                    optionValue: "Option 4",
                  },
                ],
                editedOptionIndex: 0,
                optionTextFieldDialog: false,
                editedCkeditorValue: "",
                editorConfig: {
                  extraPlugins: "ckeditor_wiris",
                },
                editorConfigForOption: {
                  extraPlugins: "ckeditor_wiris",
                },
                proficiencyLevelList: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
                imagePreview: false,
                mainImage: true,
                selectedFile: null,
                tempOptions: new Map(),
                questionAssetUrl: "",
                uploadingForOption: false,
                uploadingMsg: "File Uploading...",
                questionAssetUrlForPreview: IMAGE_ALT_SRC,
                selectedAnswers: [],
                displayQuestionsStatement:"",
                uploadingImage: false,
            }
        },

        mounted() {
           setTimeout(()=>{
            if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                this.displayQuestionsStatement = this.questionDetails.QuestionDetails[languageKey]?.questionStatement || ''
                // return this.questionDetails.QuestionDetails[languageKey]?.questionStatement || '';
              }else{
                this.displayQuestionsStatement = '';
              }
             
          },1000)
        },
        watch: {
          imagePreview(newValue) {
            if (newValue) {
              this.resolveQuestionAssetUrl(); // Call the function when the dialog opens
            }
          },
          questionDetails: {
            handler(val) {
              if (this.questionDetails.questionType == 'MULTIPLE_CHOICE') {
                // Ensure correctAnswer is an array, split if it's a comma-separated string
                if (typeof val.correctAnswer === 'string') {
                  this.selectedAnswers = val.correctAnswer.split(',');
                } else {
                  this.selectedAnswers = Array.isArray(val.correctAnswer)
                    ? val.correctAnswer
                    : [val.correctAnswer];
                }
              }
            },
            immediate: true,
            deep: true
          }
        },
        methods: {
            logout() {
                AuthService.logout();
                this.$router.push("/login");
            },
            updateCorrectAnswer() {
              this.questionDetails.correctAnswer = [...this.selectedAnswers].toString();
            },
            async fetchAssessmentQuestionById(assessmentId) {
                this.isLoading = true;
                const response = await StaticAssessmentController.getStaticAssessmentsById(assessmentId, this.pageSize, this.page);
                if(response.status == 200) {
                    const questionsList = response.data.assessments?.[0]?.questions;
                    this.questionDetails = questionsList.find(question => question.questionId == this.questionId);
                    if(this.questionDetails && this.questionDetails.QuestionDetails) {
                      this.availableQuesLanguages = Object.keys(this.questionDetails.QuestionDetails).map(key => key.charAt(0).toUpperCase() + key.slice(1))
                      this.currentSelectedLanguage = this.availableQuesLanguages[0];
                    }
                    this.preloader = false;
                    this.isLoading = false;
                } else {
                    this.preloader = false;
                    this.isLoading = false;
                }
            },
            goToViewAssessment() {
              this.$store.dispatch('setQuestionForEdit', {});
              this.$router.push({ path: "/create-static-assessment", query: {e1:2 }});
            },
            async getLevels() {
              const response = await LevelController.getLevel();
              if (response.status == 200) {
                if (response.data.levels.length > 0) {
                  this.allLevels = response.data.levels;
                  this.levels = this.allLevels.map(level => level.name);
                } else {
                  this.levels = [];
                  this.allLevels = [];
                }
              }
            },
            async getGrades() {
              const response = await GradeController.getAllGrades();
              if (response.status == 200) {
                this.gradesData = response.data.grades;
                this.gradeNames = this.gradesData.map(grade => grade.name);
              } else {
                alert("Levels Not Found!!");
              }
            },
            async getSkills() {
              const response = await SkillsController.getSkills();
              if (response.status) {
                if (response.data.skills.length > 0) {
                  this.skillsData = response.data.skills;
                  // this.skillNames = this.skillsData.map(skill => skill.name);
                  this.skillNames = ["Core Skills", "Communication Skills"]
                } else {
                  this.skillsData = [];
                  this.skillNames = [];
                }
              } else {
                alert(response.data.error);
              }

            },
            onLevelChange () {
            },
            selectChip(chip) {
              if(chip) {
                this.saveVmodelValueInQuestionsStatement()
                this.currentSelectedLanguage = chip;
                if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                this.displayQuestionsStatement = this.questionDetails.QuestionDetails[languageKey]?.questionStatement || ''
                // return this.questionDetails.QuestionDetails[languageKey]?.questionStatement || '';
              }else{
                this.displayQuestionsStatement = '';
              }
              }
            },
            saveVmodelValueInQuestionsStatement(){
                const languageKey = this.currentSelectedLanguage.toLowerCase();
               this.questionDetails.QuestionDetails[languageKey].questionStatement =  this.displayQuestionsStatement
            },
            saveQuestionDetail() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.QuestionDetails[languageKey]) {
                  // Update the value of questionStatement dynamically
                  this.questionDetails.QuestionDetails[languageKey].questionStatement = this.displayQuestionsStatement;
                }
              }
              this.questionDetails.isEdited = true;
              this.$store.dispatch('setQuestionForEdit', this.questionDetails);
              // Update the specific question in staticAssessmentData.questions
              const questionIndex = this.staticAssessmentData.questions.findIndex(question => question.questionId === this.questionId);
              if (questionIndex !== -1) {
                  this.staticAssessmentData.questions[questionIndex] = this.questionDetails; // Update with latest questionDetails
              }
              // Update the specific question in staticAssessmentData.questionsToUpload
              const uploadQuestionIndex = this.staticAssessmentData.questionsToUpload.findIndex(question => question.questionId === this.questionId);
              if (uploadQuestionIndex !== -1) {
                  this.staticAssessmentData.questionsToUpload[uploadQuestionIndex] = this.questionDetails; // Update with latest questionDetails
              }
              //stashing staticAssessmentData and QuestionForEdit in vuex store
              this.$store.dispatch('setAssessmentData', this.staticAssessmentData);
              let pageQuery = {}
              if(this.$route.query.hasOwnProperty('tablePageSize')){
                pageQuery['tablePageSize'] = this.$route.query.tablePageSize
              }
              this.$router.push({ path: "/create-static-assessment", query: {e1:2, ...pageQuery}});
            },
            removeOption(index) {
              let updatedOptions = this.questionOptions.filter((_, i) => i !== index);
              updatedOptions = updatedOptions.map((option, i) => ({
                ...option,
                optionKey: String.fromCharCode('A'.charCodeAt(0) + i),
              }));
                this.questionOptions = updatedOptions; // Trigger setter
            },
            removeImageOption(option){
              option.optionValue = '';
            },
            addOption() {
              const keys = this.questionOptions.map(option => option.optionKey);
              const nextKey = String.fromCharCode(
                keys.length > 0 ? Math.max(...keys.map(key => key.charCodeAt(0))) + 1 : 'A'.charCodeAt(0)
              );
              const newOption = {
                optionValue: '',
                optionKey: nextKey,
                isSelected: false
              };
              const updatedOptions = [...this.questionOptions, newOption];
              this.questionOptions = updatedOptions;
              // console.log(this.options);
            },
            getAlphabet(num) {
              // ASCII value for 'A' is 65
              // The alphabet letter corresponding to num can be calculated by adding num to 65 and then getting the character using fromCharCode()
              return String.fromCharCode(65 + num);
            },
            editOption(index) {
              this.editedOptionIndex = index;
              this.optionTextFieldDialog = true;
              setTimeout(() => {
                this.editedCkeditorValue = this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()]?.questionOptions[this.editedOptionIndex].optionValue

                // This code will execute after a 1 second delay
                //this.options[this.editedOptionIndex] = this.options[index];
              }, 1000);
            },
            onEditorReady(editor) {
              editor.on("afterCommandExec", function(event) {
                var commandName = event.data.name;
                if(['ckeditor_wiris_openFormulaEditor', 'ckeditor_wiris_openFormulaEditorChemistry'].includes(commandName) ) {
                  setTimeout(() => {
                      const wiris_license_overlays = document.querySelectorAll('.wrs_tickContainer')
                      wiris_license_overlays.forEach(element => {
                        element.remove();
                      });
                    }, 2000)
                }
              });
            },
            saveEditedValue() {
              if (this.questionDetails && this.questionDetails.QuestionDetails && this.currentSelectedLanguage) {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                
                if (!this.questionDetails.QuestionDetails[languageKey].questionOptions) {
                  this.questionDetails.QuestionDetails[languageKey].questionOptions = [];
                }

                if (this.questionDetails.QuestionDetails[languageKey].questionOptions[this.editedOptionIndex]) {
                  this.questionDetails.QuestionDetails[languageKey].questionOptions[this.editedOptionIndex].optionValue = this.editedCkeditorValue;
                }

                this.optionTextFieldDialog = false;
              }
            },
            getTextWithoutHTMLTags(text) {
              return text.replace(/<[^>]*>?/gm, '');
            },
            onAttachmentChange(isOption, index = null) {
              const file = event.target.files[0];
              if (!file) return;

              this.selectedFile = file;
              this.uploadingMsg = "File Uploading...";
              this.uploadingForOption = isOption;
              this.editedOptionIndex = index;

              // Trigger the upload
              this.uploadAsset();
            },
            uploadAsset() {
              this.uploadingImage = true;
              if (this.uploadingForOption && this.editedOptionIndex !== null) {
                this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].questionOptions[this.editedOptionIndex].resolvedOptionUrl = new URL(`../assets/loading.gif`, import.meta.url).href;
              } else {
                this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].resolvedQuestionAssetUrl = new URL(`../assets/loading.gif`, import.meta.url).href;
              }
              if (this.selectedFile != null) {
                const storageRef = ref(
                  storage,
                  "/question_bank/" + this.selectedFile.name + "_" + new Date().getTime()
                );
                uploadBytes(storageRef, this.selectedFile).then((snapshot) => {
                  getDownloadURL(snapshot.ref).then((url) => {
                    if (this.uploadingForOption && this.editedOptionIndex !== null) {
                      // Upload for an option
                      this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].questionOptions[
                        this.editedOptionIndex
                      ].optionValue = url; // Store URL as optionValue
                      this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].questionOptions[
                        this.editedOptionIndex
                      ].resolvedOptionUrl = url; //overwrite resolved assetURL
                    } else {
                      // Upload for main question image
                      this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].questionAssetUrl = url;
                      this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].resolvedQuestionAssetUrl = url;
                      this.mainImage = true;
                    }
                    this.uploadingForOption = false;
                    this.uploadingMsg = "File uploaded successfully";
                    this.uploadingImage = false;
                  });
                });
              }
            },
            openImagePreviewDialog() {
              this.imagePreview = true
            },
            closeChip() {
              this.questionDetails.QuestionDetails[this.currentSelectedLanguage.toLowerCase()].questionAssetUrl = 'NA';
              this.mainImage = false;
            },
            resolveQuestionOptionUrl: async function (url) {
              const languageKey = this.currentSelectedLanguage.toLowerCase();
              const options = this.questionDetails.QuestionDetails[languageKey]?.questionOptions;
              
              const optionIndex = options?.findIndex(({ optionValue }) => optionValue === url);
              if (optionIndex !== -1 && url.startsWith('https://drive.google.com/')) {
                let fileId = null;
                this.questionDetails.QuestionDetails[languageKey].questionOptions[optionIndex].optionValue = new URL(`../assets/loading.gif`, import.meta.url).href
                if (url.indexOf('&id=') > -1) {
                  fileId = url.split('&id=').pop();
                } else {
                  const pattern = /\/file\/d\/([^\\/]+)\//;
                  fileId = url.match(pattern)[1];
                }

                const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId);
                if(response.status == 200) { 
                  this.questionDetails.QuestionDetails[languageKey].questionOptions[optionIndex].optionValue = window.URL.createObjectURL(new Blob([response.data]));
                } else {
                  this.questionDetails.QuestionDetails[languageKey].questionOptions[optionIndex].optionValue = new URL(`../assets/caution.png`, import.meta.url).href;
                }
              }
            },
            restorePrevValue(index) {
              const languageKey = this.currentSelectedLanguage.toLowerCase();
              const options = this.questionDetails.QuestionDetails[languageKey]?.questionOptions;

              if (this.tempOptions.has(index) && options) {
                options[index].optionValue = this.tempOptions.get(index);
              }
            },
            resolveQuestionAssetUrl: async function () {
              const languageKey = this.currentSelectedLanguage?.toLowerCase();
              const url = this.questionDetails?.QuestionDetails[languageKey]?.resolvedQuestionAssetUrl || IMAGE_ALT_SRC;

              if (this.questionAssetUrlForPreview === IMAGE_ALT_SRC) {
                if (url.startsWith('https://drive.google.com/')) {
                  let fileId = null;
                  if (url.indexOf('&id=') > -1) {
                    fileId = url.split('&id=').pop();
                  } else {
                    // Regular expression pattern to extract file ID
                    const pattern = /\/file\/d\/([^\\/]+)\//;
                    fileId = url.match(pattern)[1];
                  }

                  try {
                    const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId);
                    if (response.status === '500') {
                      this.questionAssetUrlForPreview = IMAGE_ALT_SRC;
                    } else {
                      this.questionAssetUrlForPreview = window.URL.createObjectURL(new Blob([response.data]));
                    }
                  } catch (err) {
                    this.questionAssetUrlForPreview = IMAGE_ALT_SRC;
                    alert("Error occurred while fetching the file: " + err);
                    console.error('Error occurred ', err);
                  }
                } else if (url !== this.questionAssetUrlForPreview) {
                  this.questionAssetUrlForPreview = url;
                }
              }
            },
            closeDialog() {
              this.imagePreview = false;
              this.questionAssetUrlForPreview = IMAGE_ALT_SRC; // Reset or set to a default image
            },
            openDialog() {
              this.imagePreview = true;
              this.resolveQuestionAssetUrl(); // Call the method when opening the dialog
            },
            selectFile(index) {
              this.editedOptionIndex = index
              this.uploadingForOption = true
              const fileInput = this.fileInputRefs[index];

              if (fileInput) {
                fileInput.click();
              } else {
                console.error(`Ref ${refName} not found`);
              }
            },

        },

        computed: {
          ...mapGetters({
            staticAssessmentData : 'getAssessmentData',
            questionForEdit: 'getQuestionForEdit'
          }),
          isValid() {
            return this.getTextWithoutHTMLTags(this.editedCkeditorValue).length <= 200;
          },
          getQuestionStrand: {
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetails.QuestionDetails[languageKey]?.strand || '';
              }
              return '';
            },
            set(newValue) {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.QuestionDetails[languageKey]) {
                  // Update the value of strand dynamically
                  this.questionDetails.QuestionDetails[languageKey].strand = newValue;
                }
              }
            }
          },
          getQuestionSubStrand: {
            // Getter: Retrieves the current value of subStrand
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetails.QuestionDetails[languageKey]?.subStrand || '';
              }
              return '';
            },
            // Setter: Updates the value of subStrand when the user types
            set(newValue) {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.QuestionDetails[languageKey]) {
                  // Update the value of subStrand dynamically
                  this.questionDetails.QuestionDetails[languageKey].subStrand = newValue;
                }
              }
            }
          },
          getQuestionTopic: {
            // Getter: Retrieves the current value of topic
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetails.QuestionDetails[languageKey]?.topic || '';
              }
              return '';
            },
            // Setter: Updates the value of topic when the user types
            set(newValue) {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.QuestionDetails[languageKey]) {
                  // Update the value of topic dynamically
                  this.questionDetails.QuestionDetails[languageKey].topic = newValue;
                }
              }
            }
          },
          getQuestionExplanation: {
            // Getter: Retrieves the current value of answerExplanation
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetails.QuestionDetails[languageKey]?.answerExplanation || '';
              }
              return '';
            },
            // Setter: Updates the value of answerExplanation when the user types
            set(newValue) {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.QuestionDetails[languageKey]) {
                  // Update the value of answerExplanation dynamically
                  this.questionDetails.QuestionDetails[languageKey].answerExplanation = newValue;
                }
              }
            }
          },
          getQuestionHint: {
            // Getter: Retrieves the current value of topic
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetails.QuestionDetails[languageKey]?.hint || '';
              }
              return '';
            },
            // Setter: Updates the value of topic when the user types
            set(newValue) {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.QuestionDetails[languageKey]) {
                  // Update the value of topic dynamically
                  this.questionDetails.QuestionDetails[languageKey].hint = newValue;
                }
              }
            }
          },
          questionOptions: {
            // Getter: Retrieves the current question options for the selected language
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetails.QuestionDetails[languageKey]?.questionOptions || [];
              }
              return [];
            },
            // Setter: Updates the question options when the user adds/removes or modifies options
            set(newOptions) {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.QuestionDetails[languageKey]) {
                  // Update the options dynamically
                  this.questionDetails.QuestionDetails[languageKey].questionOptions = newOptions;
                }
              }
            }
          },
          enableSave: {
          get() {
            const marksAllotted = this.questionDetails.marksAllotted ? Number(this.questionDetails.marksAllotted) || 0 : 0;
            const correctAnswer = this.questionDetails.correctAnswer;
            // Validate essential fields
            const validTopic = this.availableQuesLanguages.every(language => {
            const languageKey = language.toLowerCase();
            const topic = this.questionDetails.QuestionDetails[languageKey]?.topic || "";
            return topic && topic.trim().length > 0;
            });          

            const validQuestionStatement = this.availableQuesLanguages.every(language => {
            const languageKey = language.toLowerCase();
            const questionStatement = this.questionDetails.QuestionDetails[languageKey]?.questionStatement || "";
            return questionStatement && questionStatement.trim().length > 0;
            });
            const validQuestionsOptions = this.availableQuesLanguages.every(language => {
              const languageKey = language.toLowerCase();
              const questionOptions = this.questionDetails.QuestionDetails[languageKey]?.questionOptions || [];
              return Array.isArray(questionOptions) &&
              questionOptions.length >= 2 &&
              !questionOptions.some(option => option.optionValue === "");
            })           
            const isValidBase = marksAllotted > 0 && correctAnswer && validTopic && validQuestionStatement && validQuestionsOptions;
        
            return !(isValidBase);
          }
          }, 
          getQuestionSubject: {
            // Getter: Retrieves the current value of topic
            get() {
              if (this.currentSelectedLanguage) {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetails.subject[0] || '';
              }
              return '';
            },
            // Setter: Updates the value of topic when the user types
            set(newValue) {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                if (this.questionDetails.subject && this.questionDetails.subject.length > 0) {
                  // Update the value of topic dynamically
                  this.questionDetails.subject[0] = newValue;
                }
              }
            }
          },
        },

        created() {
          this.$store.state.breadcrumb = "Assessment";
          this.questionId = this.$route.query.questionId;
          if(Object.keys(this.questionForEdit).length > 0){
            this.questionDetails = cloneDeep(this.questionForEdit);
            if(this.questionDetails && this.questionDetails.QuestionDetails) {
              this.availableQuesLanguages = Object.keys(this.questionDetails.QuestionDetails).map(key => key.charAt(0).toUpperCase() + key.slice(1))
              this.currentSelectedLanguage = this.availableQuesLanguages[0];
            }
            this.preloader=false;
          }
          this.getLevels();
          this.getGrades();
          this.getSkills();
        },
    }

</script>

<style scoped>
  .m-ckeditor {
    height: 153px;
  }
  .add-options {
    color: #1B72E8;
    cursor: pointer;
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1B72E8;
    border-radius: 50px;
  }
  textarea {
    width: 100%;
    min-height: 73px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    resize: none;
  }

.language-chips {
  .v-chip {
    background: white !important;
  }
  .active-chip.v-chip--outlined {
    font-weight: 600;
    color: var(--blue-01) !important;
    border: 1px solid var(--blue-01);
    background: var(--gray-02) !important;
  }
}

.options-row {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 12px;
  gap: 12px; 
  max-width: 100%;
  overflow: hidden;
}

.questions-options {
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
}

.close-icon-wrapper {
  background: #EE4D374D;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0
}

.buttons-container {
  .v-list-item__action--stack {
    flex-direction: row;
    margin: 0;
  }
}

</style>