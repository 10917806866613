<template>
  <div>
    <v-card height="56px" width="100%"
      class="mx-4 pt-5 pr-4 background fixBar elevation-0 d-flex flex-row justify-space-between align-center">
      <v-card-title>
        <!-- Update Role <v-span><v-icon class="ml-2 ">mdi-chevron-down</v-icon></v-span> -->
        <span class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/roles')">Role</span>
        <v-icon class="breadcrumb-arrow-unselected pt-1">mdi-chevron-right</v-icon>
        <span class="breadcrumb-text">Update</span>
        <v-icon class="breadcrumb-arrow pt-1">mdi-chevron-down</v-icon>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center fixLog ">

        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img src="../assets/bell 1.svg">
          </v-hover>
        </v-badge>

        <div>

          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <v-container fluid class="px-8 pad background">

      <v-dialog v-model="successDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>

              <p class="text-h5 py-4">Role Updated</p>

              <v-btn class="btn primary-btn" large width="157px" rounded @click="
                successDialog = false;
              $router.push('/roles');
              ">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>


      <!-- <div class="d-flex w-100 flex-row justify-space-between">
         <div class="ma-0 pa-0">
           <span class="cursor" @click="$router.push('/roles')">Role</span>
           <v-icon>mdi-chevron-right</v-icon>
           <span class="cursor">Update</span>
           <v-icon>mdi-chevron-down</v-icon>
         </div>
         <div width="600px"></div>
       </div> -->

      <v-row justify="space-between" class="my-0 mt-4">
        <v-col cols="4" sm="4" md="4">
          <!-- <div  v-if="update" class="text-h5">update Role</div> -->
          <div class="text-h6">Update Role</div>
        </v-col>

        <v-col cols="8" sm="8" class="d-flex flex-row justify-end">
          <v-btn class="btn outline-btn" v-if="$store.state.role.rolesAndPrivilage.admin" @click="$router.push('/roles')"
            rounded>Cancel</v-btn>
          <v-btn v-if="$store.state.role.rolesAndPrivilage.admin" :loading="CLoading" class="btn primary-btn mx-2"
            @click="updateRole_" rounded :disabled="disableSubmit" >Update</v-btn>
        </v-col>
      </v-row>

      <div style="height:70vh">

        <v-card elevation="0" class="background">
          <v-card elevation="0" class="pa-4 rounded-lg">
            <v-text-field class="rounded-xl" v-model="roles.roleName" label="Enter Role Name" outlined
              hide-details></v-text-field>
          
          <div class="text-body-1 my-2">Description</div>
            <v-row>
              <v-col>
                <ckeditor :config="editorConfig" v-model="roles.roleDescription" class="m-ckeditor"
                 @ready="onEditorReady">
                </ckeditor>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-card elevation="0" class="pa-4 mt-4 rounded-lg">
            <v-row class="d-flex justify-center">
              <v-col col="6" sm="6" md="6">
                <v-select class="rounded-xl" hide-details label="Select Cluster*" v-model="roles.clusterName"
                  :items="clusterData" item-value="name" attach item-text="name" outlined
                  :rules="[(v) => !!v || 'Cluster is required']" required @change="getSchool()">
                </v-select>
              </v-col>
              <v-col col="6" sm="6" md="6">
                <v-autocomplete :disabled="roles.clusterName == 'All Clusters'" hide-details="" clearable deletable-chips
                  label="Select School*" outlined v-model="roles.schools" required
                  :rules="[(v) => !!v || 'School is required']" class="rounded-xl" small-chips :items="schools"
                  :multiple="roles.clusterName == 'All Clusters'" item-text="name" item-value="name" flat solo attach>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card> -->
        </v-card>

        <v-card  id="myScroll" elevation="0" class="background mt-2 createRoleHeight">
          <v-card elevation="0" class="pa-0 ma-0 pb-4 rounded-lg">
            <v-card elevation="0" height="40px" class="px-3" color=" white--text rounded-t-lg">
              <!-- header  -->
              <v-row justify="space-between" class="my-0 mt-4 gray-02">
                <v-col cols="8" sm="8" md="8" class="d-flex flex-row align-center">
                  <!-- <div class="text-h6">Module Name</div> -->
                  <v-card-subtitle class="ma-0 pa-0 black--text text-subtitle-2">
                    Module Name
                  </v-card-subtitle>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="d-flex flex-row justify-center align-center">
                  <!-- <div class="text-h6">actions</div> -->
                  <v-card-subtitle class="ma-0 pa-0 black--text text-subtitle-2">
                    Actions
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
            <v-row justify="space-between" class="ma-0 mx-4 mt-5 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 pt-1 d-flex justify-center">
                <v-card-text class="ma-0 pa-0"><b>User Management</b></v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0 pb-2">
                <v-checkbox hide-details class="mr-8 mt-0" @click="
                  roles.userManagement.read = roles.userManagement.admin;
                allUserManagement(roles.userManagement.admin);
                " v-model="roles.userManagement.admin" label="Admin"></v-checkbox>
                <v-checkbox @click="allUsersRead(roles.userManagement.read)" :disabled="roles.userManagement.admin"
                  hide-details class="mr-8 mt-0" v-model="roles.userManagement.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-divider :thickness="3"></v-divider>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0 pt-1">Teacher Onbording</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="
                  roles.teacherOnboarding.read = roles.teacherOnboarding.admin
                  " v-model="roles.teacherOnboarding.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.teacherOnboarding.admin" hide-details class="mr-8 mt-0"
                  v-model="roles.teacherOnboarding.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0 pt-1">Other Admin Onbording</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="
                  roles.otherAdminOnboarding.read =
                  roles.otherAdminOnboarding.admin
                  " v-model="roles.otherAdminOnboarding.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.otherAdminOnboarding.admin" hide-details class="mr-8 mt-0"
                  v-model="roles.otherAdminOnboarding.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0 pt-1">Roles & Privilage</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="
                  roles.rolesAndPrivilage.read = roles.rolesAndPrivilage.admin
                  " v-model="roles.rolesAndPrivilage.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.rolesAndPrivilage.admin" hide-details class="mr-8 mt-0"
                  v-model="roles.rolesAndPrivilage.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
          </v-card>

          <v-card class="my-4 py-4 rounded-lg" elevation="0">
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pb-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 pt-1">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0"><b>Masters</b></v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="
                  roles.masters.read = roles.masters.admin;
                allMasters(roles.masters.admin);
                " v-model="roles.masters.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.masters.admin" hide-details class="mr-8 mt-0"
                  @click="allMastersRead(roles.masters.read)" v-model="roles.masters.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0 pt-1">Cluster</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.cluster.read = roles.cluster.admin"
                  v-model="roles.cluster.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.cluster.admin" hide-details class="mr-8 mt-0" v-model="roles.cluster.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Brand</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.Brand.read = roles.Brand.admin"
                  v-model="roles.Brand.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.Brand.admin" hide-details class="mr-8 mt-0" v-model="roles.Brand.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">School</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.school.read = roles.school.admin"
                  v-model="roles.school.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.school.admin" hide-details class="mr-8 mt-0" v-model="roles.school.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Board</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.board.read = roles.board.admin"
                  v-model="roles.board.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.board.admin" hide-details class="mr-8 mt-0" v-model="roles.board.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Level</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.Level.read = roles.Level.admin"
                  v-model="roles.Level.admin" label="Admin"></v-checkbox>
                <v-checkbox hide-details :disabled="roles.Level.admin" class="mr-8 mt-0" v-model="roles.Level.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Grade</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.Grade.read = roles.Grade.admin"
                  v-model="roles.Grade.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.Grade.admin" hide-details class="mr-8 mt-0" v-model="roles.Grade.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Subject Category</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0"
                  @click="roles.subjectCategory.read = roles.subjectCategory.admin" v-model="roles.subjectCategory.admin"
                  label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.subjectCategory.admin" hide-details class="mr-8 mt-0"
                  v-model="roles.subjectCategory.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Subjects</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.subjects.read = roles.subjects.admin"
                  v-model="roles.subjects.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.subjects.admin" hide-details class="mr-8 mt-0" v-model="roles.subjects.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Skills</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="roles.skills.read = roles.skills.admin"
                  v-model="roles.skills.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.skills.admin" hide-details class="mr-8 mt-0" v-model="roles.skills.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Learning Objective</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
                <v-checkbox hide-details class="mr-8 mt-0" @click="
                  roles.learningObjective.read = roles.learningObjective.admin
                  " v-model="roles.learningObjective.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.learningObjective.admin" hide-details class="mr-8 mt-0"
                  v-model="roles.learningObjective.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
            
              <v-card-text class="ma-0 pa-0">League</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0"  @click="
                  roles.league.read = roles.league.admin"
                  v-model="roles.league.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.league.admin" hide-details class="mr-8 mt-0"
                v-model="roles.league.read" label="Read"></v-checkbox>
       
            </v-col>
          </v-row>
          </v-card>

          <v-card elevation="0" class="my-4 py-4 rounded-lg">
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0 pt-2">Campaign</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0 ">
                <v-checkbox hide-details class="mr-8 mt-0 " @click="roles.campaign.read = roles.campaign.admin"
                  v-model="roles.campaign.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.campaign.admin" hide-details class="mr-8 mt-0" v-model="roles.campaign.read"
                  label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0 pt-4">Assessment</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0">
                <v-checkbox hide-details class="mr-8  mt-0" @click="roles.assessment.read = roles.assessment.admin"
                  v-model="roles.assessment.admin" label="Admin"></v-checkbox>
                <v-checkbox :disabled="roles.assessment.admin" hide-details class="mr-8 mt-0"
                  v-model="roles.assessment.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
              <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
                <!-- <div class="text-h5">User Management</div> -->
                <v-card-text class="ma-0 pa-0">Question Bank</v-card-text>
              </v-col>
              <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0 py-0">
                <v-checkbox hide-details class="mr-8 mt-0 py-0"
                  @click="roles.questionBank.read = roles.questionBank.admin" v-model="roles.questionBank.admin"
                  label="Admin"></v-checkbox>
                <v-checkbox hide-details class="mr-8  mt-0 py-0" :disabled="roles.questionBank.admin"
                  v-model="roles.questionBank.read" label="Read"></v-checkbox>
                <!-- <v-checkbox></v-checkbox> -->
              </v-col>
            </v-row>

            <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Topics</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0 py-0">
              <v-checkbox hide-details class="mr-8 mt-0 py-0" @click="roles.topics.read = roles.topics.admin"
                v-model="roles.topics.admin" label="Admin"></v-checkbox>
              <v-checkbox hide-details class="mr-8  mt-0 py-0" :disabled="roles.topics.admin"
                v-model="roles.topics.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Interview</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0 py-0">
              <v-checkbox hide-details class="mr-8 mt-0 py-0" @click="roles.interview.read = roles.interview.admin"
                v-model="roles.interview.admin" label="Admin"></v-checkbox>
              <v-checkbox hide-details class="mr-8  mt-0 py-0" :disabled="roles.interview.admin"
                v-model="roles.interview.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          </v-card>
        </v-card>

      </div>

    </v-container>
  </div>
</template>
     
     
     
<script>
import ClusterController from "@/controllers/ClusterController";
import SchoolController from "@/controllers/SchoolController";
import RolesController from "@/controllers/RolesController";
import AuthService from "../services/AuthService";


export default {
  name: "UpdateRole",
  data() {
    return {
      clusterData: [
        {
          name: "All Clusters",
          id: "all",
        },
      ],
      schools: [
        { name: "All" }
      ],
      update: false,
      roleId: "",
      CLoading: false,
      successDialog: false,
      adminKeys: [
        "cluster",
        "Brand",
        "board",
        "school",
        "Level",
        "Grade",
        "subjectCategory",
        "subjects",
        "skills",
        "learningObjective",
        "league",
        "topics",
        "interview"
      ],
      userKeys: [
        "otherAdminOnboarding",
        "rolesAndPrivilage",
        "teacherOnboarding",
      ],
      roles: {
        roleName: "",
        dashboard: true,
        clusterName: "",
        users: [],
        schools: [{
          name: 'All'
        }],
        userManagement: {
          admin: false,
          read: false,
        },
        league: {
          admin: false,
          read: false,
        },

        teacherOnboarding: {
          admin: false,
          read: false,
        },
        otherAdminOnboarding: {
          admin: false,
          read: false,
        },
        rolesAndPrivilage: {
          admin: false,
          read: false,
        },
        masters: {
          admin: false,
          read: false,
        },
        cluster: {
          admin: false,
          read: false,
        },
        Brand: {
          admin: false,
          read: false,
        },
        board: {
          admin: false,
          read: false,
        },
        school: {
          admin: false,
          read: false,
        },
        Level: {
          admin: false,
          read: false,
        },
        Grade: {
          admin: false,
          read: false,
        },
        subjectCategory: {
          admin: false,
          read: false,
        },
        subjects: {
          admin: false,
          read: false,
        },
        skills: {
          admin: false,
          read: false,
        },
        learningObjective: {
          admin: false,
          read: false,
        },
        campaign: {
          admin: false,
          read: false,
        },
        assessment: {
          admin: false,
          read: false,
        },
        questionBank: {
          admin: false,
          read: false,
        },
        topics: {
          admin: false,
          read: false,
        },
        interview: {
          admin: false,
          read: false,
        }
        
      },
      editorConfig: {
        extraPlugins: "ckeditor_wiris",
      },
      editorConfigForOption: {
        extraPlugins: "ckeditor_wiris",
      },
    };
  },

  methods: {
    onEditorReady(editor) {
      editor.on("afterCommandExec", function(event) {
        var commandName = event.data.name;
        if(['ckeditor_wiris_openFormulaEditor', 'ckeditor_wiris_openFormulaEditorChemistry'].includes(commandName) ) {
          setTimeout(() => {
              const wiris_license_overlays = document.querySelectorAll('.wrs_tickContainer')
              wiris_license_overlays.forEach(element => {
                element.remove();
              });
            }, 2000)
        }
      })
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    allUserManagement(value) {

      var keys = this.userKeys;
      console.log("keys", keys)
      keys.forEach(key => {
        this.roles[key].admin = value;
        this.roles[key].read = value;
      }
      );

    },
    allUsersRead(value) {
      var keys = this.userKeys;
      console.log("keys", keys)
      keys.forEach(key => {

        this.roles[key].read = value;
      }
      );
    },
    allMasters(value) {
      var keys = this.adminKeys;
      console.log("keys", keys)
      keys.forEach(key => {
        this.roles[key].admin = value;
        this.roles[key].read = value;
      }
      );
    },
    allMastersRead(value) {
      var keys = this.adminKeys;
      console.log("keys", keys)
      keys.forEach(key => {

        this.roles[key].read = value;
      }
      );
    },

    moveToRole() {
      this.$router.push("/roles");
    },


    async updateRole_() {
      this.CLoading = true;
      console.log("update Role function")
      console.log(this.roles)
      // this.$store.state.role = this.roles
      //  console.log("create role store", this.$store.state.role);
      const response = await RolesController.updateRole(this.roles, this.roles.id);
      if (response.data.flag) {
        this.CLoading = false;
        this.successDialog = true;
        this.$store.state.updateRole = null
      } else {
        alert("Failed");
      }
      console.log("Update role", response);
    },


    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();
      console.log("clusters response", response);
      if (response.data.flag) {
        console.log(response);

        var data = response.data.clusters;
        this.clusterData = [...this.clusterData, ...data];
      } else {
        alert(response.data.error);
      }
    },
    async getSchool() {
      if (this.roles.clusterName != 'All Clusters') {
        var temp = [this.roles.clusterName]
        this.schools = [];
        const response = await SchoolController.getClusterSchool(temp);
        console.log("school", response);
        if (response.data.flag) {
          var schools = response.data.schools;
          this.schools = ['All', ...schools];

        } else {
          alert(response.data.error);
        }
      }
      else {
        this.roles.schools = "All"
      }
    },
  },
  async mounted() {
    console.log("mounted")
    // add event listener for beforeunload event
    if (!this.$store.state.role.rolesAndPrivilage.read) {
      this.$router.push("/notFound");
    }
    this.fetchAllClusters();
    if (this.$store.state.updateRole != null) {
      console.log("in if block")
      this.roles = this.$store.state.updateRole;
      let roleDescription = this.roles.roleDescription
      setTimeout(()=>{
        this.roles.roleDescription = roleDescription
      }, 1000)
    
      console.log("cluster name",this.roles)  
      var temp = [this.roles.clusterName]
      const response = await SchoolController.getClusterSchool(temp);
      var data = response.data.schools;
      console.log("cluster school",data)
      this.schools = [...this.schools, ...data];
      
      if(this.$store.state.updateRole.league == undefined){
        this.$store.state.updateRole.league  = {};
        this.$store.state.updateRole.league.admin = false;this.$store.state.updateRole.league.read = false;
      } 
     
    }
    else {
      console.log("in else block")
      this.$router.push("/roles")
    }
  },

  watch: {

  },
  created() {
    console.log("created")

  },
  computed:{
    disableSubmit(){
        return  !Object.values(this.roles).some((role)=> {
          return role.admin || role.read
         })
  }},
};
</script>