<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Assessments</p> <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center " style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg">
          </v-hover>
        </v-badge>

        <div>

          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad px-8 background">
      <v-row>
        <v-col class="d-flex align-center">


          <div class="mt-4" v-if="showUsers">
            <span class="m-4 cursor" @click="showUsers = false">Assessments</span>
            <v-icon>mdi-chevron-right</v-icon>
            <span class="text--secondary">Assessment Name</span>
            <v-icon>mdi-chevron-right</v-icon>
            <select @change="() => {
              screeningMainsUser == 'false'
                ? fetchMainsUsers(selectedId)
                : fetchScreeningUsers(selectedId);
            }
              " v-model="screeningMainsUser">
              <option value="true" selected>
                Users in Screening Assessments
              </option>
              <option value="false">Users in Mains Assessments</option>
            </select>
          </div>
        </v-col>
        <v-col cols="4">
        </v-col>
      </v-row>

      <!-- assessment user table row with filter btn and color code -->
      <v-row class="my-8 justify-space-between" v-if="showUsers">
        <v-col cols="6" sm="6" md="" class="ma-0 pa-0 f-flex align-center">
          <div class="m-tab">
            <v-tabs background-color="#0000000D" class="ml-2 d-flex space-evenly" height="38" hide-slider>
              <v-tab active-class=" white ma-1 black--text" class="rounded" v-on:click="assessmentUsers = inProgress">
                In Progress
              </v-tab>
              <v-tab active-class=" white ma-1 black--text" class="rounded" v-on:click="assessmentUsers = cleared">
                Cleared
              </v-tab>

              <v-tab active-class="white ma-1 black--text" class="rounded" v-on:click="assessmentUsers = yetToAttempt">
                Not Cleared
              </v-tab>
              <v-tab active-class="ma-1 white black--text" class="rounded" v-on:click="assessmentUsers = yetToAttempt">
                Yet To Attempt
              </v-tab>
            </v-tabs>
          </div>
        </v-col>
        <!-- filter btn and color code -->
        <v-col cols="6" sm="6" md="6" class="d-flex justify-end align-center ma-0 pa-0">
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>

          <v-btn class="primary ml-2" rounded>
            <v-icon>mdi-tune</v-icon>Filter</v-btn>
        </v-col>
      </v-row>

      <!-- Assessment row and fiter and export btn -->

      <v-row class="justify-space-between mb-1" v-if="!showUsers">
        <v-col cols="3">
          <div class="text-h6">Assessments</div>
        </v-col>
        <v-col cols="9" class="mt-0 mb-6">
          <v-row class="justify-end">
            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" hide-details
              class="search-bar border-button rounded-pill mx-2" dense clearable></v-text-field>

              
            <v-btn text depressed rounded outlined class="border-button mx-2 pl-4 pr-6"><v-icon class="pr-2">mdi-tune</v-icon><span
                v-if="filterCount > 0" @click="filterDialog = true">Filter ({{ filterCount }})</span>
              <span v-else @click="filterDialog = true">Filter</span></v-btn>



            <v-btn text depressed rounded outlined class="border-button mx-2 pl-4 pr-6"
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>


            <v-btn depressed outlined rounded class="border-button mx-2 "><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn>
            <!-- <v-btn class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn v-if="!showUsers && $store.state.role.assessment.admin" @click="() => { dialog = true; e1 = 1; }"
              class="accent ml-2 pl-4 pr-6" rounded elevation="0"><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>
          </v-row>
        </v-col>
      </v-row>

      <!-- Live assessment and color code row  -->
      <v-row class="justify-space-between mb-4 mt-0" v-if="!showUsers">
        <v-col cols="4" sm="4" md="4">
          <div class="text-h5 ml-0">
            Live Assessments ({{ assessments.length }})
          </div>
        </v-col>

        <v-col cols="8" sm="8" class="d-flex justify-end align-center">
          <v-icon color="#06C270CC" class="mr-2 ml-4">mdi-circle</v-icon> Cleared
          <v-icon class="mr-2 ml-4" color="#1B72E8B2">mdi-circle</v-icon> In
          progress
          <v-icon class="mr-2 ml-4" color="#FF9501CC">mdi-circle</v-icon> Not
          Cleared<v-icon class="mr-2 ml-4" color="#0000001F">mdi-circle</v-icon>
          Yet to attempt
        </v-col>
      </v-row>

      <!------------------------------------------ Assessment card here---------------------------- -->
      <div class="d-flex flex-row pb-4" id="myScroll-x" v-if="!showUsers">
        <v-card width="427px" min-width="427px" height="auto" class="pa-5 mx-2 pb-8 cardBorder rounded-lg"
          v-for="assessment in assessments" :key="assessment.id">
          <v-card-title @click="fetchScreeningUsers(assessment.id)" class="pa-0 cursor">{{ assessment.name
          }}</v-card-title>
          <div class="my-2 text-subtitle-1">
            <!-- Assessment title -->
            Senior Secondary
          </div>
          <!-- assessment type -->

          <div>
            <v-chip class="my-chip">{{ assessment.screeningConfiguration.assessmentype }}</v-chip>
            <div class="d-flex flex-row grey--text justify-space-between mt-4 mb-4 ">
              <div class="assessmentIconColor">
                <v-icon class="pr-0 ">mdi-help-circle-outline</v-icon>{{
                  assessment.screeningConfiguration.totalNoOfQuestions }} Questions
              </div>
              <div class="assessmentIconColor">
                <v-icon class="pr-0 pl-4">mdi-clock-outline</v-icon>{{
                  formatTime(assessment.screeningConfiguration.durationOfAssessment) }}
              </div>
              <div class="assessmentIconColor">
                <v-icon class="pr-0 pl-4">mdi-alpha-a-circle-outline</v-icon>{{
                  assessment.screeningConfiguration.correctScoreAnswer }} marks
              </div>
            </div>
            <v-card class="d-flex flex-row pa-0 ma-0" width="100%" height="40px" color="transparent" depressed
              elevation="0">
              <v-card elevation="0" class="d-flex flex-row pa-0 ma-0" width="100%" height="0px" color="grey">
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="20%" height="10px"
                  color="#06C270">
                  <div class="graph-value litegreen">50</div>
                </v-card>
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="30%" height="10px"
                  color="#1b72e8b3">
                  <div class="graph-value liteblue">50</div>
                </v-card>
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="25%" height="10px"
                  color="#ff9501cc">
                  <div class="graph-value liteyellow">50</div>
                </v-card>
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="25%" height="10px"
                  color="#D9D9D9">
                  <div class="graph-value">50</div>
                </v-card>
              </v-card>
            </v-card>
            <div class="d-flex justify-end">

              <v-card-title class="pa-0 cursor"
                @click="publishMethod(assessment.id, assessment.screeningConfiguration.assessment_type)">PUBLISH
                RESULTS</v-card-title>
            </div>
            <v-divider class="mb-4 mt-2"></v-divider>
          </div>
          <div>
            <v-chip class="my-chip">{{ assessment.mainsConfiguration.assessmentype }}</v-chip>
            <div class="d-flex flex-row grey--text justify-space-between mt-4 mb-4 ">
              <div class="assessmentIconColor">
                <v-icon class="pr-0 ">mdi-help-circle-outline</v-icon>{{ assessment.mainsConfiguration.totalNoOfQuestions
                }} Questions
              </div>
              <div class="assessmentIconColor">
                <v-icon class="pr-0 pl-4">mdi-clock-outline</v-icon>{{
                  formatTime(assessment.mainsConfiguration.durationOfAssessment) }}
              </div>
              <div class="assessmentIconColor">
                <v-icon class="pr-0 pl-4">mdi-alpha-a-circle-outline</v-icon>{{
                  assessment.mainsConfiguration.correctScoreAnswer }} marks
              </div>
            </div>
            <v-card class="d-flex flex-row pa-0 ma-0" width="100%" height="40px" color="transparent" depressed
              elevation="0">
              <v-card elevation="0" class="d-flex flex-row pa-0 ma-0" width="100%" height="0px" color="grey">
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="20%" height="10px"
                  color="#06C270">
                  <div class="graph-value litegreen">50</div>
                </v-card>
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="30%" height="10px"
                  color="#1b72e8b3">
                  <div class="graph-value liteblue">50</div>
                </v-card>
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="25%" height="10px"
                  color="#ff9501cc">
                  <div class="graph-value liteyellow">50</div>
                </v-card>
                <v-card elevation="0" class="d-flex flex-row pa-0 ma-0 justify-center rounded-0" width="25%" height="10px"
                  color="#D9D9D9">
                  <div class="graph-value">50</div>
                </v-card>
              </v-card>
            </v-card>
            <div class="d-flex justify-end">

              <v-card-title class="pa-0 cursor"
                @click="publishMethod(assessment.id, assessment.mainsConfiguration.assessment_type)">PUBLISH
                RESULTS</v-card-title>
            </div>
            <v-divider class="mb-4 mt-2"></v-divider>
          </div>

          <!-- Assessment type -->
        </v-card>
      </div>

      <v-data-table v-if="showUsers" :headers="screeningHeaders" :items="assessmentUsers">
        <template v-slot:[`item.actions`]="{}">
          <img width="30px" class="pt-2 cursor" src="../assets/todo.svg" />
        </template>
      </v-data-table>

      <!-- <v-dialog v-model="successDialog" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center">
            <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-4">New Grade Created</p>
            <v-btn
              class="primary"
              large
              width="157px"
              rounded
              @click="successDialog = false"
              >OK</v-btn
            >
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog> -->


      <!-- Notification Dialog -->
      <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
        <Notification @close-dialog="notificationDialog = false" />
      </v-dialog>

      <!-- Assessment Creation Dialog Form -->
      <v-dialog v-model="dialog" fullscreen hide-overlay class="cdz">
        <v-card class="background">
          <v-card-title class="mx-4">
            <v-toolbar elevation="0" class="background">
              <v-toolbar-title class="text-h5">Create Assessment</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-row align="center" class="justify-end">
                <v-btn rounded text @click="dialog = false" class="mx-2">Cancel</v-btn>
                <!-- <v-btn outlined rounded v-if="e1 > 1" @click="e1--" class="mr-4"
                >Back</v-btn
              > -->
                <v-btn rounded depressed class="btn-color white--text" @click="nextStep(e1)">
                  <v-icon v-if="e1 == 6">mdi-book-open-variant</v-icon>{{ e1 == 6 ? "Create" : "Next" }}
                </v-btn>
              </v-row>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="e1" elevation="0" class="background">
              <v-stepper-header class="mx-6 white rounded-xl">
                <v-stepper-step :complete="e1 > 1" step="1">
                  DETAILS
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  SCREENING CONFIGURATION
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3">
                  QUESTIONS
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 4" step="4">
                  MAINS CONFIGURATION
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 5" step="5">
                  QUESTIONS
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 6" step="6">
                  PREVIEW
                </v-stepper-step>
              </v-stepper-header>
              <!-- stepper items starts here -->
              <v-stepper-items class="background">
                <v-stepper-content step="1">
                  <v-form ref="step1" lazy-validation>
                    <v-card elevation="0">
                      <v-card-text class="pt-10">
                        <v-row class="py-0">
                          <v-col class="py-0">
                            <div class="text-body-1 my-2">Assessment Name*</div>
                            <v-text-field outlined class="rounded-xl" v-model="name" solo text
                              label="Enter Assessment Name*" :rules="[
                                (v) => !!v || 'Assessment Name is required',
                              ]"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="py-0">
                          <v-col class="py-0">
                            <div class="text-body-1 my-2">Instructions*</div>
                            <v-textarea outlined class="rounded-xl" label="Enter Instructions" solo text
                              v-model="instructions" :rules="[(v) => !!v || 'Instructions is required']">
                            </v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-form ref="step2" lazy-validation>
                    <v-card elevation="0">
                      <v-card-text>
                        <div class="text-body-1 my-4">
                          Screening Configuration
                        </div>
                        <v-row class="py-0" v-for="(
                          skillsField, index
                        ) in screeningConfiguration.skillsFields" :key="index" align="center">
                          <v-col fluid class="py-0" cols="5">
                            <div class="text-body-1 my-2">
                              Skill Distribution* (change skill settings)
                            </div>
                            <v-select :items="skillsList" outlined class="rounded-xl py-0 mytextFiled"
                              v-model="skillsField.skill" item-text="name" item-value="name"
                              :rules="[(v) => !!v || 'Skill is required']">
                            </v-select>
                            <div class="py-0 text-subtitle-2 font-weight-light">
                              Novice : 0-20, Advance : 20-40, Competent : 40-60,
                              Proficient : 60-80, Expert : 80-100
                            </div>
                          </v-col>
                          <v-col class="py-0 pl-10" cols="3">
                            <div class="text-body-1 my-2">
                              Number of Questions*
                            </div>
                            <v-text-field v-model.number="skillsField.noOfQuestions" outlined class="rounded-xl"
                              type="number" :rules="[
                                (v) => !!v || 'Number of Questions is required',
                              ]" disabled required></v-text-field>
                          </v-col>
                          <v-col align-self="center" v-if="screeningConfiguration.skillsFields.length > 1">
                            <v-btn @click="deleteScreeningSkillField(index)" text color="blue">
                              Delete
                            </v-btn>
                          </v-col>
                          <v-col>
                            <!-- screning open dialog -->
                            <v-btn v-if="checkSubjects(skillsField.skill)" text color="blue" @click="
                              openSubjectDialog(
                                skillsList.find(
                                  (skill) => skill.name == skillsField.skill
                                ),
                                skillsField
                              )
                              ">Add/Edit Subject</v-btn>
                          </v-col>
                        </v-row>

                        <v-row align="center" class="px-2 blue--text justify-start" @click="addScreeningSkillsField">
                          <v-icon large>mdi-plus-circle-outline</v-icon>
                          <v-btn width="125px" height="20px" class="pl-0" text x-large color="blue">Add Skills</v-btn>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card elevation="0" class="my-4">
                      <v-card-text>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1 my-4">Difficulty Level*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="screeningConfiguration.difficultyLevel"
                              :rules="[(v) => !!v || 'Please select one']" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card elevation="0" class="my-4">
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">
                              Total Number Of Questions
                            </div>
                            <v-text-field hide-details outlined class="rounded-xl" solo text
                              label="Total Number Of Questions" type="number" v-model.number="screeningConfiguration.totalNumberQuestions
                                "></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">Teaching Level</div>
                            <v-select hide-details label="Choose Level" :items="levels" item-text="name" item-value="name"
                              outlined class="rounded-xl" v-model="screeningConfiguration.teachingLevel">
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">
                              Score For Correct Answer*
                            </div>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field hide-details outlined class="rounded-xl green" solo text type="number" required
                              v-model.number="screeningConfiguration.correctAnswerScore
                                "></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Duration Of Assessment*</div>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field hide-details type="number" outlined class="rounded-xl green" solo text
                              v-model.number="screeningConfiguration.assessmentDuration
                                " required></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Negative Marking*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="screeningConfiguration.negativeMarking" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Randomize Questions*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="screeningConfiguration.randomizeQuestions" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col> </v-row><v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Shuffle Options*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="screeningConfiguration.shuffleOptions" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col> </v-row><v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Display Correct Answer*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group required v-model="screeningConfiguration.displayCorrectAnswer
                              ">
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col> </v-row><v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Display Result*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="screeningConfiguration.displayResult" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Passing Criteria*</div>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field append-icon="mdi-percent-outline" hide-details type="number" outlined required
                              class="rounded-xl green" solo text v-model.number="screeningConfiguration.passingCriteria
                                "></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">
                              Assessment Time Up First Reminder
                            </div>
                            <v-select v-model="screeningConfiguration.timeUpFirstReminder" hide-details
                              label="Choose First Reminder Time" :items="[1, 2, 3, 4, 5]" outlined class="rounded-xl">
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">
                              Assessment Time Up Last Reminder
                            </div>
                            <v-select v-model="screeningConfiguration.timeUpLastReminder" hide-details
                              label="Choose Last Reminder Time" :items="[1, 2, 3, 4, 5]" outlined class="rounded-xl">
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-form>
                    <v-card elevation="0">
                      <v-card-text>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn v-if="scrBtnShow" class="ma-4" @click="expandAndCollMethod" text>{{ screeningBtnValue()
                          }}</v-btn>
                        </v-row>

                        <v-expansion-panels v-model="screenPanel" popout multiple>
                          <v-expansion-panel v-for="(skill, index) in skillQuestions" :key="index">
                            <v-expansion-panel-header>{{
                              skill.skillName
                            }}</v-expansion-panel-header>
                            <v-expansion-panel-content v-for="(question, i) in skill.questions" :key="i">
                              <v-card>
                                <v-card-subtitle class="pb-0">
                                  {{ question.questionType }}
                                </v-card-subtitle>
                                <v-card-title class="pt-0">
                                  <div v-html="question.questionStatement"></div>

                                </v-card-title>
                                <v-card-title>
                                  <div v-if="question.mime_type != null">
                                    <video v-if="question.mime_type.includes('video')" height="180px" controls>
                                      <source :src="question.s3_asset_urls" :type="question.mime_type">
                                      Your browser does not support the video tag.
                                    </video>
                                    <v-img v-if="question.mime_type.includes('image')" :src="question.questionAssetUrl"
                                      height="100px">

                                    </v-img>
                                    <audio v-if="question.mime_type.includes('audio')" controls>
                                      <source :src="question.s3_asset_urls" :type="question.mime_type">
                                      Your browser does not support the audio tag.
                                    </audio>

                                  </div>
                                </v-card-title>
                                <v-card-text>
                                  <p class="mb-4">Options</p>
                                  <v-row class="justify-start">
                                    <div v-for="(
                                      option, inx
                                    ) in question.questionOptions" :key="inx">
                                      <!-- please change != to == line no 1202 -->

                                      <v-chip v-if="option.option_type != 'TEXT'" class="ma-2">
                                        <div v-html="option.optionValue"></div>
                                      </v-chip>

                                      <object v-else height="50px" class="ma-2" :data="option.option_value"></object>
                                    </div>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-form ref="step4">
                    <v-card elevation="0">
                      <v-card-text>
                        <div class="text-body-1 my-4">Mains Configuration</div>
                        <v-row class="py-0" v-for="(
                          skillsField, index
                        ) in mainsConfiguration.skillsFields" :key="index" align="center">
                          <v-col fluid class="py-0" cols="5">
                            <div class="text-body-1 my-2">
                              Skill Distribution* (change skill settings)
                            </div>
                            <v-select :items="skillsList" outlined class="rounded-xl py-0 mytextFiled"
                              v-model="skillsField.skill" item-text="name" item-value="name" required
                              :rules="[(v) => !!v || 'Skills is required']">
                            </v-select>
                            <div class="py-0 text-subtitle-2 font-weight-light">
                              Novice : 0-20, Advance : 20-40, Competent : 40-60,
                              Proficient : 60-80, Expert : 80-100
                            </div>
                          </v-col>
                          <v-col class="py-0 pl-10" cols="3">
                            <div class="text-body-1 my-2">
                              Number of Questions*
                            </div>
                            <v-text-field v-model.number="skillsField.noOfQuestions" outlined class="rounded-xl"
                              type="number" :rules="[
                                (v) => !!v || 'Number of Questions is required',
                              ]" required disabled></v-text-field>
                          </v-col>
                          <v-col cols="2" align-self="center" v-if="mainsConfiguration.skillsFields.length > 1">
                            <v-btn @click="deleteMainsSkillField(index)" text color="blue">
                              Delete
                            </v-btn>
                          </v-col>
                          <v-col>
                            <!-- mains open subject Dialog -->
                            <v-btn v-if="checkSubjects(skillsField.skill)" text color="blue" @click="
                              openSubjectDialogMains(
                                skillsList.find(
                                  (skill) => skill.name == skillsField.skill
                                ),
                                skillsField
                              )
                              ">Add/Edit Subject</v-btn>
                          </v-col>
                        </v-row>

                        <v-row align="center" class="px-2 blue--text justify-start" @click="addMainsSkillsField">
                          <v-icon large>mdi-plus-circle-outline</v-icon>
                          <v-btn width="125px" height="20px" class="pl-0" text x-large color="blue">Add Skills</v-btn>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card elevation="0" class="my-4">
                      <v-card-text>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1 my-4">Difficulty Level*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="mainsConfiguration.difficultyLevel"
                              :rules="[(v) => !!v || 'Please select one']" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card elevation="0" class="my-4">
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">
                              Total Number Of Questions
                            </div>
                            <v-text-field hide-details outlined class="rounded-xl" solo text
                              label="Total Number Of Questions" type="number" v-model.number="mainsConfiguration.totalNumberQuestions
                                " :rules="[
    (v) =>
      !!v || 'Total Number Of Questions is required',
  ]" required></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">Teaching Level</div>
                            <v-select hide-details label="Choose Level" :items="levels" item-text="name" item-value="name"
                              outlined class="rounded-xl" v-model="mainsConfiguration.teachingLevel">
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">
                              Score For Correct Answer*
                            </div>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field hide-details outlined class="rounded-xl green" solo text type="number" required
                              v-model.number="mainsConfiguration.correctAnswerScore
                                "></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Duration Of Assessment*</div>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field hide-details type="number" outlined class="rounded-xl green" solo text
                              v-model.number="mainsConfiguration.assessmentDuration
                                " required></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Negative Marking*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="mainsConfiguration.negativeMarking" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1" required>
                              Randomize Questions*
                            </div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="mainsConfiguration.randomizeQuestions" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col> </v-row><v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Shuffle Options*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="mainsConfiguration.shuffleOptions" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col> </v-row><v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Display Correct Answer*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="mainsConfiguration.displayCorrectAnswer" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col> </v-row><v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Display Result*</div>
                          </v-col>
                          <v-col cols="3">
                            <v-radio-group v-model="mainsConfiguration.displayResult" required>
                              <v-row>
                                <v-col>
                                  <v-radio label="YES" value="YES"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-radio label="NO" value="NO"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <div class="text-body-1">Passing Criteria*</div>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field append-icon="mdi-percent-outline" hide-details type="number" outlined
                              class="rounded-xl green" solo text v-model.number="mainsConfiguration.passingCriteria"
                              required></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">
                              Assessment Time Up First Reminder
                            </div>
                            <v-select v-model="mainsConfiguration.timeUpFirstReminder" hide-details
                              label="Choose First Reminder Time" :items="[1, 2, 3, 4, 5]" outlined class="rounded-xl">
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <div class="text-body-1 my-4">
                              Assessment Time Up Last Reminder
                            </div>
                            <v-select v-model="mainsConfiguration.timeUpLastReminder" hide-details
                              label="Choose Last Reminder Time" :items="[1, 2, 3, 4, 5]" outlined class="rounded-xl">
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-stepper-content>
                <v-stepper-content step="5">
                  <v-form>
                    <v-card elevation="0">
                      <v-card-text>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn v-if="mainBtnShow" class="ma-4" @click="mainExpandAndCollMethod" text>{{ mainBtnValue()
                          }}</v-btn>
                        </v-row>
                        <v-expansion-panels v-model="mainPanel" popout multiple>
                          <v-expansion-panel v-for="(skill, index) in mainsQuestions" :key="index">
                            <v-expansion-panel-header>{{
                              skill.skillName
                            }}</v-expansion-panel-header>
                            <v-expansion-panel-content v-for="(question, i) in skill.questions" :key="i">
                              <v-card elevation="0">
                                <v-card-subtitle class="pb-0">
                                  {{ question.questionType }}
                                </v-card-subtitle>
                                <v-card-title class="pt-0">
                                  <div v-html="question.questionStatement"></div>
                                </v-card-title>
                                <v-card-title>
                                  <div v-if="question.mime_type != null">
                                    <video v-if="question.mime_type.includes('video')" height="180px" controls>
                                      <source :src="question.questionAssetUrl" :type="question.mime_type">
                                      Your browser does not support the video tag.
                                    </video>
                                    <v-img v-if="question.mime_type.includes('image')" :src="question.questionAssetUrl"
                                      height="100px">

                                    </v-img>
                                    <audio v-if="question.mime_type.includes('audio')" controls>
                                      <source :src="question.questionAssetUrl" :type="question.mime_type">
                                      Your browser does not support the audio tag.
                                    </audio>
                                  </div>
                                </v-card-title>
                                <v-card-text>
                                  <p class="mb-4">Options</p>
                                  <v-row class="justify-start">
                                    <div v-for="(
                                      option, inx
                                    ) in question.questionOptions" :key="inx">
                                      <!-- please change != to == line no 1202 -->
                                      <v-chip v-if="option.option_type != 'TEXT'" class="ma-2">
                                        <div v-html="option.optionValue"></div>
                                      </v-chip>

                                      <object v-else height="50px" class="ma-2" :data="option.optionValue"></object>
                                    </div>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-stepper-content>
                <v-stepper-content step="6">
                  <v-form>
                    <v-row>
                      <v-col cols="6">
                        <v-card elevation="0">
                          <v-card-title> Mobile App </v-card-title>
                          <v-card-title>
                            <v-img src="../assets/phone.png" height="60vh" contain></v-img>
                          </v-card-title>
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <v-card elevation="0">
                          <v-card-title> Web App </v-card-title>
                          <v-card-title>
                            <v-img src="../assets/web.png" height="60vh" contain></v-img>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Success Dialog -->
      <v-dialog v-model="successDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">{{ successMessage }}</p>
              <v-btn class="accent" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 py-4">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- ASSESSMENT PUBLISH CONFORMATION POP UP -->
      <v-dialog v-model="publishDialog" max-width="366px" persistent class="cdz">
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"><v-icon size="65">mdi-notebook-check-outline</v-icon></v-avatar>

              <p class="text-h5 pt-4 pb-0">Publish Results</p>
              <p class="text-disabled grey--text text-subtitle-1 mb-4" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will publish the assessment results. This cannot be undone
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn depressed class="background black--text" large width="157px" rounded
                  @click="publishDialog = false">CANCEL</v-btn>
                <v-btn class="accent white--text" depressed large width="157px" rounded
                  @click="publishResults(publishData.id, publishData.assessmentType)">Publish</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>


      <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-4 pb-0">Delete Assessment</p>
              <p class="text-disabled grey--text text-subtitle-1" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn depressed class="background black--text" large width="157px" rounded
                  @click="deleteDialog = false">CANCEL</v-btn>
                <v-btn class="accent white--text" depressed large width="157px" rounded
                  @click="deleteAssessment(selectedId)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Add Subject Dialog Screening-->
      <v-dialog max-width="550px" v-model="subjectDialog" center>
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="secondary mb-8">Add Subject</v-card-title>

            <v-container>
              <v-card-text>
                <v-row v-for="(subjectField, index) in selectedSubjects" :key="index">
                  <v-col fluid class="py-0" cols="6">
                    <div class="font-weight-medium">Subject</div>
                    <v-select v-model.number="subjectField.subject" clearable deletable-chips
                      label="Select or Search Subject" outlined class="rounded-xl" :items="selectedSkillSubjects"
                      item-text="name" item-value="id">
                    </v-select>
                  </v-col>
                  <v-col fluid class="py-0" cols="4">
                    <div class="font-weight-medium">Number of Questions*</div>
                    <v-text-field v-model.number="subjectField.noOfQuestions" hide-details type="number" outlined
                      class="rounded-xl green" solo text :rules="[rules.required]" required></v-text-field>
                  </v-col>

                  <v-col class="pr-5 mb-5" align-self="center" cols="2" v-if="selectedSubjects.length > 1">
                    <v-btn @click="deleteSubjectField(index)" text color="blue">
                      Delete
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row align="center" class="justify-start px-2 blue--text" @click="addSubjectField">
                  <v-icon large>mdi-plus-circle-outline</v-icon>
                  <v-btn class="pl-0" text x-large color="blue">Add New Subject</v-btn>
                </v-row>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-btn rounded outlined class="pa-4" @click="() => {
                  subjectDialog = false;
                }
                  ">Cancel</v-btn>
                <v-btn rounded class="accent pa-4" @click="addSubjectsToSkill">Apply</v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>
      <!-- Add Subject Dialog Mains-->
      <v-dialog max-width="550px" v-model="MainsDialog" center>
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="background mb-8">Add Subject</v-card-title>

            <v-container>
              <v-card-text>
                <v-row v-for="(subjectField, index) in selectedSubjectsMains" :key="index">
                  <v-col fluid class="py-0" cols="6">
                    <div class="font-weight-medium">Subject</div>
                    <v-select v-model.number="subjectField.subject" clearable deletable-chips
                      label="Select or Search Subject" outlined class="rounded-xl" :items="selectedSkillSubjectsMains"
                      item-text="name" item-value="id">
                    </v-select>
                  </v-col>
                  <v-col fluid class="py-0" cols="4">
                    <div class="font-weight-medium">Number of Questions*</div>
                    <v-text-field v-model.number="subjectField.noOfQuestions" hide-details type="number" outlined
                      class="rounded-xl green" solo text :rules="[rules.required]" required></v-text-field>
                  </v-col>

                  <v-col class="pr-5 mb-5" align-self="center" cols="2" v-if="selectedSubjectsMains.length > 1">
                    <v-btn @click="deleteSubjectFieldMains(index)" text color="blue">
                      Delete
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row align="center" class="px-2 blue--text justify-start" @click="addSubjectFieldMains">
                  <v-icon large>mdi-plus-circle-outline</v-icon>
                  <v-btn class="pl-0" text x-large color="blue">Add New Subject</v-btn>
                </v-row>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-btn rounded outlined class="pa-4" @click="MainsDialog = false">Cancel</v-btn>
                <v-btn rounded class="accent pa-4" @click="addSubjectsToSkillMains">Apply</v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>


      <!-- filter dialog-->

      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">Filter</div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div @click="clearFilter" class="clear-filter-btn">CLEAR FILTER </div>
            </v-col>
          </v-row>

          <v-tabs vertical>

            <div class="ml-3 mt-4 mr-4 filterLine">

              <v-tab class="black--text justify-start" active-class="activeTab">Skills
                <span v-if="selectedSkillsFilter.length > 0" class="ml-1">({{ selectedSkillsFilter.length }})</span>
              </v-tab>

            </div>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedSkillsFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(skillType, index) in skillsList" :key="index" :value="skillType" elevated>
                      {{ skillType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn rounded outlined class="accent--text pa-4" @click="filterDialog = false">Cancel</v-btn>
              <v-btn rounded class="accent pa-4" @click="appliedFilter = true, filterDialog = false, getFilterCount()">Apply</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
      <!-- 
      <v-dialog v-model="filterDialog" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card height="450px" elevation="0" id="myScroll" class="pt-5 ,pb-5">
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Skills
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group v-model="selectedSkillsFilter" active-class="primary" column :multiple="true">
                  <v-chip v-for="(skillType, index) in skillsList" :key="index" :value="skillType" elevated>
                    {{ skillType.name }}
                  </v-chip>
                </v-chip-group>
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn rounded outlined class="pa-4" @click="filterDialog = false">Cancel</v-btn>
                  <v-btn rounded class="accent pa-4">Apply</v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->

    </div>
  </div>
</template>
<script>
import "../styles.css";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AssessmentController from "@/controllers/AssessmentController";
import SkillsController from "@/controllers/SkillsController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";
import AuthService from "../services/AuthService";
import Notification from "./Notification";


export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  name: "AssessmentView",
  components: {
    Notification
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Field is required",
      },
      notificationDialog: false,
      appliedFilter: false,
      successMessage: 'New Assessment Created',
      selectedSkillSubjects: [],
      selectedSkillSubjectsMains: [],
      MainsDialog: false,
      selectedSubjects: [
        {
          subject: 0,
          noOfQuestions: 0,
        },
      ],
      selectedSubjectsMains: [
        {
          subject: 0,
          noOfQuestions: 0,
        },
      ],
      screenPanel: [],
      e1: 1,
      showUsers: false,
      cleared: [],
      inProgress: [],
      notCleared: [],
      yetToAttempt: [],
      name: "",
      scrBtnShow: false,
      screeningItem: 0,
      mainItem: 0,
      assessmentUsers: [],
      screeningMainsUser: "true",
      instructions: "",
      filterDialog: false,
      filterCount: 0,
      mainPanel: [],
      mainBtnShow: false,
      screeningConfiguration: {
        difficultyLevel: null,
        totalNumberQuestions: null,
        assessmentId: null,
        teachingLevel: null,
        searchSubjects: null,
        correctAnswerScore: 1,
        assessmentDuration: 0,
        negativeMarking: "NO",
        randomizeQuestions: "NO",
        shuffleOptions: "NO",
        displayCorrectAnswer: "NO",
        displayResult: "NO",
        selectedId: null,
        passingCriteria: 40,
        timeUpFirstReminder: null,
        timeUpLastReminder: null,
        assessmentType: "SCREENING",
        skillsFields: [
          {
            skill: null,
            noOfQuestions: 10,
          }
        ],

      },
      breadItems: [
        {
          text: "Dashboard",
          disabled: false,
          to: "/",
        },
        {
          text: "Dashboard",
          disabled: false,
          to: "/",
        },
        {
          text: "Assessments",

          to: "/assessment",
        },
        {
          text: "Users in Mains Assessment",
          disabled: true,
          href: "",
        },
      ],
      publishData: {
        id: null,
        assessmentType: null,
      },
      skill_questions: [],
      skillQuestions: [],
      mainsQuestions: [],
      mainsConfiguration: {
        difficultyLevel: null,
        totalNumberQuestions: null,
        teachingLevel: null,
        correctAnswerScore: 1,
        assessmentDuration: null,
        negativeMarking: "NO",
        randomizeQuestions: "NO",
        shuffleOptions: "NO",
        displayCorrectAnswer: "NO",
        displayResult: "NO",
        passingCriteria: 40,
        timeUpFirstReminder: null,
        timeUpLastReminder: null,
        assessmentType: "MAINS",
        skillsFields: [{ skill: null, noOfQuestions: 10 }],
      },
      screeningUsers: [],
      errorMessage: "Failed",
      dialog: false,
      subjectDialog: false,
      singleSelect: true,
      selected: [],
      successDialog: false,
      errorDialog: false,
      deleteDialog: false,
      publishDialog: false,
      subjectData: [],
      selectedSkillsFilter: [],
      headers: [
        { text: "Assessment Name", value: "name" },
        { text: "Created At", value: "created_at" },
        { text: "Instructions", value: "instructions" },
        { text: "Score Type", value: "scoreType" },
        { text: "Actions", value: "actions" },
      ],
      breadMenu: ["menu1", "menu2", "menu3"],
      breadData: "menu1",
      screeningHeaders: [
        { text: "Name", value: "user.first_name" },
        { text: "Email ID", value: "user.email" },
        { text: "Cluser", value: "cluser" },
        { text: "Brand", value: "scoreType" },
        { text: "Status", value: "screening_status" },
        { text: "Actions", value: "actions" },
      ],
      assessments: [],
      skillsList: [],
      levels: [],
      skillQuestionsIds: [],
      mainsQuestionsIds: [],
      editedSkill: null,
      preloader: true,
    };
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    fileType(type) {
      console.log("executing ...ype=", type)
      if (type.chatAt(0) == 'a') {
        return true
      }
      else if (type.chatAt(0) == 'v') {
        return true
      }
      else if (type.chatAt(0) == 'i') {
        return true
      }
      else {
        return false;
      }

    },
    screeningBtnValue() {
      return this.screenPanel.length === 0 ? "EXPAND" : "COLLAPSE";
    },
    expandAndCollMethod() {
      return this.screenPanel.length === 0 ? this.screenPanel = [...Array(this.screeningItem).keys()].map((k, i) => i) : this.screenPanel = [];
    },
    mainBtnValue() {
      return this.mainPanel.length === 0 ? "EXPAND" : "COLLAPSE";
    },
    mainExpandAndCollMethod() {
      return this.mainPanel.length === 0 ? this.mainPanel = [...Array(this.mainItem).keys()].map((k, i) => i) : this.mainPanel = [];
    },
    publishMethod(id, type) {
      this.publishData.id = id;
      this.publishData.assessmentType = type;
      this.publishDialog = true;
    },
    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      //const remainingSeconds = seconds % 60;
      if (hours == 0) {
        return String(minutes).padStart(2, "0") + " mins";
      } else {
        return (
          String(hours).padStart(2, "0") +
          " h and " +
          String(minutes).padStart(2, "0") +
          " mins"
        );
      }
    },
    async publishResults(assessmentId, type) {

      const response = await AssessmentController.publishResults(assessmentId, type);


      if (response.data.flag) {
        this.successMessage = 'Results Published Successfully';
        this.successDialog = true;
      }
      else {
        this.errorDialog = true;

      }
      this.publishDialog = false;

    },
    filterData() {
      this.notCleared = this.assessmentUsers.filter(
        (item) => item.screening_status == "FAILED"
      );
      console.log("not cleared", this.notCleared);
      this.inProgress = this.assessmentUsers.filter(
        (item) => item.screening_status == "STARTED"
      );
      console.log("inprogress", this.inProgress);
      this.yetToAttempt = this.assessmentUsers.filter(
        (item) => item.screening_status == "PENDING"
      );

      console.log("yet to attemp", this.yetToAttempt);
      this.cleared = this.assessmentUsers.filter(
        (item) => item.screening_status == "FINISHED"
      );
      console.log("cleared", this.cleared);
    },

    async nextStep(step) {
      if (this.e1 == 6) {
        if (this.Assessmentsupdate()) {
          this.successDialog = true;
          this.fetchAssessment();
        } else {
          alert("Failed");
        }
      } else {
        //console.log("current step is = ", step);
        switch (step) {
          case 1:
            if (this.$refs.step1.validate()) {
              //console.log("step1", this.step1);
              if (this.createAssessment()) {
                this.e1++;
              } else {
                alert("Invalid Data");
              }
            }
            break;
          case 2:
            if (this.$refs.step2.validate()) {
              //console.log("step2 value", this.step2);
              if (this.createScreening()) {
                this.e1++;
              } else {
                alert("Invalid Data");
              }
            }
            break;
          case 4:
            if (this.$refs.step4.validate()) {
              //console.log("step2 value", this.step2);
              const response = await this.createMains()
              if (response.data.flag) {
                this.e1++;
                this.getMainsQuestions();
              } else {
                alert(response.data.error);
              }
            }
            break;

          default:
            this.e1++;
        }
      }
    },
    addSubjectsToSkill() {
      //console.log(this.editedSkill);
      this.editedSkill.subjects = this.selectedSubjects;
      // this.selectedSubjects = [];
      this.subjectDialog = false;
      console.log(this.mainsConfiguration.skillsFields);
    },
    addSubjectsToSkillMains() {
      this.MainsDialog = false;
      console.log(this.editedSkill);
      this.editedSkill.subjects = this.selectedSubjectsMains;
      // this.selectedSubjects = [];      
      console.log(this.mainsConfiguration.skillsFields);
    },
    openSubjectDialog(skill, skillsField) {
      this.selectedSkillSubjects = skill.subjects;
      this.subjectDialog = true;
      console.log(skill.subjects);
      this.editedSkill = skillsField;
      console.log("skill field", skillsField)
    },
    openSubjectDialogMains(skill, skillsField) {
      this.selectedSkillSubjectsMains = skill.subjects;
      this.MainsDialog = true;
      console.log(skill.subjects);
      this.editedSkill = skillsField;
    },
    async getScreeningQuestions() {
      const response = await AssessmentController.screeningQuestions(
        this.assessmentId
      );
      if (response.data.flag) {
        this.skillQuestions = response.data.screeningQuestions;
        console.log("skill questions", this.skillQuestions);
        this.storingSkillsQuestionIds();
        this.screeningItem = this.skillQuestions.length;
        this.scrBtnShow = true
      }
      else {
        alert(response.data.error)
      }



      //console.log("screening request", response);
    },
    addSubjectField() {
      this.selectedSubjects.push({
        subject: 0,
        noOfQuestions: 0,
      });
    },
    addSubjectFieldMains() {
      this.selectedSubjectsMains.push({
        subject: 0,
        noOfQuestions: 0,
      });
    },
    deleteSubjectField(index) {
      this.selectedSubjects.splice(index, 1);
    },
    deleteSubjectFieldMains(index) {
      this.selectedSubjectsMains.splice(index, 1);
    },

    async getMainsQuestions() {
      const response = await AssessmentController.mainsQuestions(
        this.assessmentId
        // "aCO36pER7rlwKkndTh6M"

      );

      //console.log("screening request", response);
      if (response.data.flag) {
        this.mainsQuestions = response.data.mainsQuestions;
        console.log("mains questions", this.mainsQuestions);
        this.storingMainsQuestionIds();
        this.mainItem = this.mainsQuestions.length
        this.mainBtnShow = true
      }
      else {
        alert(response.data.error)
      }
    },
    addScreeningSkillsField() {
      this.screeningConfiguration.skillsFields.push({
        skill: null,
        noOfQuestions: 10,
      });
      console.log("list", this.editedSkill);
      this.getAssessmentDuration();
    },
    deleteScreeningSkillField(index) {
      this.screeningConfiguration.skillsFields.splice(index, 1);
      this.getAssessmentDuration();
    },
    addMainsSkillsField() {
      this.mainsConfiguration.skillsFields.push({
        skill: null,
        noOfQuestions: 10,
      });
      this.getAssessmentDuration();
    },
    getAssessmentDuration() {
      this.screeningConfiguration.assessmentDuration =
        this.screeningConfiguration.skillsFields.length * 20 * 10;
      this.mainsConfiguration.assessmentDuration =
        this.mainsConfiguration.skillsFields.length * 20 * 10;
    },
    deleteMainsSkillField(index) {
      this.mainsConfiguration.skillsFields.splice(index, 1);
      this.getAssessmentDuration();
    },
    async createAssessment() {
      const response = await AssessmentController.createAssessment({
        name: this.name,
        scoreType: "ASSESSMENT",
        instructions: this.instructions,
      });

      this.assessmentId = response.data.id;
      console.log(" this.assessmentId", this.assessmentId)
      return response.data.flag;
    },

    async createScreening() {
      const response = await AssessmentController.createScreening(
        {
          skillDistributions: this.screeningConfiguration.skillsFields,
          difficultyLevel: this.screeningConfiguration.difficultyLevel,
          totalNoOfQuestions: this.screeningConfiguration.totalNumberQuestions,
          level: this.screeningConfiguration.teachingLevel,
          correctScoreAnswer: this.screeningConfiguration.correctAnswerScore,
          negativeMarking: this.screeningConfiguration.negativeMarking,
          durationOfAssessment: this.screeningConfiguration.assessmentDuration,
          randomizeQuestions: this.screeningConfiguration.randomizeQuestions,
          shuffleQuestions: this.screeningConfiguration.shuffleOptions,
          displayCorrectAnswer: this.screeningConfiguration.displayCorrectAnswer,
          displayResult: this.screeningConfiguration.displayResult,
          passingCriteria: this.screeningConfiguration.passingCriteria,
          timeUpFirstRemainder: this.screeningConfiguration.timeUpFirstReminder,
          timeUpLastRemainder: this.screeningConfiguration.timeUpLastReminder,
        },
        this.assessmentId
      );

      this.getScreeningQuestions();
      return response.data.flag;
      //console.log(response);
    },

    async createMains() {
      const response = await AssessmentController.createMains(
        {
          skillDistributions: this.mainsConfiguration.skillsFields,
          difficultyLevel: this.mainsConfiguration.difficultyLevel,
          totalNoOfQuestions: this.mainsConfiguration.totalNumberQuestions,
          level: this.mainsConfiguration.teachingLevel,
          correctScoreAnswer: this.mainsConfiguration.correctAnswerScore,
          negativeMarking: this.mainsConfiguration.negativeMarking,
          durationOfAssessment: this.mainsConfiguration.assessmentDuration,
          randomizeQuestions: this.mainsConfiguration.randomizeQuestions,
          shuffleQuestions: this.mainsConfiguration.shuffleOptions,
          displayCorrectAnswer: this.mainsConfiguration.displayCorrectAnswer,
          displayResult: this.mainsConfiguration.displayResult,
          passingCriteria: this.mainsConfiguration.passingCriteria,
          timeUpFirstRemainder: this.mainsConfiguration.timeUpFirstReminder,
          timeUpLastRemainder: this.mainsConfiguration.timeUpLastReminder,
        },
        this.assessmentId
      );


      console.log(response);
      return response;
    },

    async getSubjects() {
      const response = await SubjectController.getSubject();
      console.log("status", response.status)
      if (response.status == 200) {
        if (response.data.subjects.length > 0) {
          this.subjectData = response.data.subjects;
        } else {
          this.subjectData = [];
        }
      } else {
        alert(response.data.error)
      }
      //console.log("surbject responser", this.subjectData);
    },
    async getSkills() {
      const response = await SkillsController.getSkills();
      //console.log(response);

      if (response.status) {
        if (response.data.skills.length > 0) {
          this.skillsList = response.data.skills;
        } else {
          this.skillsList = [];
        }
      } else {
        alert(response.data.error);
      }

    },
    async deleteAssessment(id) {
      const response = await AssessmentController.deleteAssessment(id);
      //console.log(response.data);




      if (response.data.flag) {
        this.deleteDialog = false;
        this.selected = [];
        this.fetchAssessment();
      }
      else {
        alert(response.data.error)
      }
    },

    async fetchAssessment() {
      const response = await AssessmentController.getAssessments();
      console.log("get assessments ", response);
      if (response.status == 200) {
        if (response.data.assessments.length > 0) {

          response.data.assessments.forEach((assessment) => {
            if (assessment.screeningConfiguration != null && assessment.mainsConfiguration != null) {
              this.assessments.push(assessment);
            }
          })
          // this.assessments = response.data.assessments;
          this.assessments.reverse();
        } else {
          this.assessments = [];
        }

        this.preloader = false;
      }
      else {
        alert(response.data.error);
        this.preloader = false;

      }
      console.log(response.data.data);
    },

    async fetchMainsUsers(id) {
      console.log("mains user clicked");
      const response = await AssessmentController.getMainsUser(id);

      if (response.data.flag) {
        this.assessmentUsers = [];
        this.assessmentUsers = response.data.data.user_assessments;
        if (this.assessmentUsers != undefined) {
          this.filterData();
        }
        console.log("filter data", this.assessmentUsers);
      }
      else {
        alert(response.data.error)
      }
    },
    async fetchScreeningUsers(id) {
      console.log("screening clicked");
      this.showUsers = true;
      this.selectedId = id;
      const response = await AssessmentController.getScreeningUser(id);

      if (response.data.flag) {
        this.assessmentUsers = response.data.data.user_assessments;
        this.filterData();

        // console.log(this.assessmentUsers);
      }
      else {
        alert(response.data.error)
      }
    },

    async getLevels() {
      const response = await LevelController.getLevel();

      if (response.status == 200) {
        if (response.data.levels.length > 0) {
          this.levels = response.data.levels;
        } else {
          this.levels = [];
        }
      }
      else {
        alert(response.data.error)
      }
      // console.log("level data", this.levelData.rows);
    },
    checkSubjects(id) {
      if (id == null) {
        return false;
      } else {
        //console.log(id);
        const skill = this.skillsList.find((skill) => skill.name == id);
        //console.log(skill);
        return skill.subjects.length == 0 ? false : true;
      }
    },
    storingSkillsQuestionIds() {
      this.skillQuestions.forEach((Questions_) => {
        Questions_.questions.forEach((question_) => {
          this.skillQuestionsIds.push(question_.id);
        });
      });
      console.log("skills questions", this.skillQuestionsIds);
    },

    storingMainsQuestionIds() {
      this.mainsQuestions.forEach((Questions_) => {
        Questions_.questions.forEach((question_) => {
          this.mainsQuestionsIds.push(question_.id);
        });
      });
      console.log("mains questions", this.mainsQuestionsIds);
    },

    async Assessmentsupdate() {
      const response = await AssessmentController.updateAssessments(
        {
          name: this.name,
          screening_question_ids: this.skillQuestionsIds,
          mains_question_ids: this.mainsQuestionsIds,
        },
        this.assessmentId
      );
      console.log(response);
      this.dialog = false;
      if (response.data.flag) {
        this.fetchAssessment();
        return response.data.flag;
      }
      else {
        alert(response.data.error)
        return false;
      }

    },
    clearFilter() {
      this.appliedFilter = false;
      this.selectedSkillsFilter = [];
      this.filterCount = 0;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedSkillsFilter.length > 0) {
        this.filterCount++;
      }
    },

  },

  created() {
    if (!this.$store.state.role.assessment.read) {
      this.$router.push('/notFound')
    }
    this.fetchAssessment();
    this.getAssessmentDuration();
    this.getSubjects();
    this.getSkills();
    this.getLevels();
    this.$store.state.breadcrumb = "Assessment";


    // if (this.$route.params.cdialog == true) {
    //   this.dialog = true;
    // }
    // this.getMainsQuestions();
  },
};
</script>

<style>
.pad {
  padding-top: 80px;
}
</style>
  