import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});
export default {
    getTopics: async function () {
        try {
            const response = await instance.get('demoTopic')
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getNewTopics: async function (query,subjects, levels, grades) {
        try {
            const response = await instance.post(`filteredDemoTopics`+query,{
                "subjects": subjects,
                "levels": levels,
                "grades": grades
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkTopics: async function (ids) {
        try {
            console.log("brands", ids)
            const response = await instance.delete('demoTopics', {
                data: {
                    ids: ids
                }
            }, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateTopic: async function (data, id) {
        try {
            const response = await instance.put('demoTopic?id=' + id, data,
                {
                    headers: {
                        'Authorization': AuthService.getToken()
                    }
                })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    searchTopics: async function (pageSize, page, search) {
        try {
            const response = await instance.get('filteredDemoTopics?searchQuery=' + search + '&&pgNum=' + page + '&&itemsPerPage=' + pageSize)
            return response;
        } catch (error) {
            return error.response;
        }
    },
    createTopic: async function (data) {
        try {
            const response = await instance.post('demoTopic',
                data
                ,
                {
                    headers: {
                        'Authorization': AuthService.getToken()
                    }
                })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getCamapignConfifrationTopics: async function (levels, subjects) {
        try {
            const response = await instance.post('campaignConfigraionDemoTopics', {
                subjects,
                levels
            },
                {
                    headers: {
                        'Authorization': AuthService.getToken()
                    }
                });
            return response;

        } catch (error) {
            return error.response;

        }


    },







}