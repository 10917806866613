import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});
export default {
    getLO: async function () {
        try {
            const response = await instance.get('learning-objectives')
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getLOByPagination: async function (query) {
        try {
            const response = await instance.get('learning-objectives'+query)
            return response;
        } catch (error) {
            return error.response;
        }
    },



    updateLO: async function (io_code,name,description,id) {
        console.log("updating data function controller ")
    
               try {
            const response = await instance.put('learning-objective?id='+id, 
            {
                   "description": description,
                    "lOCode": io_code,
                    "name": name               
            } 
            ,{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },


    

   createLO : async function (io_code,name, description) {
        try {
            const response = await instance.post('learning-objective',
            {
                    "lOCode": io_code,
                    "description": description,
                    "name": name     
               
            }               

                ,{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },



    deleteLO : async function (id) {
        console.log("delete selected id",id)
        try {
            const response = await instance.delete('admin/meta/learning-objectives/'+id,
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkLO : async function (ids) {
        console.log("delete selected id",ids)
        try {
            const response = await instance.delete('learning-objectives',{data:{ids:ids}},
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    searchLoBank: async function (pageSize,page,search) {
        try {
            const response = await instance.get('meta/learning-objectives?pageSize='+pageSize+'&page='+page+'&search='+search)
            return response;
        } catch (error) {
            return error.response;
        }
    },


}