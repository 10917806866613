<template>
    <div>
      <v-dialog max-width="887px" class="cdz" v-model="uploadBulkAudienceRoles" center>
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="gray-02 mb-8"
              >Upload HRIS Unique Roles in Bulk</v-card-title
            >
  
            <div class="d-flex justify-center mb-6 bg-surface-variant">
              <div class="box2" @dragover.prevent>
                <div
                  class="w-100 d-flex flex-row justify-center"
                  style="margin-top: 80px"
                >
                  <img src="../../../public/svgs/xlsx_icon 1.svg" />
                </div>
  
                <div class="w-100 d-flex flex-row justify-center mt-4">
                  <div>
                    <div class="font-weight-light text-center">
                      Drag and drop a file or
                      <div class="uplaodbtn2">
                        browse file
                        <input
                          type="file"
                          @change="handleFileUpload"
                          name="csv"
                        />
                      </div>
                      <p class="grey--text">(Supported only .csv file)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <v-card-actions class="px-9 pb-6">
              <v-btn text @click="downloadSampleCSV" class="mx-2 blue--text">
                <img class="mr-2" src="../../../public/svgs/fi_share.svg" />
                <span class="blue--text"> Download Template Excel </span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                width="102px"
                height="48px"
                outlined
                class="btn outline-btn pa-4"
                @click="
                  () => {
                    uploadBulkAudienceRoles = false; //
                    formbtnBool = false;
                  }
                "
                >Cancel</v-btn
              >
              <!-- @click="uploadAudienceRoles" -->
              <v-btn
                depressed
                rounded
                width="102px"
                height="48px"
                class="btn primary-btn pa-4"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog
        max-width="600px"
        max-height="80vh"
        v-model="uploadingAudienceRoles"
        center
      >
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6" md="6">
              <v-card-title class="pa-0 ma-0">
                Uploading... {{ bulkAudienceRolesResponse.length }} /
                {{ bulkAudienceRolesJsonData.length }}
              </v-card-title>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn
                class="float-right"
                icon
                @click="uploadingAudienceRoles = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-data-table
            :headers="headersresponce"
            :items="bulkAudienceRolesResponse"
            :items-per-page="-1"
          >
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex flex-row ml-2">
                {{ item.status }}
  
                <v-icon
                  v-if="item.status.includes('Uploaded Successfully!')"
                  color="green"
                  >mdi-check-all</v-icon
                >
                <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              </div>
            </template>
  
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row">
                <img
                  width="36px"
                  height="36"
                  @click="updateData(item)"
                  class="cursor"
                  src="../../assets/edit.svg"
                />
                <img
                  width="36px"
                  height="36"
                  class="cursor"
                  src="../../assets/userdelete.svg"
                />
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
  
      <v-card
        height="64px"
        width="100%"
        class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
        style="
          position: fixed;
          z-index: 200;
          top: 0;
          width: 98%;
          padding-top: 16px;
        "
      >
        <v-card-title class="pl-0 ml-0">
          <p
            class="breadcrumb-text-unselected underline-on-hover cursor"
            @click="$router.push('/masters')"
          >
            Masters
          </p>
          <v-icon
            class="breadcrumb-arrow-unselected"
            style="margin-top: 6px !important"
            >mdi-chevron-right</v-icon
          >
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
                HRIS Unique Roles
                <v-icon
                  class="breadcrumb-arrow cursor"
                  style="margin-top: 5px !important"
                  >mdi-chevron-down</v-icon
                >
              </span>
            </template>
            <MastersDropdownComponent />
          </v-menu>
        </v-card-title>
        <div
          class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
          style="position: fixed; right: 0"
        >
          <v-badge
            color="red"
            dot
            right
            transition="slide-x-transition"
            class="mr-8"
          >
            <v-hover>
              <img
                class="cursor"
                @click="notificationDialog = true"
                width="24px"
                src="../../assets/bell 1.svg"
              />
            </v-hover>
          </v-badge>
  
          <div>
            <img
              src="../../assets/avtar.png"
              class="rounded-xl mr-2"
              width="32"
              height="32"
            />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="40px"
                height="40px"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>
      <div fluid class="px-8 pad">
        <v-row>
          <v-col>
            <v-row v-if="dialog" class="px-0 pt-4">
              <v-card class="w-100 ml-4">
                <v-row class="background">
                  <v-card-title class="pl-4 mt-3 mb-1"
                    >{{ formbtn() }} Hris Unique Roles</v-card-title
                  >
                  <v-spacer></v-spacer>
                  <v-card-actions class="mt-3 mb-2">
                    <small class="pa-4 mr-1 mt-2"
                      >*All fields are mandatory</small
                    >
                    <v-btn
                      width="102px"
                      height="40px"
                      rounded
                      text
                      class="btn outline-btn pa-4 mr-1 mt-2"
                      @click="dialog = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      rounded
                      depressed
                      width="102px"
                      height="40px"
                      @click="saveInputs"
                      class="btn primary-btn pa-4 mt-2 mr-1"
                      :loading="loading"
                      >{{ formbtn() }}</v-btn
                    >
                  </v-card-actions>
                </v-row>
  
                <v-form ref="form" lazy-validation>
                  <v-card id="myScroll" style="height: calc(100vh - 176px)">
                    <v-card-text>
                      <v-row class="my-4 mx-1">
                        <h3>DETAILS</h3>
                      </v-row>
                      <v-text-field
                        outlined
                        class="py-0 my-0 rounded-xl"
                        v-model="HRIS_unique_role"
                        label="Enter Hris Unique Role Name*"
                        :rules="[(v) => !!v || 'Hris Unique Role name is required']"
                        required
                        >
                      </v-text-field>
                      <v-row class="py-0 my-0">
                        <v-col class="py-0 my-0" cols="6">
                          <v-text-field
                            outlined
                            class="rounded-xl"
                            v-model="HRIS_unique_role_code"
                            label="HRIS Unique Code*"
                            :rules="[(v) => !!v || 'HRIS Unique code is required']"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col class="py-0 my-0" cols="6">
                          <v-text-field
                            outlined
                            class="py-0 my-0 rounded-xl"
                            v-model="department"
                            label="Department Name*"
                            :rules="[(v) => !!v || 'Department name is required']"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-0 my-0">
                        <v-col class="py-0 my-0" cols="6">
                          <v-text-field
                            outlined
                            class="rounded-xl"
                            v-model="department_group"
                            label="Department Group*"
                            :rules="[(v) => !!v || 'Department Group is required']"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col class="py-0 my-0" cols="6">
                          <v-text-field
                            outlined
                            class="py-0 my-0 rounded-xl"
                            v-model="level"
                            label="Level*"
                            :rules="[(v) => !!v || 'Level is required']"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-0 my-0">
                        <v-col class="py-0 my-0" cols="6">
                          <v-text-field
                            outlined
                            class="rounded-xl"
                            v-model="roles"
                            label="Role*"
                            :rules="[(v) => !!v || 'Role is required']"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col class="py-0 my-0" cols="6">
                          <v-autocomplete
                          outlined
                          class="rounded-xl"
                          v-model="audience_role"
                          :items="audienceRoleGroups"
                          item-text="name"
                          item-value="id"
                          label="Audience Role*"
                          :rules="[(v) => !!v || 'Audience Role is required']"
                          required
                        ></v-autocomplete>
                        </v-col>
                        
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-card>
            </v-row>
  
            <v-dialog style="z-index: 300 !important" fullscreen v-model="viewAudienceRolesDialog" center>
              <v-card>
                <v-row class="gray-02 topfixed mb-8"
                  ><v-card-title class="pl-12 mt-3 mb-2"
                    >HRIS Unique Roles View</v-card-title
                  >
                  <v-spacer></v-spacer>
                  <v-card-actions class="mt-3 mb-2 mr-6">
                    <v-btn
                      width="102px"
                      height="48px"
                      outlined
                      rounded
                      text
                      class="btn outline-btn pa-4 mr-1 mt-2"
                      @click="viewAudienceRolesDialog = false"
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-row>
                <v-card-text>
                  <v-text-field
                    readonly
                    outlined
                    class="py-0 my-0 rounded-xl"
                    v-model="HRIS_unique_role"
                    label="Enter Hris Unique Role Name"
                  >
                  </v-text-field>
                  <v-row class="py-0 my-0">
                    <v-col class="py-0 my-0" cols="6">
                      <v-text-field
                        readonly
                        outlined
                        class="rounded-xl"
                        v-model="HRIS_unique_role_code"
                        label="HRIS Unique Code"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0 my-0" cols="6">
                      <v-text-field
                        readonly
                        outlined
                        class="py-0 my-0 rounded-xl"
                        v-model="department"
                        label="Department Name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="py-0 my-0">
                    <v-col class="py-0 my-0" cols="6">
                      <v-text-field
                        readonly
                        outlined
                        class="rounded-xl"
                        v-model="department_group"
                        label="Department Group"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0 my-0" cols="6">
                      <v-text-field
                        readonly
                        outlined
                        class="py-0 my-0 rounded-xl"
                        v-model="level"
                        label="Level"
                      ></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          readonly
                          outlined
                          class="rounded-xl"
                          v-model="roles"
                          label="Role"
                        ></v-text-field>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                          <v-autocomplete
                          outlined
                          class="rounded-xl"
                          v-model="audience_role"
                          :items="audienceRoleGroups"
                          item-text="name"
                          item-value="id"
                          label="Audience Role*"
                          :rules="[(v) => !!v || 'Audience Role is required']"
                          required
                        ></v-autocomplete>
                        </v-col>
                      
                    </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
  
            <!-- Filter Dialog -->
            <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
          <v-card width="570px" height="100vh" class="filterDialogClass">
            <v-row class="mt-4 mx-1">
              <v-col>
                <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                  Filter
                </div>
              </v-col>
              <v-col class="d-flex justify-end">
                <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>

            <v-tabs vertical>
              <div class="ml-3 mt-4 mr-4 filterLine">

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Level</span>
                  <span v-if="selectedLevelFilter.length > 0" class="ml-1">({{ selectedLevelFilter.length
                    }})</span></v-tab>
              </div>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedLevelFilter" active-class="active-chip" column :multiple="true">
                      <v-chip v-for="(level, index) in levels" :key="index" :value="level.name" elevated>
                        {{ level.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>

            <div class="filterDialogButton">
              <v-card-actions class="px-6 pb-3">
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="btn outline-btn font-weight-medium pa-4"
                  @click="filterDialog = false">Cancel</v-btn>
                <v-btn rounded class="btn primary-btn pa-4" @click="
                  
                  (filterDialog = false),
                  filterAudienceRoles(),
                  getFilterCount()
                  ">Apply</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

          </v-col>
        </v-row>
  
        <div v-if="!dialog">
          <v-row class="d-flex justify-center my-0 py-0">
            <v-col>
              <div class="text-h6">HRIS Unique Roles</div>
            </v-col>
  
            <v-col cols="10" class="pl-0 ml-0">
              <v-row justify="end" class="mb-1 mt-0">
                <v-text-field
                  solo
                  label="Search here..."
                  prepend-inner-icon="mdi-magnify"
                  v-model="search"
                  hide-details
                  class="search-bar border-button rounded-pill mx-3"
                  dense
                  clearable
                ></v-text-field>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
                  :class="filterCount > 0 ? 'outline-disabled-btn' : ''"
                  rounded
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :disabled="filterCount > 0"
                >
                <v-img
                v-bind="attrs" 
                v-on="on" 
                :style="filterCount > 0 ? 'filter: grayscale(100%) opacity(50%);' :''"
                :src="require(`@/assets/svg/sort.svg`)"
                />              
                  <span>SORT</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item 
                  v-for="option in sortOptions" 
                  :key="option.value" 
                  @click="selectSortOption(option.value)"
                  :class="{'selected-sort-option': selectedSort === option.value}"
                >
                  <v-list-item-title>
                    {{ option.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
                <v-btn depressed outlined rounded class="btn outline-btn elevation-0  mx-3"
                  ><v-icon class="pr-2">mdi-tune</v-icon
                  ><span v-if="filterCount > 0" @click="filterDialog = true"
                    >Filter ({{ filterCount }})</span
                  >
                  <span v-else @click="filterDialog = true">Filter</span></v-btn
                ><v-btn
                  class="btn  mx-3"
                  :disabled="selected.length == 0"
                  :class="selected.length?'btn outline-btn elevation-0':''"
                  rounded
                  outlined
                  @click="deleteDialog = true"
                  ><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn
                >
                <v-btn
                  depressed
                  outlined
                  rounded
                  class="btn outline-btn elevation-0 mx-3"
                  @click="downlaodRoles"
                  ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn
                >
  
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$store.state.role.school.admin"
                    class="btn primary-btn mx-3 white--text"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    ><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn
                  >
                </template>
                  <v-list>
                    <v-list-item @click="createForm">
                      <v-list-item-title>
                        <v-icon class="pr-1">mdi-email-plus-outline</v-icon>CREATE
                        NEW HRIS UNIQUE ROLES
                      </v-list-item-title>
                    </v-list-item>
  
                    <v-list-item @click="uploadBulkAudienceRoles = true">
                      <v-list-item-title>
                        <v-icon>mdi-account-multiple-plus </v-icon>
  
                        CREATE HRIS UNIQUE ROLE IN BULK
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            fixed-header
            height="calc(100vh - 230px)"
            class="rounded-table"
            :search="search"
            v-model="selected"
            :loading="dataTableLoading"
            loading-text="Loading HRIS Unique Roles"
            :disable-sort="true"
            :headers="headers"
            :items="tableData"
            :items-per-page="dataTableOptions.itemsPerPage"
            show-select
            :single-select="singleSelect"
            :options.sync="dataTableOptions"
            :server-items-length="count"
            @update:options="getAudienceRoles"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100],
            }"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ getDate(item.created_at) }}
            </template>
            <template v-slot:item.audience_role="{ item }">
              <span>{{ getAudienceRoleName(item.audience_role) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex align-center">
                <v-btn
                  v-if="$store.state.role.school.admin"
                  icon
                  class="pa-4"
                  @click="updateData(item)"
                >
                  <img
                    width="36px"
                    height="36px"
                    class="cursor"
                    src="../../assets/edit.svg"
                  />
                </v-btn>
                <v-btn
                  v-if="$store.state.role.school.admin"
                  icon
                  class="pa-4"
                  @click="handleClick(item)"
                >
                  <img
                    width="24px"
                    height="24px"
                    class="cursor"
                    src="../../assets/preview.svg"
                  />
                </v-btn>
              </div>
            </template>
            <template v-slot:no-results>
              <div class="mt-16 pt-16">
                <img 
                src="../../assets/noResultFound.svg"/>
              </div>
            </template>
          </v-data-table>
        </div>
  
        <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
          <v-card fluid>
            <v-container fluid class="pa-0">
              <v-card-text class="text-center">
                <v-avatar color="background" size="90"
                  ><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar
                >
  
                <p class="text-h5 pt-4 pb-0">Delete Audience Roles</p>
                <p
                  class="text-disabled grey--text text-subtitle-1"
                  color="rgba(0, 0, 0, 0.6)"
                  disabled
                >
                  This action will permanently delete the item . This cannot be
                  undone
                </p>
  
                <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                  <v-btn
                    depressed
                    class="btn outline-btn"
                    large
                    width="157px"
                    rounded
                    @click="deleteDialog = false"
                    >CANCEL</v-btn
                  >
                  <v-btn
                    class="btn primary-btn"
                    depressed
                    large
                    width="157px"
                    rounded
                    :loading="dLoading"
                    @click="deleteData(selected)"
                    >DELETE HRIS Unique Roles</v-btn
                  >
                </div>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
  
        <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
          <v-card>
            <v-container fluid class="pa-8">
              <v-card-text class="text-center">
                <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
                <p class="text-h5 pt-2 font-weight-medium">Error</p>
                <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
                <v-btn
                  class="primary"
                  large
                  width="157px"
                  rounded
                  @click="errorDialog = false"
                  >OK</v-btn
                >
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="deleteSuccessDialog"
          class="cdz"
          max-width="366px"
          persistent
        >
          <v-card>
            <v-container fluid class="pa-8">
              <v-card-text class="text-center">
                <v-icon color="#228B22" size="96"
                  >mdi-check-circle-outline</v-icon
                >
                <p class="text-h5 font-weight-bold py-4">Audience Roles Deleted</p>
                <v-btn
                  class="accent"
                  large
                  width="157px"
                  rounded
                  @click="deleteSuccessDialog = false"
                  >OK</v-btn
                >
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="successDialog"
          class="cdz"
          max-width="366px"
          persistent
        >
          <v-card>
            <v-container fluid class="pa-8">
              <v-card-text class="text-center">
                <v-icon color="#228B22" size="96"
                  >mdi-check-circle-outline</v-icon
                >
                <p class="text-h5 py-4">Audience Roles {{ formbtnValue() }}</p>
                <v-btn
                  class="accent"
                  large
                  width="157px"
                  rounded
                  @click="successDialog = false"
                  >OK</v-btn
                >
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
  
      <!-- Notification Dialog -->
      <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
        <Notification @close-dialog="notificationDialog = false" />
      </v-dialog>
    </div>
  </template>
  <script>
  import { debounce } from 'lodash';

  import axios from "axios";
  import AuthService from "../../services/AuthService";
  import AudienceRolesController from "@/controllers/AudienceRolesController";
  import LevelController from "@/controllers/LevelController";
  import Papa from "papaparse";
  import Notification from "../Notification.vue";
  import MastersDropdownComponent from "@/components/MastersDropdownComponent.vue";
  import { collection, getDocs, query, where } from "firebase/firestore";
  
  import { db } from "../../firebase";
  export default {
    name: "AudienceRolesView",
    components: {
      Notification, MastersDropdownComponent
    },
  
    data() {
      return {
        filterCount: 0,
        debouncedSearch: null,
        removedRows: [],
        
        uploadBulkAudienceRoles: false,
        uploadingAudienceRoles: false,
        notificationDialog: false,
        appliedFilter: false,
        dataTableOptions: {},
        unsubscribe: [],
        bulkAudienceRolesJsonData: [],
        bulkAudienceRolesResponse: [],
        headersresponce: [
          { text: "Index in CSV", value: "audienceRolesIndex" },
          { text: "Status", value: "status" },
        ],
        dataTableLoading: false,
        pageSize: 10,
        page: 1,
        count: 0,
        errorDialog: false,
        successDialog: false,
        deleteSuccessDialog: false,
        dialog: false,
        errorMessage: "",
        deleteDialog: false,
        audienceRolesData: "",
        dLoading: false,
        singleSelect: false,
        isFilterLoading: false,
        selected: [],
        levels: [],
        clusterData: [],
        filterDialog: false,
        formbtnBool: false,
        loading: false,
        HRIS_unique_role: "",
        HRIS_unique_role_code: "",
        viewAudienceRolesDialog: false,
        countryName: "India",
        filterData: false,
        department: "",
        selectedLevelFilter: [],
        selectedDepartmentFilters: [],
        sortCol:"HRIS_unique_role",
        sortOrder: "asc", // Default sort order (asc or desc)
        selectedSort: "HRISAsc", // Default selected sort option
        sortOptions: [
        { value: "HRISAsc", label: "HRIS Role Name - Ascending (A-Z)" },
        { value: "HRISDesc", label: "HRIS Role Name - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],
        headers: [
          { text: "HRIS Unique Role Code", value: "HRIS_unique_role_code", class: "elipsis" },
          { text: "HRIS Unique Role Name", value: "HRIS_unique_role", class: "elipsis" },
          { text: "Department", value: "department", class: "elipsis" },
          { text: "Department Group", value: "department_group", class: "elipsis" },
          { text: "Level", value: "level", class: "elipsis" },
          { text: "Roles", value: "roles", class: "elipsis" },
          { text: "Audience Roles", value: "audience_role", class: "elipsis" },
          { text: "Actions", value: "actions", class: "elipsis" },
        ],
        tableData: [],
        search: "",
        searchBool: false,
        rules: {
          required: (value) => !!value || "Field is required",
        },
        department_group:"",
        level:"",
        roles:"",
        audience_role:"",
        audienceRoleGroups:[],
      };
    },
    
    created() {
      const urlSearch = this.$route.query.search;
      if (urlSearch) {
        this.search = urlSearch;
      }      
      this.getLevels();
      if (!this.$store.state.role.school.read) {
        this.$router.push("/notFound");
      }
      this.debouncedSearch = debounce(this.getAudienceRoles, 500);
      // this.getAudienceRoles();
      this.$store.state.breadcrumb = "HRIS Unique Roles";
    },
    mounted(){
      this.getAudienceRoleGroups();
    },
    unmounted() {
    this.debouncedSearch.cancel();
  },
    computed: {
      selectedItemsRequired() {
              return (v) => (v && v.length > 0) || 'Boards is required';
          },
    },
    watch: {
      search(newValue) {
        console.log("search Val :", newValue);
        this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
        }).catch(() => {});
        if (newValue === null)
          this.search = ""
          this.options.page = 1
        this.debouncedSearch();
      },
    },
    methods: {

      async getLevels() {
      const response = await LevelController.getLevel();
      this.levelsGrades =  response.data.levels;
      this.levels = response.data.levels;
    },

      downloadTextFile(text, filename) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
  
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "HRISAsc":
          this.sortCol = "HRIS_unique_role";
          this.sortOrder = "asc";
          break;
        case "HRISDesc":
          this.sortCol = "HRIS_unique_role";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "HRISAsc";
          this.sortCol = "HRIS_unique_role";
          this.sortOrder = "asc";
      }
      this.getAudienceRoles();
    },
  
  
      uploadAudienceRoles() {
      return new Promise(async (resolve, reject) => {
        this.uploadingAudienceRoles = true;
        this.uploadBulkAudienceRoles = false;
  
        let index = 2;
        const uploadPromises = this.bulkAudienceRolesJsonData.map(async (audienceRoles_) => {
          try {
            const response = await AudienceRolesController.createAudienceRoles(audienceRoles_);
            if (response.status == 200) {
              this.bulkAudienceRolesResponse.push({
                audienceRolesIndex: index,
                status: "Uploaded Successfully!",
              });
            } else {
              this.bulkAudienceRolesResponse.push({
                audienceRolesIndex: index,
                status: "Uploaded Failed!",
              });
            }
            index++;
          } catch (error) {
            this.bulkAudienceRolesResponse.push({
              audienceRolesIndex: index,
              status: "Upload Failed due to error!",
            });
            index++;
          }
        });
  
        try {
          await Promise.all(uploadPromises);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.processCSV(file);
      }
    },

    skippedQuestion(id, reason) {
      if (reason == "") {
      } else {
        this.removedRows.push(
          "Row" + id + ". Failed" + "->reason-> " + reason
        );
      }
    },

    checkMatters(word) {
      // Check if the value is not empty, null, or undefined
      return word !== "" && word !== null && word !== undefined;
    },
  
    csvFileToJSON(file) {
    return new Promise((resolve, reject) => {
      try {
        let contents = [];
        let jsonData = [];
        this.removedRows = [];
        let chunkId = this.$store.state.userInfo.id + "---" + new Date().toISOString();

        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: (results) => {
            contents = results.data;
            if (!contents.length) {
              reject("File is empty or invalid.");
              return;
            }

            let headers = contents[0];

            for (let i = 1; i < contents.length; i++) {
              let rowData = {};
              let cells = contents[i];
              let isValidRow = true;

              for (let j = 0; j < cells.length; j++) {
                if (!this.checkMatters(cells[j])) {
                    this.skippedQuestion(i, headers[j]);
                    isValidRow = false;
                    break;
                }
                rowData[headers[j]] = cells[j].trim();
              }

              if (isValidRow) {
                rowData["chunkId"] = chunkId;
                jsonData.push(rowData);
              }
            }
            let msg = "Row No. ";
            this.removedRows.forEach((obj) => {
              msg = msg + obj + ",";
            });
            msg = msg + " Will not upload due to black cell or Wrong Entry";
            if (this.removedRows.length > 0) {
              let msg2 = this.removedRows.join("\n");
              this.downloadTextFile(msg2, "upload_report.txt");
            }

            this.bulkAudienceRolesJsonData = jsonData;
            resolve(jsonData);
          },
          error: (error) => {
            console.error("Papa Parse error:", error);
            reject(error);
          }
        });
      } catch (e) {
        console.error("Error in parsing:", e);
        reject(e);
      }
    });
},

    async processCSV(file) {
      try {
        await this.csvFileToJSON(file);
        await this.uploadAudienceRoles();
        await this.getAudienceRoles();
      } catch (error) {
        console.error("Error processing CSV:", error);
      }
    },
      logout() {
        AuthService.logout();
        this.$router.push("/login");
      },
      async downloadCSV(csvData, fileName) {
        const csv = Papa.unparse(csvData);
  
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
  
      async downlaodRoles() {
        const fileName = "hris_unique_roles.csv";
        const response = await AudienceRolesController.getAudienceRoles("?pgNum=1");
        const audienceRoles = response.data.audienceRoles
        this.downloadCSV(audienceRoles, fileName);
      },

      async downloadSampleCSV() {

      let fileName = 'Hubble STAR - HRIS Unique Roles Sample File.csv'
      try {
        const response = await axios.get(`https://firebasestorage.googleapis.com/v0/b/vgos-sss-stag.appspot.com/o/audience_roles%2F${fileName}?alt=media`, {
          responseType: 'blob' // Important
        });
      
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
      }

    },
  
      async searchData(search) {
        const response = await AudienceRolesController.searchAudienceRole(
          this.pageSize,
          this.page,
          search
        );
        this.count = response.data.data.count;
        this.tableData = response.data.data.rows;
      },
      getDate(timeStamp) {
        return new Date(timeStamp).toString().substring(0, 16);
      },
  
      createForm() {
        this.HRIS_unique_role = "",
        this.HRIS_unique_role_code = "",
        this.department = "",
        this.department_group = "",
        this.level = "",
        this.roles = "",
        this.audience_role = ""
        this.dialog = true;
        this.formbtnBool = false;
      },
  
      updateData(item) {
        this.viewAudienceRolesDialog = false;
        this.HRIS_unique_role_code = item.HRIS_unique_role_code;
        this.HRIS_unique_role = item.HRIS_unique_role;
        this.department =  item.department;
        this.department_group = item.department_group;
        this.level =  item.level;
        this.roles = item.roles;
        this.audience_role = item.audience_role;
        this.editId = item.id;
        // selected id for edit
        this.formbtnBool = true; // change update/create btn value
        this.dialog = true;
      },
  
      formbtn() {
        return this.formbtnBool === false ? "Create" : "Update";
      },
      formbtnValue() {
        return this.formbtnBool === false ? "Created" : "Updated";
      },
      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        let charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode < 48 || charCode > 57) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
  
      async saveInputs() {
        // checking case for update/create
        if (this.$refs.form.validate()) {
          this.loading = true;
          let res;
          if (this.formbtnBool == false) {
            const response = await AudienceRolesController.createAudienceRoles({
              HRIS_unique_role: this.HRIS_unique_role,
              HRIS_unique_role_code: this.HRIS_unique_role_code,
              department: this.department,
              department_group: this.department_group,
              level: this.level,
              roles: this.roles,
              audience_role: this.audience_role
            });
            res = response;
          } else {
            const response = await AudienceRolesController.updateAudienceRole(
              {
                HRIS_unique_role: this.HRIS_unique_role,
                HRIS_unique_role_code: this.HRIS_unique_role_code,
                department: this.department,
                department_group: this.department_group,
                level: this.level,
                roles: this.roles,
                audience_role: this.audience_role
              },
              this.editId
            );
            res = response;
          }
          // Close the dialog
          this.formbtnBool == false;
          this.dialog = false;
          if (res.status == 200) {
            this.successDialog = true;
          }else if(res.status == 200){
            this.errorDialog = true;
            this.errorMessage = 'HRIS Unique Role Code Already Exist';
          }
           else {
            this.errorDialog = true;
            this.errorMessage = res.data.error;
          }
          this.getAudienceRoles();
          this.loading = false;
        }
      },
  
      async deleteData(items) {
        let ids = [];
        items.forEach((doc) => {
          ids.push(doc.id);
        });
        this.dLoading = true;
        const response = await AudienceRolesController.deleteAudienceRole({
          ids: ids,
        });
        if (response.status == 200) {
          this.getAudienceRoles();
        } else {
          alert(response);
        }
  
        this.deleteDialog = false;
        this.dLoading = false;
        this.selected = [];
        this.deleteSuccessDialog = true;
      },
  
      async getAudienceRoles() {

        try {
        this.dataTableLoading = true;
        const itemsPerPage = this.dataTableOptions.itemsPerPage;
        const pgNum = this.dataTableOptions.page;
        const searchQuery = this.search;
        const sortCol = this.sortCol;
        const sortOrder = this.sortOrder;
        const levels = this.selectedLevelFilter.join(',');
        const audienceRoleGroupId = null;
        const params = {
          itemsPerPage,
          pgNum,
          searchQuery,
          sortCol,
          sortOrder,
          levels,
          audienceRoleGroupId,
        }
        const query = "?"+(new URLSearchParams(params)).toString()
        const response = await AudienceRolesController.getAudienceRoles(
         query,
        );
        if (response.status == 200) {
          this.dataTableLoading = false;
          this.tableData = response.data.audienceRoles;
          this.count = response.data.count;
        } else {
          this.dataTableLoading = false;
          alert("Something went wrong");
        }
        return response;
      } catch (error) {
          console.log(error);
        }
    },

      sortByAlphabet(array, key) {
        (array || []).sort(function (a, b) {
          let nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
          let nameB = b[key].toUpperCase();
  
          if (nameA < nameB) {
            return -1;
          }
  
          if (nameA > nameB) {
            return 1;
          }
  
          return 0;
        });
        return array;
      },
      handleClick(item) {
        this.viewAudienceRolesDialog = true;
        this.HRIS_unique_role_code = item.HRIS_unique_role_code;
        this.HRIS_unique_role = item.HRIS_unique_role;
        this.department =  item.department;
        this.department_group = item.department_group;
        this.audience_role = item.audience_role;
        this.level =  item.level;
        this.roles = item.roles;
        this.editId = item.id;
  
        this.editId = item.id;
      },
      clearFilter() {
        this.selectedLevelFilter = [];
        this.selectedDepartmentFilters = [];
        this.selectedSort = "HRISAsc";
        this.sortCol = "HRIS_unique_role";
        this.sortOrder = "asc";
        this.getAudienceRoles();
        this.filterData = false;
        this.appliedFilter = false;
        this.filterCount = 0;
      },
      async filterAudienceRoles() {
        this.selectedSort = "HRISAsc";
        this.sortCol = "HRIS_unique_role";
        this.sortOrder = "asc";
        this.isFilterLoading = true;
        const response = await this.getAudienceRoles();
        if (response.status == 200) {
          this.filterDialog = false;
          this.tableData = response.data.audienceRoles
          this.count = response.data.count;
          this.filterData = true;
          this.isFilterLoading = false;
          this.appliedFilter = true;
        } else {
          alert(response.alert.error);
          this.isFilterLoading = false;
        }
      },
  
      getFilterCount() {
        this.filterCount = 0;
        if (this.selectedLevelFilter.length > 0) {
          this.filterCount += this.selectedLevelFilter.length
        }
        if (this.selectedDepartmentFilters.length > 0) {
          this.filterCount += this.selectedDepartmentFilters.length
        }
      },
      async getAudienceRoleGroups(){
        const response = await AudienceRolesController.getAudienceRoleGroups();
        if(response.status==200){
          this.audienceRoleGroups = response.data.audienceRoleGroup;
          console.log(this.audienceRoleGroups)
        }
      },
      getAudienceRoleName(id) {
        const role = this.audienceRoleGroups.find(role => role.id === id);
        return role ? role.name : "-";
      },
    },
  };
  </script>
  
  <style scoped>
  .fixBar {
    position: fixed;
    z-index: 200;
    top: 0;
    width: 99.8%;
    padding-top: 4px;
  }
  </style>