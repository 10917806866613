<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div v-else>
    <v-card
      height="64px"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="pl-0 ml-0">
        <p
          class="breadcrumb-text-unselected underline-on-hover cursor"
          @click="goToCampaigns"
        >
          Campaigns
        </p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
          >mdi-chevron-right</v-icon
        >
        <p
          class="breadcrumb-text-unselected underline-on-hover cursor"
          @click="goBack"
        >
          {{ campaignName }}
        </p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
          >mdi-chevron-right</v-icon
        >
        <p class="breadcrumb-text" style="margin-top: 2px">Test Report</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important"
          >mdi-chevron-down</v-icon
        >
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
        style="position: fixed; right: 0"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <v-hover>
            <img
              @click="notificationDialog = true"
              class="cursor"
              width="24px"
              src="../assets/bell 1.svg"
            />
          </v-hover>
        </v-badge>

        <div>
          <img
            src="../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>

    <v-card class="repCard">
      <div style="position: sticky; top: 0; z-index: 2; background: white">
        <div
          elevation="0"
          class="d-flex flex-row align-center pt-3 pb-1 justify-space-between px-3"
        >
          <p class="font-weight-bold">{{ selectedUserName }}</p>
          <div>
            <!-- <v-btn @click="goBack" outlined elevation="0" class="accent--text mr-4 mt-3" rounded>Close</v-btn> -->
            <!-- <v-btn @click="generateReport" :loading="downloadLoader" elevation="0" class="accent mt-3"
                            rounded>Download</v-btn> -->
            <!-- <v-icon>mdi-arrow-left</v-icon>
                            <v-icon>mdi-arrow-right</v-icon> -->
            <v-btn-toggle
              @change="switchResult($event)"
               v-model="isSelect"
              dense
              rounded
            >
              <v-btn 
              v-if="!campaignStages.stages || campaignStages.stages.length <= 0"
                width="100px"
                :class="
                  isSelect == 0
                    ? 'blue white--text text-subtitle-2 '
                    : '#000000 gray-02 text-subtitle-2'
                "
              >
                Screening
              </v-btn>
              <v-btn
                width="100px"
                :disabled="!hasMains"
                :class="
                  isSelect == 1
                    ? 'blue white--text ttext-subtitle-2'
                    : '#000000 gray-02 text-subtitle-2'
                "
              >
                Mains
              </v-btn>
              <v-btn
                v-if="!campaignStages.stages || campaignStages.stages.length <= 0"
                :disabled="!hasDemo"
                width="100px"
                :class="
                  isSelect == 2
                    ? 'blue white--text ttext-subtitle-2'
                    : '#000000 gray-02 text-subtitle-2'
                "
              >
                Demo
              </v-btn>
              <v-btn
              v-if="!campaignStages.stages || campaignStages.stages.length <= 0"
                :disabled="!hasInterview"
                width="100px"
                :class="
                  isSelect == 3
                    ? 'blue white--text ttext-subtitle-2'
                    : '#000000 gray-02 text-subtitle-2'
                "
              >
                Interview
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <div class="d-flex">
          <div
          class="py-0 mx-4 d-flex flex-column justify-space-between viewTestProctoringBox" style="flex: 1;"
        >
          <div class="py-0 mx-4 d-flex flex-row justify-space-between">
            <div class="d-flex flex-row align-center py-0 pr-0">
              <v-card-title class="pl-0"><b>Net Score-</b></v-card-title>
              <div class="d-flex flex-row ml-1">
                <v-card-title class="px-0"
                  ><b>({{ totalScore }} / {{ maxMarks }})</b></v-card-title
                >
              </div>
            </div>
            
            <div>
              <v-btn
              @click="generateUserPerformanceReport"
              :loading="userPerformanceReportDownloadLoader"
              elevation="0"
              class="mt-3 mr-2"
              rounded
              color="red"
              ><v-icon>mdi-download</v-icon></v-btn
            >
              <v-btn
              @click="downloadPDF"
              :loading="downloadLoader"
              elevation="0"
              class="mt-3"
              rounded
              ><v-icon>mdi-download</v-icon></v-btn
            >
            </div>
            
          </div>
          <div class="py-0 pb-3 mx-4 d-flex flex-row justify-space-between">
            <div v-if="isSelect === 1" class="d-flex flex-row">
              <p class="px-0"><b>Rank- </b></p>
              <p class="px-0">
                <b>{{
                  selectedSkill.rank == 0 ? " NA" : "#" + selectedSkill.rank
                }}</b>
              </p>
            </div>
            <p>Screenshot Attempted : {{ proctoringData.Screenshot }}</p>
            <p>Exit Full Screen - {{ proctoringData["Exit Fullscreen"] }}</p>
            <p>Screen Change - {{ proctoringData["Switch Tab/Browser"] }}</p>
            <!-- <p>Key Stroke Attempted : {{ proctoringData["Copy/Paste"] }}</p> -->
            <div style="display: flex">
              <p><b>Proctoring Data </b></p>
              <v-icon @click="showProc" color="blue">mdi-information</v-icon>
            </div>
          </div>
          <div class=" pb-3 pt-0 mt-0 d-flex flex-row justify-space-between viewTestProctoringBox" v-if="isVgosTeacher && leagueData?.length > 0">
            <div v-for="(league, index) in leagueData" :key="index" class="d-flex w-100">
              <div style="flex:1;" class="fs-14">{{league.subjectName}} -   Score {{ ((league.score / league.maximumMarks)*10).toFixed(1) }}/10 <span style="color: #A3A3A3;">(#{{league.rank}})</span>

              <v-tooltip top >
                                    <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" >                                        
                                        <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="pt-2">
                                        <path d="M23.641 20.5703H5.35494C4.40978 20.5703 3.64062 21.3395 3.64062 22.2846C3.64062 23.2298 4.40978 23.999 5.35494 23.999H23.641C24.5862 23.999 25.3553 23.2298 25.3553 22.2846C25.3553 21.3395 24.5862 20.5703 23.641 20.5703Z" :fill="crownColorMap[league.league.toLowerCase()]"/>
                                        <path d="M26.4972 3.42669C25.2366 3.42669 24.2114 4.45187 24.2114 5.71245C24.2114 6.55934 24.68 7.29189 25.3668 7.68732C24.0445 10.8188 21.9816 12.74 20.0536 12.5663C17.9095 12.3914 16.1609 9.8565 15.1963 5.61529C16.4329 5.30211 17.3541 4.18781 17.3541 2.85525C17.3541 1.27921 16.0729 -0.00195312 14.4969 -0.00195312C12.9208 -0.00195312 11.6397 1.27921 11.6397 2.85525C11.6397 4.18786 12.5608 5.30217 13.7974 5.61529C12.8328 9.8565 11.0842 12.3914 8.94018 12.5663C7.02017 12.74 4.94808 10.8188 3.62696 7.68732C4.31381 7.29189 4.7824 6.55928 4.7824 5.71245C4.7824 4.45187 3.75721 3.42669 2.49664 3.42669C1.23612 3.42669 0.210937 4.45187 0.210937 5.71245C0.210937 6.88507 1.10236 7.84279 2.24066 7.97304L4.44188 19.427H24.552L26.7531 7.97304C27.8915 7.84279 28.7829 6.88507 28.7829 5.71245C28.7829 4.45187 27.7577 3.42669 26.4972 3.42669Z" :fill="crownColorMap[league.league.toLowerCase()] || '#000'"/>
                                    </svg>
                                    </span>
                                    </template>
                                    {{league.league ?? 'NA' }}
                                </v-tooltip>
              </div>
              
            </div>
          </div>
        </div>

        <div class="viewTestProctoringBox" v-if="isVgosTeacher">
          <div class="d-flex flex-column pa-2 justify-space-between gap-8p">
          <p class="c-date">Psychometry</p>
            <p class="league-score-rank pt-4">This action will download the psychometric report</p>
                <div class="d-flex justify-start pt-16">
                  <v-btn class="btn primary-btn text-subtitle-2 br-40" :loading="downloadPsychometryLoader" rounded
                   :disabled="disablePsychometryDownload"
                    @click="generatePsychometryReport">Download Report</v-btn>
                </div> 
          </div>
        </div>
        </div>
        

        <div
          class="w-100 d-flex flex-row align-center justify-space-between py-0 my-0"
        >
          <div>
            <div
              v-if="selectedSkill.skillName == 'Psychometry'"
              class="d-flex flex-row align-center py-0 mb-3 mt-0"
            >
              <p class="mx-4 px-0">
                {{ selectedSkill.skillName }} -
                <b
                  >({{ selectedSkill.score }}/{{ selectedSkill.maximumMarks }})
                  -
                  <span :style="{ background: getPsychometricEvaluation(selectedSkill.score,
                        selectedSkill.maximumMarks).color }">

                    {{
                      getPsychometricEvaluation(
                        selectedSkill.score,
                        selectedSkill.maximumMarks
                        ).message
                      }}
                      </span>
                  
                  </b
                >
              </p>
            </div>

            <div v-else class="d-flex flex-row align-center py-0 mb-3 mt-0">
              <p class="mx-4 px-0">
                {{ selectedSkill.skillName }} -
                <b
                  >({{ selectedSkill.score }}/{{
                    selectedSkill.maximumMarks
                  }})</b
                >
              </p>
            </div>

            <div class="d-flex flex-row ml-4">
              <div class="text-body-2">Time Taken -</div>
              <div class="text-subtitle-2 pl-2">
                <b>({{ userTime }}/{{ maxTime }}) mins</b>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row mr-5">
            <v-card width="270px" class="elevation-0 transparent mt-1">
              <!-- <img src="../../public/svgs/CurrentUserIcon.svg" alt="current icon"
                                :style="{ position: 'absolute', left: `${progressLevel}px`, bottom: '16px' }"> -->

              <v-progress-linear
                v-model="progressLevel"
                rounded
                :color="selectedSkill.result !=='PASSED' ? 'error' : 'success'"
                height="10"
                style="position: relative; pointer-events: none"
              ></v-progress-linear>

              <!-- <img src="../../public/svgs/AverageIcon.svg" alt="avg icon"
                                style="position: absolute; left: 72px"> -->              
                               
                                <v-tooltip bottom style="z-index: 999">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      <img
                                        src="../../public/svgs/PassingIcon.svg"
                                        alt="pass icon"
                                        style="position: absolute"
                                        :style="`left:${((selectedSkill.threhold * 270) / maxMarks)-10}px`"
                                      />
                                    </span>
                                  </template>
                                  <span>{{ selectedSkill.threhold }} / {{maxMarks}}</span>
                                </v-tooltip>
            </v-card>

            <div class="d-flex flex-row align-center mx-0 px-0">
              <!-- <img class="mx-2" src="../../public/svgs/AverageIcon.svg" alt="avg reference icon">
                            <div class="text-body-2">Average User</div> -->
              <img
                class="ml-6 mr-2"
                src="../../public/svgs/PassingIcon.svg"
                alt="pass reference icon"
              />
              <div class="text-body-2">Passing Score</div>
              <!-- <img class="mx-2" src="../../public/svgs/CurrentUserUpIcon.svg" alt="current reference icon">
                            <div class="text-body-2">User's score</div> -->
            </div>
          </div>
        </div>

        <v-row class="py-0 px-4 my-1 mx-0 flex-row align-center">
          <v-spacer></v-spacer>
        </v-row>
      </div>

      <div class="w-100 d-flex flex-row question-result-view pa-4">
        <v-card
          class="scroll-view core-skills-container w-18"
          height="calc(100vh - 312px)"
          id="myScroll"
          outlined
          elevation="0"
          style="position: sticky; top: 300px"
        >
        <v-btn-toggle v-model="selectedSkillName" class="d-flex flex-column pa-4 ga-8 v-btn-toggle--group">           
          <v-card-subtitle class="pa-0 font-weight-bold">CORE SKILLS</v-card-subtitle>
          <template v-for="(skill, index) in selectedResult.skills">
            <template v-if="skill.isCoreSkill">
              <v-btn  
                depressed
                :color="
                  currenrSkillIndex == index
                    ? skill.result == 'PASSED'
                      ? 'success'
                      : skill.result == 'FAILED'
                      ? 'error'
                      : ''
                    : skill.result == 'PASSED'
                    ? 'success'
                    : skill.result == 'FAILED'
                    ? 'error'
                    : 'grey-02'
                "
                @click="getSelectedSkillData(skill, index)"
                outlined
                class="rounded-xl tabBtn mx-0" 
                :key="index" :value="skill.skillName" 
                >
                  <div class="d-flex flex-column w-100 align-start text-capitalize">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="text-subtitle-2 text-uppercase text--secondary">
                          {{ skill.skillName }}
                        </div>
                      </template>
                      <span>{{ skill.skillName }}</span>
                    </v-tooltip>
                    <div class="d-flex flex-row w-100 justify-space-between">
                      <div>
                        <div class="text-body-2 text--secondary">
                          Weightage
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{ skill.weightage }}%
                        </div>
                      </div>
                      <div>
                        <div class="text-body-2 text--secondary">
                          Score
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{((skill.score / skill.maximumMarks)*10).toFixed(2) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-btn
              >           
            </template>
          </template>
          <v-card-subtitle class="px-0 pb-0 font-weight-bold">OTHER SKILLS</v-card-subtitle>
          <template v-for="(skill, index) in selectedResult.skills">
              <v-btn                
                depressed
                :color="
                  currenrSkillIndex == index
                    ? skill.result == 'PASSED'
                      ? 'success'
                      : skill.result == 'FAILED'
                      ? 'error'
                      : ''
                    : skill.result == 'PASSED'
                    ? 'success'
                    : skill.result == 'FAILED'
                    ? 'error'
                    : 'gray-02'
                "
                @click="getSelectedSkillData(skill, index)"
                outlined
                class="rounded-xl tabBtn mx-0"  
                :key="index" :value="skill.skillName" 
                v-if="!skill.isCoreSkill"             
                >
                  <div class="d-flex flex-column w-100 align-start text-capitalize">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="text-subtitle-2 text-uppercase text--secondary">
                          {{ skill.skillName }}
                        </div>
                      </template>
                      <span>{{ skill.skillName }}</span>
                    </v-tooltip>
                    <div class="d-flex flex-row w-100 justify-space-between">
                      <div>
                        <div class="text-body-2 text--secondary">
                          Weightage
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{ skill.weightage }}%
                        </div>
                      </div>
                      <div>
                        <div class="text-body-2 text--secondary">
                          Score
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{((skill.score / skill.maximumMarks)*10).toFixed(2) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-btn
              >           
          </template>
        </v-btn-toggle>
        </v-card>

        <v-card
          class="scroll-view w-82"
          height="calc(100vh - 312px)"
          id="myScroll"
          elevation="0"
        >
          <v-list>
            <v-list-item
              v-for="(item, index) in selectedSkill.questions"
              :key="index"
            >
              <div class="w-100 d-flex flex-column">
                <div class="d-flex flex-row w-100">
                  <div class="d-flex flex-column w-50">
                    <div class="d-flex flex-row">
                      <div>Q.{{ index + 1 }}</div>
                      <div class="pl-4" v-html="item.questionStatement"></div>
                      
                    </div>
                    <div class="pl-4" v-if="item.questionAssetUrl!=='NA'">
                      <v-img v-if="item.mediaType === 'IMG'" :width="500" @error="imgError(item)" :src="item.questionAssetUrl"/>
                      <object v-if="item.mediaType === 'OBJ'" :width="220" @error="objError(item)" :data="item.questionAssetUrl"></object>
                      <div v-if="item.mediaType === 'ERR'" outlined class="d-flex justify-center flex-column align-center error--text ">                     
                            <!-- Media Not Found -->
                            <img src="../assets/caution.png" alt="media not found" width="48" height="48">
                            <div class="mt-2">Media Not Found</div>
                      </div>
                    </div> 
                    <v-row class="pl-8 pt-2 my-1 ml-2">
                      <v-chip
                        v-if="
                          item.difficultyLevel != 'NA' &&
                          item.skill != 'Psychometry'
                        "
                        small
                        class="mr-2"
                        >{{ item.difficultyLevel }}</v-chip
                      >
                      <v-chip
                        v-if="
                          item.complexityLevel != 'NA' &&
                          item.skill != 'Psychometry'
                        "
                        small
                        class="mr-2"
                        >{{ item.complexityLevel }}</v-chip
                      >
                      <v-chip
                        v-if="
                          item.knowledgeLevel &&
                          item.knowledgeLevel != 'NA' &&
                          item.skill != 'Psychometry'
                        "
                        small
                        class="mr-2"
                        >{{ item.knowledgeLevel }}</v-chip
                      >
                      <v-chip v-if="item.level != 'NA'" small class="mr-2">{{
                        item.level
                      }}</v-chip>
                    </v-row>

                    <v-layout wrap row class="pl-9 pt-2 ml-1">
                      <div class="pr-3">Options -</div>
                      <div v-if="imageInOption(item.questionOptions)"
                      class="d-flex mx-1">
                        <div
                        v-for="(option, i) in item.questionOptions"
                        :key="i">
                          <v-img :width="100" :src="option.optionValue"/>
                        </div>
                      </div>
                      <div
                        v-if="item.skill !== 'Psychometry' && !imageInOption(item.questionOptions)"
                        v-for="(option, i) in item.questionOptions"
                        :key="i"
                      >
                        <div
                          :class=" 
                            item.correctAnswer?.includes(option.optionKey)
                              ? 'pr-3 mt-1 text-body-2 success--text d-flex flex-row'
                              : 'pr-3 mt-1 text-body-2 d-flex flex-row'
                          "
                        >
                          <div class="mr-1"> {{option.optionKey}}.</div>
                          <div v-html="option.optionValue"></div>
                        </div>
                      </div>
                      <div
                        v-if="item.skill == 'Psychometry' && !imageInOption(item.questionOptions)"
                        v-for="(option, i) in item.questionOptions"
                        :key="i"
                      >
                        <div
                          :class="
                            option.optionScore > 0
                              ? 'pr-3 mt-1 text-body-2 success--text'
                              : 'pr-3 mt-1 text-body-2'
                          "
                        >
                          {{ option.optionKey }}. {{ option.optionValue }}
                        </div>
                      </div>
                    </v-layout>

                    <v-row class="pl-8 pt-2 ml-1">
                      <div class="pl-1 text-body-2">Time allocated -</div>
                      <div class="pl-1 text-subtitle-2">
                        {{ item.estimatedTime }} Sec
                      </div>
                      <v-spacer></v-spacer>
                      <div class="pl-1 text-body-2">
                        Avg time taken by other users -
                      </div>
                      <div
                        v-if="!item.averageTimeTaken"
                        class="pl-1 text-subtitle-2"
                      >
                        NA
                      </div>
                      <div v-else class="pl-1 text-subtitle-2">
                        {{ item.averageTimeTaken.toFixed(2) }}
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>

                  <div class="w-25">
                    <PieChart
                      :pieData="[
                        ['Type', 'Percentage'],
                        [
                          'Correct',
                          (item.noOfCorrectAttempt / item.noOfAttempt) * 100,
                        ],
                        [
                          'Incorrect',
                          item.noOfCorrectAttempt
                            ? 100 -
                              (item.noOfCorrectAttempt / item.noOfAttempt) * 100
                            : 100,
                        ],
                      ]"
                    />
                  </div>

                  <div class="w-25">
                    <TimeChart
                      :timeData="[
                        ['Type', 'Time', { role: 'style' }],
                        ['Given', parseFloat(item.estimatedTime), '#9F84AE'],
                        [
                          'Average',
                          item.averageTimeTaken?.toFixed(2),
                          '#AA69CE',
                        ],
                        ['Current', item.timeTaken?.toFixed(2), '#9F84AE'],
                      ]"
                    />
                  </div>
                </div>

                <div class="d-flex flex-column w-100 ml-1">
                  <div class="px-0 pl-9 w-100 mt-4">
                    <v-divider></v-divider>
                  </div>

                  <div
                    v-if="item.skill != 'Psychometry'"
                    class="pl-9 pt-2 text-subtitle-2 w-100 d-flex flex-row"
                  >
                    <div>
                      Response Analytics -

                      <span v-if="!item.myAnswer" class="mr-6"
                        >Not Attempted</span
                      >

                      <span v-else class="mr-6">Attempted</span>
                    </div>

                    <div>Answered -</div>

                    <div v-if="!item.myAnswer" class="mr-6">Skipped</div>
                    <div
                      class="mr-6"
                      v-else
                      :class="
                        item.currectAns ? 'pl-1 success--text' : 'pl-1 error--text'
                      "
                    >
                      {{
                        item.currectAns
                          ? item.myAnswer + " (Correct)"
                          : item.myAnswer + " (Incorrect)"
                      }}
                    </div>
                    <!-- <v-row v-if="item.skill == 'Psychometry'" class="pl-9 text-body-2 rowSpacing">
                  <div>Answered -</div>
                  <div v-if="!item.myAnswer">Skipped</div>
                  <div v-else :class="item.questionOptions
                      .filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                      .map(option => option.optionKey).length > 0
                        ? 'pl-1 correctAnswer'
                        : 'pl-1 incorrectAnswer'
                                      ">
                    {{
                      item.questionOptions.filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                    .map(option => option.optionKey).length > 0
                    ? item.myAnswer + " (Correct)"
                    : item.myAnswer + " (Incorrect)"
                    }}
                  </div>
                </v-row> -->

                    <div>Time taken -</div>
                    <div v-if="item.timeTaken != null" class="pl-1">
                      {{ item.timeTaken.toFixed(2) }} Sec
                    </div>
                  </div>
                  <div
                    v-if="item.skill == 'Psychometry'"
                    class="pl-9 pt-2 text-subtitle-2 w-100 d-flex flex-row"
                  >
                    <div>
                      Response Analytics -

                      <span v-if="!item.myAnswer" class="mr-6"
                        >Not Attempted</span
                      >

                      <span v-else class="mr-6">Attempted</span>
                    </div>

                    <div>Answered -</div>

                    <div v-if="!item.myAnswer" class="mr-6">Skipped</div>
                    <div
                      class="mr-6"
                      v-else
                      :class="
                        item.questionOptions
                          .filter(
                            (option) =>
                              item.myAnswer.includes(option.optionKey) &&
                              parseInt(option.optionScore) > 0
                          )
                          .map((option) => option.optionKey).length > 0
                          ? 'pl-1 success--text'
                          : 'pl-1 error--text'
                      "
                    >
                      {{
                        item.questionOptions
                          .filter(
                            (option) =>
                              item.myAnswer.includes(option.optionKey) &&
                              parseInt(option.optionScore) > 0
                          )
                          .map((option) => option.optionKey).length > 0
                          ? item.myAnswer + " (Correct)"
                          : item.myAnswer + " (Incorrect)"
                      }}
                    </div>
                    <!-- <v-row v-if="item.skill == 'Psychometry'" class="pl-9 text-body-2 rowSpacing">
                  <div>Answered -</div>
                  <div v-if="!item.myAnswer">Skipped</div>
                  <div v-else :class="item.questionOptions
                      .filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                      .map(option => option.optionKey).length > 0
                        ? 'pl-1 correctAnswer'
                        : 'pl-1 incorrectAnswer'
                                      ">
                    {{
                      item.questionOptions.filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                    .map(option => option.optionKey).length > 0
                    ? item.myAnswer + " (Correct)"
                    : item.myAnswer + " (Incorrect)"
                    }}
                  </div>
                </v-row> -->

                    <div>Time taken -</div>
                    <div v-if="item.timeTaken != null" class="pl-1">
                      {{ item.timeTaken.toFixed(2) }} Sec
                    </div>
                  </div>
                  <div class="my-3 customDivider"></div>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </v-card>

    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

    <v-dialog class="cdz" v-model="proctorDialog" fullscreen persistent>
      <v-card class="proctorCard" v-if="proctorDialog">
        <v-tabs color="success accent-4" left>
          <v-tab>Cumulative</v-tab>
          <v-tab>Timeline</v-tab>

          <v-tab-item :key="1">
            <v-container fluid>
              <div class="procBubChart">
                <GChart
                  v-if="proctorDialog"
                  type="BarChart"
                  style="height: 100%; width: 100%"
                  :data="cumulativeProctoringData"
                  :options="barChartOptions"
                />
              </div>
            </v-container>
          </v-tab-item>

          <v-tab-item :key="2">
            <v-container fluid>
              <div class="procBubChart">
                <GChart
                  v-if="proctorDialog"
                  type="BubbleChart"
                  style="height: 100%; width: 100%"
                  :data="bubbleChartData"
                  :options="bubbleChartOptions"
                />
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs>
        <div class="w-100 d-flex justify-end px-4">
          <v-btn
            class="btn outline-btn rounded-pill elevation-0 mx-4"
            @click="proctorDialog = false"
          >
            Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="800"
        :filename="psychometryReportFileName"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a3"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        ref="html2Pdfpsychometry"
        color="true"
        @progress="onProgress($event)"
        >
            <section slot="pdf-content">
                <div class="dev-box" style="background: rgba(251, 245, 242, 1); font-size:14px !important; font-family: GraphikRegular; page-break-inside: avoid !important; padding:16px;">
                    <div style="font-size: 20px">Psychometry</div>
                    <div style="border: 1px solid rgba(0, 0, 0, 0.12); display: flex;  flex-direction: row; background: #FFFFFF; border-radius: 8px; padding: 4px;">
                        <div style="display: flex;  flex-direction: row; width: 25%; align-items:center">
                            <v-avatar size="69" style="margin: 8px">
                                <img v-if="proctoringPic !== ''" :src="proctoringPic" alt="Report User Pic">
                                 <img v-else  src="../assets/avtar.png" alt="Report User Pic"> 
                            </v-avatar>
                            <div style="display: flex;  flex-direction: column; width:100%">
                            <div style="margin-top:8px; font-size:14px;" class="reportTableText"> {{ userName }} </div>
                            <div style="margin-top:8px; white-space:wrap;"> {{ userEmail }} </div>
                            <div style="margin-top:8px;"> {{ userPhone }} </div>
                            </div>
                        </div>
                        <v-divider vertical></v-divider>
                        <div style="width: 35%; padding:16px">
                            <v-row>
                            <v-col cols="3" style="padding-right: 0px; padding-left:16px">
                                Level -
                            </v-col>
                            <v-col cols="9" style="padding: 4px 0px;">
                                <v-chip style="font-size:14px" v-for="(level, key) in levels" :key="key">{{ level }}</v-chip>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="3" style="padding: 8px 0px 8px 16px;">
                                Subjects -
                            </v-col>
                            <v-col cols="9" style="display: flex;  flex-direction: row; padding: 0px;">
                                <v-chip
                                v-for="(subject, index) in subjects"
                                :key="index" style="font-size:14px; margin-right:2px;">
                                {{ subject }}
                                </v-chip>
                            </v-col>
                            </v-row>
                        </div>   
                        <v-divider vertical></v-divider>      
                        <div style="width: 20%; display: flex;  flex-direction: column; justify-content: space-around; padding: 0px 16px">
                            <div style="display: flex;  flex-direction: row; justify-content: space-between; padding-top: 4px;">
                            <div style="font-weight:400;">
                                Total Experience-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.totalExperience?.years !== undefined?
                                    `${interviewDetails?.candidateDetails?.totalExperience?.years} yrs` : "" }}  
                                {{ interviewDetails?.candidateDetails?.totalExperience?.months !== undefined?
                                    `${interviewDetails?.candidateDetails?.totalExperience?.months} mo` : "-"}}
                            </div>
                            </div>
                            <div style="display: flex !important; flex-direction: row flex !important; justify-content: space-between; padding-top: 4px;">
                            <div style="font-weight:400;">
                                Relevant Experience-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.relevantExperience?.years !== undefined?
                                    `${interviewDetails?.candidateDetails?.relevantExperience?.years} yrs` : "" }}
                                {{ interviewDetails?.candidateDetails?.relevantExperience?.months !== undefined?
                                    `${interviewDetails?.candidateDetails?.relevantExperience?.months} mo` : "-"}}
                            </div>
                            </div>
                            <div style="display: flex !important;  flex-direction: row !important; justify-content: space-between; padding-top: 4px;">
                            <div style="font-weight:400;">
                                Notice Period-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.noticePeriod !== undefined?
                                    interviewDetails?.candidateDetails?.noticePeriod : "-"}}
                            </div>
                            </div>
                        </div>
                        <v-divider vertical></v-divider>      
                        <div style="width: 20%; display: flex;  flex-direction: column; justify-content: space-around; padding: 0px 16px">
                            <div style="display: flex;  flex-direction: row; justify-content: space-between;">
                            <div style="font-weight:400;">
                                Current CTC-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.currentCTC !== undefined?
                                    `${interviewDetails?.candidateDetails?.currentCTC} LPA` : "-"}}
                            </div>
                            </div>
                            <div style="display: flex;  flex-direction: row; justify-content: space-between;">
                            <div style="font-weight:400;">
                                Expected CTC-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.expectedCTC !== undefined?
                                    `${interviewDetails?.candidateDetails?.expectedCTC} LPA` : "-"}}
                            </div>
                            </div>
                    </div>
                    </div>
                    <div style="background: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px">
                        Self-awareness
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.selfAwareness?.score)" style="display: flex;  flex-direction: row; border-radius: 8px; padding:4px;">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; justify-content: center; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px;">
                        <div style="margin: 4px 0px">
                            Self Motivation
                        </div>
                        <div class="margin: 4px 0px">
                            Self-concept
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left:auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.selfAwareness?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left: auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.selfAwareness?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div class="mt-2 pa-2" style="background-color: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px !important;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px !important">
                        Social Awareness
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.socialAwareness?.score)" style="display: flex;  flex-direction: row; border-radius: 8px; padding:4px">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Adaptability
                        </div>
                        <div style="margin: 4px 0px">
                            Behavior Monitoring 
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Regulation
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left: auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.socialAwareness?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto;">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding:8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.socialAwareness?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px !important;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom: 4px !important">
                        Self Management
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.selfManagement?.score)" style="display: flex;  flex-direction: row; border-radius: 8px; padding:4px;">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Organizational Awareness
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Perception
                        </div>
                        <div style="margin: 4px 0px">
                            Empathetic Concern 
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left:auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.selfManagement?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card  style="font-size: 14x; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.selfManagement?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; padding:8px; margin-top:16px;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px;">
                        Responsible decision making
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.responsibleDecisionMaking?.score)" style="display: flex;  flex-direction: row; border-radius:8px; padding:4px">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; white-space: nowrap; width: 25%; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Ethical Dilemma
                        </div>
                        <div style="margin: 4px 0px">
                            Reasoning Skills
                        </div>
                        <div style="margin: 4px 0px">
                            Critical thinking
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left: auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.responsibleDecisionMaking?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding:8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                           {{ normalizedStrandsScore?.responsibleDecisionMaking?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px"> 
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px;">
                        Relationship Skills
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.relationshipSkills?.score)" style="display: flex;  flex-direction: row; align-items: center; border-radius:8px; padding:4px">
                        <div class="reportTableText" style="display: flex; flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Collaboration
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Maturity
                        </div>
                        <div style="margin: 4px 0px">
                            Interpersonal Skills
                        </div>
                        <div style="margin: 4px 0px">
                            Intrapersonal Skills
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; margin-left:auto; padding: 16px;">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.relationshipSkills?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto;">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                           {{ normalizedStrandsScore?.relationshipSkills?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; margin-top: 16px; padding: 8px;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px;">
                        Personality
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.personality?.score)" style="display: flex;  flex-direction: row;  border-radius:8px; padding:4px;">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px;; font-size:14px">
                        <div style="margin: 4px 0px">
                            Conscientiousness
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Stability
                        </div>
                        <div style="margin: 4px 0px">
                            Extraversion
                        </div>
                        <div style="margin: 4px 0px">
                            Openness
                        </div>
                        <div style="margin: 4px 0px">
                            Teacher Effectiveness
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left:auto; padding:16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.personality?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.personality?.feedback }}
                        </v-card>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </VueHtml2pdf>  



    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="841.89"
      :manual-pagination="true"
      :html-to-pdf-options="{
        margin: 1,
        filename: 'user-report',
        image: {
          type: 'jpeg', 
          quality: 1,
        },
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: {
          format: 'a4',
          orientation: 'portrait',
          unit: 'pt',
        }
      }"
      pdf-content-width="595.28"
      ref="html2Pdf"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content">
        <v-card class="w-85 pdfCard" height="100%" elevation="0">
          <div class="pdfHead" v-if="campaignStages?.stages?.length >= 0">
            <h2>Mains Result</h2>
          </div>
          <div class="pdfHead" v-else>
            <h2 v-if="isSelect === 0">Screening Result</h2>
            <h2 v-if="isSelect === 1">Mains Result</h2>
          </div>
          <div class="pdfTitle">
            <b>{{ selectedUserName }}</b>
            <b>Total Score: ({{ totalScore }} / {{ maxMarks }})</b>
          </div>

          <v-list
            v-for="(skill, ind) in selectedResult.skills"
            :key="ind"
          >
            <div class="pdfSubTitle">
              <b
                >Skill: {{ skill.skillName }} ({{ skill.score }}/{{
                  skill.maximumMarks
                }})</b
              >
            </div>

            <v-list-item v-for="(item, index) in skill.questions" :key="index" class="pdf-item">
              <v-col>
                <v-row class="rowSpacing">
                  <div>Q. {{ index + 1 }}</div>
                  <div class="pl-4" v-html="item.questionStatement"></div>
                </v-row>
                <div class="pl-4"  v-if="item.questionAssetUrl!=='NA'">
                  <v-img :width="500" :src="item.questionAssetUrl"/>
                </div> 
                <v-row class="pl-8 pt-2 rowSpacing">
                  <v-chip
                    v-if="item.difficultyLevel !== 'NA' &&  item.skill !== 'Psychometry'"
                    small
                    class="chipSpacing"
                    >{{ item.difficultyLevel }}</v-chip
                  >
                  <v-chip
                    v-if="item.complexityLevel !== 'NA' &&  item.skill !== 'Psychometry'"
                    small
                    class="chipSpacing"
                    >{{ item.complexityLevel }}</v-chip
                  >
                  <v-chip v-if="item.level !== 'NA'" small class="chipSpacing">{{
                    item.level
                  }}</v-chip>
                </v-row>
                <v-layout wrap row class="pl-9 pt-2 rowSpacing">
                  <div class="pr-3">Options -</div>
                  <div v-for="(option, i) in item.questionOptions" :key="i">
                    <div
                      :class="item.skill!='Psychometry'?
                      item.correctAnswer.includes(option.optionKey)?
                         'pr-3 mt-1 text-body-2 success--text correctOption': 'pr-3 mt-1 text-body-2 otherOption'
                         :'pr-3 mt-1 text-body-2 otherOption'"
                         style="display: flex;">
                       <div class="mr-1">{{ option.optionKey }}.</div>
                       <div v-html="option.optionValue" style="display: inline;"></div>
                    </div>
                  </div>
                </v-layout>
                <v-row class="pl-8 pt-4 rowSpacing">
                  <div class="pl-1 text-body-2">Time allocated -</div>
                  <div class="pl-1 text-subtitle-2">
                    {{ item.estimatedTime }} Sec
                  </div>
                  <v-spacer></v-spacer>
                  <div class="pl-1 text-body-2">
                    Avg time taken by other users -
                  </div>
                  <div
                    v-if="!item.averageTimeTaken"
                    class="pl-1 text-subtitle-2"
                  >
                    NA
                  </div>
                  <div v-else class="pl-1 text-subtitle-2">
                    {{ item.averageTimeTaken.toFixed(2) }}
                  </div>
                  <v-spacer></v-spacer>
                  <div class="pl-1 text-body-2">
                    People answered correctly -
                  </div>
                  <div
                    v-if="!item.noOfAttempt && !item.noOfCorrectAttempt"
                    class="pl-1 text-subtitle-2"
                  >
                    NA
                  </div>
                  <div
                    v-else-if="!item.noOfCorrectAttempt && item.noOfAttempt"
                    class="pl-1 text-subtitle-2"
                  >
                    0%
                  </div>
                  <div v-else class="pl-1 text-subtitle-2">
                    {{ ((item.noOfCorrectAttempt / item.noOfAttempt) * 100).toFixed(2) }}%
                  </div>
                </v-row>
                <v-row class="px-0 pl-9 rowSpacing">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="pl-9 pt-4 text-subtitle-2 rowSpacing">
                  <div>
                    Response Analytics -

                    <span v-if="!item.myAnswer">Not Attempted</span>
                    <!-- <span v-else>{{ item.status }}</span> -->
                    <span v-else>Attempted</span>
                  </div>
                </v-row>
                <v-row class="pl-9 text-body-2 rowSpacing">
                  <div>Answered -</div>
                  <div v-if="!item.myAnswer">Skipped</div>
                  <div
                    v-else
                    :class="
                      item.skill !== 'Psychometry'?
                      item.currectAns?
                       'pl-1 correctAnswer': 'pl-1 incorrectAnswer'
                       : 'pl-1'
                    "
                  >
                    {{
                      item.skill !== 'Psychometry'?
                      item.currectAns?
                       item.myAnswer + " (Correct)": item.myAnswer + " (Incorrect)"
                      : item.myAnswer+""
                    }}
                  </div>
                </v-row>

                <v-row class="pl-9 text-body-2 rowSpacing">
                  <div>Time taken -</div>
                  <div v-if="item.timeTaken != null" class="pl-1">
                    {{ item.timeTaken.toFixed(2) }} Sec
                  </div>
                </v-row>
                <v-row class="px-0 pl-9">
                  <v-divider></v-divider>
                </v-row>
              </v-col>
            </v-list-item>
            <div class="html2pdf__page-break"></div>
          </v-list>
        </v-card>
      </section>
    </VueHtml2pdf>
    <section slot="pdf-content" class="hide-it">
        <v-card class="w-85 pdfCard" height="100%" elevation="0">
          <v-list
            v-for="(skill, ind) in selectedResult.skills"
            :key="ind" :aria-label="'pdf-page-' + (ind + 1)"
          >
            <div v-if="ind === 0">
              <div class="pdfHead" v-if="campaignStages?.stages?.length > 0" >
                <h2>Mains Result</h2>
              </div>
              <div class="pdfHead" v-else>
                <h2 v-if="isSelect === 0">Screening Result</h2>
                <h2 v-if="isSelect === 1">Mains Result</h2>
              </div>
              <div class="pdfTitle">
                <b>{{ selectedUserName }}</b>
                <b>Total Score: ({{ totalScore }} / {{ maxMarks }})</b>
              </div>
            </div>
            <div class="pdfSubTitle">
              <b
                >Skill: {{ skill.skillName }} ({{ skill.score }}/{{
                  skill.maximumMarks
                }})</b
              >
            </div>

            <v-list-item v-for="(item, index) in skill.questions" :key="index" class="pdf-item">
              <v-col>
                <v-row class="rowSpacing">
                  <div>Q. {{ index + 1 }}</div>
                  <div class="math-container">
                    <div class="pl-4" v-html="item.questionStatement"></div>
                  </div>
                </v-row>
                <div class="pl-4"  v-if="item.questionAssetUrl!=='NA'">
                  <v-img :width="500" :src="item.questionAssetUrl"/>
                </div> 
                <v-row class="pl-8 pt-2 rowSpacing">
                  <v-chip
                    v-if="item.difficultyLevel !== 'NA' &&  item.skill !== 'Psychometry'"
                    small
                    class="chipSpacing"
                    >{{ item.difficultyLevel }}</v-chip
                  >
                  <v-chip
                    v-if="item.complexityLevel !== 'NA' &&  item.skill !== 'Psychometry'"
                    small
                    class="chipSpacing"
                    >{{ item.complexityLevel }}</v-chip
                  >
                  <v-chip v-if="item.level !== 'NA'" small class="chipSpacing">{{
                    item.level
                  }}</v-chip>
                </v-row>
                <v-layout wrap row class="pl-9 pt-2 rowSpacing">
                  <div class="pr-3">Options -</div>
                  <div v-for="(option, i) in item.questionOptions" :key="i">
                    <div
                      :class="item.skill!='Psychometry'?
                      item.correctAnswer.includes(option.optionKey)?
                         'pr-3 mt-1 text-body-2 success--text correctOption': 'pr-3 mt-1 text-body-2 otherOption'
                         :'pr-3 mt-1 text-body-2 otherOption'"
                         style="display: flex;">
                       <div class="mr-1">{{ option.optionKey }}.</div>
                       <div v-html="option.optionValue" style="display: inline;"></div>
                    </div>
                  </div>
                </v-layout>
                <v-row class="pl-8 pt-4 rowSpacing">
                  <div class="pl-1 text-body-2">Time allocated -</div>
                  <div class="pl-1 text-subtitle-2">
                    {{ item.estimatedTime }} Sec
                  </div>
                  <v-spacer></v-spacer>
                  <div class="pl-1 text-body-2">
                    Avg time taken by other users -
                  </div>
                  <div
                    v-if="!item.averageTimeTaken"
                    class="pl-1 text-subtitle-2"
                  >
                    NA
                  </div>
                  <div v-else class="pl-1 text-subtitle-2">
                    {{ item.averageTimeTaken.toFixed(2) }}
                  </div>
                  <v-spacer></v-spacer>
                  <div class="pl-1 text-body-2">
                    People answered correctly -
                  </div>
                  <div
                    v-if="!item.noOfAttempt && !item.noOfCorrectAttempt"
                    class="pl-1 text-subtitle-2"
                  >
                    NA
                  </div>
                  <div
                    v-else-if="!item.noOfCorrectAttempt && item.noOfAttempt"
                    class="pl-1 text-subtitle-2"
                  >
                    0%
                  </div>
                  <div v-else class="pl-1 text-subtitle-2">
                    {{ ((item.noOfCorrectAttempt / item.noOfAttempt) * 100).toFixed(2) }}%
                  </div>
                </v-row>
                <v-row class="px-0 pl-9 rowSpacing">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="pl-9 pt-4 text-subtitle-2 rowSpacing">
                  <div>
                    Response Analytics -

                    <span v-if="!item.myAnswer">Not Attempted</span>
                    <!-- <span v-else>{{ item.status }}</span> -->
                    <span v-else>Attempted</span>
                  </div>
                </v-row>
                <v-row class="pl-9 text-body-2 rowSpacing">
                  <div>Answered -</div>
                  <div v-if="!item.myAnswer">Skipped</div>
                  <div
                    v-else
                    :class="
                      item.skill !== 'Psychometry'?
                      item?.currectAns?
                       'pl-1 correctAnswer': 'pl-1 incorrectAnswer'
                       : 'pl-1'
                    "
                  >
                    {{
                      item.skill !== 'Psychometry'?
                      item?.currectAns?
                       item.myAnswer + " (Correct)": item.myAnswer + " (Incorrect)"
                      : item.myAnswer+""
                    }}
                  </div>
                </v-row>

                <v-row class="pl-9 text-body-2 rowSpacing">
                  <div>Time taken -</div>
                  <div v-if="item.timeTaken != null" class="pl-1">
                    {{ item.timeTaken.toFixed(2) }} Sec
                  </div>
                </v-row>
                <v-row class="px-0 pl-9">
                  <v-divider></v-divider>
                </v-row>
              </v-col>
            </v-list-item>
              <div class="html2pdf__page-break"></div>
            </v-list>
        </v-card>
      </section>

      <!-- user performance report-->
    <div class="not-for-screen-render">
      <!-- Cover Page -->
      <div ref="userPerformanceReportCoverPage" :style="{
        backgroundImage: `url(${performanceReportCoverImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        height: '11in',
        width: '8.5in',
        backgroundColor: 'white'
      }">
        <div style="position:relative; top:712px; left:204px;
         font-size:25px; color:#2E5EAB;  font-style: italic; font-family: 'Graphik', sans-serif; font-weight:700;">
          {{ currentDate }}
        </div>
      </div>

      <!-- Understand Your Report -->
       <div ref="understandYourReport" :style="{
        backgroundImage: `url(${understandYourReport})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        minHeight: 'calc(11in - 160px)',
        width:'7.5in',
        backgroundColor: 'white'
      }">
       </div>
      <!-- Index -->
      <div ref="userPerformanceReportIndex" style="display:flex; flex-direction:column;">
        <div style="display:flex; justify-content:center; font-size:20px; font-weight:600">Index</div>
        <!-- Section -->
        <div style="display:flex; flex-direction: column; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px; ">
          <div style="display:flex; flex-direction: column; margin-bottom: 16px;">
            <div style="display:flex;">
              <span  style="font-size:16px;">1. Understanding your report</span>
              <span class="dots"></span>
              <span>01</span>
            </div>
          </div>
          <!-- Section -->
          <div style="display:flex; flex-direction: column; margin-bottom: 16px; gap:8px">
            <div style="display:flex;">
              <span style="font-size:16px;">2. Performance at Glance</span>
              <span class="dots"></span>
              <span>02</span>
            </div>
            <!-- Sub-sections -->
            <div style="padding-left:30px">
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.1. Assessment Analysis</span>
                <span class="dots"></span>
                <span>02</span>
              </div>
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.2. Achievement Marker</span>
                <span class="dots"></span>
                <span>03</span>
              </div>
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.3. Assessment completion time</span>
                <span class="dots"></span>
                <span>03</span>
              </div>
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.4. Skill-wise Score Distribution</span>
                <span class="dots"></span>
                <span>04</span>
              </div>
            </div>
          </div>

           <!-- Section -->
           <div style="display:flex; flex-direction: column; gap:8px">
            <div style="display:flex;">
              <span style="font-size:16px;">3. Detailed Report</span>
              <span class="dots"></span>
              <span>05</span>
            </div>
            <!-- Sub-sections --> 
            <div style="display:flex; flex-direction: column; gap:4px; padding-left:30px">
              <div v-for="(skill, index) in skillsSections" :key="index" style="display:flex; flex-direction:column; width:100%;">
                <div style="display:flex;">
                  <span style="font-size:14px;"> {{` 3.${index+1} ${skill.title}`}}</span>
                  <span class="dots"></span>
                  <span>{{ padWithZero(skill.pageNumber) }}</span>
                </div>
                <div v-if="skill.title == 'Core Skills'" 
                v-for="(subject, i) in subjectsSubSection" 
                :key="i" style="display:flex; width:100%; padding-left:30px;">
                  <span style="font-size:12px;"> {{` 3.1.${i+1} ${subject.title.split('-')[1]}`}}</span>
                  <span class="dots"></span>
                  <span>{{ padWithZero(subject.pageNumber) }}</span>
                </div>
              </div>
            </div>
            <div style="display:flex; font-size:16px; margin-top: 16px;">
              <span>4. Recommendations For you</span>
              <span class="dots"></span>
              <span>{{padWithZero(allSections?.at(-1)?.pageNumber)}}</span>
            </div>
          </div>
        </div>
      </div>
    <div style="padding-left: 40px;">
      <div ref="wrapperTemplateUserPerformanceReport">
        <div class="page-wrapper html2pdf__page-break" style="
          display:flex;
          flex-direction:column;
          background-color: white;
          height:100%;
          font-family: 'Graphik', sans-serif;
          min-height: 10.99in;
          max-height: 11in;
          position: relative;
        "> 
        
        <!-- Header UserPerformanceReport-->
          <div class="pdf-header" style="margin: 16px 16px 0px; width:100%; display:flex; justify-content:end; background-color: white; padding-right:16px;">
            <img style="
            height: 56px;
            width: 200px;" 
            :src="userPerformanceReportHeaderImage" alt=""/>
          </div>

        <!-- Page Content UserPerformanceReport -->
          <div style="margin:16px; flex: 1; height:100%;  overflow:auto;" class="content">
            userPerformanceReportContent
          </div>

        <!-- Footer UserPerformanceReport -->
          <footer class="pdf-footer" style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: white;
          ">
            <div style="display: flex; justify-content:space-between;">
              <div style="padding: 16px">userPerformanceReportPageNumber</div>
              <div>
                <img style="height: 64px; width: 200px" :src="userPerformanceReportFooterImage"/>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div ref="userPerformanceReport1" class="user-performance-report" 
        style="display: flex; 
        flex-direction:column;
        padding:0 16px;
        gap: 16px;
        ">
          <div style="display: flex; flex-direction:row; flex-grow: 1; align-items: stretch; justify-content: center; width:100%; gap: 16px; margin-bottom: 26px;">
            <div style="width:100%; display: flex; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:14px;">
              <v-avatar size="69">
                <img v-if="userInfo?.proctoringPic !== ''" width="32px" height="32px" :src="userInfo?.proctoringPic" alt="">
                <img v-else width="32px" height="32px" :src="userAvatarPlaceholder" alt="Report User Pic">
              </v-avatar>
              <div style="display:flex; flex-direction:column; justify-content: space-evenly; width:100%; margin-left:16px;">
                <div style="font-size:16px; font-weight:400;">{{ userInfo?.name }}</div>
                <div style="display: flex; align-items:center">
                  <div style="font-size:14px; font-weight:400;">IIEP Rank</div>
                  <div
                  style="
                  margin-left:8px;
                  display:flex;
                  justify-content:center;
                  align-items:center;
                  border-radius:16px; 
                  background-color:#E1E8F5;
                  color:#3F41D1;
                  padding: 4px 8px 6px;
                  font-size:12px;
                  font-weight:400;"> 
                    #3424
                  </div>
                </div>
              </div>
            </div>
            <div style="
            width:100%;
            font-size:14px;
            font-weight:400;
            display:flex;
            flex-direction:column;
            justify-content: space-evenly;
            border-radius: 8px !important; 
            border: 1px solid rgba(0, 0, 0, 0.12);
            padding:14px">
              <div>
                  Email -  {{ userInfo?.email }}
              </div>
              <div>
                  Mobile - {{ userInfo?.phone }}
              </div>
            </div>
          </div>
          <div style="display:flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px; gap:8px; margin-bottom: 16px;">
            <div style="display:flex; flex-direction:row; align-items:center;">
              <div style="width:25%; font-size:16px; font-weight:400;">Level</div>
              <div style="margin-right:16px;">-</div>
              <div style="width:70%; display:flex; flex-direction:row; align-items:center;">
                <div v-for="(level, index) in userInfo?.levels" :key="index"
                  style="
                  display:flex;
                  justify-content:center;
                  align-items:center;
                  border: 0.5px solid #3F41D1; 
                  border-radius:16px; 
                  background-color:#E1E8F5;
                  color:#3F41D1;
                  padding: 4px 8px 6px;
                  margin: 4px;
                  margin-left:8px;
                  font-size:12px;
                  font-weight:400;">
                    {{  level }}
                </div>
              </div>
            </div>
            <div style="display:flex; flex-direction:row; align-items:center;">   
              <div style="width:25%; font-size:16px; font-weight:400;">Subjects/Skills</div>
              <div style="margin-right:16px;">-</div>
              <div style="max-width:70%; display: flex; flex-wrap: wrap;">
                <div v-for="(subject, index) in userInfo.skillsToShow" :key="index"
                style="
                display:flex;
                justify-content:center;
                align-items:center;
                border: 0.5px solid #3F41D1; 
                border-radius:16px; 
                background-color:#E1E8F5;
                color:#3F41D1;
                padding: 4px 8px 6px;
                margin: 4px;
                margin-left:8px;
                font-size:12px;
                font-weight:400;">
                  {{ subject }}
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 16px;">
            <h4 style="font-size:20px; font-weight: 600; margin-bottom: 26px;">Performance at a Glance</h4>
            <div style="display:flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px; gap:8px;">
              <h5 style="font-size: 18px; font-weight: 500;">Assessment Analysis</h5>
              <div style="position: relative; display:flex; justify-content:center; align-items:center;">
                <!-- Radial Bar Chart -->
                <VueApexCharts
                  type="radialBar"
                  :options="overallAssessmentChartOptions"
                  :series="overallAssessmentChartData"
                  :width="500"
                  :height="500"
                />
            
                <!-- Overlay Labels -->
                <div
                  v-for="(value, index) in overallAssessmentChartData"
                  :key="index"
                  :style="getLabelStyle(index)"
                  style="pointer-events: none;"
                >
                  {{ value }}%
                </div>
              </div>
            </div>
          </div>
      </div>
      <div ref="userPerformanceReport2" class="user-perform ance-report" style="padding:16px;gap: 16px;"> 
        <div style="width:100%; display: flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px;  margin-bottom: 20px;">
          <h5 style="font-size: 18px; font-weight: 600;">Achievement Marker</h5>
          <div style="
          font-size: 12px;
          font-weight: 400;
          padding-bottom: 8px;
          color:#666666">
            Indicates your score and the highest score overall
          </div>
          <div style="display: flex; justify-content:center;">
            <div style="display: flex; flex-direction:column; justify-content:center; width:90%;">
              <div style="display: flex; width:26px; align-items:center; flex-direction:column; position:relative; transform: translateX(-13px)" 
              :style="`left:${getPercentage(scoreProfile?.userScore, 10)}%`">
                <div style="font-size: 12px;">
                  {{ scoreProfile?.userScore }}
                </div>
                <img
                :src="yourScoreImage"
                alt="pass icon"
                style="width:26px; height:24px; transform:rotate(180deg);"
                />
              </div>
              <div style="width:100%; height: 40px; background-color: #E0E0E0; border-radius:20px;">
                <div style="position:relative; left:0;height: 40px; background-color: #06C270;"
                :style="`
                width:${getPercentage(scoreProfile?.userScore, 10)}%;
                border-radius: ${scoreProfile?.userScore == 10? '20px':'20px 0px 0px 20px'};
                `">
                </div>
              </div>
              <div style="display: flex; width:26px; align-items:center; flex-direction:column; position:relative; transform: translateX(-13px)" 
              :style="`left:${getPercentage(scoreProfile?.highestScore, 10)}%`">
                  <img
                  :src="highestScoreImage"
                  alt="pass icon"
                  style="width:26px; height:24px;"
                  />
                  <div style="font-size: 12px;">
                    {{scoreProfile?.highestScore}}
                  </div>
                </div>
            </div>
          </div>
          
          <div style="display: flex; justify-content: center; align-items: center; gap:16px;">
             <div style="display: flex; align-items:center">
               <img
                 :src="highestScoreImage"
                 alt="pass icon"
                 style="width:26px; height:24px; margin-right:16px"
               />
               <div style="font-size: 14px;">Highest Score</div>
             </div>
             <div style="display: flex; align-items:center">
               <img
               :src="yourScoreImage"
               alt="pass icon"
               style="width:26px; height:24px; margin-right:16px"
               />
               <div style="font-size: 14px;">Your Score</div>
             </div>
          </div>
          
       </div>
       <div style="font-size:14px; width:100%; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px;">
        <v-row>
          <v-col cols="6" style="height:100%">
            <div style="display:flex; flex-direction:column; justify-content:space-between; height:260px">
              <div>
                <h5 style="font-size: 18px; font-weight: 600;">Assessment completion time</h5>
                <div style="
                font-size: 12px;
                font-weight: 400; ; 
                padding-bottom: 8px;
                color:#666666">
                  Indicates your completion time vs average time
                </div>
              </div>
              <div style="display:flex; flex-direction:column; gap:12px">
                <div style="display:flex; gap:8px;">
                  <div style="background-color:#FFB86B; height:16px; width:24px; border-radius:4px;"></div>
                  <div>Average Time Taken (in mins)</div>
                </div>
                <div style="display:flex; gap:8px;">
                  <div style="background-color:#06C270; height:16px; width:24px; border-radius:4px;"></div>
                  <div>Your Time Taken (in mins)</div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div style="display: flex; gap:24px; justify-content: end; padding-right:32px">
              <div style="display: flex; gap:24px; justify-content: end;">
                <div style="width:40px; height: 260px; background-color: #E0E0E0; border-radius:20px;
                display:flex; flex-direction:column; align-items:center; justify-content:end;">
                  <div style="position:relative; background-color: #FFB86B;
                  border-radius: 0px 0px 20px 20px;"
                  :style="`height:${calculatePosition(scoreProfile?.averageTimeTaken, scoreProfile?.maximumTime/60)}%`">
                  <div style="
                  position:relative;
                  top: -25px;
                  display:flex;
                  justify-content: center;
                  align-items: center;
                  height:40px; width:40px; 
                  border-radius:20px; 
                  border: 2px solid #C37C2F; background-color:#FFD4B2;">{{ scoreProfile?.averageTimeTaken }}</div>
                  </div>
                </div>
              </div>
              <div style="width:40px; height: 260px; background-color: #E0E0E0; border-radius:20px;
               display:flex; flex-direction:column; align-items:center; justify-content:end;">
                <div style="position:relative; background-color: #06C270;
                border-radius: 0px 0px 20px 20px;"
                :style="`height:${calculatePosition(scoreProfile?.userTimeTaken, scoreProfile?.maximumTime/60)}%;`">
                  <div style="
                  position:relative;
                  top: -25px;
                  display:flex;
                  justify-content: center;
                  align-items: center;
                  height:40px; width:40px; 
                  border-radius:20px;
                  border: 2px solid #00A755; 
                  background-color:#6FD3A6;">
                    {{ scoreProfile?.userTimeTaken }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      
       </div>
       <div style="width:100%; display: flex;justify-content: space-between; align-items: center; 
        border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
        margin:16px; margin-top:200px; padding:16px;">
          <div style="position: relative; transform: rotate(180deg); bottom:40px; height:20px; width:22.49px;">
            <img src="../../src/assets/userPerformanceQuote2.svg" alt="">
          </div>
          <div style="display: flex; flex-direction:column;">
            <p style="font-size: 16px; color: #666666; font-weight: 600;">
              Education is the most powerful weapon which you can use to change the world.
            </p>
            <div style="font-size: 14px; margin-left:auto; font-weight:500; color:#B68C00;">— Pele</div>
          </div>
          <div style="position: relative; top: 40px; height:20px; width:22.49px;">
            <img src="../../src/assets/userPerformanceQuote2.svg" alt="">
          </div>
        </div>
      </div>
      <div ref="userPerformanceReport3" class="user-performance-report" style="display: flex; flex-direction:column; justify-content:space-between; padding:16px;gap: 16px;">
          <div style="width:100%; display: flex; flex-direction:column;
          border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
          padding:16px;">
            <div style="display:flex; justify-content:center; align-items:center;">
              <VueApexCharts
              type="radar"
              :options="scoreDistributionChartOptions"
              :series="scoreDistributionChartData"
              :width="500"
              :height="500"
              />
            </div>
            <div style="display:flex; justify-content:center; align-items: center;">
              <div style="display:flex; flex-direction:row; gap:8px">
                <div style="display:flex; flex-direction:column; justify-content:center;">
                  <div style="background-color: #06C27099; width:24px; height: 16px; border-radius: 4px;"></div>
                </div>
                <div>Achieved</div>
              </div>
            </div>
        </div>
        <div style="width:100%; display: flex; flex-direction:row; 
        justify-content: space-between; align-items: center; 
        border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
        margin:16px; margin-top:100px; padding:16px;">
          <div style="position: relative; transform: rotate(180deg); bottom:40px; height:20px; width:22.49px;">
            <img src="../../src/assets/userPerformanceQuote1.svg" alt="">
          </div>
          <div style="display: flex; flex-direction:column;">
            <p style="font-size: 16px; font-weight: 600; color: #666666;">
              Success is no accident. It is hard work, perseverance, learning, studying, and sacrifice.
            </p>
            <div style="margin-left:auto; font-weight:500; color:#5F347B;">— Nelson Mandela</div>
          </div>
          <div style="position: relative; top: 40px; height:20px; width:22.49px;">
            <img src="../../src/assets/userPerformanceQuote1.svg" alt="">
          </div>
        </div>
      </div>
      <div v-for="(skill,index) in skillsAnalysis" :key="index" :data-section-title="`${skill.isCoreSkill ? 'Subject-': ''}${skill.skillName}`" :aria-label="`skills-${skill.skillName}`" 
      style="width:100%; display: flex; flex-direction:column; 
      margin:0px 16px; padding:8px 16px; gap:16px" class='user-performance-report'>
        <!-- Skills Title Card Page -->
        <!-- <div v-if="skill.isTitleCard" style="height:100%; display:flex; flex-direction: column; align-items: end;"> -->
          <div v-if="skill.isTitleCard" style="height:8in; display:flex; flex-direction: column; align-items: end;
          background-size: contain; background-repeat: no-repeat; background-size: 100% 100%;"
          :style="{ backgroundImage: `url(${skillTitleCardBackgroundImage})` }">
          <div style="display: flex; align-items:center; height:8in;">
            <div style="width:392px; display:flex; flex-direction:column;justify-content:center; text-align: center;
             padding:36px 20px; margin-right:32px; border-radius:8px;"
              :class="[skill.backgroundColor, skill.borderGradient]">
              <h3 style="font-size: 24px; font-weight: 600; color: #212121; margin-bottom: 24px;">{{ skill.skillName }}</h3>
              <div style="
              word-wrap: break-word;            
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.0025em;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #666666;">{{ skill.text }} </div>
            </div>
          </div>
          
        </div>
        <!-- Skills/Subjects Template -->
        <div style="width:100%; padding: 16px; border-radius: 8px !important;
         border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18);" 
         v-else-if="skill?.skillName !== 'Psychometry'">
          <div v-if="skill.isFirstSkillsPage">
            <div style="font-size:14px; font-weight:600; margin-bottom: 8px;">{{ skill.skillName }}</div>
            <div v-if="skill?.isCoreSkill" style="display: flex; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
              padding:8px 16px; background-color: #1B72E81F; border: 1px solid #E0E0E0; margin-bottom: 8px; font-size:12px;">
              <v-row>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <v-row>
                    <v-col cols="6">
                      Rank
                    </v-col>
                    <v-col style="display:flex; justify-content:end; align-items: center" cols="2">
                      -
                    </v-col>
                    <v-col style="display:flex; justify-content:end;" cols="4">
                      {{ skill?.rank }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <v-row>
                    <v-col cols="6">
                      League
                    </v-col>
                    <v-col style="display:flex; justify-content:end; align-items: center" cols="2">
                      -
                    </v-col>
                    <v-col style="display:flex; justify-content:end; align-items: center" cols="4">
                      <img v-if="skill?.league !== '' || skill?.league !== 'NA'"
                      style="height:16px; width:20px; margin-right:8px;"
                      :src="getLeagueIcon(skill?.league)"/>
                      <div>
                        {{ skill?.league }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div style="display: flex; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
              padding:8px 16px; background-color: #B27CC433; border: 1px solid #E0E0E0; margin-bottom: 8px; font-size:12px;">
              <v-row>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <v-row>
                    <v-col cols="6">
                      Total Questions
                    </v-col>
                    <v-col style="display:flex; justify-content:end; align-items: center" cols="2">
                      -
                    </v-col>
                    <v-col style="display:flex; justify-content:end;" cols="4">
                      {{ skill?.questionCounts?.total }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <v-row>
                    <v-col cols="6">
                      Questions Attempted
                    </v-col>
                    <v-col style="display:flex; justify-content:end; align-items: center" cols="2">
                      -
                    </v-col>
                    <v-col style="display:flex; justify-content:end;" cols="4">
                      {{ skill?.questionCounts?.correct + skill?.questionCounts?.incorrect }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div style="width:100%; display: flex; flex-direction:column; 
            border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
            padding:8px;">
              <div style="color: #212121; font-size:14px; font-weight:600;">Strength & Areas of Improvement</div>
              <div style="color: #666666; font-size:10px; font-weight:400;">Displays key strengths and improvement areas based on assessment results.</div>
                <v-row>
                  <v-col cols="8">
                    <div style="width:500px; height:auto">
                      <VueApexCharts
                      type="donut"
                      :options="donutChartOptions"
                      :series="[skill?.totalStrengths, skill?.totalWeaknesses]" 
                      />
                    </div>
                  </v-col>
                  <v-col cols="4"></v-col>
                </v-row>
              
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 8px;">
            <h4 v-if="skill?.strengths?.length > 0" style="font-weight:600; font-size:14px; color: #212121;">
              Strengths
            </h4>
            <div v-if="skill?.strengths?.length > 0" style="display: flex; flex-direction: column; margin-bottom: 16px; gap: 8px;">
              <div v-for="(topic, index) in skill?.strengths" style="width: 100%; height:30px; display: flex;  border-radius: 15px; background-color: #06C27023;">
                <div style="display: grid; place-items: center;  background-color:#06C2704C; width:30px; border-radius:15px; border: 0.75px solid #007C2A; font-weight:400; font-size:10px;">
                  {{ skill?.strengthsInLastPage + index + 1}}
                </div>
                <div style="display: flex; justify-content:center; align-items:center; margin:4px 36px; font-weight:400; font-size:10px;">
                  {{topic}}
                </div>
              </div>
            </div>
            <h4 v-if="skill?.weaknesses?.length > 0" style="font-weight:600; font-size:14px; color: #212121;">
              Areas of Improvement
            </h4>
            <div v-if="skill?.weaknesses?.length > 0" style="display: flex; flex-direction: column; margin-bottom: 16px; gap: 8px;">
              <div v-for="(topic, index) in skill?.weaknesses" style="width: 100%; height:30px; display: flex; border-radius: 15px; background-color: #F77CAE23;">
                <div style="display: grid; place-items: center;  background-color:#F77CAE4C; width:30px; border-radius:15px; border: 0.75px solid #C54A7C; font-weight:400; font-size:10px;">
                  {{ skill?.weaknessesInLastPage + index + 1 }}
                </div>
                <div style="display: flex; justify-content:center; align-items:center; margin:4px 36px; font-weight:400; font-size:10px;">
                  {{topic}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Psychometry Template -->
        <div v-else-if="skill?.skillName == 'Psychometry'" style="width:100%; display: flex; flex-direction:column; 
        border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18);
        margin:16px; padding:16px; gap:16px">
          <div v-if="skill.isFirstPage" style="display: flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
          padding:8px 16px; background-color: #B27CC433; border: 1px solid #E0E0E0">
            <div>
              <v-row>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <div>
                    Total Questions
                  </div>
                  <div style="margin-right:16px">
                    -  {{ skill?.questionCounts?.total }}
                  </div>
                </v-col>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <div>
                    Questions Attempted
                  </div>
                  <div style="margin-right:16px">
                    -  {{ skill?.questionCounts?.correct + skill?.questionCounts?.incorrect }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="skill.isFirstPage" style="margin-bottom:16px">
            <div style="color:#212121; font-weight:600;">Legend</div>
            <div>The given rating is out of 4</div>
            <v-row style="margin-top:8px;">
              <v-col style="padding:4px" cols="6">
                <div style="display: flex; justify-content: start; gap:8px">
                  <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :class="selectBackgroundColor(4, true)"></div>
                  <div>Highly recommended</div>
                </div>
              </v-col>
              <v-col style="padding:4px" cols="6">
                <div style="display: flex; justify-content: start; gap:8px">
                  <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :class="selectBackgroundColor(3, true)"></div>
                  <div>Recommended</div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="padding:4px" cols="6">
                <div style="display: flex; justify-content: start; gap:8px">
                  <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :class="selectBackgroundColor(2, true)"></div>
                  <div>Development needed</div>
                </div>
              </v-col>
              <v-col style="padding:4px" cols="6">
                <div style="display: flex; justify-content: start; gap:8px">
                  <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :class="selectBackgroundColor(1, true)"></div>
                  <div>Not recommended at this time</div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-for="(strandData, strandName, index) in skill?.psychometryAnalysis?.strands"
          style="display:flex; flex-direction:column; gap:16px; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); padding:8px; margin-top: 8px"
          > 
            <div style="font-weight:600; padding:8px">{{ strandName }}</div>
            <div style="display: flex; flex-wrap: wrap;">
              <v-chip  v-for="(subStrand, index) in strandData.subStrands" :key="index" class="blue--text text-subtitle-2" style="margin:4px; margin-left:8px; background: #E1E8F5 !important; flex-wrap: wrap"> 
                {{subStrand}}
              </v-chip>
            </div>
            <div style="display: flex; justify-content:space-between">
              <div style="font-weight:600; padding:8px">Rating and Feedback</div>
              <div style="height:32px; width:56px; border-radius:28px; display:grid; place-items: center" :class="selectBackgroundColor(strandData?.normalizedScore, true)">{{ strandData.normalizedScore }}</div>
            </div>
            <div style="border-radius: 8px; padding:16px;" :class="selectBackgroundColor(strandData?.normalizedScore, false)">
              {{ strandData?.feedback }}
            </div>
          </div>
        </div>
      </div>
    <!-- Recommendation Links template -->
      <div ref="recommendationLinksTemplate">
        <div style="display:flex; flex-direction:column; gap:16px; font-size:20px; font-weight:600;">
          <div>Recommendations For you</div>
          <div v-for="(skill, key, index) in recommendationLinks" :key="key" 
          style="display: flex; flex-direction: column; gap:12px; padding:16px;
          border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); 
          border-bottom: 2px solid rgba(0, 0, 0, 0.18);"
          :class="index % 2 == 0 ? 'page-break-after:always;':''">
            <div style="border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); 
            border-bottom: 2px solid rgba(0, 0, 0, 0.18); display:flex; align-items:center;
            height:36px; padding: 4px 12px; font-size: 14px; font-weight:600"
            :class="skill.backgroundColor"> 
              {{ skill.skillName }}
            </div>
            <div style="padding-left:12px; font-size: 12px; font-weight:600">
              Videos
            </div>
            <a 
              v-for="(video, index) in skill.recommendedReadings.videos" 
              :href="video" 
              target="_blank" 
              style="padding-left: 12px; font-size: 12px; font-weight: 400; text-decoration: none; color: inherit;"
            >
              {{ video }}
            </a>
            <div style="padding-left:12px; font-size: 12px; font-weight:600">
              Books
            </div>
            <div style="padding-left:12px; font-size: 12px; font-weight:400" v-for="(book, index) in skill.recommendedReadings.books">
              {{ book }}
            </div>
          </div>
        </div>
      </div>
    </div> 
 </div>
 </div>
</template>
<script>
import AuthService from "../services/AuthService";
import Notification from "./Notification";
import CampaignController from "@/controllers/CampaignController";
import AssessmentController from "@/controllers/AssessmentController";
import { GChart } from "vue-google-charts/legacy";
import VueHtml2pdf from "vue-html2pdf";
import html2pdf from "html2pdf.js";
import PieChart from "../components/ReportGraphs/PieChart.vue";
import TimeChart from "../components/ReportGraphs/TimeChart.vue";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.js";
import VueApexCharts from "vue-apexcharts";
import { cloneDeep } from "lodash";


export default {
  components: {
    GChart,
    PieChart,
    TimeChart,
    Notification,
    VueHtml2pdf,
    VueApexCharts,
  },
  data() {
    return {
      currentDate: '',
      totalPages: 0,
      userInfo: {},
      answerProfile: [],
      scoreProfile: {},
      skillsAnalysis: [],
      recommendationLinks: [],
      scoreDistributionChartData: [
        {
          name: 'Screening/Mains Score',
          data: [],
        },
      ],
      scoreDistributionChartOptions: {
        chart: {
          height: 350,
          type: 'radar',
          toolbar: {
            show: false,
          },
        },
        title: {
          text: 'Skillset Distribution',
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              colors: ["#000000DE","#000000DE","#000000DE","#000000DE","#000000DE","#000000DE"],
              fontSize: "12px",
            }
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#06C270"],
          dashArray: 0,
          lineCap: 'butt',
        },
        markers: {
          size:0,
        },
        fill: {
          color: ["#06C270"],
          opacity: 0.20,
        },
        dataLabels: {
          enabled: true,
          background:{
            enabled: false,
          },
          style: {
            colors: ["#212121"],
            fontWeight: 400,
          },
        },
        yaxis: {
          max: 10,
          min: 0,
          tickAmount: 5,
          labels: {
            show: true,
            formatter: function(val) {
              return val
            },
            style: {
              colors: "#666666",
              backgroundColor: "#FBDDC2",
            },
          }
        },
      },
      overallAssessmentChartData: [], // [Total Questions, Attempted, Correct]
      overallAssessmentChartOptions: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "20%", // Adjust the inner circle size
            },
            track: {
              background: "#e7e7e7", // Color for the unfilled track
              strokeWidth: "100%",
            },
            dataLabels: {
              show: true, // Enable data labels
              name: {
                show: false, // Hide names (e.g., "Correct", "Attempted")
              },
              value: {
                show: true, // Always show percentage values
                fontSize: "14px", // Adjust font size for better visibility
                formatter: function (val) {
                  return `${val}%`; // Display the percentage
                },
              },
            },
          },
        },
        colors: ["#1B72E8", "#B27CC4", "#06C270"], // Colors for Total, Attempted, Correct
        labels: ["Total Questions", "Attempted", "Correct"],
        legend: {
          show: true,
          position: "right", // Legend aligned to the right
          horizontalAlign: "center",
          fontSize: '14px',
          markers: {
            shape: "square",
          },
        },
      },
      donutChartOptions: {
        chart: {
          type: 'donut',
          height: 200,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '55%',
              offsetX: -30,
              hollow: {
                size: '45%',
              },
              labels: {
                show: true,
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          style: {
            fontSize: '14px',
            fontWeight: 400,
            colors: ['#212121']
          },
          dropShadow: {
            enabled: false
          }
        },
        legend: {
          show: true,
          position: 'right',
          fontSize: '14px',
          fontWeight: 400,
          horizontalAlign: 'center',
          markers: {
            width: 20,
            height: 12,
            shape: 'square',
            radius: 1,
          },
          labels: {
            colors: '#212121',
            useSeriesColors: false
          },
          itemMargin: {
            horizontal: 10,
            vertical: 5
          }
        },
        stroke: {
          show: false
        },
        colors: ['#06C270', '#F77CAE'],
        labels: ['Strengths', 'Areas of Improvement'],
      },
      skillTitleCardMap:{
        'Core Skills': {
          skillName: 'Core Skills',
          text:'Core skills are essential abilities for teaching, that reflect a strong understanding and mastery of the teaching subject.',
          backgroundColor: 'core-skills-background',
          borderGradient: 'core-skills-card-border',
          isTitleCard: true,
        },
        'Communication Skills': {
          skillName: 'Communication Skills',
          text: 'Communication skills involve the ability to explain concepts clearly, listen actively, provide feedback, and create an engaging environment for learning.',
          backgroundColor: 'communication-skills-background',
          borderGradient: 'communication-skills-card-border',
          isTitleCard: true,
        },
        'Digital Literacy': {
          skillName: 'Digital Literacy',
          text: 'Digital literacy in teaching is the ability to use technology effectively to enhance learning and support students in using digital tools responsibly.',
          backgroundColor: 'digital-literacy-background',
          borderGradient: 'digital-literacy-card-border',
          isTitleCard: true,
        },
        'Pedagogy':{
          skillName: 'Pedagogy',
          text: 'Pedagogy refers to the art and science of teaching, encompassing the methods, strategies, and practices used to deliver knowledge, foster understanding, and promote effective learning among students.',
          backgroundColor: 'pedagogy-background',
          borderGradient: 'pedagogy-card-border',
          isTitleCard: true,
        },
        'Psychometry':{
          skillName: 'Psychometry',
          text: 'Psychometry uses psychological assessments to measure an individual’s abilities, traits, and behavior, helping inform decision-making and personal development.',
          backgroundColor: 'psychometry-background',
          borderGradient: 'psychometry-card-border',
          isTitleCard: true,
        }
      },
      allSections:[],
      skillsSections: [],
      subjectsSubSection:[],
      yourScoreImage: require('@/assets/user-score.svg'),
      highestScoreImage: require('@/assets/highest-score.svg'),
      performanceReportCoverImage: require('@/assets/CoverPage.svg'),
      understandYourReport:  require('@/assets/understandYourReport.svg'),
      skillTitleCardBackgroundImage: require('@/assets/SkillTitleCardBackground.svg'),
      userPerformanceReportHeaderImage: require('@/assets/performanceReportHeader.svg'),
      userPerformanceReportFooterImage: require('@/assets/performanceReportFooter.svg'),
      userAvatarPlaceholder: require('@/assets/userAvatarPlaceHolder.svg'),
      goldLeagueIcon: require('@/assets/gold.svg'),
      silverLeagueIcon: require('@/assets/silver.svg'),
      bronzeLeagueIcon: require('@/assets/bronze.svg'),
      isVgosTeacher:false,
      subjects:[],
      levels: [],
      userEmail: '',
      userPhone: '',
      normalizedStrandsScore: {},
      proctoringPic: "",
      psychometryScoreMap : new Map([
                ["Self-Management", new Map([
                    [this.withinScoreRange(0, 7), {score: 1, feedback:"Likely to need significant assistance with adaptability, behavior monitoring, and emotional regulation. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(8,15), {score: 2, feedback:"Likely to demonstrate some adaptability, behavior monitoring, and emotional regulation skills, with room for improvement."}],
                    [this.withinScoreRange(16,21), {score: 3, feedback:"Likely to show proficiency in adapting to change, consistent behavior monitoring, and effective emotional regulation."}],
                    [this.withinScoreRange(22,25), {score: 4, feedback:"Likely to demonstrate exceptional adaptability, effective behavior monitoring, and strong emotional regulation skills."}]
                ])],
                ["Relationship Skills", new Map([
                    [this.withinScoreRange(0,7), {score: 1, feedback:"Likely to need significant assistance in building effective relationships, engaging in collaborative efforts, and demonstrating emotional maturity. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(8,15), {score: 2, feedback:"Likely to demonstrate some ability in collaboration, interpersonal skills, and emotional maturity, with inconsistent application."}],
                    [this.withinScoreRange(16,21), {score: 3, feedback:"Likely to show proficiency in collaboration, interpersonal skills, and emotional maturity, with potential for further growth."}],
                    [this.withinScoreRange(22,25), {score: 4, feedback:"Likely to build and maintain effective collaborations, demonstrate emotional maturity, and engage positively in interpersonal dynamics."}]
                ])],
                ["Social Awareness", new Map([
                    [this.withinScoreRange(0,9), {score: 1, feedback:"Likely to need significant assistance with organizational awareness, emotional perception, and empathy. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(10,19), {score: 2, feedback:"Likely to demonstrate basic organizational awareness, emotional perception, and empathy, with inconsistencies in application."}],
                    [this.withinScoreRange(20,25), {score: 3, feedback:"Likely to show good organizational awareness, emotional perception, and empathetic concern, fostering positive interactions."}],
                    [this.withinScoreRange(26,30), {score: 4, feedback: "Likely to display exceptional organizational awareness, accurate emotional perception, and genuine empathetic concern."}]
                ])],
                ["Responsible decision making", new Map([
                    [this.withinScoreRange(0,5), {score: 1, feedback:"Likely to need significant assistance with ethical reasoning, reasoning skills, and critical thinking. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(6,11), {score: 2, feedback:"Likely to show potential in ethical reasoning, reasoning skills, and critical thinking, but application is inconsistent in complexity."}],
                    [this.withinScoreRange(12,16), {score: 3, feedback:"Likely to demonstrate proficiency in ethical decision-making, reasoning, and critical thinking, with occasional need for further analysis."}],
                    [this.withinScoreRange(17,20), {score:4, feedback:"Likely to consistently apply ethical reasoning, critical thinking, and effective decision-making skills in complex situations."}]
                ])],
                ["Personality", new Map([
                    [this.withinScoreRange(0, 7), {score: 1, feedback:"Likely to need significant assistance with developing personality traits that support effective interactions and performance. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(8,15), {score:2 , feedback:"Likely to show potential in exhibiting positive personality traits, but consistency and depth of application vary."}],
                    [this.withinScoreRange(16,21), {score: 3, feedback:"Likely to demonstrate positive personality traits that support effective interactions and performance, with areas for enhancement."}],
                    [this.withinScoreRange(22,25), {score:4, feedback:"Likely to exhibit conscientiousness, emotional stability, extraversion, openness to experience, and positively influence interactions."}]
                ])],
                ["Self-Awareness", new Map([
                    [this.withinScoreRange(0,9), {score: 1, feedback: "Likely to demonstrate limited self-awareness and understanding of personal emotions, impacting overall self-awareness growth. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(10,19), {score: 2, feedback: "Likely to display basic self-awareness and understanding of personal emotions, with potential for further development."}],
                    [this.withinScoreRange(20,25), {score: 3, feedback: "Likely to show strong self-motivation, awareness of strengths and weaknesses, and consistent pursuit of personal goals."}],
                    [this.withinScoreRange(26,30), {score: 4, feedback: "Likely to demonstrate proactive initiative, optimism, and a deep understanding of self-concept and emotions."}]
                ])]
            ]),
      interviewDetails: null,
      preloader: true,
      userData: {},
      currenrSkillIndex: 0,
      selectedUserName: "",
      totalScore: 0,
      downloadLoader:false,
      userPerformanceReportDownloadLoader: false,
      downloadPsychometryLoader:false,
      disableDownload:true,
      disablePsychometryDownload:false,
      progressLevel: 0,
      userTime: 0,
      maxTime: 0,
      maxMarks: 10,
      campName: "",
      audienceId: null,
      userId: null,
      campaignId: null,
      notificationDialog: false,
      isSelect:0,
      selectedSkill: {},
      userName: "",
      selectedResult: {},
      proctoringData: {
        "Copy/Paste": 0,
        "Exit Fullscreen": 0,
        Screenshot: 0,
        "Switch Tab/Browser": 0,
      },
      selectedUser: "",
      proctorDialog: false,
      userProctoringActivities: [],
      cumulativeProctoringData: [["Activity", "Count", { role: "style" }]],
      selectedUserName: "",
      dataDemoVideos: {},
      campaignStages:{},
      bubbleChartOptions: {
        title: "",

        hAxis: {
          title: "Time (min)",
          format: 0,
          viewWindow: {
            min: 0,
            max: 10,
          },
        },
        vAxis: {
          title: "Ques",
          format: 0,
          viewWindow: {
            min: 0,
            max: 10,
          },
        },

        sizeAxis: {
          minSize: 5,
          maxSize: 5,
        },

        // legend: 'none',
      },

      bubbleChartData: [
        ["Id", "Time (min)", "Ques", "Activity", "Time Stamp"],
        // ["Screenshot", 15, 1, "03:15:47"],
        // ["Screenshot", 20, 2, "03:16:47"],
        // ["Screenshot", 25, 3, "03:17:47"],
        // ["Screenshot", 30, 4, "03:18:47"],
      ],

      // barChartData: this.cumulativeProctoringData,

      barChartOptions: {
        title: "",
        titleTextStyle: {
          fontSize: 12,
        },
        hAxis: {
          title: "Count",
          format: 0,
          gridlines: {
            color: "transparent",
          },
          viewWindow: {
            min: 0,
          },
        },
        vAxis: {
          title: "Activity",
        },
        legend: "none",
      },
      selectedSkillName: null,
      hasMains: false,
      hasDemo: false,
      hasInterview: false,
      userType: "",
      leagueData:[],
      userReportData:{},
      resultData:[],
      crownColorMap: {
                gold: 'rgba(219, 212, 36, 1)',
                silver: 'rgb(192, 192, 192)',
                bronze: 'rgba(174, 173, 145, 1)',
                bonze: 'rgba(174, 173, 145, 1)'
            },
      firstTestName:'SCREENING'

    };
  },
  watch: {
    search(newValue) {
      console.log(newValue);
      if (newValue == "" || newValue == null) {
        this.users = this.calculateUsersForLeague(this.preSearchUser);
      } else {
        this.searchData(newValue);
      }
    },

  },
  computed:{
        psychometryReportFileName(){
            let userName = this.userName.split(" ");
            console.log('userName',userName)
            let firstName = userName[0];
            let lastName = userName[1];
            return `${firstName}_${lastName}_${this.levels[0]}_psychometry_report`
        }
  },
  async mounted() {
    const id = this.$route.params.campaignId;
    this.getCampaignInfo(id)
    // this.fetchCampaignAudience(id);

      this.isSelect = [0,1].includes(this.$route.query.isSelect)?  Number(this.$route.query.isSelect) : 0;
  },
  async created() {
    this.getParams();
    await this.getResult();
    this.preloader = false;
    await this.getReportData();
    this.isVgosTeacher ? await this.getProcDetailsForTeacher("MAINS"): "";

 
  
  },
  methods: {
    getLabelStyle(index) {
      const positions = [
        { top: "25%", left: "30%" }, // Position for Total Questions
        { top: "33%", left: "32%" }, // Position for Attempted
        { top: "40%", left: "35%" }, // Position for Correct
      ];
      return {
        position: "absolute",
        zIndex: 100, // Ensure the label appears above the chart
        top: positions[index].top,
        left: positions[index].left,
        transform: "translate(-50%, -50%)",
        fontSize: "12px",
        color: index==0?"white": "#FFFFFFF",
        fontWeight: 500,
      };
    },
    wrapPageContent(content, pageNumber, isIndexPage) {
      const wrapperTemplate = this.$refs.wrapperTemplateUserPerformanceReport.innerHTML;
      let wrappedPageContent = wrapperTemplate.replace('userPerformanceReportContent', content);
      wrappedPageContent = wrappedPageContent.replace('userPerformanceReportPageNumber', isIndexPage?"":pageNumber)
      return wrappedPageContent;
    },
    async generateUserPerformanceReport() {
      this.userPerformanceReportDownloadLoader = true;
      try {
        // Get all pages content
        const userPerformanceReportCoverPage = this.$refs.userPerformanceReportCoverPage;
        const recommendationLinksTemplate = this.$refs.recommendationLinksTemplate;
        const initialPages = [
          this.$refs.userPerformanceReportIndex,
          this.$refs.understandYourReport,
          this.$refs.userPerformanceReport1,
          this.$refs.userPerformanceReport2,
          this.$refs.userPerformanceReport3,
        ];
        const skillsPages = Array.from(
          document.querySelectorAll('div[aria-label^="skills-"]')
        );

        const allPages = [...initialPages, ...skillsPages, recommendationLinksTemplate];
        this.totalPages = allPages.length + 1;

        // creating an array to represent all content and their page numbers in the UserPerformanceReport as sections
        const sections = this.createSections(allPages);

        // wait for DOM updates
        await this.$nextTick();

        // create temporary container
        const container = document.createElement('div');
        // adding cover page
        container.innerHTML += userPerformanceReportCoverPage.outerHTML;

        // wrapping contents of each page with header and footer templates
        allPages.forEach((page, index) => {
          let isIndexPage = false;
          if(index == 0)isIndexPage=true;

          container.innerHTML += this.wrapPageContent(page.outerHTML, index, isIndexPage);
        });
        
        // pdf config
        const options = {
          margin: 0,
          filename: 'performance-report.pdf',
          image: { 
            type: 'jpeg', 
            quality: 0.98 
          },
          html2canvas: { 
          scale: 2,
          useCORS: true,
          logging: false,
          letterRendering: true,
          windowHeight: 11 * 96,
          windowWidth: 8.5 * 96,
        },
          jsPDF: {
            unit: 'in',
            format: 'letter',
            orientation: 'portrait',
            compress: true
          },
          pagebreak: { 
            mode: ['avoid-all', 'css', 'legacy']
          }
        };

        // Generate PDF
        await html2pdf()
          .set(options)
          .from(container)
          .save();

      } catch (error) {
        console.error('PDF generation error:', error);
      } finally {
        this.userPerformanceReportDownloadLoader = false;
      }
    },
    createSections(allPages){
      this.allSections = [];
      let sections = allPages.map((page, index) => ({
          title: page.getAttribute('data-section-title') || `Section ${index + 1}`,
          pageNumber: index,
          considerPageNumber: !!page.getAttribute('data-section-title'), //data-* is only defined for skills & subjects
          isCoreSkill: !!page.getAttribute('data-section-title')?.includes("Subject"),
        }));
        //retain sections objects for the lowest page number among sections objects of the same title
        let seenTitles = new Set();
        sections.forEach((section) => {
          if (!seenTitles.has(section.title)) {
            this.allSections.push(section);
            seenTitles.add(section.title);
          }
        });

        // set subjects & skills sections
        this.skillsSections = this.allSections.filter(item=>!item.isCoreSkill && item.considerPageNumber);
        this.subjectsSubSection = this.allSections.filter(section => section.isCoreSkill);
        return sections;
    },
    async getPerformanceReport(){
      const latestUserAssessment = this.resultData.slice(0, 2).at(-1)
      const latestTestType = latestUserAssessment.testType;
      try {
        const response = await CampaignController.getPerformanceReport(this.userId, this.campaignId, latestTestType);
        if(response.flag){
          const {
            userInfo,
            answerProfile,
            scoreProfile,
            skillsAnalysis,
            recommendationLinks
            } = response.data;

            this.userInfo = userInfo;
            userInfo.skillsToShow = [...userInfo.subjects, ...userInfo.skills.filter(skill=>skill != "Core Skills")]
            this.answerProfile = answerProfile;
            this.scoreProfile = scoreProfile;
            this.skillsAnalysis = this.formatSkillsAnalysisForReport(skillsAnalysis);
            this.recommendationLinks = this.formatRecommendationLinksForReport(recommendationLinks);

            this.currentDate = this.getCurrentDate();
            this.overallAssessmentChartData = [
                100, 
                Math.round(this.getPercentage(answerProfile.answeredQuestions, answerProfile.totalQuestions)), 
                Math.round(this.getPercentage(answerProfile.correctAnswers, answerProfile.totalQuestions))
              ] // [Total Questions%, Attempted%, Correct%];
            this.scoreDistributionChartData[0].data = skillsAnalysis.map(skill=>this.normalizeToTen(skill.score, skill.maximumMarks).toFixed(2));
            this.scoreDistributionChartOptions.xaxis.categories = skillsAnalysis.map(skill=>skill.skillName);
        }
      }
      catch(error){
        console.error(error);
      }
    },
    formatSkillsAnalysisForReport(skillsAnalysis) {
      let distributedSkills = this.getDistributedSkills(skillsAnalysis);
      let coreSkills = distributedSkills.filter(({isCoreSkill})=>isCoreSkill);
      let otherSkills = distributedSkills.filter(({isCoreSkill})=>!isCoreSkill);
      
      let formattedOtherSkills = []
      otherSkills.forEach((skill)=>{
        formattedOtherSkills.push(...[this.skillTitleCardMap[skill.skillName], skill]);
      })
      const indexofPsychometry = formattedOtherSkills.findIndex(skill=>!skill.isTitleCard && skill.skillName == 'Psychometry');
      const psychometryData = formattedOtherSkills.splice(indexofPsychometry, 1)[0];
      //distributing psychometry strands into three seperate psychometry clone objects, ensuring that only two strand  are rendered per page.
      formattedOtherSkills.push(...this.splitPsychometryStrands(psychometryData, 2));

      return [this.skillTitleCardMap['Core Skills'],...coreSkills,...formattedOtherSkills];
    },
    getDistributedSkills(skills) {
      let distributedSkills = [];
      skills.forEach(skill => {
        if(skill.skillName !== 'Psychometry'){
          distributedSkills.push(...this.distributeStrengthWeaknessForSkill(skill, 10));
        }
        else{
          distributedSkills.push(skill);
        }
      })
      return distributedSkills;
    },
    distributeStrengthWeaknessForSkill({ strengths = [], weaknesses = [], ...rest }, maxPerPage) {
      const firstPageStrengths = strengths?.slice(0, maxPerPage);
      const remainingStrengths = strengths?.slice(maxPerPage);
      const weaknessesOnFirstPage = weaknesses?.slice(0, maxPerPage - firstPageStrengths?.length);
      const remainingWeaknesses = weaknesses?.slice(maxPerPage - firstPageStrengths?.length);

      const firstPage = {
          isFirstSkillsPage: true,
          totalStrengths: strengths?.length,
          totalWeaknesses: weaknesses?.length,
          strengths: firstPageStrengths,
          weaknesses: weaknessesOnFirstPage,
          strengthsInLastPage: 0,
          weaknessesInLastPage: 0,
          ...rest
      };
      const secondPage = {
          strengths: remainingStrengths,
          weaknesses: remainingWeaknesses,
          strengthsInLastPage: firstPageStrengths.length,
          weaknessesInLastPage: weaknessesOnFirstPage.length,
          ...rest
      };

      if(remainingWeaknesses?.length == 0 && remainingStrengths?.length == 0) return[firstPage] 
      else return [firstPage, secondPage];
    },
    splitPsychometryStrands(psychometryData, strandsPerPage){
      let splitStrands = [];
      let strands = Object.entries(psychometryData.psychometryAnalysis.strands);
      for (let i=0; i < strands.length; i += strandsPerPage) {
        const clonedPsychometryData = cloneDeep(psychometryData);
        clonedPsychometryData.isFirstPage = i == 0;
        clonedPsychometryData.psychometryAnalysis.strands = Object.fromEntries(strands.slice(i, i + strandsPerPage));
        splitStrands.push(clonedPsychometryData);
      }
      return splitStrands;
    },
    formatRecommendationLinksForReport(recommendationLinks){
      return Object.entries(recommendationLinks).map(skill=>{
        const skillName = skill[0];
        const recommendedReadingsForSkill = skill[1];
        return {
          skillName: skillName,
          recommendedReadings: this.getConsolidatedRecommendedReadings(recommendedReadingsForSkill),
          backgroundColor: this.skillTitleCardMap[skillName]?.backgroundColor,
        }
      })
    },
    getConsolidatedRecommendedReadings(recommendedReadingsForSkill){
        return recommendedReadingsForSkill.reduce(
          (result, item) => {
            result.books.push(...item?.recommendedReadings?.books)
            result.videos.push(...item?.recommendedReadings?.videos)
            return result;
          },
          { books: [], videos: [] }
        );
    },
    padWithZero(number){
      return number < 10 ? `0${number}` : `${number}`;
    },
    getPercentage(value, total){
      return (value/total)*100;
    },
    normalizeToTen(score, maxScore) {
      if (maxScore === 0) {
        throw new Error("maxScore for skill cannot be zero.");
      }
      return (score / maxScore) * 10;
    },
    getCurrentDate(){
      const date = new Date();
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'long' });
      const year = date.getFullYear();
      return `${day}${this.getOrdinalSuffix(day)} ${month} ${year}`;
    },
    getOrdinalSuffix(day){
      if (3 > day && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    },
    calculatePosition(value, total){
      let position = (value / total) * 100;
      return position > 90 ? 90 : position < 5 ? 5 : position;
    },
    getLeagueIcon(league){
      const leagueMap = new Map([
        ['gold', this.goldLeagueIcon],
        ['silver', this.silverLeagueIcon],
        ['bronze', this.bronzeLeagueIcon],
        ['bonze', this.bronzeLeagueIcon],
      ])
      return leagueMap.get(league.toLowerCase());
    },
  async getInterviewDetails(){
      try {
          const response = await CampaignController.getInterviewReport(this.userId, this.campaignId);
          if (response.data?.result) {
              this.interviewDetails = response.data?.result?.interviewDetails;
          } else {
              console.log('response data error...', response.data.error)
          }
      } catch (err) {
          console.log(err);
      }
  },
  withinScoreRange(min, max){
      return score => { return (min <= score && score <= max)}
  },
  strandScoreSum(strand){
      let strandScores = strand.map(question => 
          question.myAnswer != undefined?
            (question.questionOptions.filter(opt=>opt.optionKey === question?.myAnswer[0]).pop()?.optionScore) : 0
      );
      return strandScores.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
  },
  camelCase(str) {
      str = str.toLowerCase().replace(/-/g, ' ');
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
  },
  onProgress(event) {
      if (event === 100) {
          this.downloadLoader = false;
          this.downloadPsychometryLoader = false;
      }
  },
  generateReport() {
   try{
    this.downloadLoader = true;
    this.$nextTick(()=>{
      this.$refs.html2Pdf.generatePdf()
      })
   }
   catch(err){
    console.log(err)
   }
  },
  generatePsychometryReport() {
      this.downloadPsychometryLoader = true;
      this.$refs.html2Pdfpsychometry.generatePdf();
  },
  
  selectRating(rating, ratingValue) {
      switch (rating) {
          case 1:
          return ratingValue >= 1
              ? "interviewRate1 white--text"
              : "interviewRate";
          case 2:
          return ratingValue >= 2
              ? "interviewRate4"
              : "interviewRate";
          case 3:
          return ratingValue >= 3
              ? "interviewRate6"
              : "interviewRate";
          case 4:
          return ratingValue >= 4
              ? "interviewRate9"
              : "interviewRate";
          default:
          return "interviewRate";
      }
  },
  selectBackgroundColor(score, setBorderStyle = false){
      let backgroundColorMap = new Map([
          [1, setBorderStyle? "primary-box-red" : "secondary-box-red"],
          [2, setBorderStyle? "primary-box-orange": "secondary-box-orange"],
          [3, setBorderStyle? "primary-box-yellow": "secondary-box-yellow"],
          [4, setBorderStyle? "primary-box-green": "secondary-box-green"]
      ])
      return backgroundColorMap.get(score);
  },
    toggleProctoringData(newValue) {
      let testType = "SCREENING";

      if (newValue == 1) {
        testType = "MAINS";
      } else {
        testType = "SCREENING";
      }
      this.getProcDetailsForTeacher(testType)
    },
    async getCampaignInfo(){
      let query = "?id="+this.campaignId;
      const response = await CampaignController.getCampaign(
          query
        );
      console.log('campaign response',response)
      if(response.status == 200){
        this.campaignStages = response.data.data;
        if(this.campaignStages.stages.length > 0){
          this.isVgosTeacher = true;
        }
      }
    },
    getPsychometricEvaluation(score, maxScore) {
      const percentage = (score / maxScore) * 100;
      let interpretation = {};
      if (percentage <= 25) {
        interpretation = {
          message:
            "Below Average - The participant has below average abilities in psychometric and socio-emotional dimension. Thourough validation is recommended during subsequent stage of the recruitment process before decision of hiring is taken.",
          color: "#F4CCCC",
        };
      } else if (percentage > 25 && percentage <= 50) {
        interpretation = {
          message:
            "Average - The participant has average abilities in psychometric and socio-emotional dimension. Further validation is recommended during subsequent stages of the recruitment process.",
          color: "#FFF2CC",
        };
      } else if (percentage > 50 && percentage <= 75) {
        interpretation = {
          message:
            "Appropriate - The participant has appropriate abilities in psychometric and socio-emotional dimension. He/she is recommended for the position and may required some scaffolding as per the need of the school",
          color: "#D0E0E3",
        };
      } else {
        interpretation = {
          message:
            "Excellent - The participant has excellent abilities in psychometric and socio-emotional dimension. He/she is best suited for the position and will be the asset to the school",
          color: "#D8EAD3",
        };
      }
      return interpretation;
    },
   async getProcDetailsForTeacher(testType){
      const testRef = doc(
        db,
        "proctoredtests",
        this.campaignId,
        this.userId,
        testType
      );
      const test = await getDoc(testRef);
      
      // Reset proctoring data
        this.proctoringData = {
          "Copy/Paste": 0,
          "Exit Fullscreen": 0,
          Screenshot: 0,
          "Switch Tab/Browser": 0,
        };
        if (this.userProctoringActivities?.length > 0) {
          this.userProctoringActivities.splice(0);
        }

        if (this.cumulativeProctoringData?.length > 1) {
          this.cumulativeProctoringData.splice(1);
        }

      if (test.exists() && test.data().proctoringType !== "NONE") {
        const activities = test.data().activities || [];
        
        for (const temp of activities) {
          if (temp.activity in this.proctoringData) {
            this.proctoringData[temp.activity] += 1;
          }
          this.userProctoringActivities.push(temp);

          if (temp.activity !== "Started" && temp.activity !== "Completed") {
            let flag = true;
            for (let i = 0; i < this.cumulativeProctoringData.length; i++) {
              // if activitiy already exists
              if (this.cumulativeProctoringData[i][0] === temp.activity) {
                this.cumulativeProctoringData[i][1] += 1;
                flag = false;
                break;
              }
            }
            // if a new activity is found
            if (flag) {
              this.cumulativeProctoringData.push([
                temp.activity,
                1,
                this.generateRandomColor(),
              ]);
            }
          }
        }
        this.addDataToChart();
      } else {
        console.log("No proctoring data available or proctoring type is NONE");
      }
    },
    isAnswerCorrect(myAnswer, correctAns) {
      if (myAnswer && correctAns) {
        correctAns = correctAns.split(',').filter(answer => /^[A-Ea-e]$/.test(answer)).join(',');
        var sortedStr = correctAns.split(",").sort().join("");
        myAnswer.sort();
        return myAnswer.join("") === sortedStr ? true : false;
      } else {
        return false;
      }
    },
    getParams() {
      this.campaignId = this.$route.params.campaignId;
      this.userId = this.$route.params.userId;
      console.log("campaignId..?", this.campaignId);
      console.log("userId..?", this.userId);
    },

    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async getReportData() {
            try {
                
                const response = await CampaignController.getReportData(this.userId, this.campaignId);
                if (response.data?.result) {
                    this.userReportData = response.data.result;
                    this.selectedUserName = this.userReportData?.name;
                    this.campaignName = this.userReportData?.campaignName;
                    this.userType = this.userReportData.userType.toLowerCase();
                    this.hasMains = this.userType == 'teacher'? this.resultData.length>0 : this.resultData.length>1;
                    this.subjects = this.userReportData.coreSkillsSubject.map(subject=>subject.subjectName)
                    this.leagueData = this.userReportData.coreSkillsSubject;
                    
                    if(response.data?.result?.proctoringPic){
                        this.proctoringPic = response.data?.result?.proctoringPic
                    } 
                    
                    this.userName = this.userReportData.name;
                    this.userEmail = this.userReportData.email;
                    this.userPhone = this.userReportData.phone;
                    this.schoolName = Array.isArray(this.userReportData.schoolName) ? this.userReportData.schoolName.join(',') : this.userReportData.schoolName;
                    this.displaySchoolName = Array.isArray(this.userReportData.schoolName) ? this.userReportData.schoolName.join(',').substr(0, 100) + '..' : this.userReportData.schoolName;
                    if(typeof this.userReportData.levels == 'string'){
                        this.levels.push(this.userReportData.levels)
                    } else {
                        this.levels = this.userReportData.levels
                    }
                      
                    this.mainsUserScore = this.userReportData.mainsTest.netScore;
                    
                    this.mainsTestData = this.userReportData.mainsTest
                    

                } else {
                    console.log('response data error...', response.data.error)
                    alert("Something went wrong");
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.preloader = false;
            }
        },
    async getResult() {
      try{
        console.log(
          "Seeing here all the data...????",
          this.campaignId,
          this.userId
        );
        // const docRef = doc(db, "users", this.userId);
        // const docSnap = await getDoc(docRef);

        // if (docSnap.exists()) {
        //   console.log("Document data:", docSnap.data());
        //   this.userData = docSnap.data();
        // } else {
        //   console.log("No such document!");
        // }

        const response = await CampaignController.getResult(this.userId,this.campaignId);
        console.log("report response..?", response);
        if (response.status == 200) {
          if (response.data.data.length == 0) {
            alert("User test not Started");
            // this.btnLoading = false;
          } else {
            this.resultData = response.data.data;
            await this.getPerformanceReport();
            this.firstTestName = this.resultData[0].testType;
            this.hasInterview = this.resultData.length > 3;
            this.hasDemo = this.resultData.length > 2;
            this.questionDialog = true;
            this.switchResult(this.isSelect);
            this.selectedUser = this.userId;
            // this.selectedUserName = `${this.userData.personalInfo.firstName} ${(this.userData.personalInfo.middleName || '')} ${this.userData.personalInfo.lastName}`;
            this.bubbleChartOptions.title = this.selectedUserName;

            if( this.hasInterview && !this.isVgosTeacher){
            await this.getInterviewDetails();
           }
            if( this.resultData.length > 1 || (this.resultData.length == 1 && this.isVgosTeacher == true)) {
                let mainsData  = this.resultData.filter(item=>item.testType=='MAINS').pop();
                let psychometryData = mainsData.skills.filter(item=>item.skillName=='Psychometry').pop();
                let questionsData = psychometryData.questions;
                            
                let strandsData = Object.groupBy( questionsData , ({strand})=>strand)
                let strandsScore = {};
                for (const strand in strandsData){
                    strandsScore[strand] = this.strandScoreSum(strandsData[strand]);
                }
                for (const strand of Object.keys(strandsScore)){
                    if(this.psychometryScoreMap.has(strand)){
                        const score = strandsScore[strand];
                        for (const [checkScoreRange,newScore] of this.psychometryScoreMap.get(strand)){
                            if (checkScoreRange(score)){
                                this.$set(this.normalizedStrandsScore, this.camelCase(strand), newScore);
                                break;
                            }
                        }
                    }
                }
                this.disablePsychometryDownload = false;
                }
  
          }
        } else {
          alert(response.data.error);
        }
      } catch(err){
        console.log(err);
      } finally{
        this.preloader=false;
      }
    },

    goBack() {
      if (window.opener) {
        window.close();
      } else {
        this.$router.back();
      }
    },
    goToCampaigns() {
      if (window.opener) {
        window.opener.location.href = '/#/campaign';
        window.close();
      } else {
        this.$router.push('/#/campaign');
      }
    },
    generateRandomColor() {
      const minRed = 255;
      const maxRed = 255;
      const minGreen = 255;
      const maxGreen = 0;
      const minBlue = 0;
      const maxBlue = 0;

      const randomRed = Math.floor(
        Math.random() * (maxRed - minRed + 1) + minRed
      );
      const randomGreen = Math.floor(
        Math.random() * (maxGreen - minGreen + 1) + minGreen
      );
      const randomBlue = Math.floor(
        Math.random() * (maxBlue - minBlue + 1) + minBlue
      );

      const randomColor = `rgb(${randomRed},${randomGreen},${randomBlue})`;

      return randomColor;
    },

    demoReport() {
      // console.log("data demo videos...???", this.dataDemoVideos);
      // this.dataDemoVideos.sort((x, y) => x.date.localeCompare(y.date));

      // this.dataDemoVideos = this.dataDemoVideos[this.dataDemoVideos.length - 1]
      // localStorage.setItem("videoInfo", JSON.stringify(this.dataDemoVideos))
      // this.$store.commit("setVideoDetails", this.dataDemoVideos)

      this.$router.push(`/demoreport/${this.campaignId}/${this.userId}`);
      // this.demoDialog = true
    },


    showProc() {
      if (this.bubbleChartData.length > 1) {
        this.proctorDialog = true;
      } else {
        alert("Proctoring data not found");
      }
    },

    async switchResult(index) {
      if(index == undefined){
        return;
      }
      this.$router.replace({query:{isSelect:index}})
      if ([0,1].includes(index)) {
        this.isSelect = index;
        !this.isVgosTeacher ? await this.toggleProctoringData(index) : await this.getProcDetailsForTeacher("MAINS");
        this.selectedResult = this.resultData[index];
        this.totalScore=this.selectedResult?.netScore

        this.maxTime = this.selectedResult.maximumTime / 60;
        this.userTime = (this.selectedResult.timeTakenByUser / 60).toFixed(2);
        if (this.selectedResult == null) {
          alert("Test not started yet");
          this.switchResult(0);
        } else {
            this.selectedSkill = this.resultData[index]?.skills[0];
            this.selectedSkillName = this.selectedSkill.skillName
            
            this.progressLevel =
              (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100;
            let promises = this.selectedResult.skills.map(async (skill) => {
              await skill.questions.map(async question=>{
                if (question?.myAnswer) {
                  question.currectAns = this.isAnswerCorrect(
                    question.myAnswer,
                    question.correctAnswer
                  );
                }
                if(question.questionAssetUrl !=="NA" && question.skill !== "Psychometry"){
                  await this.resolveQuestionAssetUrl(question);
                  question.mediaType = 'IMG';
                }
                await question.questionOptions.map(async option=>{
                  if(option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')){
                    await this.resolveQuestionOptionUrl(option);
                  }
                })
              });
            });
            await Promise.all(promises);
          }
      } else if (index === 2) {
        this.demoReport();
      }else if (index === 3) {
        this.$router.push(`/interviewReport/${this.campaignId}/${this.userId}`);
      }
    },

    addDataToChart() {
      this.bubbleChartData.splice(1);
      
      if (this.userProctoringActivities.length === 0) {
        console.log("No proctoring activities to add to chart");
        return;
      }

      this.bubbleChartOptions.hAxis.viewWindow.max =
        parseInt(
          this.timeToMinutes(
            this.userProctoringActivities[
              this.userProctoringActivities.length - 1
            ].time
          ) - this.timeToMinutes(this.userProctoringActivities[0].time)
        ) + 1;

      this.bubbleChartOptions.vAxis.viewWindow.max = 1;

      for (const act of this.userProctoringActivities) {
        // this.bubbleChartData.push([act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time), act.time, act.activity, act.ques])
        this.bubbleChartData.push([
          act.activity,
          this.timeToMinutes(act.time) -
            this.timeToMinutes(this.userProctoringActivities[0].time),
          act.ques,
          act.activity,
          this.timeToMinutes(act.time) -
            this.timeToMinutes(this.userProctoringActivities[0].time),
        ]);

        this.bubbleChartOptions.vAxis.viewWindow.max = Math.max(
          this.bubbleChartOptions.vAxis.viewWindow.max,
          act.ques + 1
        );
      }
    },

    timeToMinutes(timeString) {
      var timeParts = timeString.split(":");
      var hours = parseInt(timeParts[0]);
      var minutes = parseInt(timeParts[1]);
      var seconds = parseInt(timeParts[2]);
      return (hours * 3600 + minutes * 60 + seconds) / 60;
    },

    getSelectedSkillData(skill, index) {
      console.log("skill :", skill);
      console.log("selectedResult :", this.selectedResult);
      this.currenrSkillIndex = index;
      this.selectedSkill = skill;
      // this.selectedSkillName = skill.skillName
      console.log("showing skills", this.selectedSkill);
      this.selectedSkill.questions.forEach((obj) => {
        if (obj.myAnswer) {
          obj.currectAns = this.isAnswerCorrect(
            obj.myAnswer,
            obj.correctAnswer
          );
        }
      });
      this.progressLevel =
        (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100;
    },

    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    // calculateUsersForLeague(users) {
    //   users.forEach((user) => {
    //     var coreSkills = [];
    //     if (user.coreSkillsSubject !== undefined) {
    //       user.coreSkillsSubject.forEach((coreSkillSubject) => {
    //         if (coreSkillSubject.result == "PASSED") {
    //           coreSkills.push(coreSkillSubject);
    //         }
    //       });
    //     }
    //     user.coreSkillsSubject = coreSkills;
    //   });
    //   return users;
    // },
    async fetchCampaignAudience(id) {
      const campaignRef = collection(db, "campaigns", id, "audience");
      const campDetailsRef = doc(db, "campaigns", id);

      const docSnap = await getDoc(campDetailsRef);

      if (docSnap.exists()) {
        // console.log("camp details here..?", docSnap.data())
        const campDetails = docSnap.data();
        this.campName = campDetails.name;
        // console.log("name of the campaign..?", this.campName)
      }

      // console.log("heyyylooooo");
      onSnapshot(campaignRef, (snapshot) => {
        this.dataTableLoading = false;
        this.users = [];
        this.preSearchUser = [];
        var preUsers = [];
        if (snapshot.empty) {
        } else {
          this.count = snapshot.docs.length;
          snapshot.docs.forEach(async (doc) => {
            // this.users.push(doc.data());
            this.preSearchUser.push(doc.data());
            preUsers.push(doc.data());
          });
          this.users = this.calculateUsersForLeague(preUsers);

          // this.preSearchUser = response.data.audience;
          // var preUsers = response.data.audience == null ? [] : response.data.audience;
          this.chartData.invited = this.filterByStatus(
            preUsers,
            "INVITED"
          ).length;
          this.invited = this.filterByStatus(preUsers, "INVITED").length;
          this.screeningStarted = this.filterByStatus(
            preUsers,
            "SCREENING STARTED"
          ).length;
          this.screeningPassed = this.filterByStatus(
            preUsers,
            "SCREENING PASSED"
          ).length;
          this.mainsStarted = this.filterByStatus(
            preUsers,
            "MAINS STARTED"
          ).length;
          this.mainsPassed = this.filterByStatus(
            preUsers,
            "MAINS PASSED"
          ).length;
          this.screeningFailed = this.filterByStatus(
            preUsers,
            "SCREENING FAILED"
          ).length;
          this.mainsFailed = this.filterByStatus(
            preUsers,
            "MAINS FAILED"
          ).length;

          this.chartData.screeningStarted = this.filterByStatus(
            preUsers,
            "SCREENING STARTED"
          ).length;
          this.chartData.screeningPassed = this.filterByStatus(
            preUsers,
            "SCREENING PASSED"
          ).length;
          this.chartData.mainsStarted = this.filterByStatus(
            preUsers,
            "MAINS STARTED"
          ).length;
          this.chartData.mainsPassed = this.filterByStatus(
            preUsers,
            "MAINS PASSED"
          ).length;
          this.chartData.screeningFailed = this.filterByStatus(
            preUsers,
            "SCREENING FAILED"
          ).length;
          this.chartData.mainsFailed = this.filterByStatus(
            preUsers,
            "MAINS FAILED"
          ).length;
          this.apraisals = this.filterByStatus(preUsers, "MAINS PASSED").length;
          this.pip = this.filterByStatus(preUsers, "MAINS FAILED").length;
          this.tbd = this.filterByStatus(preUsers, "SCREENING FAILED").length;
        }
        // console.log("this.chartData", this.chartData)
      });
    },

    async resolveQuestionAssetUrl(question) {
      let url = question.questionAssetUrl;
      if(url.startsWith('https://drive.google.com/')) {
        let fileId = null;
        if (url.indexOf('&id=') > -1) {
          fileId = url.split('&id=').pop();
        } else {
          // Regular expression pattern to extract file ID
          const pattern = /\/file\/d\/([^\\/]+)\//;
          fileId = url.match(pattern)[1];
        }
        try {
          const response = await AssessmentController.doFetchFileContentFromDriveUrl(fileId);
          if (response.status === 200) {
            question.questionAssetUrl = window.URL.createObjectURL(new Blob([response.data]));
          } else {
            question.mediaType = 'ERR';
          }
        } catch (error) {
          console.error('Error fetching file content:', error);
          question.mediaType = 'ERR';
        }
      }
    },
    imageInOption(questionOptions){
      return questionOptions.some(option=>(option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')));
    },
    async resolveQuestionOptionUrl(option) {
      let url = option.optionValue;
      if(url.startsWith('https://drive.google.com/')) {
        let fileId = null
        if(url.indexOf('&id=') > -1) {
          fileId = url.split('&id=').pop()
        } else {
          // Regular expression pattern to extract file ID
          const pattern = /\/file\/d\/([^\\/]+)\//;
          fileId = url.match(pattern)[1];
        }
        const response = await AssessmentController.doFetchFileContentFromDriveUrl(fileId);
        if(response.status === 200) {
          option.optionValue = window.URL.createObjectURL(new Blob([response.data]));
        }
      }
    },
    imgError(question){
      question.mediaType = 'OBJ'
    },
    objError(question){
      question.mediaType = 'ERR'; 
    },

    async downloadPDF() {
    this.downloadLoader = true;
    const pages = Array.from(document.querySelectorAll('div[aria-label^="pdf-page-"]'));

    // Function to process MathML elements with MathJax
    const processMathMLElements = async (element) => {
        const mathElements = element.querySelectorAll('math'); // Find all MathML elements
        for (const mathElement of mathElements) {
            const mathContent = mathElement.innerHTML.trim();
            if (mathContent) {
                // Use a div to allow MathJax to render properly
                mathElement.outerHTML = `<div style="font-size: 16px; display: inline-block;">${mathContent}</div>`;
            } else {
                mathElement.outerHTML = '<div style="font-size: 16px; color: red;">[Empty MathML]</div>';
            }
        }
        // Typeset the math elements using MathJax
        await MathJax.typesetPromise([element]);
    };

    // Process each page to handle MathML
    for (const page of pages) {
        await processMathMLElements(page);
    }

    // Wait for all MathJax typesetting to finish
    await MathJax.typesetPromise();

    // PDF options
    const pdfOptions = {
        margin: 1,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // Initialize the html2pdf worker
    let worker = html2pdf().set(pdfOptions).from(pages[0]);

    // If there are multiple pages, process them individually
    if (pages.length > 1) {
        worker = worker.toPdf(); // Create the first page PDF
        // Append the rest of the pages
        for (let i = 1; i < pages.length; i++) {
            await worker
                .get('pdf')
                .then((pdf) => {
                    pdf.addPage(); // Add a new page
                })
                .from(pages[i]) // Add the content of the next page
                .toContainer()
                .toCanvas()
                .toPdf();
        }
    }

    // Save the PDF once all pages are processed
    await worker.save();
    this.downloadLoader = false;
},
  },
};
</script>

<style scoped>
.page-wrapper {
  position: relative;
}

.pdf-header img,
.pdf-footer img {
  max-width: 100%;
  height: auto;
}

.content {
  position: relative;
  z-index: 1;
}
.dots {
  flex: 1;
  margin: 0 8px;
  border-bottom: 2px dotted #212121;
}
.user-performance-report-progress-green::-webkit-progress-value {
  background-color: #06C270;
}
.user-performance-report-progress-orange::-webkit-progress-value {
  background-color: #FFB86B;
}
.user-performance-report-progress-green::-webkit-progress-bar, .user-performance-report-progress-orange::-webkit-progress-bar {
  background-color: #E0E0E0;
}
.primary-box-red{
    background-color: #D4002829 !important;
    border: 1px solid #D40028;
    color: #D40028 !important;
}
.primary-box-orange{
    background-color: #FB750029 !important;
    border: 1px solid #FB7500 !important;
    color: #FB7500 !important;
}
.primary-box-yellow{
    background-color: #FADC0029 !important;
    border: 1px solid #FADC00;
    color: #FADC00 !important;
}
.primary-box-green{
    background-color: #06C27029 !important;
    border: 1px solid #06C270 !important;
    color: #06C270 !important;

}
.secondary-box-red{
  background-color: #D4002829 !important;
}
.secondary-box-orange{
  background-color: #FB750029 !important;
}
.secondary-box-yellow{
  background-color: #FADC0029 !important;
}
.secondary-box-green{
  background-color: #06C27029 !important;
}
.core-skills-card-border {
  border: 4px solid #007C2A;
  border-radius: 8px;
  border-image: linear-gradient(261.89deg, #007C2A 0%, #029745 37.2%, #04AA58 62.29%, #05B866 81.08%, #06C270 94.9%) 1;
  border-image-slice: 1;
}
.digital-literacy-card-border {
  border: 4px solid #A72C5E;
  border-radius: 8px;
  border-image: linear-gradient(
    261.89deg,
    #A72C5E 0%,
    #BB4072 37.2%,
    #CF5486 62.29%,
    #D95E90 81.08%,
    #F77CAE 94.9%
  ); 
  border-image-slice: 1;
  border-radius: 8px;
}
.communication-skills-card-border {
  border: 4px solid #002298;
  border-radius: 8px;
  border-image: linear-gradient(
    261.89deg,
    #002298 0%,
    #0040B6 37.2%,
    #0054CA 62.29%,
    #075ED4 81.08%,
    #1B72E8 94.9%
  );
  border-image-slice: 1;
}
.pedagogy-card-border {
  border: 4px solid #622C74;
  border-radius: 8px;
  border-image: linear-gradient(
    261.89deg,
    #622C74 0%,
    #764088 37.2%,
    #8A549C 62.29%,
    #945EA6 81.08%,
    #B27CC4 94.9%
  );
  border-image-slice: 1;
}
.psychometry-card-border {
  border: 4px solid #AF5600;
  border-radius: 8px;
  border-image: linear-gradient(
    261.89deg,
    #AF5600 0%,
    #C36A0B 37.2%,
    #D77E1F 62.29%,
    #E18829 81.08%,
    #FFA647 94.9%
  );
  border-image-slice: 1;
  border-radius: 8px;
}
.core-skills-background {
  background-color: #06C27024 !important;
}
.pedagogy-background {
  background-color: #B27CC424 !important;
}
.digital-literacy-background {
  background-color: #F77CAE24 !important;
}
.communication-skills-background {
  background-color: #1B72E824 !important;
}
.psychometry-background {
  background-color: #FFA64724 !important;
}
@media print{
  .user-performance-report {
    width: 8.27in;
    height: auto;
    margin: 0;
    font-family: "GraphikRegular", sans-serif !important;
  }
  .shadow-fallback {
    border: 1px solid rgba(0, 0, 0, 0.12); /* Slight shadow effect */
    border-bottom: 2px solid rgba(0, 0, 0, 0.18); /* Enhance bottom shadow */
  }
}
.math-container {
  display: flex;
  flex-wrap: wrap;
}

.hide-it{
  position: absolute;
  top:-100000vh;

}
.repCard {
  margin: 80px 12px 0 32px;
}

.tabBtn {
  font-size: 14px;
  border-color: inherit !important;
  width: 100% !important;
  height : auto !important;
  padding: 10px !important;
  border-left-width: thin !important;
}

.viewTestProctoringBox {
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
}

.pdfTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 12px;
}

.pdfSubTitle {
  margin: 8px 12px;
}

.page-break-item {
  page-break-after: always;
  /* Add a page break before each v-list-item */
}
.pdf-item{
  page-break-inside: avoid;
}

.rowSpacing {
  margin-bottom: 12px;
}

.chipSpacing {
  margin-right: 8px;
}

.pdfCard {
  padding: 4px;
}

.correctOption {
  color: green;
  padding-right: 12px;
  margin-top: 4px;
}

.otherOption {
  padding-right: 12px;
  margin-top: 4px;
}

.correctAnswer {
  color: green;
  padding-left: 4px;
}

.incorrectAnswer {
  color: red;
  padding-left: 4px;
}

.pdfHead {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 8px 12px;
}

.g-8 {
  gap: 8px
}

.core-skills-container {
  .text-subtitle-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 174px;
    font-family: "GraphikMedium", sans-serif !important;
  }
}
.interviewRate, .interviewRate1, .interviewRate2, .interviewRate3, .interviewRate4, .interviewRate5, .interviewRate6, .interviewRate7, .interviewRate8, .interviewRate9, .interviewRate10 {
    margin: 0 16px;
    color: white !important;
}
.reportTableText{
    font-family: "GraphikRegular" !important;
    font-weight: 600 !important; 
}
.backgroundRed{
    background-color: #CD406E1A !important;
}
.backgroundOrange{
    background-color: #FEE3CC !important;
}
.backgroundYellow{
    background-color: #FEF9D6 !important;
}
.backgroundGreen{
    background-color: #EBFAF3 !important;
}
.fs-14{
    font-size: 14px; 
}
div[aria-label^="pdf-page-"] {
  margin-bottom: 20px;
  /* page-break-after: always; Ensure each div renders as a separate page in the PDF */
}
</style>